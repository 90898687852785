import { ThemeOptions, Theme, createTheme } from '@mui/material'
import { mergeThemes } from './merge'
import { deepmerge } from '@mui/utils'

export const list_theme: ThemeOptions = {
    components: {
        MuiListItemText: {
          styleOverrides: {
            primary: {
              color: '#515151',
              fontFamily: 'Kanit',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.00625rem',
            },
          },
        },
        MuiListItem:{
            styleOverrides:{
                root:{
                    padding:'4px 0'
                }
            }
        },
        MuiList:{
            styleOverrides:{
                root:{
                    width: 'fit-content',
                    margin: '0 auto',
                }
            }
        },
    }
}

const autocomplete_basetheme: ThemeOptions = {
    components:{
        MuiInputBase:{
            styleOverrides:{
                root:{
                    backgroundColor: 'white',
                },
                input:{
                    marginLeft:'10px',
                    fontSize:'16px'
                }
            }
        },
        MuiAutocomplete:{
            styleOverrides:{
                option:{
                    fontFamily: 'Kanit',
                    textAlign: 'left',
                    paddingLeft:'30px'
                },
                groupLabel:{
                    fontFamily: 'Kanit',
                    fontSize: '16px',
                    textAlign: 'left'
                },
                inputRoot:{
                    paddingTop: '5px',
                    paddingBottom: '5px'
                }
            }
        },
        MuiFormControl:{
            styleOverrides:{
                root:{
                    margin: '40px 0 4.9px 0'
                }
            }
        },
        MuiFormLabel:{
            styleOverrides:{
                root:{
                    marginLeft: '10px',
                    top: '-3px !important',
                    fontFamily: 'Kanit'
                }
            }
        },
        MuiOutlinedInput:{
            styleOverrides:{
                notchedOutline:{
                    borderRadius:'50px'
                },
                root:{
                    borderRadius: '50px',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#0182FA',
                    },
                }
            }
        }
    }
}

const autocomplete_dynamic_theme:  (theme: Theme) => ThemeOptions  = (theme: Theme) => ({
    components:{
        MuiOutlinedInput: {
            styleOverrides:{
                input:{
                    [theme.breakpoints.down('sm')]:{
                        fontSize: '14px'
                    }
                }
            }
        },
        MuiAutocomplete:{
            styleOverrides:{
                listbox:{
                    [theme.breakpoints.down('sm')]:{
                        fontSize: '14px',
                    }
                },
                option:{
                    [theme.breakpoints.down('sm')]:{
                        paddingTop: '1px',
                        paddingBottom:'1px'
                    }
                }
            }
        }
    }
})

export const autocomplete_theme = deepmerge(autocomplete_basetheme, autocomplete_dynamic_theme(createTheme({})))

export const select_theme: ThemeOptions = {
    components: {
        MuiFormLabel:{
            styleOverrides:{
                root:{
                    fontFamily: 'Kanit'
                }
            }
        },
        MuiMenuItem:{
            styleOverrides:{
                root:{
                    fontFamily: 'Kanit',
                    whiteSpace:'normal'
                }
            }
        },
        MuiMenu:{
            styleOverrides:{
                root:{
                    maxHeight: '400px'
                },
                list:{
                    padding: '0'
                }
            }
        },
        
        MuiSelect:{
            styleOverrides:{
                select:{
                    backgroundColor:'white'
                    
                    
                }
            }
        },
        MuiFormHelperText:{
            styleOverrides:{
                root:{
                        marginBottom:"10px",
                }
            }
        },
        MuiPopover:{
            styleOverrides:{
                paper:{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "0 0.5px 0.5px 0 solid #CBCBCB",
                    borderRadius: "0 12px", 
                    width: "min-content"
                }
            }
        }
      }
}

const card_theme: ThemeOptions = {
    components:{
        MuiCard:{
            styleOverrides:{
                root:{
                    border: '1px solid #CBCBCB',
                    borderRadius: '0.75rem',
                    paddingTop:'1.81rem',
                    paddingBottom:'26px',
                    boxShadow: 'none',
                    '&:hover':{
                        border:'1px solid #0182FA',
                        backgroundColor: '#F1F7FD'
                    }
                }
            }
        },

    }
    
}

export const registration_theme = mergeThemes(select_theme,list_theme, autocomplete_theme,card_theme)
export const non_card_theme = mergeThemes(select_theme,list_theme, autocomplete_theme)

export const grid_card_theme: ThemeOptions = {
    components:{
        MuiCard:{
            styleOverrides:{
                root:{
                    display: 'grid',
                    gridTemplateColumns: '8% 1fr 8%',
                    gridTemplateRows: '105px 137px 50px auto',
                    gridTemplateAreas:'"header header header" "list list list" ". . ." ". button-area ." ',
                    paddingTop: '41px',
                    paddingBottom: '26px',
                    paddingLeft: '2.5%',
                    paddingRight: '2.5%',
                    border: '1px solid #CBCBCB',
                    borderRadius: '0.75rem',
                    boxShadow: 'none',
                    '&:hover':{
                        border:'1px solid #0182FA',
                        backgroundColor: '#F1F7FD'
                    }
                }
            }
        },
        MuiFormControl:{
            styleOverrides:{
                root:{
                    margin: '0'
                }
            }
        },
    }
}

const card_breakpoint = (theme: Theme) => ({
    components:{
        MuiCard:{
            styleOverrides:{
                root:{
                    [theme.breakpoints.down('md')] : {
                        display:'flex',
                        gridTemplateColumns: 'none',
                        gridTemplateRows: 'none',
                        gridTemplateAreas:'none',
                        flexDirection: 'column',
                        alignItems:'center',
                        paddingLeft: '10%',
                        paddingRight: '10%'
                    },
                    [theme.breakpoints.down('sm')] : {
                        paddingTop: '36px',
                        paddingLeft: '3%',
                        paddingRight: '3%'
                    }
                }
            }
        },
        MuiListItemText:{
            styleOverrides:{
                primary:{
                    [theme.breakpoints.down('sm')] : {
                        fontSize:'15px'
                    }
                }
            }
        }
    }
})

export const new_card_theme = mergeThemes(grid_card_theme, card_breakpoint(createTheme({})))