import React, { useState, useEffect } from 'react'
import { 
    Grid, 
    Card, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    ThemeProvider, 
    createTheme,
    Box,
    Typography,
    Autocomplete,
    TextField,
    Container
 } from '@mui/material'
import { crown } from '../../assets/registration/index'
import TVLButton from '../../components/shared/TVLButton/TVLButton'
import { check } from '../../assets/registration'
import style from './Registration.module.css'
import { getOrg } from '../../services/data-connector'
import AlliancePopUp from './AlliancePopUp/AlliancePopUp'
import { Formik, FormikHelpers } from 'formik'
import ErrorElement from '../../components/shared/ErrorElement/ErrorElement'
import { mergeThemes } from '../../theme/merge'
import { typography_theme } from '../../theme/typography'
import { new_card_theme, non_card_theme } from '../../theme/registration'
import { textbox_theme } from '../../theme/textbox'
import RegistrationPopUp from '../../components/shared/RegistrationPopup/RegistrationPopUp'
import TextRequired from '../../components/shared/TextRequired/TextRequired'
import { Organization } from '../../interfaces/Register'
import ResponsePopUp from '../../components/shared/ResponsePopUp/ResponsePopUp'
import LogInPopUp from '../../components/shared/LogInPopUp/LogInPopUp'
import { useHistory } from 'react-router-dom'

const Registration = () => {
    const INITIAL_ALLIANCE = {
            orgName: '',
            orgAbb: '',
            orgEmails: [],
            orgType: 'สมาคม'
        } as Organization
    const [orgList, setOrgList] = useState<Organization[]>([INITIAL_ALLIANCE])
    const [allianceNameList, setAllianceNameList] = useState<string[]>([])
    // const [govOrgNameList, setGovOrgNameList] = useState<string[]>([])
    const [openUser,setOpenUser] = useState<boolean>(false)
    const [openAlliance,setOpenAlliance] = useState<boolean>(false)
    const [openAlliancePopUp, setOpenAlliancePopUp] = useState<boolean>(false)
    const [openLoginPopUp, setOpenLoginPopUp] = useState<boolean>(false)
    const [alliance, setAlliance] = useState<string>('')
    const [responseStatus, setResponseStatus] = useState<"" | "redirect">("")
    const [responsePopUp, setResponsePopUp] = useState<React.ReactNode>(<></>)
    const params = new URLSearchParams(window.location.search)
    let from = params.get('from')
    const history = useHistory()
        
    const mergedTheme = mergeThemes(textbox_theme,non_card_theme, new_card_theme,typography_theme)
    const theme = createTheme(mergedTheme)

    const validate = (values: {alliance: string | null}) => {
        let errors:{alliance?:string} = {};
      
        if (!values.alliance) {
            errors.alliance = 'กรุณาเลือกหน่วยงานเพื่อดำเนินการในขั้นถัดไป';
        } 
        return errors;
      };
     
    useEffect(() => {
        // fetchData
        const fetchData = async () => {
            const { success, res } = await getOrg()
            if(success){
                let orgListTmp: Organization[] = res["orgList"]
                orgListTmp.sort((a,b) => a.orgName.localeCompare(b.orgName,"th"))
                orgListTmp.push({
                    orgAbb: "other",
                    orgEmails: ['.go.th', '.or.th', '.ac.th', '.mi.th'],
                    orgName:'อื่น ๆ',
                    orgType: 'หน่วยงาน'
                  })
                setOrgList(orgListTmp)

                let allianceNameListTmp = orgListTmp
                .filter((orgTmp) => orgTmp.orgType === "สมาคม")
                .map((orgTmp) => orgTmp.orgName)
                setAllianceNameList(allianceNameListTmp)

            }
        }

        fetchData()

        //check if redirect from dashboard detail
        if(from && from === 'login'){
            setResponseStatus('redirect')
          }
    },[])


    const handleSubmit = (values: {alliance: string}, orgType: "สมาคม" | "หน่วยงาน", actions: FormikHelpers<{alliance:string}>) => {
        if(orgType === 'สมาคม'){
            let matchOrg = orgList.find((orgTmp) => orgTmp.orgName === values.alliance)
            if(matchOrg){
                setAlliance(matchOrg.orgAbb)
                setOpenAlliance(true)
            }
        } else {
            setOpenUser(true)
        }
        actions.setSubmitting(false)
    }
    const response_element = () => {
        if(responseStatus === ''){
          return <></>
        }
        else if(responseStatus === 'redirect'){
            return (
            <ResponsePopUp 
            open
            setOpen={() => {setResponseStatus('')}} 
            status='warning'
            title= 'ท่านยังไม่มีบัญชีกับเรา'
            text1='กรุณาสมัครสมาชิกเพื่อเข้าชมแดชบอร์ด'
            text2=''
            button={'ดำเนินการต่อ'}
            handleButton={() => {setResponseStatus('')}}/>
            )
        }
    }

    useEffect(() => {
        setResponsePopUp(response_element())
      },[responseStatus])



  return (
    <Container sx={{padding:{md: '0 18px !important', sm: '0 42px!important', xs: '0 23px !important'}}}>
        {responsePopUp}
        <div className={style.headerWrapper}>
            <div className={style.header}>ลงทะเบียน</div>
            <div className={style.subheader}>เลือกประเภทสมาชิกที่ต้องการลงทะเบียน</div>
        </div>
        <RegistrationPopUp open={openUser} setOpen={setOpenUser} setOpenLoginPopUp={setOpenLoginPopUp}/>
        {
            alliance ? 
            <RegistrationPopUp open={openAlliance} setOpen={setOpenAlliance} orgAbb = {alliance} setOpenLoginPopUp={setOpenLoginPopUp}/>
            : <></>
        }
        <AlliancePopUp open={openAlliancePopUp} setOpen={setOpenAlliancePopUp} allianceList={allianceNameList}/>
        <LogInPopUp open={openLoginPopUp} setOpen={setOpenLoginPopUp} redirect_uri='/'/>
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="space-around" spacing="22px" marginTop={{md: "40px", sm: '40px', xs: '0px'}} marginBottom="50px">
                <Grid xs={12} item sm={12} md={4}>
                    <Card sx={{height:'100%'}}>
                        <Box sx={{
                            gridArea: 'header',
                            justifySelf: 'center',
                            alignSelf: {md: 'flex-start', sm:'center', xs:'center'}
                        }}>
                            <div className={style.header}>บุคคลทั่วไป</div>
                            <div className={style.subheaderCard} style ={{marginBottom:'20px'}}>สิทธิพิเศษที่จะได้รับ</div>
                        </Box>
                        
                        <Box sx={{
                            gridArea: 'list',
                            justifySelf: 'center',
                            alignSelf: {md:'flex-end', sm:'center', xs:'center'}
                        }}>
                            <List dense>
                                <ListItem>
                                    <ListItemIcon>
                                        <img src={check} style={{width:'25.67px', height:'25.68px'}}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="การใช้งานแดชบอร์ดทั่วไป"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                    <img src={check} style={{width:'25.67px', height:'25.68px'}}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="เข้าถึงแค็ตตาล็อกข้อมูล"
                                    />
                                </ListItem>
                            </List>


                        </Box>
                        
                        
                        
                        <Box sx = {{
                            gridArea: 'button-area',
                            justifySelf: 'stretch',
                            alignSelf: {md: 'flex-start', sm:'center', xs:'center'},
                            paddingTop: {md: '61px', sm: '20px', xs: '20px'},
                            width: {md:"100%", sm: "calc(100% - 30px)", xs: "calc(100% - 30px)"}
                        }}>
                            <TVLButton size='small' fluid onClick = {() => setOpenUser(true)} sx={{fontWeight:'500'}}>ลงทะเบียนสมาชิกทั่วไป</TVLButton>
                        </Box>
                    </Card>
                </Grid>

                <Grid xs={12} item sm={12} md={4}>
                    <Card sx={{height:'100%'}}>
                        <Box sx={{
                            gridArea: 'header',
                            justifySelf: 'center',
                            alignSelf: {md:'flex-start', sm:'center', xs:'center'}
                        }}>
                            <div className={style.header}>ภาครัฐ</div>
                            <div className={style.subheaderCard} style ={{marginBottom:'20px'}}>ราชการ, องค์การมหาชน, รัฐวิสาหกิจ, มหาวิทยาลัยรัฐ</div>
                            
                        </Box>
                        
                        <Box sx={{
                            gridArea: 'list',
                            justifySelf: 'center',
                            alignSelf: {md:'flex-end', sm:'center', xs:'center'}
                        }}>
                            <List dense>
                                <ListItem>
                                    <ListItemIcon>
                                        <img src={crown} style={{width:'25.84px', height:'19.44px'}}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="การใช้งานแดชบอร์ดความร่วมมือ"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <img src={check} style={{width:'25.67px', height:'25.68px'}}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="การใช้งานแดชบอร์ดทั่วไป"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                    <img src={check} style={{width:'25.67px', height:'25.68px'}}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="เข้าถึงแค็ตตาล็อกข้อมูล"
                                    />
                                </ListItem>
                            </List>
                        </Box>
                        
                        
                        
                        <Box sx = {{
                            gridArea: 'button-area',
                            justifySelf: 'stretch',
                            height: '100%',
                            paddingTop: {md: '61px', sm: '20px', xs: '20px'},
                            width: {md:"100%", sm: "calc(100% - 30px)", xs: "calc(100% - 30px)"}
                        }}>
                            <TVLButton size='small' fluid sx={{fontWeight:'500'}} onClick={() => history.push('/register-member-mou')}>ลงทะเบียนสมาชิกภาครัฐ</TVLButton>
                            <div className={style.reminder}><TextRequired /> สมัครด้วยอีเมลองค์กรเท่านั้น</div>
                        </Box>
                    </Card>
                </Grid>

                <Grid xs={12} item sm={12} md={4}>
                    <Card sx={{height:'100%'}}>
                        <Box sx={{
                            gridArea: 'header',
                            justifySelf: 'center',
                            alignSelf: {md: 'flex-start', sm:'center', xs:'center'}
                        }}>
                            <div className={style.header}>เอกชนภายใต้ MOU</div>
                            <div className={style.subheaderCard} style ={{marginBottom:'20px'}}> 
                                <span onClick={() => setOpenAlliancePopUp(true)}><Typography variant='ahref' sx={{fontWeight: '400'}}>ตรวจสอบ</Typography></span>สมาชิกสมาคม, องค์กร, หน่วยงาน
                            </div>
                        </Box>
                        
                        <Box sx={{
                            gridArea: 'list',
                            justifySelf: 'center',
                            alignSelf: {md:'flex-end', sm:'center', xs:'center'}
                        }}>
                            <List dense>
                                <ListItem>
                                    <ListItemIcon>
                                        <img src={crown} style={{width:'25.84px', height:'19.44px'}}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="การใช้งานแดชบอร์ดความร่วมมือ"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <img src={check} style={{width:'25.67px', height:'25.68px'}}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="การใช้งานแดชบอร์ดทั่วไป"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                    <img src={check} style={{width:'25.67px', height:'25.68px'}}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="เข้าถึงแค็ตตาล็อกข้อมูล"
                                    />
                                </ListItem>
                            </List>
                        </Box>
                        
                        
                        
                        <Box sx = {{
                            gridArea: 'button-area',
                            justifySelf: 'stretch',
                            alignSelf: {md: 'flex-end', sm:'center', xs:'center'},
                            height: '100%',
                            paddingTop: {md: '0', sm: '20px', xs: '20px'},
                            width: {md:"100%", sm: "calc(100% - 30px)", xs: "calc(100% - 30px)"}
                        }}>
                            <Formik
                                initialValues={{alliance: ""}}
                                onSubmit={(values,actions) => handleSubmit(values, "สมาคม", actions)}
                                validate={validate}
                                >
                                    {({
                                        values,
                                        touched,
                                        errors,
                                        handleSubmit,
                                        setFieldValue
                                    }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: "flex-start",
                                            flexDirection: "column",
                                        }}>
                                            
                                            <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={values.alliance} 
                                            options={allianceNameList}
                                            onChange={(event: any, newValue: string | null) => {
                                                setFieldValue('alliance', newValue)
                                            }}
                                            fullWidth
                                            renderInput={(params) => 
                                                <TextField  
                                                {...params} 
                                                name="alliance" 
                                                label={<>เลือกองค์กรที่สังกัด<TextRequired /></>} 
                                                error={touched.alliance && !!errors.alliance}
                                                helperText={
                                                    touched.alliance && !!errors.alliance ?
                                                    <ErrorElement errorMessage={errors.alliance} /> : <></>
                                                }
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline legend':{
                                                        marginLeft: '10px'
                                                    }

                                                }}
                                                />}
                                            />
                                            

                                            
                                                <TVLButton size='small' fluid type='submit' sx={{fontWeight:'500'}}>ลงทะเบียนสมาชิกภายใต้ MOU</TVLButton>


                                        </Box>
                                        
                                    </form>
                                    )}

                                </Formik>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <Box className={style.text} sx={{marginTop:'40px', display: {md: 'block', sm: 'none', xs: 'none'}}}>
                <span className={style.textBold}>สนใจเข้าร่วม MOU เพื่อสิทธิพิเศษเพิ่มเติม ?</span> 
                สนใจเข้าร่วมเป็นหน่วยงาน MOU กับเราทั้งภาครัฐและเอกชน <Typography variant='ahref'>
                    <a href='/mou-register'>คลิกที่นี่</a>
                </Typography>
            </Box>

            <Box className={style.text} sx={{marginTop:'40px', display: {md: 'none', sm: 'block', xs: 'block'}}}>
                <span className={style.textBold}>สนใจเข้าร่วม MOU เพื่อสิทธิพิเศษเพิ่มเติม ?</span> 
                <br />
                สนใจเข้าร่วมเป็นหน่วยงาน MOU กับเราทั้งภาครัฐและเอกชน <Typography variant='ahref' sx={{whiteSpace: 'nowrap'}}>
                    <a href='/mou-register'>คลิกที่นี่</a>
                </Typography>
            </Box>
        </ThemeProvider>
        <Box sx={{height:'4rem'}}></Box>
        
    </Container>
  )
}

export default Registration