import { useEffect, useRef } from 'react';
import { register } from 'swiper/element/bundle';
export function Swiper(props) {
  const swiperRef = useRef(null);
  const {
    children,
    ...rest
  } = props;

  useEffect(() => {
    // Register Swiper web component
    register();

    // pass component props to parameters
    const params = {
      // These are new...
      injectStyles: [
        `
        swiper-slide {
          height: auto;
          align-self: stretch;
        }
        .swiper-horizontal{
          padding-bottom:20px;
        }
        .swiper-backface-hidden ::slotted(swiper-slide){
          display: flex;
          justify-content: center;
        }
        .swiper-horizontal > .swiper-pagination-bullets{
          bottom: var(--swiper-pagination-bottom,-4px);
        }
        .swiper {
          display: grid;
        }
        .swiper-wrapper{
          min-width: 0;
        }
        .swiper-button-prev, .swiper-button-next {
          color: #194B7E;
        }
        
        @media (width < 600px) {
          .swiper-button-next, .swiper-button-prev {
            width: 10px;
          }
        }
      `,
      ],
      ...rest
    };

    // Assign it to swiper element
    Object.assign(swiperRef.current, params);

    // initialize swiper
    swiperRef.current.initialize();
  }, []);

  return (
    <swiper-container init="false" ref={swiperRef}>
        {children}
    </swiper-container>
  );
}
export function SwiperSlide(props) {
  const {
    children,
    ...rest
  } = props;

  return (
    <swiper-slide {...rest}>
      {children}
    </swiper-slide>
  );
}
