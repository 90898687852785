import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import { useTitle } from "react-use"
import EmailVerificationModule from "./EmailVerification.module.css"
import { verify_email } from "../../services/data-connector"
import { useParams } from "react-router-dom"
import Loading from "../Loading/Loading"
import { checkCircle, cancel } from "../../assets/login"
import AuthenFrame from "../../components/shared/AuthenFrame/AuthenFrame"
import TVLButton from "../../components/shared/TVLButton/TVLButton"
import LogInPopUp from "../../components/shared/LogInPopUp/LogInPopUp"


function EmailVerification() {
    useTitle("Travel Link | Email Verification")
    const { id } = useParams<{id: string}>()
    const [status, setStatus] = useState<boolean | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [openLoginPopUp, setOpenLoginPopUp] = useState<boolean>(false)

    useEffect(() => {
        // setIsLoading(true)
        verify_email(id).then(({ success, res }) => {
            setStatus(success)
            setIsLoading(false)

        })
    }, [])

    const handleLoginPopUp = () => {
        setOpenLoginPopUp(true)
    }

    return (
        <AuthenFrame>
            <>
                <LogInPopUp open={openLoginPopUp} setOpen={setOpenLoginPopUp} redirect_uri='/'/>
                <Grid item xs = {12} sm = {6} md = {6}>
                    <div className={EmailVerificationModule.textHeader}>ยืนยันตัวตน</div>
                    
                    {
                        isLoading ? <Loading /> 
                        :
                        status ?
                        <div className={EmailVerificationModule.text}>
                            <div style = {{display: 'flex', alignItems: 'center'}}>
                                <img src = {checkCircle} className= {EmailVerificationModule.sign}></img>
                                ท่านได้ยืนยันตัวตนเรียบร้อยแล้ว
                                <br />
                                ท่านสามารถเข้าสู่ระบบได้ทันที
                            </div>
                            <br />
                            
                            <TVLButton fluid size='large' lean onClick={handleLoginPopUp}>เข้าสู่ระบบ</TVLButton>
                        </div>
                        :
                        <div className={EmailVerificationModule.text}>
                            <div style = {{display: 'flex', alignItems: 'center'}}>
                                <img src = {cancel} className= {EmailVerificationModule.sign}></img>
                                รหัสการยืนยันตัวตนของท่านไม่ถูกต้อง
                            </div>
                            <br />
                            <a href = '/'><TVLButton fluid size='large' lean>กลับสู่หน้าหลัก</TVLButton></a>
                        </div>
                        
                    }
                </Grid>
            </>

        </AuthenFrame>
    )
}

export default EmailVerification
