import { DashboardType, DashboardTypeThai, DataType, DataTypeThai, DataUpdateCycle, DataUpdateCycleThai, InfographicType, InfographicTypeThai, PortType, PortTypeThai, Sorting, SortingEng } from "../interfaces/FilterTypes";

export const dataUpdateCycleItems: {thai: DataUpdateCycleThai, english: DataUpdateCycle}[] = [
    {
        thai: "รายงานสด",
        english: 'live'
    },
    {
        thai: "รายวัน",
        english: "daily"
    },
    {
        thai: "รายสัปดาห์",
        english: "weekly"
    },
    {
        thai: "รายเดือน",
        english: "monthly"
    },
    {
        thai: "รายไตรมาส",
        english: "quarterly"
    }
]

export const infographicTypeItems: {thai: InfographicTypeThai, english: InfographicType}[] = [
    {
        thai: "อินโฟกราฟิกทั้งหมด",
        english: "all"
    },
    {
        thai: "อินโฟกราฟิกพิเศษ",
        english: 'privileged'
    },
    {
        thai: "อินโฟกราฟิกทั่วไป",
        english: "public"
    },
    {
        thai: "อินโฟกราฟิกที่ติดตาม",
        english: "bookmarked"
    }
]

export const dashboardTypeItems: {thai: DashboardTypeThai, english: DashboardType}[] = [
    {
        thai: "แดชบอร์ดทั้งหมด",
        english: "all"
    },
    {
        thai: "แดชบอร์ดพิเศษ",
        english: "privileged"
    },
    {
        thai: "แดชบอร์ดทั่วไป",
        english: "public"
    },
    {
        thai: "แดชบอร์ดที่ติดตาม",
        english: "bookmarked"
    }
]

export const dataTypeItems: {thai:  DataTypeThai, english: DataType}[] = [
    {
        thai: "รายด่าน",
        english: "port"
    },
    {
        thai: "รายจังหวัด",
        english: "province"
    },
    {
        thai: "ภาพรวมประเทศ",
        english:"overall"
    }
]

export const portTypeItems: {thai: PortTypeThai, english: PortType}[] = [
    {
        thai: "ด่านทั้งหมด",
        english: "all"
    },
    {
        thai: "ด่านบก",
        english: "land"
    },
    {
        thai: "ด่านน้ำ",
        english: "sea"
    },
    {
        thai: "ด่านอากาศ",
        english: "air"
    }
]

export const sortingTypeItems: {thai: Sorting, english: SortingEng}[] = [
    {
        thai:"ความนิยม",
        english: 'popularity'
    },
    {
        thai:"ตัวอักษร ก-ฮ",
        english: 'alphabetical'
    },
    {
        thai:"ตัวอักษร ฮ-ก",
        english: 'reverse-alphabetical'
    },
    {
        thai:"ใหม่",
        english: 'new'
    }
]
