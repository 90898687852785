import React from 'react'
import { useTitle } from 'react-use';
import { Grid } from '@mui/material'

import PoliciesModule from './Policies.module.css'
import PolicySelectionTab from './PolicySelectionTab/PolicySelectionTab'
import CustomContainer from '../../components/shared/CustomContainer/CustomContainer';

function Policies() {
    useTitle('Travel Link | Policies');
    return (
        <CustomContainer>
            <div className = {PoliciesModule.wrapper}>
                <Grid container>
                    <Grid md = {2} sx = {{display:{xs: 'none', md: 'flex'}}}>

                    </Grid>
                    <Grid xs = {12} md = {10}>
                        <h1 className={PoliciesModule.title}>Terms & Policies</h1>
                    </Grid>
                </Grid>
                <PolicySelectionTab />
            </div>
            
        </CustomContainer>
    )
}

export default Policies
