import React from 'react'
import {
    Box
} from '@mui/material'
import DynamicSearchSelector from '../DynamicSearchSelector/DynamicSearchSelector'
import DynamicSelector from '../DynamicSelector/DynamicSelector'

interface SelectorMobildElementProps{
    selectorName: string,
    placeholder: string
    value: string,
    setValue: React.Dispatch<string>,
    dynamicList: string[]
    searchable?: boolean
}

const SelectorMobileElement = ({selectorName, placeholder, value, setValue, dynamicList, searchable}: SelectorMobildElementProps) => {
  return (
    <Box marginBottom={1.25}>
    <Box textAlign='left' display='flex' justifyContent='flex-start' alignItems='center' marginBottom={0.75}>
      <Box width={150}>{selectorName}</Box>
      <Box width={150} display={{md:'flex', sm:'flex', xs:'none'}}>
        {
            searchable ? 
            <DynamicSearchSelector 
            noMaxWidth
            selectorName={placeholder}
            value={value}
            setValue={setValue}
            dynamicList={dynamicList} />
            :

            <DynamicSelector 
            selectorName={placeholder}
            value={value}
            setValue={setValue}
            dynamicList={dynamicList} />
        }
        
      </Box>
    </Box>
    <Box display={{md:'none', sm:'none', xs:'flex'}}>
    {
            searchable ? 
            <DynamicSearchSelector 
            fullWidth
            selectorName={placeholder}
            value={value}
            setValue={setValue}
            dynamicList={dynamicList} />
            :
            
            <DynamicSelector 
            fullWidth
            selectorName={placeholder}
            value={value}
            setValue={setValue}
            dynamicList={dynamicList} />
        }
      </Box>
    </Box>
  )
}

export default SelectorMobileElement