import React, { useState, useEffect, useCallback } from 'react'
import { DashboardGalleryCard } from '../../../interfaces/DashboardMetadata'
import { categoricalMetadataType, sortDashboardMetadataByCategory } from '../../../utils/dashboard'
import DashboardRow from './DashboardRow/DashboardRow'
import { DashboardCategoryThai, DashboardTag } from '../../../interfaces/DashboardTag'
import { DashboardTypeThai, DataTypeThai, DataUpdateCycleThai, PortTypeThai } from '../../../interfaces/FilterTypes'
import { ProvinceThai } from '../../../interfaces/Province'
import { SnackbarMessage } from '../../../interfaces/Snackbar'
import DashboardRowSkeleton from './DashboardRowSkeleton/DashboardRowSkeleton'
import { useHistory } from 'react-router-dom'
import style from '../Dashboard.module.css'
import { padded_dashboard_card_theme } from '../../../theme/dashboard'
import { ThemeProvider, createTheme } from '@mui/material'

const dashboard_tags: DashboardTag[] = require('../../../variable/dashboard_tag.json')

interface DashboardRowsProps{
    isLoading: boolean
    dashboardMetadatas: DashboardGalleryCard[]
    searchText: string,
    activeTag?: string,
    dashboardType?: string,
    dataUpdateCycle?: string,
    dataType?: string,
    portType?: string,
    province?: string,
    portName?: string,
    setActiveTag: React.Dispatch<DashboardCategoryThai>,
    setOpenSnackBar: React.Dispatch<boolean>,
    setSnackPack: React.Dispatch<React.SetStateAction<readonly SnackbarMessage[]>>,
    handleLoginPopUp: (link: string) => void
    handleLoginPopUpFromBookmark: () => void
}

const DashboardRows = ({
    isLoading,
    dashboardMetadatas,
    searchText,
    activeTag,
    dashboardType,
    dataUpdateCycle,
    dataType,
    portType,
    province,
    portName,
    setActiveTag,
    setOpenSnackBar,
    setSnackPack,
    handleLoginPopUp,
    handleLoginPopUpFromBookmark
}: DashboardRowsProps) => {
    const [categoricalDashboardMetadatas, setCategoricalDashboardMetadatas] = useState<categoricalMetadataType[]>(sortDashboardMetadataByCategory(dashboardMetadatas))

    useEffect(() => {
        setCategoricalDashboardMetadatas(sortDashboardMetadataByCategory(dashboardMetadatas))
    },[dashboardMetadatas])

    const history = useHistory()

    const handleSetActiveTag = useCallback((activeTag: "แดชบอร์ดที่ติดตาม" | DashboardCategoryThai) => {
        if(activeTag !== "แดชบอร์ดที่ติดตาม") {
            let matchedCategory = dashboard_tags.find(({category_thai}) => category_thai === activeTag)
            history.push({
                pathname: '/dashboard',
                state:{
                    category: "dashboard"
                },
                search: matchedCategory ? "?category="+matchedCategory.category_eng : ""
            })
            setActiveTag(activeTag)
        }
        const searchElement = document.querySelector('.MuiInputBase-root')    
        searchElement?.scrollIntoView({behavior:'smooth',block:'center'})
    },[history, dashboard_tags, setActiveTag])

    const theme = createTheme(padded_dashboard_card_theme)

  return (
    <>
    {
        isLoading ?
        <DashboardRowSkeleton />
        :
        categoricalDashboardMetadatas.length > 0 ?
        <ThemeProvider theme={theme}>
            {
                categoricalDashboardMetadatas.map((categoricalDashboardMetadata) => 
                (
                    <DashboardRow
                    key={categoricalDashboardMetadata.category}
                    categoricalDashboardMetadata={categoricalDashboardMetadata}
                    searchText={searchText}
                    activeTag={activeTag}
                    dashboardType={dashboardType}
                    dataUpdateCycle={dataUpdateCycle}
                    dataType={dataType}
                    portType={portType}
                    province={province}
                    portName={portName}
                    setActiveTag={() => handleSetActiveTag(categoricalDashboardMetadata.category)}
                    isBookmarkRow={categoricalDashboardMetadata.category === "แดชบอร์ดที่ติดตาม"}
                    setOpenSnackBar={setOpenSnackBar}
                    setSnackPack={setSnackPack}
                    handleLoginPopUp={handleLoginPopUp}
                    handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                    />
                )
                
                )
            }

        </ThemeProvider>
        
        :
        <div className={style.notFound}>
            ไม่พบแดชบอร์ดที่ท่านค้นหา
        </div>
    }
    
    
    </>
  )
}

export default DashboardRows