import React from "react"
import { Grid, createTheme, ThemeProvider } from "@mui/material"

import commentator from "../../../assets/mission/Pic/comment.png"

import CommentModule from "./Comment.module.css"

function Comment() {
    const breakPointTheme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 700,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    })
    return (
        <div className={CommentModule.wrapper}>
            <p className={CommentModule.header}>ความเห็นจากผู้เกี่ยวข้อง</p>
            <div className={CommentModule.textbox}>
                <ThemeProvider theme={breakPointTheme}>
                    <Grid container>
                        <Grid xs={0} sm={1} md={1} />
                        <Grid xs={12} sm={3} md={3}>
                            <img
                                src={commentator}
                                className={CommentModule.commentator_img}
                            />
                            <p className={CommentModule.commentator_name}>
                                รศ.ดร.ธีรณี{" "}
                                <span style={{ whiteSpace: "nowrap" }}>
                                    อจลากุล
                                </span>
                            </p>
                            <p className={CommentModule.commentator_position}>
                                ผู้อำนวยการสถาบันข้อมูลขนาดใหญ่ (องค์การมหาชน)
                            </p>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={1}
                            md={1}
                            sx={{ height: { xs: "20px", md: "0" } }}
                        />
                        <Grid
                            xs={12}
                            sm={6}
                            md={6}
                            className={CommentModule.comment_text}
                        >
                            "การบริหารจัดการสถานการณ์โดยไม่ใช้ข้อมูลเพื่อวิเคราะห์ก็เหมือนการขับเครื่องบินโดยไม่ใช้เซนเซอร์
                            การจะบินด้วยตาเปล่าอาจจะไม่เหมาะกับเรื่องใหญ่ ๆ
                            อย่างการฟื้นฟูอุตสาหกรรมการท่องเที่ยวของไทย Travel
                            Link
                            ถือเป็นความพยายามสำคัญที่จะสร้างให้เกิดการบูรณาการและใช้ประโยชน์ข้อมูลจากรัฐและเอกชนอย่างเต็มที่"
                        </Grid>
                        <Grid xs={0} sm={1} md={1} />
                    </Grid>
                </ThemeProvider>
            </div>
        </div>
    )
}

export default Comment
