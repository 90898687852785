import { Theme, ThemeOptions, createTheme } from "@mui/material"
import { mergeThemes } from "./merge"

const dashboardShowCaseBaseTheme: ThemeOptions = {
    components:{
        MuiCard:{
            styleOverrides:{
                root:{
                    borderRadius: "12px",
                    border: "1px solid var(--Colors-color-grey-light, #CBCBCB)",
                    background: "#FFF",
                    boxShadow: 'none',
                    cursor: "pointer",
                    "&:hover": {
                        boxShadow: "2px 19px 21px -8px rgba(0, 0, 0, 0.25)",

                    }
                }
            }
        },
        MuiCardContent:{
            styleOverrides:{
                root:{
                    color: "var(--Colors-color-blue-mid, #194B7E)",
                    textAlign: "center",
                    fontFamily: "Kanit",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                    padding:"12px 12px 15px",
                    height: '75px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }
            }
        }
    }
}

const dashboardBreakPointTheme = (theme: Theme) => ({
    components:{
        MuiCardContent:{
            styleOverrides:{
                root:{
                    [theme.breakpoints.down('sm')]:{
                        fontSize: '14px',
                        padding:"5px 5px 7px",
                        height: '50px',
                    }
                }
            }
        }
    }
})

export const dashboardShowCaseTheme = mergeThemes(dashboardBreakPointTheme(createTheme({})), dashboardShowCaseBaseTheme)

const infographicShowCaseBaseTheme = createTheme({
    components:{
        MuiCard:{
            styleOverrides:{
                root:{
                    borderRadius: "12px",
                    border: "1px solid #CBCBCB",
                    boxShadow: "none",
                    background: "#FFF",
                    "&:hover": {
                        boxShadow: "2px 19px 21px -8px rgba(0, 0, 0, 0.25)",

                    }
                    
                }
            }
        },
        MuiCardContent:{
            styleOverrides:{
                root:{
                    color: "var(--Colors-color-blue-mid, #194B7E)",
                    textAlign: "center",
                    fontFamily: "Kanit",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                    padding:"12px 12px 7px",
                }
            }
        }
    }
})
const infographicBreakPointTheme = (theme: Theme) => ({
    components:{
        MuiCardContent:{
            styleOverrides:{
                root:{
                    [theme.breakpoints.down('md')]:{
                        fontSize: '14px',
                        padding:"5px",
                        height: '47px'
                    }
                }
            }
        }
    }
})
export const infographicShowCaseTheme = mergeThemes(infographicBreakPointTheme(createTheme({})), infographicShowCaseBaseTheme)


const productListBaseTheme = createTheme({
    breakpoints:{
        values:{
            xs: 0,
            sm: 700,
            md: 900,
            lg: 1200,
            xl: 1536,
        }
    },
    components:{
        MuiListItemText:{
            styleOverrides:{
                primary:{
                    color: "#0182FA",
                    fontFamily: "Kanit",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "150%"
                }
            }
        },
        MuiListItemIcon:{
            styleOverrides:{
                root:{
                    display:'flex',
                    justifyContent: 'center',
                    width: '50px',
                    marginTop: '9px'
                }
            }
        },
        MuiList:{
            styleOverrides:{
                root:{
                    width:'fit-content',
                    margin: '0 auto',

                }
            }
        },
        MuiListItem: {
            styleOverrides:{
                root:{
                    alignItems: 'flex-start'
                }
            }
        }
    }
  })

  const productListBreakPointTheme = (theme: Theme) => ({
    components:{
        MuiListItemText:{
            styleOverrides:{
                primary:{
                    [theme.breakpoints.down('md')] : {
                        fontSize: '15px',
                        lineHeight: '22.5px'
                    }
                }
            }
        },
        MuiListItemIcon:{
            styleOverrides:{
                root:{
                    [theme.breakpoints.down('sm')]:{
                        marginTop: '6px'

                    }
                }
            }
        },
        MuiList:{
            styleOverrides:{
                root:{
                    [theme.breakpoints.down('sm')] : {
                        float: 'left',
                        marginLeft: 'calc(35vw - 150px)',
                    }
                }
            }
        }
    }
})
export const productListTheme = mergeThemes(productListBaseTheme, productListBreakPointTheme(productListBaseTheme))