export interface ChangePasswordForm {
    password1: string,
    password2: string
}

export interface ChangePasswordValidation {
    password1?: string,
    password2?: string
}

export const changePW_validate = (values: ChangePasswordForm) => {
    let errors: ChangePasswordValidation = {}

    if (!values.password1) {
        errors.password1 = "กรุณากรอกรหัสผ่านใหม่"
    } else if (values.password1.length < 8){
        errors.password1 = "กรุณากรอกรหัสผ่านใหม่ที่ยาวไม่ต่ำกว่า 8 ตัวอักษร"
    }

    if (!values.password2) {
        errors.password2 = "กรุณายืนยันรหัสผ่านใหม่"
    } else if (values.password1 !== values.password2){
        errors.password2 = "กรุณากรอกรหัสผ่านใหม่ให้ตรงกัน"
    }
    return errors
}