import React, { useState, useEffect } from "react"
import {
  Box
} from '@mui/material'
import { useTitle } from "react-use"
import Partner from "./Partner/Partner"
import ResponsePopUp from "../../components/shared/ResponsePopUp/ResponsePopUp"
import Cookies from "js-cookie"

import style from './Home.module.css'
import ProductList from "./ProductList/ProductList"
import DashboardShowCase from "./DashboardShowCase/DashboardShowCase"
import InfographicShowCase from "./InfographicShowCase/InfographicShowCase"
import DataCatalogHome from "./DataCatalogHome/DataCatalogHome"
import Welcome from "./Welcome/Welcome"

function Home() {
    useTitle("Travel Link | Home")

    // get user info from localStorage

    const [responseStatus, setResponseStatus] = useState<string>('')
    const [response, setResponse] = useState<React.ReactNode>(<></>)
    const params = new URLSearchParams(window.location.search)
    let from = params.get('from')
    const handlePopUpClose = () => {
        setResponseStatus('')
    }

    const handleReRegister = () => {
      Cookies.remove('token')
      window.location.href = '/registration'
    }

    const responseElement = () => {
        if(responseStatus === ''){
          return <></>
        } else if(responseStatus === 'failedRegister'){
          return (
              <ResponsePopUp 
              open
              setOpen={handlePopUpClose} 
              status='warning'
              title= "เหมือนว่าท่านมีบัญชีกับเราอยู่แล้ว!"
              text1= {<>
              หากท่านกลับสู่หน้าหลัก ท่านจะเข้าสู่ระบบด้วยบัญชีนี้
              <br />
              หากท่านต้องการสมัครสมาชิกใหม่อีกครั้ง กรุณาคลิก<span className={style.ahref} onClick={handleReRegister}>ที่นี่</span></>}
              text2= ''
              button='กลับสู่หน้าหลัก'
              handleButton={handlePopUpClose}/>
            
          )
        }
      }
    useEffect(() => {
        if(from && from === 'register'){
          setResponseStatus('failedRegister')
        }
    }, [])

    useEffect(() => {
        setResponse(responseElement())
    }, [responseStatus])

    return (
        <Box className={style.home}>
            {response}
            <Welcome />
            <ProductList />
            <DashboardShowCase />
            <InfographicShowCase />
            <DataCatalogHome />
            <Partner />
        </Box>
    )
}

export default Home
