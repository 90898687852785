import React from "react"

import FooterProductModule from "./FooterProduct.module.css"
import { Box } from "@mui/material"

const FooterProduct: React.FC = () => {
    return (
        <div className={FooterProductModule.content}>
            <Box className={FooterProductModule.header} marginBottom="22px">ผลิตภัณฑ์</Box>
            <ul className={FooterProductModule.nobull}>
                <li className={FooterProductModule.link}>
                    <a
                        className={FooterProductModule.linktext}
                        href="/dashboard"
                    >
                        แดชบอร์ดท่องเที่ยว
                    </a>
                </li>
                <li className={FooterProductModule.link}>
                    <a
                        className={FooterProductModule.linktext}
                        href="/infographic"
                    >
                        อินโฟกราฟิก
                    </a>
                </li>
                <li className={FooterProductModule.link}>
                    <a
                        className={FooterProductModule.linktext}
                        href="https://catalog.travellink.go.th/"
                        target="_blank"
                    >
                        แค็ตตาล็อกข้อมูล
                    </a>
                </li>
                {/* <li className={FooterProductModule.link}><a className={FooterProductModule.linktext} href='/search-travel'>บริการค้นหาสถานที่ท่องเที่ยว</a></li>
                <li className={FooterProductModule.link}><a className={FooterProductModule.linktext} href='/recommendation'>บริการแนะนำสถานที่ท่องเที่ยว</a></li> */}
                {/* <li className={FooterProductModule.link}><a className={FooterProductModule.linktext} onClick={() => window.location.href = "/#attraction-data"} >แหล่งรวมข้อมูลสถานที่ท่องเที่ยว</a></li> */}
            </ul>
        </div>
    )
}

export default FooterProduct
