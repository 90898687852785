import { ThemeOptions } from "@mui/material";

export const tertiary_button_theme: ThemeOptions = {
    palette:{
        primary:{
            main: '#000000'
        }
    },
    typography:{
        button:{
          fontFamily:'Kanit',
          textTransform: 'none',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '150%', /* 1.3125rem */
          letterSpacing: '0.00438rem'
  
        }
    },
    components:{
        MuiButton:{
            styleOverrides:{
                root:{
                    padding: '12px 24px',
                    fontSize: '14px',
                    backgroundColor:'white',
                    borderRadius: '0.5rem',
                    width: '100%',
                    border: '1px solid #C7C9D9'
                }
            }
        }
    }
}