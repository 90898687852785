import React, { useEffect, useState } from 'react'
import {
    Card,
    Grid,
    TextField,
    Autocomplete
} from '@mui/material'
import TextRequired from '../../../components/shared/TextRequired/TextRequired'
import ErrorElement from '../../../components/shared/ErrorElement/ErrorElement'
import style from '../RegisterMemberAllianceTVL.module.css'
import { useFormikContext, FormikErrors, FormikTouched } from 'formik'
import { RegisterTvlAlliance } from '../../../validation/register'
import delete_icon from '../../../assets/register-member/delete.svg';
import { Organization, Questionaire } from '../../../interfaces/Register'


interface DynamicFieldProps {
  index: number;
  orgList: Organization[]
  questionaires: Questionaire[]
  cardValues: {
    orgAbb: string,
    organizationQuestionaires: Array<{
        questionName: string
        questionId: string
        answer: string
    }>
  }
}

const cardValidation = (cardValues: {
  orgAbb: string,
  organizationQuestionaires: Array<{
      questionName: string
      questionId: string
      answer: string
  }>
}) => {
  let orgErrors: {
    orgAbb?: string;
    organizationQuestionaires?: Array<{
      answer?: string
    }>
  } = {};

  // Perform your validation for each field here
  if (!cardValues.orgAbb) {
      orgErrors.orgAbb = 'โปรดเลือกสมาคมที่ต้องการ';
  }

  let organizationQuestionaireErrors: {answer?: string}[] = []
  if(cardValues.organizationQuestionaires){
    cardValues.organizationQuestionaires.forEach(({answer}, questionIndex) => {
          let questionError: {answer?: string} = {}
          if(!answer){
              questionError.answer = "โปรดตอบคำถาม"
          }
          
          organizationQuestionaireErrors[questionIndex] = {...questionError}
      })
  }
  orgErrors.organizationQuestionaires = organizationQuestionaireErrors
  return orgErrors
}

const AllianceCard = ({ index, cardValues, orgList, questionaires }: DynamicFieldProps) => {
    const {values, handleBlur, setValues, setTouched, errors, touched, setFieldValue} = useFormikContext<RegisterTvlAlliance>()
    const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null) 
  // Access the field and its errors using the key and index
  let allianceList = values.privileges
  let allianceListTouched = touched.privileges 
  let newValues = allianceList[index];
  const [newErrors,setNewErrors] = useState(cardValidation(cardValues))
  const [newTouched, setNewTouched] = useState(touched.privileges![index]);

      const org_options = orgList.filter(({orgAbb}) => !allianceList.map(({orgAbb}) => orgAbb).includes(orgAbb))


      const handleChangeAnswer = (questionIndex: number) => (
        e: React.ChangeEvent<HTMLInputElement>
      ) => {
        let newValuesTmp = {...newValues}
        newValuesTmp['organizationQuestionaires'][questionIndex]!['answer'] = e.target.value
        let allianceListTmp = [...allianceList]
        allianceListTmp[index] = newValuesTmp
        setValues({...values, privileges:allianceListTmp});
      };

      const handleChangeAlliance =  (
          e: React.ChangeEvent<HTMLInputElement>
        ) => {
          newValues['orgAbb'] = e.target.value
          allianceList[index] = newValues
          setValues({...values, privileges:allianceList});
        };

        const handleNestedFieldBlurAlliance = () => {
            let updatedTouched = { ...touched }; // Create a shallow copy of values
            // Update the nested field within the structure
            updatedTouched.privileges![index]['orgAbb'] = true;
            setNewTouched({...newTouched, orgAbb: true})
            // Set the updated values in Formik
            setTouched({...touched, privileges: updatedTouched.privileges});
          };

          const handleNestedFieldBlur = (questionIndex: number) => {
            let updatedTouched = { ...touched }; // Create a shallow copy of values
          
            // Ensure that privileges and organizationQuestionaires are defined before updating
            if (
              updatedTouched.privileges &&
              updatedTouched.privileges[index] &&
              updatedTouched.privileges[index]['organizationQuestionaires'] &&
              updatedTouched.privileges[index]['organizationQuestionaires']![questionIndex]
            ) {
              // Update the nested field within the structure
              updatedTouched.privileges[index]['organizationQuestionaires']![questionIndex]['answer'] = true;
          
              // Update newTouched
              newTouched['organizationQuestionaires']![questionIndex]['answer'] = true;
              setNewTouched({
                ...newTouched,
                organizationQuestionaires: newTouched['organizationQuestionaires'],
              });
          
              // Set the updated values in Formik
              setTouched({ ...touched, privileges: updatedTouched.privileges });
            }
          };

      const handleChooseAlliance = (e: any, newValue: Organization | null) => {
          if(newValue){
          setSelectedOrg(newValue)
          let selectedQuestionList = questionaires.filter(({orgID}) => orgID === newValue.orgAbb)
          let newValuesTmp = {
            orgAbb: newValue.orgAbb,
            organizationQuestionaires: [] as { questionId: string; questionName: string; answer: string; }[]
          }
          newValuesTmp['organizationQuestionaires'] = selectedQuestionList.map(({id, name}) => ({
            questionId: id,
            questionName: name,
            answer: ''
          }))
          let allianceListTmp = [...allianceList]
          allianceListTmp[index] = newValuesTmp
          setFieldValue('privileges',allianceListTmp);
          let newTouched: FormikTouched<{ orgAbb: string; organizationQuestionaires: { questionName: string; questionId: string; answer: string; }[]; }> =  allianceListTouched![index]
          //set touch
          newTouched.organizationQuestionaires = selectedQuestionList.map(() => ({
            answer: false
          }))
          allianceListTouched![index] = newTouched
          setTouched({...touched, privileges:allianceListTouched})

        }
        else{
          let newValuesTmp = {...newValues}
          newValuesTmp['orgAbb'] = ''
          newValuesTmp['organizationQuestionaires'] = []
          let allianceListTmp = [...allianceList]
          allianceListTmp[index] = newValuesTmp
          setValues({...values, privileges:allianceListTmp});
          setSelectedOrg(null)

        }
        }
        
        const handleDeletingCard = () => {
          let newList = allianceList.filter((value, i) => i !== index)
          setValues({...values, privileges:newList})
          let newTouched = touched.privileges!.filter((value,i) => i !== index)
          setTouched({...touched, privileges: newTouched})
        }
        

        useEffect(() => {
          let orgTmp = orgList.find(({orgAbb}) => orgAbb === cardValues.orgAbb)
          if(orgTmp){
            setSelectedOrg(orgTmp)
            let selectedQuestionList = questionaires.filter(({orgID}) => orgID === orgTmp!.orgAbb)
            let newTouched: any =  allianceListTouched![index]
            //set touch
            newTouched.organizationQuestionaires = selectedQuestionList.map(() => ({
              answer: false
            }))
            allianceListTouched![index] = newTouched
            setNewTouched(newTouched)
            setTouched({...touched, privileges:allianceListTouched})
          }    
          setNewErrors(cardValidation(cardValues))
        },[orgList, cardValues])

        useEffect(() => {
          setNewTouched(touched.privileges![index])
        },[touched.privileges![index]])

  const isError = (questionIndex: number) => {
    let isError = errors.privileges && (errors as any).privileges[index].organizationQuestionaires
    && !!(errors as any).privileges[index].organizationQuestionaires[questionIndex] 
    && !!(errors as any).privileges[index].organizationQuestionaires[questionIndex].answer
    && touched.privileges 
    && touched.privileges[index].organizationQuestionaires![questionIndex]
    && touched.privileges[index].organizationQuestionaires![questionIndex].answer

    return isError
      
  }
  return (
      <Card sx={{paddingLeft:{md:'60px'}, paddingRight:{md:'60px'}, marginTop:{md: '45px',':first-child':'0px'}}}>

        <Grid container rowSpacing={1}>
        <Grid item md={4} sm = {4} xs={12}>
            <div className={style.label}>ชื่อสมาคม<TextRequired /></div>
        </Grid>
        <Grid item md={8} sm = {8} xs={12}>
          {
            index === 0 ?
            <TextField 
              name="alliance"
              value={selectedOrg?.orgName}
              onChange = {handleChangeAlliance}
              onBlur = {handleNestedFieldBlurAlliance}
              error={!!newErrors.orgAbb && newTouched.orgAbb}
              helperText={!!newErrors.orgAbb && newTouched.orgAbb ? <ErrorElement errorMessage={newErrors.orgAbb} />: <></>}    
              placeholder='สมาคม'
              fullWidth
              disabled/>
              :
              <>
                <img 
                  src={delete_icon} 
                  style = {{position: 'absolute', width:'18px', height:'18px', right:'19px', top:'19px', cursor:'pointer'}}
                  onClick={() => handleDeletingCard()}
                  ></img>
                <Autocomplete
                  disablePortal
                  value={selectedOrg} 
                  options={org_options}
                  getOptionLabel={(orgObj: Organization) => orgObj.orgName}
                  onChange={(e, newValue) => handleChooseAlliance(e,newValue)}
                  fullWidth
                  renderInput={(params) => 
                      <TextField  
                      {...params} 
                      name="alliance" 
                      error={!!newErrors?.orgAbb && newTouched?.orgAbb}
                      placeholder='เลือกสมาคม'
                      helperText={
                          newTouched?.orgAbb && !!newErrors?.orgAbb ?
                          <ErrorElement errorMessage={newErrors.orgAbb} /> : <></>
                      }
                      sx={{
                        '& .MuiInputBase-root':{
                          paddingLeft:'26px'
                        }
                      }}
                      />}
                  />

              </>
              
          }
        
        </Grid>
        {
          allianceList[index].orgAbb ? (
            
              allianceList[index].organizationQuestionaires.map(({questionName, answer},questionIndex) => 
              <>
                <Grid item md={4} sm = {4} xs={12}>
                    <div className={style.label}>{questionName}<TextRequired /></div>
                  </Grid>
                  <Grid item md={8} sm = {8} xs={12}>
                  <TextField 
                      name="question"
                      value={answer}
                      onChange = {handleChangeAnswer(questionIndex)}
                      onBlur = {() => handleNestedFieldBlur(questionIndex)}
                      error={isError(questionIndex)}
                      helperText={isError(questionIndex) ? 
                        <ErrorElement errorMessage={isError(questionIndex) ? 
                          (errors as any).privileges[index].organizationQuestionaires[questionIndex].answer as string
                          : ''} /> : <></>}    
                      placeholder='คำตอบ'
                      fullWidth/>
                  </Grid>
                </>
              )
            
            
          )
          :
          <></>
        }
        
        </Grid>
    </Card>
  )
}

export default AllianceCard