import { ThemeOptions, Theme, createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils'

const basetheme: ThemeOptions = {
    palette: {
        action: {
            disabled: '#EFF1F5 !important'
        },
        text:{
            disabled: '#515151'
        }
    },
    components:{
        MuiTextField: {
            variants: [
              {
                props: { variant: 'outlined', disabled: true },
                style: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: '#EFF1F5',
                        zIndex:-1
                    },
                    color: '#515151'
                }
              }
            ]
          },
        MuiOutlinedInput:{
            variants: [
                {
                  props: { disabled: true },
                  style: {
                    WebkitTextFillColor: 'red',
                    zIndex:1
                  }
                }
              ],
            styleOverrides:{
                root:{
                    textAlign: 'left',
                    fontFamily: 'Kanit',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#0182FA',
                    },
                    
                },
                input:{
                    padding:'13.5px 24px'

                }
            }
        },
    }
}

const textbox_dynamic_theme:  (theme: Theme) => ThemeOptions  = (theme: Theme) => ({
    components:{
        MuiOutlinedInput: {
            styleOverrides:{
                input:{
                    [theme.breakpoints.down('sm')]:{
                        fontSize: '14px'
                    }
                }
            }
        }
    }
})

const narrow_theme: ThemeOptions = {
    components:{
        MuiOutlinedInput:{
            styleOverrides:{
                input:{
                    paddingTop: '10px',
                    paddingBottom: '10px',
                }
            }
        },
    }
}

export const textbox_theme = deepmerge(basetheme,textbox_dynamic_theme(createTheme({})))
export const narrow_textbox_theme = deepmerge(basetheme, narrow_theme)