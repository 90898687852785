import React from 'react'
import {
    Dialog,
    DialogContent,
    ThemeProvider,
    createTheme
} from '@mui/material'
import {check, email_img, failed, warning} from '../../../assets/responsePopUp/index'
import { ResponsePopUpInput } from '../../../interfaces/ResponsePopUp'
import style from './ResponsePopUp.module.css'
import TVLButton from '../TVLButton/TVLButton'
import { response_theme } from '../../../theme/responsePopUp'

const ResponsePopUp = ({open, setOpen, status, title, text1, text2, button, handleButton, email}: ResponsePopUpInput) => {
    const theme = createTheme(response_theme)
    const handleClose = () => setOpen(false)

  return (
    <ThemeProvider theme={theme}>
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                {
                    status === 'succeed' ?
                        <img src={check} className = {style.img}/>
                    : status === 'email' ?
                        <img src={email_img} className = {style.img}/>
                    : status === 'warning' ?
                        <img src={warning} className = {style.img} />
                    :  //failed
                        <img src={failed} className = {style.img}/>

                }
                <div className = {style.title}>{title}<br/>{email}</div>
                <div className = {style.textbox}>
                    <span className = {style.text1}>{text1}</span><br /> {" "}
                    <span className = {style.text2}>{text2}</span>
                </div>
                <div style ={{marginBottom:'20px'}}>
                    <TVLButton size='small' onClick = {handleButton}>{button}</TVLButton>
                </div>
            </DialogContent>
        </Dialog>
    </ThemeProvider>
  )
}

export default ResponsePopUp 