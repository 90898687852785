import React from 'react'
import { Card, CardContent, CardMedia } from '@mui/material'
import { InfographicThumbnail } from '../../../../interfaces/Infographic'

interface InfographicHomeCardProps{
    infographicMetadata: InfographicThumbnail
  }

const InfographicHomeCard = ({infographicMetadata}: InfographicHomeCardProps) => {

    const link = "/infographic/" + infographicMetadata.infographicId
    return (
      <a href={link} target="_blank">
        <Card>
          <CardMedia
          component='img'
          image={infographicMetadata.imgUrl}
          alt={infographicMetadata.infographicTitle}
          />
          <CardContent title={infographicMetadata.infographicTitle}>
            {infographicMetadata.infographicTitle}
          </CardContent>
        </Card>
      </a>
    )
}

export default InfographicHomeCard





