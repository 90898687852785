import React from 'react'
import {
    Box,
    Skeleton,
    Divider,
    Grid
} from '@mui/material'
import style from './SkeletonPage.module.css'

const SkeletonPage = () => {
  return (
    <Box>
        <Box marginTop={5}><Skeleton className={style.title} width='70%'/></Box>
        <Box display='flex' justifyContent='space-between' alignContent='center' marginBottom={2}>
            <Box paddingTop='10px' display= 'flex' justifyContent="space-around" alignItems='center'>
                <Skeleton variant = 'rounded' className = {style.tag} width={70} height={24}></Skeleton>
                <Skeleton variant = 'rounded' className = {style.tag} width={70} height={24}></Skeleton>
                <Divider orientation='vertical' flexItem sx={{ borderRightWidth: 2, borderColor:'rgba(0,0,0,0.5)', margin: "7px 0" }} />
                <Skeleton 
                variant='circular' 
                height={40} 
                width={40} 
                sx={{
                    padding: '0',
                    marginLeft: '7px'
                }}></Skeleton>
                

            </Box>
            <Skeleton
                width={100}
                variant='rounded'
                sx={{
                        padding: '21.25px 40px !important',
                        borderRadius: "50px",
                    }} />
        </Box>
        <Grid container spacing={3} marginBottom={3}>
            <Grid item xs = {12} sm = {12} md={9}>
                <div className={style.infoBox}>
                <Skeleton 
                variant='rectangular'
                width='100%'
                sx={{position:'relative', ':before':{content:'""', display:'block', paddingTop:'100%'}}}>
                    <div style={{position:'absolute', top:'0', left:'0', width:'100%', height:'100%'}}></div>
                </Skeleton>
                </div>
                

            </Grid>
            <Grid item xs = {12} sm={12} md={3}>
                <Box className={style.detailHeader} marginBottom={3}>
                    <Skeleton width={100}/>
                </Box>
                <Box textAlign='left' display='flex' marginBottom={3}>
                    <Box className={style.header} width={100}>
                        <Skeleton width={70} />
                    </Box>
                    <Box flex={1} className={style.text}>
                        <Skeleton width={70}/>
                    </Box>
                </Box>
                <Box textAlign='left' display='flex' marginBottom={3}>
                    <Box className={style.header} width={100}>
                        <Skeleton width={70}/>
                    </Box>
                    <Box flex={1} className={style.text}>
                        <Skeleton width={90}/>
                    </Box>
                </Box>
                <Box textAlign='left'>
                    <Box className={style.header} marginBottom={2}>
                        <Skeleton width={70}/>
                    </Box>
                    <Box className={style.text}>
                        <Skeleton width='100%'/>
                        <Skeleton width='100%'/>
                        <Skeleton width='100%'/>
                        <Skeleton width='100%'/>
                    </Box>

                </Box>

            </Grid>
        </Grid>

    </Box>
    
  )
}

export default SkeletonPage