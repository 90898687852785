import { ThemeOptions } from "@mui/material";

const basetheme: ThemeOptions = {
    components:{
        MuiPaper:{
            styleOverrides:{
                root:{
                    width: '100%',
                    backgroundColor: '#092C50',
                    padding:'50px',
                    alignItems: 'center',
                    overflow: 'hidden'
                }
            }
        }
    }
}

export const thank_you_theme = basetheme