import React, { useState } from "react"
import {
    TextField,
    ThemeProvider,
    createTheme,
    Box,
    ToggleButton,
    Popover,
    MenuItem,
    InputAdornment
} from "@mui/material"
import {
    DashboardTag,
    DashboardCategoryThai,
} from "../../../interfaces/DashboardTag"
const dashboard_tag: DashboardTag[] = require("../../../variable/dashboard_tag.json")
import {
    searchingBox,
    breakPointTheme,
    toggleButton,
    popoverTheme,
} from "../../../theme/filterBar"
import { mergeThemes } from "../../../theme/merge"
import { register_theme } from "../../../theme/register"
import { textbox_theme } from "../../../theme/textbox"
import style from "./FilterBar.module.css"
import CheckRoundedIcon from "@mui/icons-material/CheckRounded"
import ListAltIcon from "@mui/icons-material/ListAlt"
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { CloseSharp } from '@mui/icons-material';

interface FilterBarProps {
    activeTag: DashboardCategoryThai
    setActiveTag: (value: DashboardCategoryThai) => void
    searchText: string
    setSearchText: (value: string) => void
    activeTagList: DashboardCategoryThai[]
}

const FilterBar = ({
    activeTag,
    setActiveTag,
    searchText,
    setSearchText,
    activeTagList,
}: FilterBarProps) => {
    const mergedTheme = mergeThemes(
        searchingBox,
        register_theme,
        textbox_theme,
        breakPointTheme,
        toggleButton,
        popoverTheme
    )
    const theme = createTheme(mergedTheme)
    const categoryList = dashboard_tag
        .filter(({ category_thai }) => category_thai !== "แดชบอร์ดพิเศษ")
        .map(({ category_thai }) => category_thai)

    // mobile view
    const [toggle, setToggle] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handleClose = () => {
        setToggle(false)
        setAnchorEl(null)
    }

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setToggle(!toggle)
        setAnchorEl(event.currentTarget)
    }

    const handleSelectMenuItem = (tag: DashboardCategoryThai) => {
        setActiveTag(tag)
        setToggle(false)
    }
    return (
        <ThemeProvider theme={theme}>
            <Box display="flex" marginBottom={3}>
                <Box flex="1">
                    <TextField
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="ค้นหาด้วยคีย์เวิร์ด หรือชื่อหมวดหมู่"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    {
                                    searchText ?
                                    <CloseSharp sx={{color:'rgba(0, 0, 0, 0.54)', cursor:'pointer'}} onClick={() => setSearchText('')}/>
                                    :
                                    <SearchRoundedIcon />
                                }
                                </InputAdornment>
                            ),
                            }}
                    />
                </Box>

                <Box
                    display={{ md: "flex", sm: "none", xs: "none" }}
                    justifyContent="space-around"
                    alignItems="center"
                    width={590}
                    className={style.categoryBox}
                >
                    {categoryList.map((tag) =>
                        activeTagList.includes(tag) ? (
                            <span
                                className={
                                    tag === activeTag ? style.active : style.tag
                                }
                                onClick={() => setActiveTag(tag)}
                            >
                                {tag}
                            </span>
                        ) : (
                            <span className={style.disabled}>
                                {tag} <sup>เร็วๆนี้</sup>
                            </span>
                        )
                    )}
                </Box>

                <ToggleButton
                    value="web"
                    selected={toggle}
                    onClick={(e) => handleOpen(e)}
                    fullWidth
                    disableRipple
                    sx={{
                        display: {
                            md: "none",
                            sm: "flex",
                            xs: "flex",
                        },
                    }}
                >
                    <ListAltIcon style={{ height: "23px", width: "23px" }} />
                    <Popover
                        open={toggle}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        sx={{
                            "& .MuiPopover-paper": {
                                borderRadius: "12px",
                            },
                        }}
                    >
                        {categoryList.map((tag) => (
                            <MenuItem
                                disabled={!activeTagList.includes(tag)}
                                disableRipple
                                selected={tag === activeTag}
                                value={tag}
                                onClick={() => handleSelectMenuItem(tag)}
                            >
                                {tag === activeTag ? (
                                    <CheckRoundedIcon
                                        sx={{ marginRight: "10px" }}
                                    />
                                ) : (
                                    <Box width={24} marginRight={1}></Box>
                                )}
                                {tag}
                                {activeTagList.includes(tag) ? (
                                    <></>
                                ) : (
                                    <sup>เร็วๆนี้</sup>
                                )}
                            </MenuItem>
                        ))}
                    </Popover>
                </ToggleButton>
            </Box>
        </ThemeProvider>
    )
}

export default FilterBar
