import React from 'react'
import {
    Card,
    ThemeProvider,
    createTheme,
    Box,
    Grid
} from '@mui/material'
import { RelatedDashboard } from '../../../interfaces/Infographic'
import DashboardTagElement from '../../../components/shared/DashboardTagElement/DashboardTagElement'
import style from './RelatedDashboardBox.module.css'
import { relatedDashboardTheme } from '../../../theme/relatedDashboard'
import { mergeThemes } from '../../../theme/merge'


const RelatedDashboardBox = ({relatedDashboard}: {relatedDashboard: RelatedDashboard}) => {
    const mergedTheme = mergeThemes(relatedDashboardTheme)
    const theme = createTheme(mergedTheme)
  return (
    <ThemeProvider theme = {theme}>
        <a href={relatedDashboard.link}>
            <Card>
                <Grid container >
                    <Grid xs = {4} sm = {4} md={4} margin='auto'>
                        <img src={relatedDashboard.imgUrl} style={{objectFit:'contain', width: '100%', }}></img>
                    </Grid>
                    <Grid xs = {8} sm = {8} md={8} textAlign='left' paddingLeft='10px'>
                        <Box className={style.title}>{relatedDashboard.title}</Box>
                        <Box>
                            {
                                relatedDashboard.tags.map((tag) => (
                                    <DashboardTagElement tiny tag={tag} pathname='/dashboard'/>
                                ))
                            }
                        </Box>
                    </Grid>
                </Grid>
                {/* <Box flex={1} marginRight={2}>
                    
                </Box>
                <Box textAlign='left' width={200}>
                    
                </Box> */}
            </Card>
        </a>
    </ThemeProvider>
    
  )
}

export default RelatedDashboardBox