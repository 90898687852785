import React from 'react'
import { 
    Select,
    MenuItem,
    OutlinedInput,
    ThemeProvider,
    createTheme
} from '@mui/material'
import { dynamicSelectorTheme } from '../../../theme/dynamicSelector';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface DynamicSelectorProps{
    fullWidth?: boolean,
    selectorName: string, 
    value: string, 
    setValue: React.Dispatch<string>, 
    dynamicList: string[]
}

const DynamicSelector = ({
    fullWidth,
    selectorName,
    value,
    setValue,
    dynamicList
}: DynamicSelectorProps) => {
    const theme = createTheme(dynamicSelectorTheme)
  return (
    <ThemeProvider theme={theme}>
        <Select
        displayEmpty
        value={value}
        onChange={(e) => setValue(e.target.value)}
        input={<OutlinedInput />}
        renderValue={(selected) => {
            if (!selected) {
            return <a>{selectorName}</a>;
            }
            return selected
        }}
        MenuProps={{
            PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
            },
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        fullWidth={fullWidth}
        >
        <MenuItem disabled value="">
            <a>{selectorName}</a>
        </MenuItem>
        {dynamicList.map((value) => (
            <MenuItem
            disableRipple
            key={value}
            value={value}
            >
            {value}
            </MenuItem>
        ))}
        </Select>

    </ThemeProvider>
    
  )
}

export default DynamicSelector