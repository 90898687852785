
export interface RegisterTvlForm {
    firstName: string,
    lastName: string,
    organization: string | null,
    position: string,
    email: string,
    phoneNo: string,
    gender: string,
    password: string,
    password2: string,
    yearOfBirth: string | null,
    checkBox: boolean
}

export interface RegisterTvlValidation {
    firstName?: string,
    lastName?: string,
    organization?: string,
    position?: string,
    email?: string,
    phoneNo?: string,
    gender?: string,
    password?: string,
    password2?: string,
    yearOfBirth?: string,
    checkBox?: string

}

export const mouValidate = (values:RegisterTvlForm) => {
    let errors : RegisterTvlValidation = {}

    if (!values.yearOfBirth) {
        errors.yearOfBirth = "กรุณาระบุปีเกิดของท่าน"
    } 

    if (!values.firstName) {
        errors.firstName = "กรุณากรอกชื่อของท่าน"
    } else if (/\d/.test(
        values.firstName
    )){
        errors.firstName = "กรุณากรอกเฉพาะตัวอักษรเท่านั้น"
    }

    if (!values.lastName) {
        errors.lastName = "กรุณากรอกนามสกุลของท่าน"
    } else if (/\d/.test(
        values.lastName
    )){
        errors.lastName = "กรุณากรอกเฉพาะตัวอักษรเท่านั้น"
    }

    if (!values.organization) {
        errors.organization = "กรุณากรอกองค์กรที่ท่านสังกัด"
    } 

    if (!values.position) {
        errors.position = "กรุณากรอกตำแหน่งในองค์กรของท่าน"
    } 
    if ( values.phoneNo && !/((\+66|0)((2)(-?\d{3}\-?\d{4})|(32|33|34|35|36|37|38|39|42|43|44|45|53|54|55|56|73|74|75|76|77)(-?\d{3}\-?\d{3})|([689])(\d{1}\-?\d{3}\-?\d{4})))/.test(
            values.phoneNo
        )
    ) {
        errors.phoneNo =
            "กรุณากรอกเบอร์โทรศัพท์ที่ใช้งานได้ในประเทศ (ตัวอย่างเช่น 0987654321, 055-123-456, +662-233-4455)"
    }

    if (!values.email) {
        errors.email = "กรุณากรอกอีเมล"
    } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            values.email
        )
    ) {
        errors.email = "กรุณากรอกอีเมลที่ถูกต้อง"
    }

    if (!values.gender) {
        errors.gender = "กรุณาระบุเพศของท่าน"
    } 
    if (!values.password) {
        errors.password = "กรุณากรอกรหัสผ่าน"
    } else if(values.password.length < 8){
        errors.password = "กรุณากรอกรหัสผ่านที่มีความยาวไม่ต่ำกว่า 8 ตัวอักษร"
    }

    if (values.password2 !== values.password) {
        errors.password2 = "กรุณากรอกรหัสผ่านให้ตรงกัน"
    }

    if (!values.checkBox){
        errors.checkBox = "กรุณายืนยันว่าท่านได้ยอมรับ Privacy Policy และ Terms & Condition แล้ว"
    }

    return errors
}

export interface RegisterMouOtherForm extends RegisterTvlForm{
    orgAbb: string
}

export interface RegisterMouOtherValidation extends RegisterTvlValidation{
    orgAbb?: string
}

export interface RegisterMouForm extends RegisterTvlForm{
    newOrg: string
    orgAbb?: string
}

export interface RegisterMouValidation extends RegisterTvlValidation{
    newOrg?: string
    orgAbb?: string
}

export const mouOtherValidate = (values:RegisterMouOtherForm) => {
    let errors : RegisterMouOtherValidation = {}
    errors = mouValidate(values)

    if (!values.orgAbb) {
        errors.orgAbb = "กรุณาระบุชื่อตัวย่อองค์กรของท่าน"
    } 
    return errors
}

export const mouNewOrgValidate = (values:RegisterMouForm) => {
    let errors : RegisterMouValidation = {}
    errors = mouValidate(values)

    let isOther = values.organization === 'อื่น ๆ'

    if (isOther && !values.newOrg){
        errors.newOrg = "กรุณากรอกองค์กรที่ท่านสังกัด"
    }

    if (isOther && !values.orgAbb) {
        errors.orgAbb = "กรุณาระบุชื่อตัวย่อองค์กรของท่าน"
    } 

    return errors
}

export interface RegisterPlatformForm {
    firstName: string,
    lastName: string,
    organization: string,
    position: string,
    email: string,
    phoneNo: string,
    gender: string,
    platform: string,
    yearOfBirth: string,
    checkBox: boolean
}

export interface RegisterPlatformValidation {
    firstName?: string,
    lastName?: string,
    organization?: string,
    position?: string,
    email?: string,
    phoneNo?: string,
    gender?: string,
    platform?: string,
    yearOfBirth?: string,
    checkBox?: string

}

export const registerUserPlatformValidate = (values:RegisterPlatformForm) => {
    let errors : RegisterPlatformValidation = {}

    if (!values.yearOfBirth) {
        errors.yearOfBirth = "กรุณาระบุปีเกิดของท่าน"
    } 

    if (!values.firstName) {
        errors.firstName = "กรุณากรอกชื่อของท่าน"
    } else if (/\d/.test(
        values.firstName
    )){
        errors.firstName = "กรุณากรอกเฉพาะตัวอักษรเท่านั้น"
    }

    if (!values.lastName) {
        errors.lastName = "กรุณากรอกนามสกุลของท่าน"
    } else if (/\d/.test(
        values.lastName
    )){
        errors.lastName = "กรุณากรอกเฉพาะตัวอักษรเท่านั้น"
    }

    if (!values.organization) {
        errors.organization = "กรุณากรอกองค์กรที่ท่านสังกัด"
    } 

    if (!values.position) {
        errors.position = "กรุณากรอกตำแหน่งในองค์กรของท่าน"
    } 

    if ( values.phoneNo &&
        !/((\+66|0)((2)(-?\d{3}\-?\d{4})|(32|33|34|35|36|37|38|39|42|43|44|45|53|54|55|56|73|74|75|76|77)(-?\d{3}\-?\d{3})|([689])(\d{1}\-?\d{3}\-?\d{4})))/.test(
            values.phoneNo
        )
    ) {
        errors.phoneNo =
            "กรุณากรอกเบอร์โทรศัพท์ที่ใช้งานได้ในประเทศ (ตัวอย่างเช่น 0987654321, 055-123-456, +662-233-4455)"
    }

    if (!values.email) {
        errors.email = "กรุณากรอกอีเมล"
    } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            values.email
        )
    ) {
        errors.email = "กรุณากรอกอีเมลที่ถูกต้อง"
    }

    if (!values.gender) {
        errors.gender = "กรุณาระบุเพศของท่าน"
    } 
    if (!values.platform) {
        errors.platform = "กรุณาระบุประเภทบัญชีเข้าใช้งาน"
    }

    if (!values.checkBox){
        errors.checkBox = "กรุณายืนยันว่าท่านได้ยอมรับ Privacy Policy และ Terms & Condition แล้ว"
    }

    return errors
}


export interface RegisterTvlAlliance {
    firstName: string,
    lastName: string,
    organization: string,
    position: string,
    email: string,
    phoneNo: string,
    gender: string,
    password: string,
    password2: string,
    yearOfBirth: string,
    checkBox: boolean,
    privileges: Array<{
        orgAbb: string,
        organizationQuestionaires: Array<{
            questionName: string
            questionId: string
            answer: string
        }>
    }>
}

export interface RegisterTvlAllianceValidation {
    firstName?: string,
    lastName?: string,
    organization?: string,
    position?: string,
    email?: string,
    phoneNo?: string,
    gender?: string,
    password?: string,
    password2?: string,
    yearOfBirth?: string,
    checkBox?: string,
    privileges?: Array<{
        orgAbb?: string,
        organizationQuestionaires?: Array<{
            questionId?: string,
            answer?: string
        }>
    }>

}

export const registerTvlAllianceValidate = (values:RegisterTvlAlliance) => {
    let errors : RegisterTvlAllianceValidation = {}

    if (!values.yearOfBirth) {
        errors.yearOfBirth = "กรุณาระบุปีเกิดของท่าน"
    } 

    if (!values.firstName) {
        errors.firstName = "กรุณากรอกชื่อของท่าน"
    } else if (/\d/.test(
        values.firstName
    )){
        errors.firstName = "กรุณากรอกเฉพาะตัวอักษรเท่านั้น"
    }

    if (!values.lastName) {
        errors.lastName = "กรุณากรอกนามสกุลของท่าน"
    } else if (/\d/.test(
        values.lastName
    )){
        errors.lastName = "กรุณากรอกเฉพาะตัวอักษรเท่านั้น"
    }

    if (!values.organization) {
        errors.organization = "กรุณากรอกองค์กรที่ท่านสังกัด"
    } 

    if (!values.position) {
        errors.position = "กรุณากรอกตำแหน่งในองค์กรของท่าน"
    } 

    if ( values.phoneNo &&
        !/((\+66|0)((2)(-?\d{3}\-?\d{4})|(32|33|34|35|36|37|38|39|42|43|44|45|53|54|55|56|73|74|75|76|77)(-?\d{3}\-?\d{3})|([689])(\d{1}\-?\d{3}\-?\d{4})))/.test(
            values.phoneNo
        )
    ) {
        errors.phoneNo =
            "กรุณากรอกเบอร์โทรศัพท์ที่ใช้งานได้ในประเทศ (ตัวอย่างเช่น 0987654321, 055-123-456, +662-233-4455)"
    }

    if (!values.email) {
        errors.email = "กรุณากรอกอีเมล"
    } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            values.email
        )
    ) {
        errors.email = "กรุณากรอกอีเมลที่ถูกต้อง"
    }

    if (!values.gender) {
        errors.gender = "กรุณาระบุเพศของท่าน"
    } 
    if (!values.password) {
        errors.password = "กรุณากรอกรหัสผ่าน"
    } else if(values.password.length < 8){
        errors.password = "กรุณากรอกรหัสผ่านที่มีความยาวไม่ต่ำกว่า 8 ตัวอักษร"
    }
    
    if (values.password2 !== values.password) {
        errors.password2 = "กรุณากรอกรหัสผ่านให้ตรงกัน"
    }

    if (!values.checkBox){
        errors.checkBox = "กรุณายืนยันว่าท่านได้ยอมรับ Privacy Policy และ Terms & Condition แล้ว"
    }
    let privilegeCardErrors: Array<Object> = []
    values.privileges.forEach((field, orgIndex) => {
        let orgErrors: {
          orgAbb?: string;
          organizationQuestionaires?: Array<{
            answer?: string
          }>
        } = {};
    
        // Perform your validation for each field here
        if (!field.orgAbb) {
            orgErrors.orgAbb = 'โปรดเลือกสมาคมที่ต้องการ';
        }

        let organizationQuestionaireErrors: {answer?: string}[] = []
        if(field.organizationQuestionaires){
            field.organizationQuestionaires.forEach(({answer}, questionIndex) => {
                let questionError: {answer?: string} = {}
                if(!answer){
                    questionError.answer = "โปรดตอบคำถาม"
                }
                
                organizationQuestionaireErrors[questionIndex] = {...questionError}
            })
        }
        if(organizationQuestionaireErrors.map((obj) => Object.keys(obj).length)
        .reduce((acc, current) => acc + current, 0) > 0){
            orgErrors.organizationQuestionaires = organizationQuestionaireErrors
        }
        // If there are errors for this field, add them to the errors object
        privilegeCardErrors[orgIndex] = { ...orgErrors };
      });

      if(privilegeCardErrors.map((obj) => Object.keys(obj).length)
      .reduce((acc, current) => acc + current, 0) > 0){
        errors.privileges = privilegeCardErrors
    }

    return errors
}

export interface RegisterPlatformAlliance {
    firstName: string,
    lastName: string,
    organization: string,
    position: string,
    email: string,
    phoneNo: string,
    gender: string,
    platform: string,
    yearOfBirth: string,
    checkBox: boolean,
    privileges: Array<{
        orgAbb: string,
        organizationQuestionaires: Array<{
            questionName: string
            questionId: string
            answer: string
        }>
    }>
}

export interface RegisterPlatformAllianceValidation {
    firstName?: string,
    lastName?: string,
    organization?: string,
    position?: string,
    email?: string,
    phoneNo?: string,
    gender?: string,
    platform?: string,
    yearOfBirth?: string,
    checkBox?: string,
    privileges?: Array<{
        orgAbb?: string,
        organizationQuestionaires?: Array<{
            questionId?: string,
            answer?: string
        }>
    }>
}

export const registerPlatformAllianceValidate = (values:RegisterPlatformAlliance) => {
    let errors : RegisterPlatformAllianceValidation = {}

    if (!values.yearOfBirth) {
        errors.yearOfBirth = "กรุณาระบุปีเกิดของท่าน"
    } 

    if (!values.firstName) {
        errors.firstName = "กรุณากรอกชื่อของท่าน"
    } else if (/\d/.test(
        values.firstName
    )){
        errors.firstName = "กรุณากรอกเฉพาะตัวอักษรเท่านั้น"
    }

    if (!values.lastName) {
        errors.lastName = "กรุณากรอกนามสกุลของท่าน"
    } else if (/\d/.test(
        values.lastName
    )){
        errors.lastName = "กรุณากรอกเฉพาะตัวอักษรเท่านั้น"
    }

    if (!values.organization) {
        errors.organization = "กรุณากรอกองค์กรที่ท่านสังกัด"
    } 

    if (!values.position) {
        errors.position = "กรุณากรอกตำแหน่งในองค์กรของท่าน"
    } 

    if ( values.phoneNo &&
        !/((\+66|0)((2)(-?\d{3}\-?\d{4})|(32|33|34|35|36|37|38|39|42|43|44|45|53|54|55|56|73|74|75|76|77)(-?\d{3}\-?\d{3})|([689])(\d{1}\-?\d{3}\-?\d{4})))/.test(
            values.phoneNo
        )
    ) {
        errors.phoneNo =
            "กรุณากรอกเบอร์โทรศัพท์ที่ใช้งานได้ในประเทศ (ตัวอย่างเช่น 0987654321, 055-123-456, +662-233-4455)"
    }

    if (!values.email) {
        errors.email = "กรุณากรอกอีเมล"
    } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            values.email
        )
    ) {
        errors.email = "กรุณากรอกอีเมลที่ถูกต้อง"
    }

    if (!values.gender) {
        errors.gender = "กรุณาระบุเพศของท่าน"
    } 
    if (!values.platform) {
        errors.platform = "กรุณาระบุประเภทบัญชีเข้าใช้งาน"
    } 

    if (!values.checkBox){
        errors.checkBox = "กรุณายืนยันว่าท่านได้ยอมรับ Privacy Policy และ Terms & Condition แล้ว"
    }

    let privilegeCardErrors: Array<Object> = []
    values.privileges.forEach((field, orgIndex) => {
        let orgErrors: {
          orgAbb?: string;
          organizationQuestionaires?: Array<{
            answer?: string
          }>
        } = {};
    
        // Perform your validation for each field here
        if (!field.orgAbb) {
            orgErrors.orgAbb = 'โปรดเลือกสมาคมที่ต้องการ';
        }

        let organizationQuestionaireErrors: {answer?: string}[] = []
        if(field.organizationQuestionaires){
            field.organizationQuestionaires.forEach(({answer}, questionIndex) => {
                let questionError: {answer?: string} = {}
                if(!answer){
                    questionError.answer = "โปรดตอบคำถาม"
                }
                
                organizationQuestionaireErrors[questionIndex] = {...questionError}
            })
        }
        if(organizationQuestionaireErrors.map((obj) => Object.keys(obj).length)
        .reduce((acc, current) => acc + current, 0) > 0){
            orgErrors.organizationQuestionaires = organizationQuestionaireErrors
        }
        // If there are errors for this field, add them to the errors object
        privilegeCardErrors[orgIndex] = { ...orgErrors };
      });

      if(privilegeCardErrors.map((obj) => Object.keys(obj).length)
      .reduce((acc, current) => acc + current, 0) > 0){
        errors.privileges = privilegeCardErrors
    }


    return errors
}
