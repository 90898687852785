import React, {useState} from 'react'
import {
    Dialog,
    DialogContent,
    TextField,
    createTheme,
    ThemeProvider,
    Divider,
    InputAdornment,
    IconButton
} from '@mui/material'
import style from './SharePopup.module.css'
import close_icon from "./../../../assets/close_button.svg"
import {share_dialog_theme} from '../../../theme/dashboard'
import { mergeThemes } from '../../../theme/merge'
import {facebook, x, line, CheckCopy} from '../../../assets/dashboard'
import TVLButton from '../../../components/shared/TVLButton/TVLButton'
import { textbox_theme } from '../../../theme/textbox'
import { register_textbox_theme } from '../../../theme/register'


interface SharePopup{
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SharePopup = ({open, setOpen}: SharePopup) => {


    const mergedTheme = mergeThemes(share_dialog_theme, textbox_theme,register_textbox_theme)
    const theme = createTheme(mergedTheme)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isCopied, setIsCopied] = useState<boolean>(false)
    const url = window.location.href

    const handleCopy = async () => {
        const copyTextWithDelay = () => {
          return new Promise<void>(async (resolve, reject) => {
            setTimeout(async () => {
              try {
                await (navigator.clipboard as Clipboard).writeText(url);
                resolve();
              } catch (error) {
                reject(error);
              }
            }, 500);
          });
        };
    
        setIsLoading(true);
    
        try {
          await copyTextWithDelay();
          setIsCopied(true);
        } catch (error) {
          console.error('Copy failed:', error);
          // Handle error as needed
        } finally {
          setIsLoading(false);
        }
      };

    const handleClosing = () => {setIsCopied(false); setOpen(false)}

    const sharedLink = encodeURIComponent(window.location.href)

  return (
    <ThemeProvider theme={theme}>
        <Dialog 
        open={open}
        onClose={() => setOpen(false)}>
            <div
                className={style.closeButton}
                onClick={() => handleClosing()}
            >
                        <img
                            src={close_icon}
                            style={{ borderRadius: "29px" }}
                        ></img>
                    </div>
            <div className={style.title}>แชร์แดชบอร์ด</div>
            <DialogContent sx = {{display:'flex', alignItems:'center', flexDirection:'column',}}>
                <div style={{marginBottom:'15px'}}>
                    <a href={`https://social-plugins.line.me/lineit/share?url=${sharedLink}`} target="_blank"  style = {{marginRight:'10px'}}>
                        <img src={line} className = {style.img}/>
                    </a>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${sharedLink}`} target="_blank" style = {{marginRight:'10px'}}>
                        <img src={facebook} className = {style.img}/>
                    </a>
                    <a href={`https://twitter.com/intent/tweet?text=${sharedLink}`} target="_blank">
                      <div style={{display:'inline-block', marginBottom:'10px'}}>
                      <div style ={{borderRadius:"50px", padding:"8px", backgroundColor:"black", width: '42px', height: '42px'}}>
                          <img src={x} style={{height:'26px', width:'26px', marginBottom:'7px'}}/>
                        </div>
                      </div>
                        
                    </a>
                </div>
                <Divider orientation='horizontal' sx={{marginBottom:'15px', width:'100%'}}>
                    <span className={style.divider}>หรือ</span>
                </Divider>
                <TextField 
                fullWidth
                value={url}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end" sx={{marginRight:'-10px'}}>
                            <TVLButton size='small' onClick={() => handleCopy()} isLoading={isLoading} sx={{padding:"10px 18px"}}>
                                {isCopied ? <><CheckCopy style={{marginRight:'5px'}}/>Copy</> :<>Copy</>}
                            </TVLButton>
                        </InputAdornment>
                    )
                }}/>
            </DialogContent>
        </Dialog>

    </ThemeProvider>
    
  )
}

export default SharePopup