import { ThemeOptions } from '@mui/material/styles';
import { Theme, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

export const  basetheme: ThemeOptions = {
    palette:{
        error:{
            main: '#FF3B3B'
        },
        primary:{
            main: '#0182FA'
        }
    },
    typography:{
        title:{
            color: '#000',
            fontFamily: 'Kanit',
            fontSize: '1.5rem',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
        },
        bodyText:{
            fontFamily: 'Kanit',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '15px',
            lineHeight: '22px',
            textAlign: 'center',

            color: '#000000',
            marginTop: '0',
            marginBottom: '0'
        },
        ahref:{
            fontFamily: 'Kanit',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            color: '#0182FA',
            textDecorationLine: 'underline',
            cursor:'pointer'
        },
        formHelperText:{
            fontFamily: 'Kanit',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '13px',
            marginTop: '0'
        }

    }
}

const breakpointTheme = (theme: Theme) => ({
    typography:{
        title: {
            [theme.breakpoints.down('sm')]:{
                fontSize: '20px'
            }
        },
        bodyText:{
            [theme.breakpoints.down('sm')]:{
                fontSize: '13px'
            }
        }
    }
})

const theme = createTheme({})

export const typography_theme = deepmerge(basetheme, breakpointTheme(theme))