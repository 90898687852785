import React, {useState, useEffect} from 'react'
import {
  ThemeProvider,
  createTheme,
  TextField,
  Grid,
  Box
} from '@mui/material'
import { Formik } from 'formik'
import style from './MOURegister.module.css'
import CustomContainer from '../../components/shared/CustomContainer/CustomContainer'
import { mergeThemes } from '../../theme/merge';
import { register_textbox_theme } from '../../theme/register';
import { textbox_theme } from '../../theme/textbox';
import TVLButton from '../../components/shared/TVLButton/TVLButton';
import { MOUForm, mouFormValidate } from '../../validation/mouForm'
import ErrorElement from '../../components/shared/ErrorElement/ErrorElement'
import ResponsePopUp from '../../components/shared/ResponsePopUp/ResponsePopUp'
import TextRequired from '../../components/shared/TextRequired/TextRequired'
import { ScrollToFieldError } from '../../components/shared/ScrollToFieldError/ScrollToFieldError'
import { mouRegister } from '../../services/data-connector'

const MOURegister = () => {
  const mergedTheme = mergeThemes(register_textbox_theme, textbox_theme)
  const [responseStatus, setResponseStatus] = useState<'' | 'failed' | 'email' | 'email_partner' | 'succeed'>('')
  const [response, setResponse] = useState<React.ReactNode>(<></>)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')
  const theme = createTheme(mergedTheme)

  const initialValues: MOUForm = {
    organization: '',
    purpose: '',
    firstName: '',
    lastName: '',
    department: '',
    position: '',
    email: '',
    phoneNo: ''
  }
  
  const initialTouched = {
    organization: false,
    purpose: false,
    firstName: false,
    lastName: false,
    department: false,
    position: false,
    email: false,
    phoneNo: false
  }

  const response_element = () => {
    if(responseStatus === ''){
      return <></>
    } else if(responseStatus === 'succeed'){
      return (
        <ResponsePopUp 
        open
        setOpen={() => {window.location.href='/'}} 
        status='succeed'
        title= 'ขอบคุณที่ลงทะเบียนเข้าร่วม MOU'
        text1='เราได้รับข้อมูลการลงทะเบียนหน่วยงานของท่านแล้ว'
        text2='เจ้าหน้าที่จะดำเนินการตรวจสอบ และติดต่อกลับไปอีกครั้ง'
        button='กลับสู่หน้าหลัก'
        handleButton={() => {window.location.href='/'}}/>
      )
    }
  }
  useEffect(() => {
    setResponse(response_element())
  },[responseStatus])

  const handleSubmit = async (values: any) => {
    setIsLoading(true)
    let {success, res} = await mouRegister(values)
    if(success){
      setResponseStatus('succeed')
    }
    else{
      setErrorText('เกิดข้อผิดพลาด กรุณาลองใหม่')
    }
    setIsLoading(false)
  }
  
  return (
    <CustomContainer>
      {response}
      <ThemeProvider theme = {theme}>
        <Grid container>
          <Grid item md={1}></Grid>
          <Grid item md={10}>
          <div className = {style.title}>ลงทะเบียนหน่วยงาน MOU</div>
      <div className={style.subtitle}>เป็นการลงทะเบียนสำหรับหน่วยงาน หรือ สมาคมที่สนใจเข้าร่วม MOU</div>
      <Box sx = {{padding:{md: '0 30px', sm: '0 30px', xs: '0'}, marginBottom: '100px'}}>
        <Formik
        initialValues={initialValues}
        initialTouched={initialTouched}
        validate={mouFormValidate}
        onSubmit = {(value) => {
          handleSubmit(value)
        }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div style={{ display: 'flex', alignItems: 'center'}}>
              <div className={style.header}>
                ข้อมูลหน่วยงาน
              </div>
              <div style={{ flex: 1, backgroundColor: '#ABABAB', height: '1px', margin: '0 10px' }}></div>
            </div>

            <div className={style.label}>ชื่อหน่วยงาน/สมาคม<TextRequired /></div>
            <TextField 
            name="organization"
            value={values.organization}
            onChange = {handleChange}
            onBlur = {handleBlur}
            error={!!errors.organization && touched.organization}
            helperText={!!errors.organization && touched.organization ? <ErrorElement errorMessage={errors.organization} />: <></>}
            placeholder='หน่วยงาน/สมาคม'
            fullWidth/>

            <div className={style.label}>จุดประสงค์ที่ต้องการเข้าร่วม MOU<TextRequired /></div>
            <TextField
              name="purpose"
              value={values.purpose}
              onChange = {handleChange}
              onBlur = {handleBlur}
              error={!!errors.purpose && touched.purpose}
              helperText={!!errors.purpose && touched.purpose ? <ErrorElement errorMessage={errors.purpose} />: <></>}
              placeholder="ข้อความเพิ่มเติม"
              fullWidth
              multiline
              rows={4}
              sx={{
                '& .MuiInputBase-root':{
                  padding: '0 26px 0 0',
                  borderRadius:'20px', 
                }
              }}
            />


            <div style={{ display: 'flex', alignItems: 'center', marginTop: '25px', marginBottom:'10px'}}>
              <div className={style.header}>
                ข้อมูลผู้ติดต่อ
              </div>
              <div style={{ flex: 1, backgroundColor: '#ABABAB', height: '1px', margin: '0 10px' }}></div>
            </div>
            
            <Grid container columnSpacing={5} sx={{marginBottom:{md:'70px', sm:'70px', xs:'50px'}}}>
            <Grid item md={6} sm={6} xs={12}>
                <div className={style.label}>ชื่อ<TextRequired /></div>
                <TextField
                name="firstName"
                value={values.firstName}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.firstName && touched.firstName}
                helperText={!!errors.firstName && touched.firstName ? <ErrorElement errorMessage={errors.firstName} />: <></>}     
                placeholder='ชื่อ'
                fullWidth/>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className={style.label}>นามสกุล<TextRequired /></div>
                <TextField 
                name="lastName"
                value={values.lastName}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.lastName && touched.lastName}
                helperText={!!errors.lastName && touched.lastName ? <ErrorElement errorMessage={errors.lastName} />: <></>}    
                placeholder='นามสกุล'
                fullWidth/>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className={style.label}>ฝ่าย/แผนก<TextRequired /></div>
                <TextField 
                name="department"
                value={values.department}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.department && touched.department}
                helperText={!!errors.department && touched.department ? <ErrorElement errorMessage={errors.department} />: <></>}
                placeholder='ฝ่าย/แผนก'
                fullWidth/>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className={style.label}>ตำแหน่ง<TextRequired /></div>
                <TextField 
                name="position"
                value={values.position}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.position && touched.position}
                helperText={!!errors.position && touched.position ? <ErrorElement errorMessage={errors.position} />: <></>}
                placeholder='ตำแหน่ง'
                fullWidth/>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className={style.label}>อีเมล<TextRequired /></div>
                <TextField 
                name="email"
                value={values.email}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.email && touched.email}
                helperText={!!errors.email && touched.email ? <ErrorElement errorMessage={errors.email} />: <></>}
                placeholder='อีเมล'
                fullWidth/>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className={style.label}>เบอร์โทรศัพท์<TextRequired /></div>
                <TextField 
                name="phoneNo"
                value={values.phoneNo}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.phoneNo && touched.phoneNo}
                helperText={!!errors.phoneNo && touched.phoneNo ? <ErrorElement errorMessage={errors.phoneNo} />: <></>}
                placeholder='เบอร์โทรศัพท์'
                fullWidth/>
              </Grid>
              

            </Grid>
              {
                errorText ? <ErrorElement errorMessage={errorText} /> : <></>
              }
            <TVLButton 
            type = 'submit' 
            size = 'large' 
            isLoading = {isLoading}
            onMouseDown={(event:React.MouseEvent<HTMLButtonElement>)=>
              {
              event.preventDefault()
              }
            }
            >
                ลงทะเบียน
            </TVLButton>
            </form>
          
          )}
        </Formik>

        


      </Box>

          </Grid>

          <Grid item md={1}></Grid>
        </Grid>
      
      
    </ThemeProvider>

    </CustomContainer>
    
    
  )
}

export default MOURegister