import React, { useEffect, useState } from 'react'
import {
    Box,  Card,  Grid, ThemeProvider, createTheme
} from '@mui/material'
import { SwiperContainer, SwiperSlide } from './SwiperDashboard/SwiperDashboard'
import style from './InfographicShowCase.module.css'
import { InfographicCardType } from '../../../interfaces/Infographic'
import { getInfographicMetadata } from '../../../services/data-connector'
import InfographicHomeCard from './InfographicHomeCard/InfographicHomeCard'
import { useHistory } from 'react-router-dom'
import TVLButton from '../../../components/shared/TVLButton/TVLButton'
import { infographicShowCaseTheme } from '../../../theme/homeThemes'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const SwiperInfographic = ({infographicMetadata}:{infographicMetadata: InfographicCardType[]}) => {
  return (
      <SwiperContainer
          spaceBetween={20}
          slidesPerView={3}
          breakpoints={{
              // when window width is >= 900px
              900: {
              slidesPerView: 4,
              spaceBetween: 35
              }
          }}
          navigation={{
            nextEl: `.custom_next_infographic`,
            prevEl: `.custom_prev_infographic`
        }}
          pagination={{
              clickable: true,
          }}
          autoplay= {{
              delay: 5000,
              pauseOnMouseEnter:true
          }}
          loop
          loopPreventSliding={false}
          >
          {
              infographicMetadata.map((infographicMetadata) =>
              <SwiperSlide
                  key={infographicMetadata.infographicId}
                  style={{height: "auto"}}>
                  <Grid width='99%' height="99%" item>
                    <InfographicHomeCard
                      infographicMetadata={infographicMetadata} />
                      

                  </Grid>
              </SwiperSlide>

          )
              
          }
      </SwiperContainer>
  )
}

const InfographicShowCase = () => {
  const [infographicMetadatas, setInfographicMetadatas] = useState<InfographicCardType[]>([])
  const [isFinished, setIsFinished] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const history = useHistory()

  const fetchInfographicMetadata = async () => {
    let { success, res } = await getInfographicMetadata("")
    if(success) {
        let infographicMetadataTmp: InfographicCardType[] = res.infographicMetadatas
        setInfographicMetadatas(infographicMetadataTmp)
        setIsFinished(true)
    } else {
        setIsError(true)
    }
    
  }

  useEffect(() => {
    fetchInfographicMetadata()
  },[])

    const theme = createTheme(infographicShowCaseTheme)
  return (
    <Box sx={{backgroundColor: '#F1F7FD'}}  >
        <Box height='64px'/>
        <Box className={style.title} id='infographic'>
            อินโฟกราฟิกข้อมูลท่องเที่ยว
        </Box>
        <Box className={style.description} color="#515151" marginTop="5px" marginBottom="40px">
            <span style={{fontWeight:'600'}}>ดูฟรี!</span> ไม่ต้องลงทะเบียน หรือลงชื่อเข้าใช้
        </Box>
        <Box 
        marginLeft={{xl: 'calc(50vw - 868px)',md: 'calccalc(-4.915vw - 14px)', sm:'-100px', xs: '-100px'}} 
        marginRight={{xl:'calc(50vw - 868px)', md:'calc(-4.915vw - 14px)', sm:'-100px', xs: '-100px'}} 
        maxWidth='1736px'
        position='relative'>
            <span className={`custom_prev_infographic ${style.prev}`}>
                <ArrowBackIosRoundedIcon sx={{
                    width:{md: "24px", sm: "24px", xs:"18px"}, 
                    height: {md: "24px", sm: "24px", xs:"18px"}
                    }} />
            </span>
            <span className={`custom_next_infographic ${style.next}`}>
                <ArrowForwardIosRoundedIcon sx={{
                    width:{md: "24px", sm: "24px", xs:"18px"}, 
                    height: {md: "24px", sm: "24px", xs:"18px"}
                    }} />
            </span>
            <ThemeProvider theme={theme}>
                {
                    isFinished && !isError ?
                    <SwiperInfographic 
                    infographicMetadata={infographicMetadatas.concat(infographicMetadatas)}/>
                    :
                    <></>
                }
                
            </ThemeProvider>
        </Box>

        <Box className={style.description} marginTop='10px' marginBottom='30px' paddingX={{md:'200px', sm: '100px', xs:'20px'}}>
        อินโฟกราฟิกคือกุญแจที่เปิดประตูสู่โลกของความรู้และความเข้าใจในข้อมูลที่ซับซ้อน
        และมากมายในปัจจุบัน ซึ่งจะช่วยให้เห็นภาพรวมของข้อมูลได้ง่ายขึ้น อีกทั้งยังง่ายต่อการเข้าใจ 
        ช่วยให้คุณสามารถตัดสินใจและแก้ไขปัญหาได้ดียิ่งขึ้น
        </Box>

        <Box paddingBottom="60px" sx={{backgroundColor: 'transparent'}}>
            <TVLButton
                color='tertiary'
                size='medium'
                sx={{
                        padding: "14px 32px",
                        fontWeight: "500",
                        fontSize:{
                            md: '18px',
                            sm: '18px',
                            xs: '14px'
                        }
                    }}
                onClick={() => {
                    history.push('/infographic')
                    window.scrollTo(0,0)
                }}>
                        สำรวจอินโฟกราฟิก
                </TVLButton>

        </Box>
    </Box>
  )
}

export default InfographicShowCase