import { ThemeOptions, Theme, createTheme } from '@mui/material'
import { deepmerge } from '@mui/utils'
import { mergeThemes } from './merge'

const card_theme: ThemeOptions = {
    components:{
        MuiCard:{
            styleOverrides:{
                root:{
                    border: '1px solid #CBCBCB',
                    borderRadius: '0.75rem',
                    padding:'24px',
                    boxShadow: 'none',
                    position: 'relative',
                    overflow: 'visible'
                }
            }
        }
    }
}

const form_radio_theme: ThemeOptions = {
    components:{
        MuiFormControlLabel:{
            styleOverrides:{
                label:{
                    fontFamily: "Kanit",
                    margin: '0 25px 0 12px'
                }
            }
        },
        MuiFormGroup:{
            styleOverrides:{
                root:{
                    justifyContent: 'start'
                }
            }
        }
    }
}


export const form_radio_gov_theme: ThemeOptions = {
    components:{
        MuiFormControlLabel:{
            styleOverrides:{
                label:{
                    fontFamily: "Kanit",
                    margin: '0 1vw 0 0'
                }
            }
        },
        MuiFormGroup:{
            styleOverrides:{
                root:{
                    justifyContent: 'space-between',
                    width:'100%'
                }
            }
        }
    }
}

const dynamic_form_radio_theme:  (theme: Theme) => ThemeOptions  = (theme: Theme) => ({
    components:{
        MuiFormControlLabel: {
            styleOverrides:{
                label:{
                    [theme.breakpoints.down('sm')]:{
                        fontSize: '14px'
                    }
                }
            }
        }
    }
})


const autocomplete_theme: ThemeOptions = {
    components:{
        MuiInputBase:{
            styleOverrides:{
                root:{
                    backgroundColor: 'white'
                }
            }
        },
        MuiAutocomplete:{
            styleOverrides:{
                option:{
                    fontFamily: 'Kanit',
                    textAlign: 'left',
                }
            }
        }
    }
}

export const registernew_theme = mergeThemes(card_theme, form_radio_theme, dynamic_form_radio_theme(createTheme({})), autocomplete_theme)