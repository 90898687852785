import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '../Button/Button'
import Cookies from 'js-cookie';
import { useHistory, RouterChildContext } from 'react-router-dom'
import close_icon from './../../../assets/close_button.svg'

import './ExpiredPopUp.css'
import ExpiredPopUpModule from './ExpiredPopUp.module.css'

interface ExpiredProps {
    isError: boolean;
    error: string;
    handleClosing?: () => void
    buttonText?: string
}
  

const ExpiredPopUp = ({isError, error, handleClosing, buttonText}: ExpiredProps) => {
    const history = useHistory<RouterChildContext>()
    const handleClosingDefault = () =>{
        window.location.reload()
    }
    return (
            <Dialog
                open={isError}
                onClose={handleClosing || handleClosingDefault}
                sx={{
                    backgroundColor: 'rgba(250, 250, 252, 0.6)',
                    opacity: "1",
                }}
                PaperProps={{sx:{
                    background: "#FFFFFF",
                    border: "1.5px solid #DEE2E6",
                    boxShadow: "2px 19px 21px -8px #E6E6E6",
                    borderRadius: "12px",
                    padding:"15px"
                }}}
                maxWidth='md'
                hideBackdrop
            >
                <div className = {ExpiredPopUpModule.closeButton} onClick = {handleClosing || handleClosingDefault}>
                    <img src = {close_icon} style = {{borderRadius: '29px'}}></img>
                </div>
                <DialogTitle>
                    <div className = {ExpiredPopUpModule.title} >Error!</div>
                </DialogTitle>
                <DialogContent>
                    <div className = {ExpiredPopUpModule.description}>{error}</div>
                </DialogContent>
                <DialogActions sx={{justifyContent:'center'}}>
                    <div className = {ExpiredPopUpModule.button}>
                        <Button onClick = {handleClosing || handleClosingDefault }>{buttonText || "รีเฟรชหน้าใหม่อีกครั้ง"}</Button>
                    </div>
                </DialogActions>
            </Dialog>            
                
        
    )
}

export default ExpiredPopUp