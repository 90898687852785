import React from 'react'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDashboardMetadata } from '../../../services/data-connector'
import { DashboardGalleryCard } from "../../../interfaces/DashboardMetadata";
import { RootState } from '../../store';


interface DashboardState {
    dashboard_metadata: DashboardGalleryCard[],
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string
}

const initialState: DashboardState = {
    dashboard_metadata: [],
    status: 'idle',
    error: ''
}

export const fetchDashboard = createAsyncThunk<DashboardGalleryCard[],string>('dashboard/fetchDashboard', async (accessToken,{rejectWithValue}) => {
    try{
        const { res, status } = await getDashboardMetadata(accessToken)
        if(status === 200){
            return res.dashboard_metadata
        }
        else{
            return rejectWithValue(res.error)
        }
    }
    catch(err){
        return rejectWithValue((err as Error).message)
    }
    
})

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers:{
        dashboardMetadataAdded(state, action) {
            state.dashboard_metadata = action.payload
        }
        
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDashboard.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchDashboard.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Adding date and reactions
                state.dashboard_metadata = action.payload as Object as DashboardGalleryCard[]
            })
            .addCase(fetchDashboard.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message ? action.error.message : ''
            })
        }
})

export const selectDashboardMetadata = (state: RootState) => state.dashboard.dashboard_metadata;

export const selectDashboardStatus = (state: RootState) => state.dashboard.status

export const selectDashboardError = (state: RootState) => state.dashboard.error

export const { dashboardMetadataAdded } = dashboardSlice.actions

export default dashboardSlice.reducer
