import React, { useState } from 'react'
import {
    Card
} from '@mui/material'
import Highlighter from "react-highlight-words";
import { InfographicThumbnail } from '../../../interfaces/Infographic'
import style from './InfographicCard.module.css'
import BookmarkElement from '../BookmarkElement/BookmarkElement';
import { SnackbarMessage } from '../../../interfaces/Snackbar' 
import InfographicTagElement from '../InfographicTagElement/InfographicTagElement';
import { bookmarkInfographic, unbookmarkInfographic } from '../../../services/data-connector';
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch } from '../../../redux/store';
import { selectInfographicMetadata, selectInfographicStatus, infographicMetadataAdded } from '../../../redux/features/infographic/infographicSlice';
import { STATUS } from '../../../variable/enum';
import { updateInfoMetaBookmark } from '../../../utils/infographic';

interface infographicCardProps{
    infographicMetadata: InfographicThumbnail
    searchText: string,
    setOpenSnackBar: React.Dispatch<boolean>,
    setSnackPack: React.Dispatch<React.SetStateAction<readonly SnackbarMessage[]>>
    handleLoginPopUpFromBookmark: () => void,
    pathname: string
}

const InfographicCard = ({
    infographicMetadata, 
    searchText, 
    // for BookmarkElement only
    setOpenSnackBar, 
    setSnackPack,
    handleLoginPopUpFromBookmark,
    pathname
}: infographicCardProps) => {
    const remove_undefined = (text:string|undefined):string => (text ? text : '')
    const [isBookmarked, setIsBookmarked] = useState<boolean>(infographicMetadata.isBookmarked)

    const urlLink = '/infographic/' + infographicMetadata.infographicId

    const dispatch = useDispatch<AppDispatch>()
    const infographicStatus = useSelector(selectInfographicStatus)
    const infographicMetadatasFull = useSelector(selectInfographicMetadata)

    const setBookmarkInternal = (pageId: string) => {
        if(infographicStatus === STATUS.SUCCEEDED){
            let infographicMetadataTmp = updateInfoMetaBookmark(infographicMetadatasFull, {infographicId: pageId, isBookmarked: true})
            dispatch(infographicMetadataAdded(infographicMetadataTmp))
        }
    }

    const setUnbookmarkInternal = (pageId: string) => {
        if(infographicStatus === STATUS.SUCCEEDED){
            let infographicMetadataTmp = updateInfoMetaBookmark(infographicMetadatasFull, {infographicId: pageId, isBookmarked: false})
            dispatch(infographicMetadataAdded(infographicMetadataTmp))
        }
    }

  return (
    <a href={urlLink} target='_blank'>
        <Card>
            <div style = {{display:'grid', gridTemplateRows:'repeat(2, max-content)'}}>
                <img src={infographicMetadata.imgUrl} style={{width: '100%'}}></img>
                <div className={style.title}>
                    <Highlighter
                        sanitize={remove_undefined}
                        highlightStyle={{backgroundColor:'#bfdefc'}}
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={infographicMetadata.infographicTitle}
                    />    
                    <BookmarkElement 
                    pageId={infographicMetadata.infographicId}
                    isBookmarked={isBookmarked}
                    setIsBookmarked={setIsBookmarked}
                    setOpenSnackBar={setOpenSnackBar}
                    setSnackPack={setSnackPack}
                    urlLink={urlLink}
                    bookmarkTooltipText="ติดตามอินโฟกราฟิกนี้" 
                    unbookmarkTooltipText="เลิกติดตามอินโฟกราฟิกนี้"
                    bookmarkSnackbarText={`ติดตามอินโฟกราฟิก "${infographicMetadata.infographicTitle}" เรียบร้อยแล้ว`}
                    unbookmarkSnackbarText="เลิกติดตามอินโฟกราฟิกเรียบร้อยแล้ว"
                    callBookmarkAPI={bookmarkInfographic}
                    callUnbookmarkAPI={unbookmarkInfographic}
                    setBookmarkInternal={setBookmarkInternal}
                    setUnbookmarkInternal={setUnbookmarkInternal}
                    handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                    bookmarkIconStyle={{
                        position: 'absolute',
                        padding:0,
                        right:"-15px",
                        top:0
                    }}
                     />
                </div>
                
                <div style = {{textAlign:'left'}}>
                    {
                        infographicMetadata.tags.map((tag) => 
                            <InfographicTagElement key = {tag} tag={tag} searchText={searchText} pathname={pathname}/>
                        )
                    }
                </div>
            </div>
        </Card>

    </a>
    
    

  )
}

export default InfographicCard