import React from 'react'
import {
    Divider,
    Skeleton

} from '@mui/material'
import style from './DashboardSkeleton.module.css'

const DashboardSkeleton = () => {
  return (
    <div>
        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'middle',  marginBottom:'10px', marginTop:'20px'}}>
            <div style={{display:'flex',justifyContent:'flex-start',width:'500px', alignItems:'center'}}>
                <Skeleton variant = 'rounded' className = {style.tag}></Skeleton>
                <Skeleton variant = 'rounded' className = {style.tag}></Skeleton>
                <Divider orientation='vertical' flexItem/>
                <Skeleton variant='circular' height={40} width={40} sx={{marginLeft:'10px'}}></Skeleton>

            </div>
            <div style={{width:'170px'}}>
                <Skeleton variant = 'rounded' sx={{borderRadius: '50px',width:'100%', height:'40px'}}></Skeleton>

                
            </div>
        </div>

        <div className={style.dashboardBox}>
            <Skeleton variant='rectangular' sx={{width:'100%', height:'100%'}}/>
        </div>
    </div>
  )
}

export default DashboardSkeleton