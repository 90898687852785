import React from 'react'
import { DashboardTag, DashboardCategoryThai } from '../../../interfaces/DashboardTag'
import { Pro } from '../../../assets/dashboard';
import Highlighter from "react-highlight-words";
import style from './DashboardTagElement.module.css'
import { useHistory } from 'react-router-dom';
const dashboard_tag: DashboardTag[] = require('../../../variable/dashboard_tag.json')

interface DashboardElementTagProps {
    tag: DashboardCategoryThai, 
    pathname?: string,
    tiny?: boolean, 
    searchText?: string,
    lean?: boolean,
}

const DashboardTagElement = ({tag, tiny, searchText, pathname, lean}:DashboardElementTagProps) => {
    const remove_undefined = (text:string|undefined):string => (text ? text : '')
    const history = useHistory()
    const handleClickTag = (e: React.MouseEvent) => {
        if(pathname){
            // remove effect on ahref
            e.preventDefault()
            e.stopPropagation()


            let matchedCategory = dashboard_tag.find(({category_thai}) => category_thai === tag)
            if(matchedCategory){
                history.push({
                    pathname: pathname,
                    state:{
                        category: matchedCategory.category_eng,
                    },
                    search: "?category=" + matchedCategory.category_eng
                })
                window.scrollTo(0,0)

            }

        }
    }

    const handleClickTagPrivilege = (e: React.MouseEvent) => {
        if(pathname){
            // remove effect on ahref
            e.preventDefault()
            e.stopPropagation()
            
            history.push({
                pathname: pathname,
                state:{
                    dashboardType: 'privileged',
                },
                search: "?dashboardType=privileged"
            })
            window.scrollTo(0,0)

        }
        
    }
  return (
    (tag === 'แดชบอร์ดพิเศษ' ?
        <span onClick={handleClickTagPrivilege} className={`${tiny ? style.tinyTag : style.tag} ${lean ? style.lean : ''}`} style={{backgroundColor:'rgba(251, 188, 4, 0.3)', }}>
                <Pro style={tiny ? {marginBottom:'-2px', width: '15.75px', height:'12px'} : {marginBottom:'-2px'}}/>
        </span>
        :
        <>
            {
                typeof searchText !== "undefined" ?
                <span
                className = {`${tiny ? style.tinyTag : style.tag} ${lean ? style.lean : ''}`} 
                style={{backgroundColor:`${dashboard_tag.find(({category_thai}:{category_thai:string}) => category_thai == tag)!['color']}`, cursor: 'pointer'}}>
                    <Highlighter
                    sanitize={remove_undefined}
                    highlightStyle={{backgroundColor:'#bfdefc'}}
                    searchWords={[searchText!]}
                    autoEscape={true}
                    textToHighlight={tag} 
                    onClick = {(e: React.MouseEvent) => handleClickTag(e)}/>
                </span>
                
                :
                <span
                className = {`${tiny ? style.tinyTag : style.tag} ${lean ? style.lean : ''}`} 
                style={{backgroundColor:`${dashboard_tag.find(({category_thai}:{category_thai:string}) => category_thai == tag)!['color']}`, cursor: 'pointer'}} 
                onClick={(e: React.MouseEvent) => handleClickTag(e)}>
                    {tag}
                </span>   
            }
        </>)
  )
}

export default DashboardTagElement