import { ThemeOptions } from '@mui/material'

export const snackbarTheme: ThemeOptions = {
    components:{
        MuiSnackbarContent:{
            styleOverrides:{
                message:{
                    fontFamily: 'Kanit',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '0.17px',
                    textAlign: 'left',

                }
            }
        }
    }
}