import React, { useEffect, useMemo, useState } from 'react'
import {
  ThemeProvider,
  createTheme,
  TextField,
  Grid,
  Card,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  Radio,
  Box,
  FormControl,
  InputAdornment,
  IconButton,
  Checkbox,
  Typography,
  Autocomplete
} from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import style from './RegisterMemberTVL.module.css'
import CustomContainer from '../../components/shared/CustomContainer/CustomContainer'
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { BootstrapTooltip } from '../../components/shared/Tooltip/Tooltip'
import { mergeThemes } from '../../theme/merge';
import { register_textbox_theme } from '../../theme/register';
import { textbox_theme } from '../../theme/textbox';
import { registernew_theme } from '../../theme/registernew'
import { typography_theme } from '../../theme/typography'
import TVLButton from '../../components/shared/TVLButton/TVLButton';
import { RegisterTvlForm, mouValidate } from '../../validation/register'
import ErrorElement from '../../components/shared/ErrorElement/ErrorElement'
import ResponsePopUp from '../../components/shared/ResponsePopUp/ResponsePopUp'
import { ScrollToFieldError } from '../../components/shared/ScrollToFieldError/ScrollToFieldError'
import TextRequired from '../../components/shared/TextRequired/TextRequired'
import AgreementPopUp from '../../components/shared/AgreementPopUp/AgreementPopUp'
import { registerTvlUser } from '../../services/data-connector'


const RegisterMemberTVL = () => {
  const mergedTheme = mergeThemes(register_textbox_theme, textbox_theme, registernew_theme,typography_theme)
  const theme = createTheme(mergedTheme)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showPassword2, setShowPassword2] = useState<boolean>(false)
  const [errorForm, setErrorForm] = useState<string>('')
  const [responseStatus, setResponseStatus] = useState<'' | 'email_partner'>('')
  const [response, setResponse] = useState<React.ReactNode>(<></>)
  const [email, setEmail] = useState<string>('')
  const [agreementNo, setAgreementNo] = useState<number>(-1)
  const [ErrorSummary, setErrorSummary] = useState<React.ReactNode>(<></>)
  const containsText = (text: string, searchText: string) =>
        text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

  const year_list: string[] = []
    for (let i = 2565; i > 2460; i--) {
        year_list.push(String(i))
    }
    const displayedOptions = useMemo(
        () => year_list.filter((value) => containsText(value, searchText)),
        [searchText]
    )

  const [initialValues, setInitialValues] = useState<RegisterTvlForm>({
    firstName: '',
    lastName: '',
    organization: '',
    position: '',
    email: '',
    phoneNo: '',
    gender: '',
    password: '',
    password2: '',
    yearOfBirth: '',
    checkBox: false
  })
  
  let initialTouched = {
    firstName: false,
    lastName: false,
    organization: false,
    position: false,
    email: false,
    phoneNo: false,
    gender: false,
    password: false,
    password2: false,
    yearOfBirth: false,
    checkBox: false
  }
  const fragment = window.location.hash.substr(1)
  const encoded_data = fragment.split("data")[1]
  useEffect(() => {
    if (encoded_data) {
        const data = JSON.parse(decodeURIComponent(encoded_data))
        if(data.email){
          setInitialValues({...initialValues, email: data['email']})
          initialTouched['email'] = true
        }
    } else {
      window.location.href='/registration'
    }
  },[])

  const response_element = (email?:string) => {
    if(responseStatus === ''){
      return <></>
    } else if(responseStatus === 'email_partner'){
      return (
        <ResponsePopUp 
        open
        setOpen={() => setResponseStatus('')} 
        status='email'
        title= {`กรุณาตรวจสอบอีเมล ${email}`}
        text1='ข้อมูลการลงทะเบียนของท่านได้ถูกจัดเก็บเข้าระบบเรียบร้อยแล้ว'
        text2='กรุณาตรวจสอบอีเมลของท่านเพื่อทำการยืนยันตัวตน'
        button='กลับสู่หน้าหลัก'
        handleButton={() => {window.location.href = '/'}}/>
      )
    }
  }

  const handleSubmit  = async (value: RegisterTvlForm, action: FormikHelpers<RegisterTvlForm>) => {
    setIsLoading(true)
    let {phoneNo, ...payload} = value 
    if(phoneNo){
      payload = value
    }

    let {success, res} = await registerTvlUser(payload)

    if(success){
      setEmail(value.email)
      setResponseStatus('email_partner')
    } else if(res.detail === 'The email has already been registered.'){
        action.setFieldError('email','อีเมลนี้ลงทะเบียนไปแล้ว กรุณาใช้อีเมลอื่นในการสมัคร')
        setErrorForm('อีเมลนี้ลงทะเบียนไปแล้ว กรุณาใช้อีเมลอื่นในการสมัคร')

        // scroll to email field
        let emailElement = document.querySelector(`input[name='email']`)
        emailElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    } else{
      setErrorForm(res.detail)
    }
    

    setIsLoading(false)


  }

  useEffect(() => {
    setResponse(response_element(email))
  },[responseStatus])

  useEffect(() => {
    let errorElement = errorForm ? 
    (<div style ={{marginBottom:'10px'}}>
      <ErrorElement errorMessage={errorForm}/>
    </div>) : (<></>)
    setErrorSummary(errorElement)
  }
  ,[errorForm])
  return (
    <CustomContainer>
      {response}
      <AgreementPopUp open={agreementNo} setOpen={setAgreementNo}/>
      <ThemeProvider theme = {theme}>
      <div className = {style.title}>ลงทะเบียนสมาชิกทั่วไป</div>
      <div className={style.subtitle}>กรอกข้อมูลลงทะเบียนเพิ่มเติม</div>
      <Box sx = {{padding:{md: '0 30px', sm: '0 30px', xs: '0'}, marginBottom: '100px'}}>
        <Formik
        enableReinitialize
        initialValues={initialValues}
        initialTouched={initialTouched}
        validate={mouValidate}
        onSubmit = {(values, action) => {
          handleSubmit(values,action)
        }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <div className={style.header}>
                  ข้อมูลสำหรับเข้าใช้งาน
                </div>
                <div style={{ flex: 1, backgroundColor: '#ABABAB', height: '1px', margin: '0 10px' }}></div>
              </div>

              <Grid container columnSpacing={5} sx={{marginBottom:{md:'70px', sm:'70px', xs:'50px'}, paddingLeft:'24px', paddingRight: '24px'}}>
            <Grid item md={12} sm={12} xs={12}>
              <div className={style.label}>อีเมล<TextRequired /></div>
                <TextField 
                name="email"
                value={values.email}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.email && touched.email}
                helperText={!!errors.email && touched.email ? <ErrorElement errorMessage={errors.email} />: <></>}    
                placeholder='อีเมล'
                fullWidth
                disabled/>
                
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className={style.label}>
                  <BootstrapTooltip title = "ต้องมีความยาวอย่างน้อย 8 ตัว" placement='right' sx={{fontFamily:'Kanit'}}>
                    <span>
                      ตั้งค่ารหัสผ่าน<TextRequired />
                    </span>
                  </BootstrapTooltip>
                </div>
                <TextField 
                name="password"
                value={values.password}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.password && touched.password}
                helperText={!!errors.password && touched.password ? <ErrorElement errorMessage={errors.password} />: <></>}    
                placeholder='รหัสผ่าน'
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                  setShowPassword(
                                      !showPassword
                                  )
                              }
                              edge="end"
                          >
                              {showPassword ? (
                                  <VisibilityOffIcon sx={{marginRight: '7px'}}/>
                              ) : (
                                  <VisibilityIcon sx={{marginRight: '7px'}}/>
                              )}
                          </IconButton>
                      </InputAdornment>
                  ),
              }}
                fullWidth/>
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <div className={style.label}>
                    <span>
                      ยืนยันรหัสผ่าน<TextRequired />
                    </span>
                </div>
                <TextField 
                name="password2"
                value={values.password2}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.password2 && touched.password2}
                helperText={!!errors.password2 && touched.password2 ? <ErrorElement errorMessage={errors.password2} />: <></>}    
                placeholder='รหัสผ่าน'
                type={showPassword2 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password2 visibility"
                              onClick={() =>
                                  setShowPassword2(
                                      !showPassword2
                                  )
                              }
                              edge="end"
                          >
                              {showPassword2 ? (
                                  <VisibilityOffIcon sx={{marginRight: '7px'}} />
                              ) : (
                                  <VisibilityIcon sx={{marginRight: '7px'}}/>
                              )}
                          </IconButton>
                      </InputAdornment>
                  ),
              }}
                fullWidth/>
              </Grid>
            </Grid>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '25px', marginBottom:'10px'}}>
              <div className={style.header}>
                ข้อมูลผู้สมัครใช้งาน
              </div>
              <div style={{ flex: 1, backgroundColor: '#ABABAB', height: '1px', margin: '0 10px' }}></div>
            </div>
            
            <Grid container columnSpacing={5}rowSpacing={4} sx={{marginBottom: '35px'}}>
              <Grid item md={6} sm={6} xs={12} display="flex" flexDirection='column'>
                <div className={style.subheader}>รายละเอียดผู้สมัครใช้งาน</div>

                <Card sx={{flex: '1'}}>
                    <div className={style.label} style={{marginTop:'0'}}>ชื่อ<TextRequired /></div>
                    <TextField
                    name="firstName"
                    value={values.firstName}
                    onChange = {handleChange}
                    onBlur = {handleBlur}
                    error={!!errors.firstName && touched.firstName}
                    helperText={!!errors.firstName && touched.firstName ? <ErrorElement errorMessage={errors.firstName} />: <></>}     
                    placeholder='ชื่อ'
                    fullWidth/>

                    <div className={style.label}>นามสกุล<TextRequired /></div>
                    <TextField
                    name="lastName"
                    value={values.lastName}
                    onChange = {handleChange}
                    onBlur = {handleBlur}
                    error={!!errors.lastName && touched.lastName}
                    helperText={!!errors.lastName && touched.lastName ? <ErrorElement errorMessage={errors.lastName} />: <></>}     
                    placeholder='นามสกุล'
                    fullWidth/>

                    <div className={style.label}>ปีเกิด<TextRequired /></div>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={values.yearOfBirth} 
                    options={year_list}
                    onChange={(event: any, newValue: string | null) => {
                        setFieldValue('yearOfBirth', newValue)
                      }}
                    fullWidth
                    renderInput={(params) => 
                        <TextField  
                        {...params} 
                        placeholder='ปีเกิด (พ.ศ.)'
                        name="alliance" 
                        error={touched.yearOfBirth && !!errors.yearOfBirth}
                        helperText={
                            touched.yearOfBirth && !!errors.yearOfBirth ?
                            <ErrorElement errorMessage={errors.yearOfBirth} /> : <></>
                        }
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline legend':{
                                marginLeft: '10px'
                            }

                        }}
                        />}
                        sx={{
                          '.MuiInputBase-root':{
                            paddingLeft:'24px',
                            paddingTop: '6px',
                            paddingBottom: '6px'
                          }
                        }}
                    />
                    <div className={style.label}>เพศ<TextRequired /></div>
                    <RadioGroup
                        row
                        name="gender"
                        value={values.gender}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        <FormControlLabel
                            control={<Radio />}
                            value="male"
                            label="ชาย"
                        />
                        <FormControlLabel
                            control={<Radio />}
                            value="female"
                            label="หญิง"
                        />
                        
                        <FormControlLabel
                            control={<Radio />}
                            value="others"
                            label="ไม่ระบุ"
                        />
                    </RadioGroup>

                    {touched.gender && errors.gender ? (
                        <FormHelperText
                            error
                            sx={{
                                paddingLeft: "15px",
                                marginTop: "-10px",
                                marginBottom:
                                    "10px !important",
                            }}
                        >
                            {
                                <ErrorElement
                                    errorMessage={
                                        errors.gender
                                    }
                                />
                            }
                        </FormHelperText>
                    ) : (
                        <></>
                    )}
                    
                  </Card>
                </Grid>
                <Grid item md={6} sm={6} xs={12} container direction='column' display='flex'>
                  <Box>

                    <div className={style.subheader}>ข้อมูลสำหรับการติดต่อ</div>
                    <Card>
                      <div className={style.label} style={{marginTop:'0'}}>เบอร์โทรศัพท์มือถือ</div>
                      <TextField 
                      name="phoneNo"
                      value={values.phoneNo}
                      onChange = {handleChange}
                      onBlur = {handleBlur}
                      error={!!errors.phoneNo && touched.phoneNo}
                      helperText={!!errors.phoneNo && touched.phoneNo ? <ErrorElement errorMessage={errors.phoneNo} />: <></>}    
                      placeholder='เบอร์โทรศัพท์มือถือ'
                      fullWidth/>

                    </Card>

                  </Box>
                  <Box height={20}/>
                  
                  <Box flex="1" display='flex' flexDirection='column'>
                    <div className={style.subheader}>อาชีพ</div>
                    <Card sx={{flex:'1'}}>
                      <div className={style.label} style={{marginTop:'0'}}>องค์กร<TextRequired /></div>
                      <TextField 
                      name="organization"
                      value={values.organization}
                      onChange = {handleChange}
                      onBlur = {handleBlur}
                      error={!!errors.organization && touched.organization}
                      helperText={!!errors.organization && touched.organization ? <ErrorElement errorMessage={errors.organization} />: <></>}
                      placeholder='องค์กร'
                      fullWidth/>

                      <div className={style.label}>ตำแหน่ง<TextRequired /></div>
                      <TextField 
                      name="position"
                      value={values.position}
                      onChange = {handleChange}
                      onBlur = {handleBlur}
                      error={!!errors.position && touched.position}
                      helperText={!!errors.position && touched.position ? <ErrorElement errorMessage={errors.position} />: <></>}
                      placeholder='ตำแหน่ง'
                      fullWidth/>

                    </Card>

                  </Box>
                  
                  
                </Grid>
              


            </Grid>

            <FormControl sx={{left:'0', right:'0', margin:'auto', marginBottom:'35px'}}>
              <FormControlLabel control={
                <Checkbox name = "checkBox" 
                value={values.checkBox} 
                onChange={(e) => {
                  setFieldValue('checkBox', e.target.checked)
                  setErrorForm('')
                }}/>} 
                label={
                  <Box textAlign='left'>
                    ท่านได้ทำการอ่าน และยอมรับ 
                  <Typography variant='ahref' sx={{margin:'0 5px', fontWeight:'400'}} onClick={(e) => {e.preventDefault();setAgreementNo(0)}}>Privacy Policy</Typography>
                และ  
                <Typography variant='ahref' sx={{margin:'0 5px', fontWeight:'400'}} onClick={(e) => {e.preventDefault();setAgreementNo(1)}}>Terms & Condition</Typography> แล้ว </Box>
                } 
                sx={{
                  alignItems: 'flex-start',
                  '& .MuiCheckbox-root':{
                    paddingTop:'0'
                  }
                }} />
              </FormControl>
            <div>
            {ErrorSummary}
            <TVLButton type = 'submit' size = 'large' 
            onMouseDown={(event:React.MouseEvent<HTMLButtonElement>)=>{event.preventDefault()}}
            isLoading={isLoading}
            onClick={() =>{
              if(!values.checkBox && Object.keys(errors).length === 1){
                setErrorForm(errors.checkBox!)
              }
            }
              
            }>ลงทะเบียน</TVLButton>

            </div>
            </form>
          
          )}
        </Formik>

        


      </Box>
      
    </ThemeProvider>

    </CustomContainer>
    
    
  )
}

export default RegisterMemberTVL