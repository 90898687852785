const { decodeJwt } = require("jose")
import { AccountInfo, OldAccountInfo } from "../interfaces/AccountInfo"

interface AccountInterface{
    getIsLoggedIn(): boolean;
    getIsAdmin(): boolean;
    getIsPrivileged(): boolean;
    getOrgPending(): string[];
    getIsEmailVerified(): boolean;
    hasAvatarPicture(): boolean;
    //only exists if user is logged in
    getFirstName?(): string;
    getLastName?(): string;
    getRoleDescription?(): string;
    getAllRoles?(): string[];
    getApprovedOrg?(): string[]
    getAllApprovedRoles?(): string[]
    getAvatarPicture?(): string | undefined;
}

class NoAccount implements AccountInterface{
    getIsLoggedIn(): boolean {
        return false
    }
    getIsAdmin(): boolean{
        return false
    }
    getIsPrivileged(): boolean{
        return false
    }
    getOrgPending(): string[]{
        return []
    }
    getIsEmailVerified(): boolean {
        return false
    }
    hasAvatarPicture(): boolean {
        return false
    }
}

class UserAccount implements AccountInterface{
    private accountInfo: AccountInfo;

    constructor(){ 
        const tokenList = document.cookie
        .split(";")
        .filter((string) => string.includes("token="))
        let token_tmp = tokenList[0].replace("token=", "").trim()
        this.accountInfo = decodeJwt(token_tmp) as AccountInfo
    }

    getAccountInfo(): AccountInfo{
        return this.accountInfo
    }

    getIsLoggedIn(): boolean {

        return Boolean(
            this.accountInfo.exp !== 0 &&
                this.accountInfo.firstName &&
                this.accountInfo.lastName
        )
    }

    getIsAdmin(): boolean{
        if(this.accountInfo.privileges.some(({orgAbb, roles}) => (
            orgAbb === 'TLNK' || 
            roles.some(({roleName}) => (
              ["superAdmin", "admin"].includes(roleName)
            ))
          ))){
            return true
          }
          return false
    }

    getOrgPending(): string[]{
        let pendingOrg = []
        try{
            for(let privilege of this.accountInfo.privileges){
                if(privilege.roles.every(({status}) => status === "pending")){
                    pendingOrg.push(privilege.orgAbb)
                }
            }
        }
        catch{
            pendingOrg = []
        }
        return pendingOrg
    }

    getIsPrivileged(): boolean {
        if(!this.accountInfo.emailVerificationStatus){
            return false
        }
        
        for(let privilege of this.accountInfo.privileges){
            
            if(privilege.roles.some(({status}) => status === "approved")){
                return true
            }
        }
        return false
    }

    getApprovedOrg(): string[]{
        let approvedOrg = []
        for(let privilege of this.accountInfo.privileges){
            if(privilege.roles.some(({status}) => status === "approved")){
                approvedOrg.push(privilege.orgAbb)
            }
        }
        return approvedOrg
    }
    getRoleDescription(): string{
        if (this.accountInfo.privileges.length === 0) {
            return "ผู้ใช้งานทั่วไป"
        } else if (this.getOrgPending().length > 0 && this.getApprovedOrg().length === 0) {
            return "รออนุมัติสิทธิ"
        } else {
            return `สิทธิพิเศษจาก (${this.getApprovedOrg().join(",")})`
        }
    }

    getAllApprovedRoles(): string[] {
        let approvedRoles = []
        for(let privilege of this.accountInfo.privileges){
            for(let role of privilege.roles){
                if(role.status === "approved"){
                    approvedRoles.push(privilege.orgAbb + "-" + role.roleName)
                }
            }
        }
        return approvedRoles
    }

    getIsEmailVerified(): boolean {
        return this.accountInfo.emailVerificationStatus
    }

    getFirstName(): string {
        return this.accountInfo.firstName
    }

    getLastName(): string {
        return this.accountInfo.lastName
    }
    hasAvatarPicture(): boolean{
        return Boolean(this.accountInfo.avatarPicture)
    } 
    getAvatarPicture(): string | undefined{
        return this.accountInfo.avatarPicture
    } 
}

class OldUserAccount implements AccountInterface{

    private accountInfo: OldAccountInfo;

    constructor(){ 
        const tokenList = document.cookie
        .split(";")
        .filter((string) => string.includes("token="))
        let token_tmp = tokenList[0].replace("token=", "").trim()
        this.accountInfo = decodeJwt(token_tmp) as OldAccountInfo
    }

    getIsLoggedIn(): boolean{
        return true
    }
    getIsAdmin(): boolean{
        return false
    }
    getIsPrivileged(): boolean{
        return this.accountInfo.verifyStatus === "approved" && Object.keys(this.accountInfo.role).length > 0
    }
    getOrgPending(): string[]{
        return this.accountInfo.verifyStatus  === "pending" ? Object.keys(this.accountInfo.role) : []
    }
    getApprovedOrg(): string[]{
        return this.accountInfo.verifyStatus  === "approved" ? Object.keys(this.accountInfo.role)  : [] 
    }
    getIsEmailVerified(): boolean{
        return true
    }
    getFirstName(): string{
        return this.accountInfo.name
    }
    getLastName(): string{
        return this.accountInfo.surname
    }
    getRoleDescription(): string {
        if (Object.keys(this.accountInfo.role).length === 0) {
            return "ผู้ใช้งานทั่วไป"
        } else if (this.getOrgPending().length > 0 && this.getApprovedOrg().length === 0) {
            return "รออนุมัติสิทธิ"
        } else {
            return `สิทธิพิเศษ (${this.getApprovedOrg().join(",")})`
        }
    }
    getAllApprovedRoles(): string[] {
        if(this.accountInfo.verifyStatus === "approved"){
            return []
        }
        let approvedRoles: string[] = []
        for(let org of Object.keys(this.accountInfo.role)){
            approvedRoles.concat(this.accountInfo.role[org].map((role) => org+'-'+role))
        }
        return approvedRoles
    }
    hasAvatarPicture(): boolean{
        return Boolean(this.accountInfo.avatar)
    } 
    getAvatarPicture(): string | undefined{
        return this.accountInfo.avatar
    } 
}

export const getUserAccount = (): AccountInterface => {
    const tokenList = document.cookie
    .split(";")
    .filter((string) => string.includes("token="))
    if(tokenList.length !== 0){
        let jwtCredentials = tokenList[0].replace("token=", "").trim()
        if(jwtCredentials){
            let accountInfo = decodeJwt(jwtCredentials)
            if(Object.keys(accountInfo).includes('userid')){
                window.localStorage.setItem('isLoggedIn', 'false')
                return new OldUserAccount()
            }
            else if(Object.keys(accountInfo).includes('userId')){
                window.localStorage.setItem('isLoggedIn', 'true')
                return new UserAccount()
            }
            window.localStorage.setItem('isLoggedIn', 'false')
            return new NoAccount()
        }
        window.localStorage.setItem('isLoggedIn', 'false')
        return new NoAccount()
    }
    window.localStorage.setItem('isLoggedIn', 'false')
    return new NoAccount()
}