import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

interface ScrollToFieldErrorProps {
  scrollBehavior?: ScrollIntoViewOptions;
}

const getFieldErrorNames = (formikErrors: Record<string, any>): string[] => {
  const transformObjectToDotNotation = (
    obj: Record<string, any>,
    prefix = '',
    result: string[] = []
  ): string[] => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError: React.FC<ScrollToFieldErrorProps> = ({
  scrollBehavior = { behavior: 'smooth', block: 'center' },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;
  
    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;
  
    let closestField: HTMLInputElement | null = null;
    let closestPosition = Number.POSITIVE_INFINITY;
  
    // Iterate through error field names to find the closest one to the bottom
    fieldErrorNames.forEach((fieldName) => {
      const element = document.querySelector(`input[name='${fieldName}']`);
      if (!element) return;
  
      const elementRect = element.getBoundingClientRect();
      const bottomPosition = elementRect.bottom;
  
      if (bottomPosition < closestPosition) {
        closestPosition = bottomPosition;
        closestField = element as HTMLInputElement; // Type assertion here
      }
    });
  
    if (!closestField) return;
  
    // Scroll to the closest error field into view
    (closestField as HTMLInputElement).scrollIntoView(scrollBehavior);
    
    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]);
  

  return null;
};
