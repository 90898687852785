import React from 'react'
import { Card, Box, Skeleton } from '@mui/material'
import style from './DashboardSkeleton.module.css'

const DashboardSkeleton = () => {
  return (
    <Card>
        <Skeleton 
                variant='rectangular'
                width='100%'
                sx={{position:'relative', ':before':{content:'""', display:'block', paddingTop:'56.25%'}}}>
                    <div style={{position:'absolute', top:'0', left:'0', width:'100%', height:'100%'}}></div>
                </Skeleton>
                <Box display='flex' justifyContent='space-between' alignItems='flex-start' position='relative'>
                    <Box width={"90%"}>
                        <Skeleton className={style.title}></Skeleton>
                        <Skeleton className={style.title} sx={{marginTop:'-5px'}}></Skeleton>
                    </Box>
                    <Skeleton variant='circular' height={40} width={40} sx={{position:"absolute", top: 10, right: 0}}></Skeleton>
                    
                </Box>
            
                <div style = {{display:'flex'}}>
                    <Skeleton variant = 'rounded' className = {style.tag}></Skeleton>
                    <Skeleton variant = 'rounded' className = {style.tag}></Skeleton>
                </div>
                <Box>
                    <Skeleton className={style.shortDescription}></Skeleton>
                    <Skeleton className={style.shortDescription} sx={{marginTop:'-5px'}}></Skeleton>
                </Box>
                <Skeleton variant = 'rounded' sx={{borderRadius: '50px',width:'100%', height:'50px'}}></Skeleton>

                

        

    
    
    </Card>
  )
}

export default DashboardSkeleton