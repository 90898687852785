import { ThemeOptions } from "@mui/material";

export const tabTheme: ThemeOptions = {
    palette:{
        primary:{
            main: "#0182FA"
        }
    },
    components:{
        MuiTab:{
            variants:[
                {
                    props:{selected: true},
                    style:{
                        fontWeight:'500'
                    }
                } as any // props selected got flagged 
            ],
            styleOverrides:{
                root:{
                    fontFamily: "Kanit",
                    fontSize: "16px",
                    fontWeight: '400',
                    lineHeight: "24px",
                    letterSpacing: "0.40px",
                    textAlign: "left",
                    "& .MuiTab-root .Mui-selected":{
                        fontWeight: '500'
                    }
                }
            }
        }
    }
}