import React, { useCallback } from 'react'
import { 
  Box,
  ThemeProvider,
  createTheme,
  ToggleButton,
  ToggleButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'
import style from './DynamicFilterBar.module.css'
import { DataTypeThai, DataUpdateCycleThai, DashboardTypeThai, PortTypeThai, Sorting } from '../../../interfaces/FilterTypes';
import DynamicSelector from '../../../components/DynamicFilterBar/DynamicSelector/DynamicSelector';
import DynamicSearchSelector from '../../../components/DynamicFilterBar/DynamicSearchSelector/DynamicSearchSelector';
import { ProvinceThai } from '../../../interfaces/Province';
import { accordionTheme, breakPointTheme } from '../../../theme/filterBar';
import { DisplayMode } from '../../../interfaces/DisplayMode';
import { DashboardCategoryThai } from '../../../interfaces/DashboardTag';
import { Rows, Tiles, List } from '../../../assets/dashboard'
import { displayButton } from '../../../theme/displayButton';
import { useHistory } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { mergeThemes } from '../../../theme/merge';
import SortingMenu from '../../../components/DynamicFilterBar/SortingMenu/SortingMenu';
import SelectorMobileElement from '../../../components/DynamicFilterBar/SelectorMobileElement/SelectorMobileElement';

interface DynamicFilterBarProps{
    activeTag: DashboardCategoryThai,
    dashboardType: "" | DashboardTypeThai,
    setDashboardType: React.Dispatch<"" | DashboardTypeThai>
    dataUpdateCycle: "" | DataUpdateCycleThai,
    setDataUpdateCycle: React.Dispatch<"" | DataUpdateCycleThai>,
    dataType: "" | DataTypeThai,
    setDataType: React.Dispatch<"" | DataTypeThai>,
    portType: "" | PortTypeThai,
    setPortType: React.Dispatch<"" | PortTypeThai>,
    province: "" | ProvinceThai,
    setProvince: React.Dispatch<"" | ProvinceThai>,
    portName: string,
    setPortName: React.Dispatch<string>,
    handleReset: () => void,
    sorting: Sorting,
    setSorting: React.Dispatch<Sorting>
    displayMode: DisplayMode,
    setDisplayMode: React.Dispatch<DisplayMode>,
    dynamicFilter: {
        dashboardTypeFilter?: DashboardTypeThai[];
        dataUpdateCycleFilter: DataUpdateCycleThai[];
        dataTypeFilter: {
            dataTypeThai: DataTypeThai;
            correspondingList: any[];
        }[]
    }
}

const DynamicFilterBar = ({
  activeTag,
  dashboardType,
  setDashboardType,
  dataUpdateCycle,
  setDataUpdateCycle,
  dataType,
  setDataType,
  portType,
  setPortType,
  province,
  setProvince,
  portName,
  setPortName,
  handleReset,
  sorting,
  setSorting,
  displayMode,
  setDisplayMode,
  dynamicFilter}: DynamicFilterBarProps) => {

    const history = useHistory()
    const handleChangeDataType = (dataType: DataTypeThai) => {
        setPortType("")
        setProvince("")
        setPortName("")
        setDataType(dataType)
    }
    const handleChangePortType = (portType: PortTypeThai) => {
        setPortName("")
        setProvince("")
        setPortType(portType)
    }
    const isFiltered = (dashboardType !== "") || (dataUpdateCycle !== "") || (dataType !== "") 
    const mergedTheme = mergeThemes(accordionTheme, breakPointTheme)
    const theme = createTheme(mergedTheme)

    const filterCounts = useCallback(() => {
      let count = 0
      if(dashboardType !== ""){
        count += 1
      }
      if(dataUpdateCycle !== ""){
        count += 1
      }
      if(dataType !== ""){
        count += 1
      }
      if(portType !== ""){
        count += 1
      }
      if(province !== ""){
        count += 1
      }
      if(portName !== ""){
        count += 1
      }

      return count
    },[dashboardType,
      dataUpdateCycle,
      dataType,
      portType,
      province,
      portName])

    

  const handleDisplayModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newDisplayMode: DisplayMode | null,
  ) => {
    if (newDisplayMode !== null) {
      setDisplayMode(newDisplayMode);
    }
  };

  const DisplayMenu = () => (
    <ThemeProvider theme={createTheme(displayButton)}>
      <ToggleButtonGroup
        value={displayMode}
        exclusive
        onChange={handleDisplayModeChange}
        aria-label="display mode"
      >
        <ToggleButton value="rows" aria-label="rows" disableRipple>
          <Rows />
        </ToggleButton>
        <ToggleButton value="tiles" aria-label="tiles" disableRipple>
          <Tiles />
        </ToggleButton>
        <ToggleButton value="list" aria-label="list" disableRipple>
          <List />
        </ToggleButton>
      </ToggleButtonGroup>
    </ThemeProvider>
  )
  
  

   return (
    <ThemeProvider theme={theme}>
      {/* mobile  */}
      <Accordion sx={{display:{md:"none", sm:"block", xs:"block"}, marginBottom: '10px'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          ตัวกรอง {filterCounts() > 0 ? <div className={style.filterCountsCircle}><span className={style.filterCounts}>{filterCounts()}</span></div> : <></>}
        </AccordionSummary>
        <AccordionDetails>
          {
            dynamicFilter.dashboardTypeFilter ?
            <SelectorMobileElement
            selectorName='ประเภทแดชบอร์ด'
            placeholder='ประเภทแดชบอร์ด'
            value={dashboardType}
            setValue={setDashboardType as React.Dispatch<string>}
            dynamicList={dynamicFilter.dashboardTypeFilter}
            />
          :
          <></>
          }
          {
            dynamicFilter.dataUpdateCycleFilter ?
            <SelectorMobileElement
            selectorName='รอบการอัปเดต'
            placeholder='รอบการอัปเดต'
            value={dataUpdateCycle}
            setValue={setDataUpdateCycle as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataUpdateCycleFilter}
            />
          :
            <></>
          }
          {
            dynamicFilter.dataTypeFilter ?
            <SelectorMobileElement
            selectorName='การจำแนกข้อมูล'
            placeholder='การจำแนกข้อมูล'
            value={dataType}
            setValue={handleChangeDataType as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataTypeFilter.map(({dataTypeThai}) => dataTypeThai)}
            />
          :
            <></>
          }

          {
            dataType === "รายด่าน" 
            && 
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')!.correspondingList.length > 0 ?
            <SelectorMobileElement
            selectorName='ประเภทด่าน'
            placeholder='เลือกประเภทด่าน'
            value={portType}
            setValue={handleChangePortType as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน') ?
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')!.correspondingList.map(({portType}) => portType) : []}
            />
            :
            <></>
          }

          {
            dataType === "รายจังหวัด" 
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')!.correspondingList.length > 0 ?
            <SelectorMobileElement
            searchable
            selectorName='จังหวัด'
            placeholder='เลือกจังหวัด'
            value={province}
            setValue={setProvince as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด') ?
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')!.correspondingList : []}
            />
            :
            <></>
          }

          {
            dataType === "รายด่าน" 
            && 
            portType !== "" 
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')?.correspondingList.find((portElem) => portElem.portType === portType).portNameList.length > 0 ?
            <SelectorMobileElement
            searchable
            selectorName='ชื่อด่าน'
            placeholder='เลือกด่าน'
            value={portName}
            setValue={setPortName as React.Dispatch<string>}
            dynamicList={
              dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน') &&
              dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')?.correspondingList.find((portElem) => portElem.portType === portType).portNameList.length > 0 ?
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')?.correspondingList.find((portElem) => portElem.portType === portType).portNameList : []}
            />
            :
            <></>
          }
          <a onClick={handleReset} className={`${style.reset} ${isFiltered ? style.visible : style.hidden}`} >
            รีเซทตัวกรอง
          </a>

        </AccordionDetails>
      </Accordion>

      {/* desktop */}
      <Box sx={{display:'flex', justifyContent:{md:'space-between', sm:'flex-end', xs:'flex-end'}, alignItems:'center', marginBottom:3}}>
        <Box display={{md:'flex', sm: 'none', xs: 'none'}} alignItems='center'>
          <span className = {style.header}>ตัวกรอง: </span>
          {
            dynamicFilter.dashboardTypeFilter ?
            <DynamicSelector 
            selectorName="ประเภทแดชบอร์ด"
            value={dashboardType}
            setValue={setDashboardType as React.Dispatch<string>}
            dynamicList={dynamicFilter.dashboardTypeFilter}
            />
          :
          <></>
          }

          {
            dynamicFilter.dataUpdateCycleFilter ?
            <DynamicSelector 
            selectorName="รอบการอัปเดต"
            value={dataUpdateCycle}
            setValue={setDataUpdateCycle as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataUpdateCycleFilter}
            />
          :
            <></>
          }

          {
            dynamicFilter.dataTypeFilter ?
            <DynamicSelector 
            selectorName="การจำแนกข้อมูล"
            value={dataType}
            setValue={handleChangeDataType as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataTypeFilter.map(({dataTypeThai}) => dataTypeThai)}
            />
          :
            <></>
          }

          {
            dataType === "รายด่าน" 
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')
            && 
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')!.correspondingList.length > 0 ?
            <DynamicSelector
            selectorName='เลือกประเภทด่าน' 
            value={portType}
            setValue={handleChangePortType as React.Dispatch<string>}
            dynamicList={
              dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน') ?
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')!.correspondingList.map(({portType}) => portType) : []} />
            :
            <></>
          }

          {
            dataType === "รายจังหวัด" 
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')!.correspondingList.length > 0 ?
            <DynamicSearchSelector
            selectorName='เลือกจังหวัด' 
            value={province}
            setValue={setProvince as React.Dispatch<string>}
            dynamicList={
              dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด') ?
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')!.correspondingList : []} />
            :
            <></>
          }

          {
            dataType === "รายด่าน" 
            && 
            portType !== "" 
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')?.correspondingList.find((portElem) => portElem.portType === portType).portNameList.length > 0 ?
            <DynamicSearchSelector
            selectorName='เลือกด่าน' 
            value={portName}
            setValue={setPortName}
            dynamicList={
              dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน') &&
              dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')?.correspondingList.find((portElem) => portElem.portType === portType).portNameList.length > 0 ?
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')?.correspondingList.find((portElem) => portElem.portType === portType).portNameList : []} />
            :
            <></>
          }


          <a onClick={handleReset} className={`${style.reset} ${isFiltered ? style.visible : style.hidden}`} >
            รีเซทตัวกรอง
          </a>
          
          
        </Box>
        
        {
          // right menu
          activeTag === "ทั้งหมด" ?
          <DisplayMenu />
          :
          <SortingMenu
          bookmarkTabSelection='dashboard'
          dataType={dataType}
          sorting={sorting}
          setSorting={setSorting} />
        }
    </Box>

    </ThemeProvider>
  )
}

export default DynamicFilterBar