import React from 'react'
import Box from "@mui/material/Box"
import './NotFound.css'

function NotFound() {
    return (
        <div>
            <Box textAlign="center">
                <h1 className='not-found-text'> Page Not Found </h1>
            </Box>
        


        </div>
    )
}

export default NotFound
