import React, { useState } from 'react'
import {
    ThemeProvider,
    Tooltip,
    createTheme,
    Box,
    ClickAwayListener
} from '@mui/material'
import ImageIcon from '@mui/icons-material/Image';
import Fade from "@mui/material/Fade";
import style from './ImgIcon.module.css'
import close_icon from "./../../../../assets/close_button.svg"

const ImgIcon = ({imgSrc}: {imgSrc: string}) => {

    const theme = createTheme({
        components:{
            MuiTooltip:{
                styleOverrides:{
                    tooltip:{
                        backgroundColor: 'white',
                        color: 'rgba(0,0,0,0.87)',
                        maxWidth: 500,
                        border: "1px solid #CBCBCB80",
                        borderRadius: '12px',
                        boxShadow: "2px 19px 21px -8px #00000040",
                        padding: "16px"
                    }
                }
            },
            MuiSvgIcon:{
                styleOverrides:{
                    root:{
                        cursor: 'pointer'
                    }
                }
            }
            
        }
    })

    const smallTheme = createTheme({
        components:{
            MuiTooltip:{
                styleOverrides:{
                    tooltip:{
                        backgroundColor: 'white',
                        color: 'rgba(0,0,0,0.87)',
                        maxWidth: 330,
                        border: "1px solid #CBCBCB80",
                        borderRadius: '12px',
                        boxShadow: "2px 19px 21px -8px #00000040",
                        padding: "16px"
                    }
                }
            },
            MuiSvgIcon:{
                styleOverrides:{
                    root:{
                        cursor: 'pointer'
                    }
                }
            }
            
        }
    })


    const [open, setOpen] = useState<boolean>(false)

    
    const handleTooltipClose = () => {
        setOpen(false)
    }
  return (
    <>
    <Box display={{md: "block", sm: 'none', xs: 'none'}}>
            <ThemeProvider theme={theme}>

                <Tooltip 
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 0 }} 
                title={
                    <>
                    <a className={style.header}>รูปตัวอย่างแดชบอร์ด</a>
                    <br />
                    <br />
                    <img src={imgSrc} style={{width:'100%'}}></img>
                    </>
                }
                placement='right'>

                    <Box sx={{"& :hover":{
                        color: '#0182FA'
                    }}}>
                        <ImageIcon />
                    </Box>
                </Tooltip>
            </ThemeProvider>
        </Box>
        <Box display={{md: "none", sm: 'block', xs: 'block'}}>
            <ThemeProvider theme={smallTheme}>
                <ClickAwayListener
                onClickAway={handleTooltipClose}
                >
                    <Tooltip 
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 0 }} 
                        title={
                            <>
                            <Box
                                className={style.closeButton}
                                onClick={handleTooltipClose}
                            >
                                <img
                                    src={close_icon}
                                    style={{ borderRadius: "29px" }}
                                ></img>
                            </Box>
                            <a className={style.header}>รูปตัวอย่างแดชบอร์ด</a>
                            <br />
                            <br />
                            <img src={imgSrc}></img>
                            </>
                        }
                        placement='right'>

                            <Box 
                            sx={{
                                "& :hover":{
                                color: '#0182FA'
                            }}}
                            onClick={() => {
                                setOpen(true)
                            }}
                            >
                                <ImageIcon />
                            </Box>
                        </Tooltip>

                </ClickAwayListener>
                    
            </ThemeProvider>

        </Box>
    </>
        

    
  )
}

export default ImgIcon