import { ThemeProvider, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, createTheme, Skeleton } from '@mui/material';
import React from 'react'
import { tableTheme } from '../../../../theme/table';

const DashboardListSkeleton = ({
    columns
}: {
    columns: any[]
}) => {

    const rows = [1,2,3,4,5];

    const theme = createTheme(tableTheme)
  return (
    <ThemeProvider theme={theme}>
        <TableContainer sx={{marginBottom: '50px'}}>
            <Table>
                <TableHead>
                    <TableRow >
                    {
                        columns.map(({Header}) => (
                                    <TableCell>
                                        {Header}
                                    </TableCell>
                            )
                        )
                    }
                    </TableRow>
                    
                </TableHead>
                <TableBody>
                    {
                        rows.map(() => (
                            <TableRow >
                                {
                                    columns.map(({width}) => (
                                        <TableCell >
                                            <Skeleton width={width*0.8} />
                                        </TableCell>
                                        )
                                    )
                                }
                            </TableRow>
                        )
                        )
                    }

                </TableBody>
            </Table>
        </TableContainer>
        </ThemeProvider>
  )
}

export default DashboardListSkeleton