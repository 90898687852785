import { TextChunk } from "../interfaces/Alert";

export const createStatusPendingTextChunks = (pendingOrgs: string[]): TextChunk[] => [
    {
        value: "สิทธิพิเศษของท่านกำลัง"
    },
    {
        value:"รอดำเนินการ",
        color:{
            alertColor: "warning"
        }
    },
    {
        value:`ยืนยันโดยแอดมินของ ${pendingOrgs.join(' และ ')} ในระหว่างนี้ท่านสามารถใช้งานแดชบอร์ดทั่วไปได้ปกติ`
    }
]