import React from "react"
import Box from "@mui/material/Box"
import "./Unauthorized.css"

interface Props {
    children?: string;
}
  

const Unauthorized: React.FC<Props> = ({children}) => {
    return (
        <div>
            <Box textAlign="center">
                <h1 className="not-found-text">
                    {children ? children : "Unauthorized"}
                </h1>
            </Box>
        </div>
    )
}

export default Unauthorized
