import React, { useEffect, useRef, useState } from 'react'
import { 
    Box,
    Button,
    Container, 
    Grid
} from '@mui/material'
import style from './DataCatalogHome.module.css'
import { accommodation, activities, touristAttraction } from '../../../assets/home/catalog'
import TVLButton from '../../../components/shared/TVLButton/TVLButton'

const DataCatalogHome = () => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [isIntersecting2, setIsIntersecting2] = useState(false);
    const [isIntersecting3, setIsIntersecting3] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const ref3 = useRef<HTMLDivElement>(null);

    // the effect that detect if the user gets pass the section or not?
    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if(entry.isIntersecting){
                setIsIntersecting(entry.isIntersecting);
            }
          },
        );
        const observer2 = new IntersectionObserver(
            ([entry]) => {
                if(entry.isIntersecting){
                    setIsIntersecting2(entry.isIntersecting);
                }
            },
          );
          const observer3 = new IntersectionObserver(
            ([entry]) => {
                if(entry.isIntersecting){
                    setIsIntersecting3(entry.isIntersecting);
                }
            },
          );
        if(ref.current){
            observer.observe(ref.current);
        }else{
            return () => {
                observer.disconnect();
              };
        }
        if(ref2.current){
            observer2.observe(ref2.current);
        }else{
            return () => {
                observer2.disconnect();
              };
        }
        if(ref3.current){
            observer3.observe(ref3.current);
        }else{
            return () => {
                observer3.disconnect();
              };
        }

      }, []);
  return (
    <Box id="data-catalog" marginX={{xl:'auto', md: '42px', sm: '20px', xs: '40px'}} maxWidth='1500px' >
        <Box className={style.title} marginTop='90px' marginBottom='40px'>
            แค็ตตาล็อกข้อมูลท่องเที่ยว
        </Box>
        <Grid container spacing={3} justifyContent='center'>
            <Grid item md={4} sm={4} xs={12}>
                <a href='https://catalog.travellink.go.th/group/hotel'  target="_blank" className = {style.category}>
                    <img src={accommodation} className={style.img}></img>
                    <Box ref={ref} className={ `${style.hideBox} ${isIntersecting ? style.slideIn : ''}`}>
                        สถานที่พักแรม
                    </Box>
                </a>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
                <a href='https://catalog.travellink.go.th/group/attraction' target="_blank" className = {style.category}>
                    <img src={touristAttraction} className={style.img}></img>
                    <Box ref={ref2} className={ `${style.hideBox} ${isIntersecting2 ? style.slideIn : ''}`}>
                        แหล่งท่องเที่ยว
                    </Box>
                </a>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
                <a href='https://catalog.travellink.go.th/group/activities' target="_blank" className = {style.category}>
                    <img src={activities} className={style.img}></img>
                    <Box ref={ref3} className={ `${style.hideBox} ${isIntersecting3 ? style.slideIn : ''}`}>
                        กิจกรรมท่องเที่ยว
                    </Box>
                </a>
            </Grid>
        </Grid>

        <Box className={style.description} marginTop='50px' marginBottom='30px' marginX={{md:'200px', sm: '100px', xs:'-20px'}}>
        แค็ตตาล็อกข้อมูลท่องเที่ยว คือระบบบัญชีข้อมูล (Data Catalog) จัดทำโดย Travel Link 
        ภายใต้ความร่วมมือของรัฐและเอกชนด้านท่องเที่ยว ซึ่งประกอบด้วยชุดข้อมูลที่สำคัญ ชุดข้อมูลเปิด 
        และชุดข้อมูลพึงมี ด้านท่องเที่ยวของไทยทั้งหมด โดยมีจุดประสงค์เพื่อส่งเสริมการเข้าถึงและใช้ประโยชน์ข้อมูล
        </Box>
        <Box paddingBottom="100px" sx={{backgroundColor: 'transparent'}}>
            <a href='https://catalog.travellink.go.th/' target='_blank'>
                <TVLButton
                color='tertiary'
                size='medium'
                sx={{
                        padding: "14px 32px",
                        fontWeight: "500",
                        fontSize:{
                            md: '18px',
                            sm: '18px',
                            xs: '14px'
                        }
                    }}>
                        สำรวจชุดข้อมูล
                </TVLButton>

            </a>
            

        </Box>
    </Box>
  )
}

export default DataCatalogHome