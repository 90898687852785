import React, { useState, useRef, useEffect} from 'react'
import {
    Grid,
    Box,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    createTheme,
    ThemeProvider,
} from '@mui/material'
import { Dashboard, Catalog, Infographic, Pro, LocationPin, World, Assessment, NoAccount, AutoRenew, ManageSearch, Description, LibraryBook } from '../../../assets/home/products'
import style from './ProductList.module.css'
import { productListTheme } from '../../../theme/homeThemes'


const ProductList = () => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [isIntersecting2, setIsIntersecting2] = useState(false);
    const [isIntersecting3, setIsIntersecting3] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const ref3 = useRef<HTMLDivElement>(null);

    // the effect that detect if the user gets pass the section or not?
    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if(entry.isIntersecting){
                setIsIntersecting(entry.isIntersecting);
            }
          },
        );
        const observer2 = new IntersectionObserver(
            ([entry]) => {
                if(entry.isIntersecting){
                    setIsIntersecting2(entry.isIntersecting);
                }
            },
          );
          const observer3 = new IntersectionObserver(
            ([entry]) => {
                if(entry.isIntersecting){
                    setIsIntersecting3(entry.isIntersecting);
                }
            },
          );
        if(ref.current){
            observer.observe(ref.current);
        }else{
            return () => {
                observer.disconnect();
              };
        }
        if(ref2.current){
            observer2.observe(ref2.current);
        }else{
            return () => {
                observer2.disconnect();
              };
        }
        if(ref3.current){
            observer3.observe(ref3.current);
        }else{
            return () => {
                observer3.disconnect();
              };
        }

      }, []);

    const theme = createTheme(productListTheme)

      const handleClickToProduct = (productId: string) => {
        const searchElement = document.querySelector(productId) 
        if(searchElement){
            searchElement.scrollIntoView({behavior:'smooth',block:'start'})
        }  
      }

  return (
        <Box paddingTop='50px' paddingBottom='50px' sx={{backgroundColor: '#F1F7FD'}} id='product-list'>
            <Container >
                <Box  marginBottom='50px' textAlign='center' className={style.title} >บริการของเรา</Box>
                <ThemeProvider theme = {theme}>
                    <Grid container rowSpacing='50px'> 
                        <Grid item md={4} sm={4} xs={12}>
                            <a onClick={() => handleClickToProduct('#dashboard')} style={{cursor: 'pointer'}}>
                                <Box display='flex' justifyContent="center" alignItems='center' marginBottom='20px' >
                                    
                                    <Dashboard />
                                        <Box className={style.header}>
                                            แดชบอร์ด
                                            <br />
                                            ข้อมูลท่องเที่ยว
                                        </Box>
                                </Box>
                            </a>
                            <Box ref={ref} className={ `${style.hideBox} ${isIntersecting ? style.slideIn : ''}`}>
                                <List dense sx={{float:{md: 'none', sm:'none', xs:'left'}}}>
                                    <ListItem key='world'>
                                        <ListItemIcon>
                                            <World />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="มีแดชบอร์ดทั่วไปมากกว่า 150+ แดชบอร์ด"
                                        />
                                    </ListItem>
                                    <ListItem key='locationPin'>
                                        <ListItemIcon>
                                            <LocationPin />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="ครอบคลุมกว่า 40+ จังหวัดทั่วประเทศ"
                                        />
                                    </ListItem>
                                    <ListItem key='pro'>
                                        <ListItemIcon>
                                            <Pro />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="บริการแดชบอร์ดพิเศษสำหรับหน่วยงานพันธมิตร"
                                        />
                                    </ListItem>
                                </List>

                            </Box>
                        </Grid>
                        <Grid item md={4} sm={4} xs={12}>
                            <a onClick={() => handleClickToProduct("#infographic")} style={{cursor: 'pointer'}}>
                                <Box display='flex' justifyContent="center" alignItems='center' marginBottom='20px'>
                                    <Infographic />
                                    <Box className={style.header}>
                                        อินโฟกราฟิก
                                        <br />
                                        ข้อมูลท่องเที่ยว
                                    </Box>
                                </Box>
                            </a>
                            <Box ref={ref2} className={ `${style.hideBox} ${isIntersecting2 ? style.slideIn : ''}`}>
                                <List dense sx={{float:{md: 'none', sm:'none', xs:'left'}}}>
                                    <ListItem key='assessment'>
                                        <ListItemIcon>
                                            <Assessment />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="เห็นภาพรวมของข้อมูล"
                                        />
                                    </ListItem>
                                    <ListItem key='noAccount'>
                                        <ListItemIcon>
                                            <NoAccount />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="ดูได้โดยไม่ต้องลงชื่อเข้าใช้"
                                        />
                                    </ListItem>
                                    <ListItem key='autoRenew'>
                                        <ListItemIcon>
                                            <AutoRenew />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="อัปเดตรายวันและรายเดือน"
                                        />
                                    </ListItem>
                                </List>

                            </Box>
                        </Grid>
                        <Grid item md={4} sm={4} xs={12}>
                            <a onClick={() => handleClickToProduct("#data-catalog")} style={{cursor: 'pointer'}}>
                                <Box display='flex' justifyContent="center" alignItems='center' marginBottom='20px'>
                                    <Catalog />
                                    <Box className={style.header}>
                                        แค็ตตาล็อก
                                        <br />
                                        ข้อมูลท่องเที่ยว
                                    </Box>
                                </Box>

                            </a>
                            <Box ref={ref3} className={ `${style.hideBox} ${isIntersecting3 ? style.slideIn : ''}`}>
                                <List dense sx={{float:{md: 'none', sm:'none', xs:'left'}}}>
                                    <ListItem key='manageSearch'>
                                        <ListItemIcon>
                                            <ManageSearch />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="ค้นหาข้อมูลได้ง่าย"
                                        />
                                    </ListItem>
                                    <ListItem key='description'>
                                        <ListItemIcon>
                                            <Description />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="ให้รายละเอียดข้อมูลที่ชัดเจน"
                                        />
                                    </ListItem>
                                    <ListItem key='libraryBook'>
                                        <ListItemIcon>
                                            <LibraryBook />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="เชื่อมโยงข้อมูลจากหลายแค็ตตาล็อก"
                                        />
                                    </ListItem>
                                </List>

                            </Box>
                        </Grid>

                    </Grid>
                </ThemeProvider>

            </Container>

        </Box>


  )
}

export default ProductList