import { Alert, AlertColor, Box, styled, Typography, useTheme } from '@mui/material'
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import React from 'react'
import { TextChunk } from '../../../interfaces/Alert'

interface AlertBarProps{
    isShowing: boolean
    color: AlertColor
    texts: TextChunk[]
    handleCloseAlert: () => void
}

const StyledAlert = styled(Alert)(() => ({
    display: 'flex', 
    justifyContent: "center",
    alignItems: "center",
    "& .MuiAlert-message": {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
    },
    borderRadius: '0'
}))

const AlertBar = ({isShowing, color, texts, handleCloseAlert}: AlertBarProps) => {
    const theme = useTheme()
  return (
    <>
    {
        isShowing &&
        <StyledAlert severity={color}>
            <Box>
            {
                texts.map(({value, fontSize, fontWeight, color, link }, index) => {
                    const alertColor = color?.alertColor && theme.palette[color.alertColor].main
                    const hexColor = color?.hex
                    const realColor = alertColor || hexColor || "inherit"
                    return(
                    link ? (
                        <a href={link} key={index} target="_blank">
                          <Typography variant='body2' sx={{
                            fontSize: `${fontSize}px`,
                            fontWeight,
                            color: realColor,
                            display: 'inline-flex',
                            textDecoration: 'underline',
                            whiteSpace:'pre-wrap'
                          }}>
                            {value}
                          </Typography>
                        </a>
                      ) : (
                        <Typography
                          key={index}
                          variant='body2'
                          sx={{
                            fontSize,
                            fontWeight,
                            color: realColor,
                            display: 'inline-flex',
                            whiteSpace:'pre-wrap'
                          }}
                        >
                          {value}
                        </Typography>
                      ))})
            }
            </Box>
            <CloseRoundedIcon
                sx={{
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    marginLeft: "20px",
                    verticalAlign: "sub",
                    cursor: "pointer",
                }}
                onClick={() => handleCloseAlert()}
            />
        </StyledAlert>
    }
    </>
  )
}

export default AlertBar