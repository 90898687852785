import React from 'react'
import {
    Box,
    Card
} from '@mui/material'
import style from './SeeMoreCard.module.css'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

interface SeeMoreCardProps{
    categoryName: string
}

const SeeMoreCard = ({categoryName}: SeeMoreCardProps) => {
  return (
    <Card>
        <Box className={style.text} display='flex' justifyContent='space-between' alignItems='center' height='100%'>
            <Box>
            ดูแดชบอร์ด
            <br />
            {categoryName}ทั้งหมด
            </Box>
            <ArrowForwardIosRoundedIcon />
        </Box>
    </Card>
  )
}

export default SeeMoreCard