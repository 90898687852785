import React, { useState } from 'react'
import {
    Box,
    Grid,
    ThemeProvider,
    createTheme,
} from '@mui/material'
import { categoricalMetadataType } from '../../../../utils/dashboard';
import style from './DashboardRow.module.css'
import DashboardCard from '../../../../components/shared/DashboardCard/DashboardCard';
import { SnackbarMessage } from '../../../../interfaces/Snackbar'
import { Swiper,SwiperSlide } from '../../../../components/shared/Swiper/Swiper';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import './DashboardRow.css'
import { padded_dashboard_card_theme } from '../../../../theme/dashboard';
import SeeMoreCard from './SeeMoreCard/SeeMoreCard';

interface DashboardRowProps{
    categoricalDashboardMetadata: categoricalMetadataType
    searchText: string,
    activeTag?: string,
    dashboardType?: string,
    dataUpdateCycle?: string,
    dataType?: string,
    portType?: string,
    province?: string,
    portName?: string,
    setActiveTag: () => void,
    isBookmarkRow: boolean,
    setOpenSnackBar: React.Dispatch<boolean>,
    setSnackPack: React.Dispatch<React.SetStateAction<readonly SnackbarMessage[]>>,
    handleLoginPopUp: (link: string) => void
    handleLoginPopUpFromBookmark: () => void
}

const DashboardRow = ({
    categoricalDashboardMetadata,
    searchText,
    activeTag,
    dashboardType,
    dataUpdateCycle,
    dataType,
    portType,
    province,
    portName,
    setActiveTag,
    isBookmarkRow,
    setOpenSnackBar,
    setSnackPack,
    handleLoginPopUp,
    handleLoginPopUpFromBookmark
}: DashboardRowProps) => {

    const [uniqueNo, setUniqueNo] = useState<number>(JSON.stringify(categoricalDashboardMetadata.dashboardGalleries).length)
    const theme = createTheme(padded_dashboard_card_theme)
    const windowWidth = window.innerWidth
    const calMaxPages = () => {
        let maxPages = 4
        if(windowWidth > 950){
            maxPages = 6
        } else if(windowWidth > 750){
            maxPages = 5
        }
        return maxPages
    }
    const maxPages = calMaxPages()
    const isSeeMore = categoricalDashboardMetadata.dashboardGalleries.length > maxPages
    return (
    <Box marginBottom={5}>
        <Box display='flex' justifyContent='flex-start' alignItems='flex-end' marginBottom={2.5}>
            <span className = {style.categoryHeader}>
                {categoricalDashboardMetadata.category}
            </span>
            {
                isBookmarkRow ?
                <a className = {style.categorySeemore} style={{color:'#A6A8AB' }} href="/bookmark?tabSelection=dashboard" target='_blank' >
                    ดูทั้งหมด<ArrowForwardIosRoundedIcon sx={{height:'15px', marginBottom: '-1px'}}/>
                </a>
                :
                <a className = {style.categorySeemore} onClick={setActiveTag}>
                    ดูทั้งหมด<ArrowForwardIosRoundedIcon sx={{height:'15px', marginBottom: '-1px'}}/>
                </a>
            }
            
        </Box>
        <Box padding={{
            md:"0 60px",
            sm: "0 50px",
            xs: "0 30px"
            }} 
            position="relative">
        <span className={`custom_prev_${uniqueNo} ${style.prev}`}>
            <ArrowBackIosRoundedIcon/>
        </span>
        <span className={`custom_next_${uniqueNo} ${style.next}`}>
            <ArrowForwardIosRoundedIcon/>
        </span>
            <Swiper
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
                // when window width is >= 600px
                750: {
                slidesPerView: 2,
                spaceBetween: 20
                },
                // when window width is >= 900px
                950: {
                slidesPerView: 3,
                spaceBetween: 20
                }
            }}
            navigation={{
                nextEl: `.custom_next_${uniqueNo}`,
                prevEl: `.custom_prev_${uniqueNo}`
            }}
            pagination={{
                clickable: true
            }}
            >
                {
                    categoricalDashboardMetadata.dashboardGalleries.slice(0,maxPages).map((dashboardMetadata) => (
                        <SwiperSlide
                        key={dashboardMetadata.galleryId}
                        style={{height: "auto"}}>
                                <Grid width='99%' height="99%" item>
                                    <DashboardCard
                                    padded
                                    dashboardMetadata={dashboardMetadata}
                                    searchText={searchText}
                                    activeTag={activeTag}
                                    dashboardType={dashboardType}
                                    dataUpdateCycle={dataUpdateCycle}
                                    dataType={dataType}
                                    portType={portType}
                                    province={province}
                                    portName={portName}
                                    setOpenSnackBar={setOpenSnackBar} 
                                    setSnackPack={setSnackPack}
                                    handleLoginPopUp={handleLoginPopUp} 
                                    handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                                    pathname='/dashboard'
                                    />
                                </Grid>
                        </SwiperSlide>
                    ))
                }
                {
                    isSeeMore ?
                    // if dashboard are more than the limit, show see more
                        <SwiperSlide
                        key='seeMore'
                        style={{height: "auto"}}>
                            <ThemeProvider theme={theme}>
                                <Grid width='99%' height="99%" item>
                                    {
                                        isBookmarkRow ?
                                        <a href="/bookmark?tabSelection=dashboard" target='_blank' >
                                            <SeeMoreCard categoryName={categoricalDashboardMetadata.category} />
                                        </a>
                                        :
                                        <a onClick={setActiveTag}>
                                            <SeeMoreCard categoryName={categoricalDashboardMetadata.category} />
                                        </a>
                                    }
                                    
                                </Grid>
                            </ThemeProvider>
                        </SwiperSlide>
                    :
                    <></>
                }
                
            </Swiper>
            
        </Box>
    </Box>
  )
}

export default DashboardRow