import { createTheme } from '@mui/material'
import { ThemeOptions } from '@mui/material/styles'
import { mergeThemes } from './merge'

const form_radio_theme: ThemeOptions = {
    palette: {
        error:{
            main: '#FF3B3B'
        }
    },
    components:{
        MuiFormControlLabel:{
            styleOverrides:{
                label:{
                    fontFamily: "Kanit"
                }
            }
        },
        MuiFormGroup:{
            styleOverrides:{
                root:{
                    paddingLeft: "19px"
                }
            }
        }
    }
}

export const register_textbox_theme: ThemeOptions = {
    palette: {
        error:{
            main: '#FF3B3B'
        }
    },
    components:{
        MuiOutlinedInput:{
            styleOverrides:{
                root:{
                    borderRadius: "40px",
                },
                input:{
                    paddingLeft:'26px',
                }
            }
        },
        MuiSelect:{
            styleOverrides:{
                select:{
                    paddingLeft: '16px'
                }
            }
        },
        MuiSvgIcon:{
            styleOverrides:{
                root:{
                    right:'12px !important'
                }
            }
        }
    }
} 



export const register_theme = mergeThemes(form_radio_theme, register_textbox_theme)