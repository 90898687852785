import { ThemeOptions } from "@mui/material";

export const toggle_button: ThemeOptions = {
    components:{
        MuiToggleButton:{
            variants:[
                {
                    props:{selected: true},
                    style:{
                        backgroundColor: '#FFFFFF !important',
                        borderColor: '#0182FA ',
                        color: '#0182FA !important'
                    }
                }
            ],
            styleOverrides:{
                root:{
                    borderRadius: '50px',
                    paddingTop: '12.5px',
                    paddingBottom: '12.5px',
                    borderColor:'rgba(0, 0, 0, 0.23)',
                    '&:hover':{
                        backgroundColor: 'white',
                        borderColor: '#0182FA'
                    }
                }
            }
        },
        MuiDivider:{
            styleOverrides:{
                root:{
                    margin: '0 10px'
                }
            }
        },
        MuiRadio:{
            styleOverrides:{
                root:{
                    padding: '2px',
                    marginRight:'10px'
                }
            }
        },
        MuiCheckbox:{
            styleOverrides:{
                root:{
                    padding: '2px',
                    marginRight:'10px'
                }
            }
        },
        MuiFormControlLabel:{
            styleOverrides:{
                root:{
                    marginLeft:'-5px',
                    marginRight: '0'
                },
                label:{
                    padding:'5px 0'
                }
            }
        }
    }
}

export const dashboard_card: ThemeOptions = {
    components:{
        MuiCard:{
            styleOverrides:{
                root:{
                    padding:'25px',
                    border: '1px solid #CBCBCB',
                    borderRadius:'12px',
                    cursor: 'pointer',
                    marginBottom:'20px',
                    boxShadow: 'none',
                    '&:hover':{
                        boxShadow: 'none',
                        backgroundColor:'#F1F7FD',
                        border: '1px solid #0182FA'
                    },

                    display:'flex',
                    flexDirection: 'column',
                    justifyContent:'space-between',
                    height:'100%'
                }
            }
        },
        MuiInputBase:{
            styleOverrides:{
                input:{
                    zIndex:'1'
                }
            }
        },
        MuiTooltip:{
            styleOverrides:{
                tooltip:{
                    fontFamily: 'Kanit',
                    fontSize: '10px',
                    fontWeight: '400',
                    lineHeight: '14px',
                    letterSpacing: '0px',
                    textAlign: 'center',

                }
            }
        },
        MuiSnackbarContent:{
            styleOverrides:{
                message:{
                    fontFamily: 'Kanit',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '0.17px',
                    textAlign: 'left',

                }
            }
        }
    }
}

export const select_theme: ThemeOptions = {
    components:{
        MuiSelect:{
            styleOverrides:{
                select:{
                    padding: '13.5px 32px',
                    zIndex: '1'
                }
            }
        },
        MuiMenuItem:{
            variants:[
                {
                    props:{selected:true},
                    style:{
                        backgroundColor:'white !important', 
                        color:'#0182FA',
                        fontFamily: 'Kanit',
                        fontSize: '16px',
                        fontWeight: '600',
                        letterSpacing: '0.005em',
                        textAlign: 'left',
                    }
                }
            ],
            styleOverrides:{
                root:{
                    fontFamily: 'Kanit',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '28px',
                    letterSpacing: '0.005em',
                    textAlign: 'left'
                }
            }
        },
        MuiOutlinedInput:{
            styleOverrides:{
                notchedOutline:{
                    backgroundColor:'white',
                }
            }
        },
        MuiMenu:{
            styleOverrides:{
                paper:{
                    maxHeight: '300px',
                    borderRadius:'12px'
                }
            }
        },
        MuiSvgIcon:{
            styleOverrides:{
                root:{
                    right:'12px !important',
                    zIndex:'1'
                }
            }
        }
    }
}

export const pagination_theme: ThemeOptions = {
    components:{
        MuiPaginationItem:{
            styleOverrides:{
                root:{
                    fontFamily: 'Kanit',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '15px',
                    lineHeight: '22px',
                    textAlign: 'center',
                }
            }
        }
    }
}

export const share_dialog_theme: ThemeOptions = {
    components:{
        MuiDialog:{
            styleOverrides:{
                paper:{
                    borderRadius:'12px',
                    padding:'25px 25px 10px 25px'
                }
            }
        },
        MuiOutlinedInput:{
            styleOverrides:{
                input:{
                    paddingRight: '0 !important'
                }
            }
        }
    }
}

export const bookmark_dialog_theme: ThemeOptions = {
    components:{
        MuiDialog:{
            styleOverrides:{
                paper:{
                    borderRadius:'12px',
                    minWidth:'500px',
                    padding:'25px 25px 25px 25px',
                    justifyContent: 'center',
                    alignItems:'center'
                }
            }
        },
        MuiFormControlLabel:{
            styleOverrides:{
                label:{
                    fontFamily: 'Kanit',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    letterSpacing: '0em',
                    textAlign: 'left'

                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
              primary: {
                fontFamily: 'Kanit',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '0em',
                textAlign: 'left'
              },
            },
          },
          MuiOutlinedInput:{
            styleOverrides:{
                root:{
                    textAlign: 'left',
                    fontFamily: 'Kanit',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#0182FA',
                    },
                    
                },
                input:{
                    padding:'13.5px 24px'

                }
            }
        }
    }
}

export const padded_dashboard_card_theme: ThemeOptions = {
    breakpoints:{
        values:{
            xs: 0,
            sm: 750,
            md: 950,
            lg: 1200,
            xl: 1536,
        }
    },
    // textbox theme
    palette: {
        action: {
            disabled: '#EFF1F5 !important'
        },
        text:{
            disabled: '#515151'
        }
    },
    components:{
        MuiTextField: {
            variants: [
              {
                props: { variant: 'outlined', disabled: true },
                style: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: '#EFF1F5',
                        zIndex:-1
                    },
                    color: '#515151'
                }
              }
            ]
          },
        MuiOutlinedInput:{
            variants: [
                {
                  props: { disabled: true },
                  style: {
                    zIndex:1
                  }
                }
              ],
            styleOverrides:{
                root:{
                    textAlign: 'left',
                    fontFamily: 'Kanit',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#0182FA',
                    },
                    borderRadius: "40px",
                },
                input:{
                    padding:'13.5px 24px'

                },
                notchedOutline:{
                    backgroundColor:'white',
                }
            }
        },
        MuiSelect:{
            styleOverrides:{
                select:{
                    padding: '13.5px 32px',
                    zIndex: '1'
                },
            }
        },
        MuiSvgIcon:{
            styleOverrides:{
                root:{
                    right:'12px !important',
                    zIndex: '1'
                }
            }
        },
        MuiCard:{
            styleOverrides:{
                root:{
                    padding:'25px',
                    border: '1px solid #CBCBCB',
                    borderRadius:'12px',
                    cursor: 'pointer',
                    marginBottom:'20px',
                    boxShadow: 'none',
                    '&:hover':{
                        boxShadow: 'none',
                        backgroundColor:'#F1F7FD',
                        border: '1px solid #0182FA'
                    },

                    display:'flex',
                    flexDirection: 'column',
                    justifyContent:'space-between',
                    height:'100%'
                }
            }
        },
        MuiInputBase:{
            styleOverrides:{
                input:{
                    zIndex:'1'
                },
            }
        },
        MuiTooltip:{
            styleOverrides:{
                tooltip:{
                    fontFamily: 'Kanit',
                    fontSize: '10px',
                    fontWeight: '400',
                    lineHeight: '14px',
                    letterSpacing: '0px',
                    textAlign: 'center',

                }
            }
        },
        MuiSnackbarContent:{
            styleOverrides:{
                message:{
                    fontFamily: 'Kanit',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '0.17px',
                    textAlign: 'left',

                }
            }
        },
        MuiMenuItem:{
            variants:[
                {
                    props:{selected:true},
                    style:{
                        backgroundColor:'white !important', 
                        color:'#0182FA',
                        fontFamily: 'Kanit',
                        fontSize: '16px',
                        fontWeight: '600',
                        letterSpacing: '0.005em',
                        textAlign: 'left',
                    }
                }
            ],
            styleOverrides:{
                root:{
                    fontFamily: 'Kanit',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '28px',
                    letterSpacing: '0.005em',
                    textAlign: 'left'
                }
            }
        },
        MuiMenu:{
            styleOverrides:{
                paper:{
                    maxHeight: '300px',
                    borderRadius:'12px'
                }
            }
        },
    }
}