export const THAI_AIR_PORT_LIST = [
  {
    pageName: 'ทุกด่านตรวจคนเข้าเมืองทางอากาศ',
    pageNameEng: 'Overview'
  },
  { pageName: 'ท่าอากาศยานกระบี่', pageNameEng: 'Krabi' },
  { pageName: 'ท่าอากาศยานชลบุรี', pageNameEng: 'Chonburi' },
  { pageName: 'ท่าอากาศยานเชียงราย', pageNameEng: 'Chaingrai' },
  { pageName: 'ท่าอากาศยานเชียงใหม่', pageNameEng: 'Chiang Mai' },
  { pageName: 'ท่าอากาศยานดอนเมือง', pageNameEng: 'Don Mueang' },
  { pageName: 'ท่าอากาศยานตราด', pageNameEng: 'Trad' },
  { pageName: 'ท่าอากาศยานตาก', pageNameEng: 'Tak' },
  { pageName: 'ท่าอากาศยานบุรีรัมย์', pageNameEng: 'Burirum' },
  { pageName: 'ท่าอากาศยานเบตง', pageNameEng: 'Betong' },
  { pageName: 'ท่าอากาศยานฝอ.2', pageNameEng: 'Po2' },
  { pageName: 'ท่าอากาศยานฝอ.6', pageNameEng: 'Po6' },
  { pageName: 'ท่าอากาศยานภูเก็ต', pageNameEng: 'Phuket' },
  { pageName: 'ท่าอากาศยานมุกดาหาร', pageNameEng: 'Mukdahan' },
  { pageName: 'ท่าอากาศยานสมุย', pageNameEng: 'Ko Samui' },
  { pageName: 'ท่าอากาศยานสุราษฎร์ธานี', pageNameEng: 'Suratthani' },
  { pageName: 'ท่าอากาศยานสุรินทร์', pageNameEng: 'Surin' },
  { pageName: 'ท่าอากาศยานสุวรรณภูมิ', pageNameEng: 'Suvarnabhumi' },
  { pageName: 'ท่าอากาศยานหาดใหญ่', pageNameEng: 'Hat Yai' },
  { pageName: 'ท่าอากาศยานอุดรธานี', pageNameEng: 'Udonthani' },
  {
    pageName: 'ท่าอากาศยานอุบลราชธานี',
    pageNameEng: 'Ubonratchathani'
  },
  { pageName: 'ท่าอากาศยานอู่ตะเภา', pageNameEng: 'U-tapao' }
]

export const THAI_SEA_PORT_LIST = [
  { pageName: 'ทุกด่านตรวจคนเข้าเมืองทางน้ำ', pageNameEng: 'Overview' },
  {
    pageName: 'ด่านจุดตรวจท่าเทียบเรือการท่องเที่ยว',
    pageNameEng: 'Nakhon Panom'
  },
  {
    pageName: 'ด่านจุดตรวจบันดาหยารีสอร์ท',
    pageNameEng: 'Bundhaya Resort'
  },
  { pageName: 'ด่านจุดตรวจบึงกาฬ', pageNameEng: 'Bueng Kan' },
  { pageName: 'ด่านจุดตรวจบุหงารีสอร์ท', pageNameEng: 'Bu-Nga Resort' },
  {
    pageName: 'ด่านจุดตรวจสะพานปลา(ปากน้ำ)',
    pageNameEng: 'Pak Nam Jetty'
  },
  { pageName: 'ด่านจุดตรวจอันดามัน', pageNameEng: 'Andaman' },
  {
    pageName: 'ด่านจุดผ่านแดนถาวรไทย-ลาว',
    pageNameEng: 'Thai-Lao Border Crossing Points'
  },
  { pageName: 'ด่านจุดผ่านแดนปากแซง', pageNameEng: 'Pak Saeng' },
  {
    pageName: 'ด่านจุดผ่านแดนสามเหลี่ยมทองคำ-บ้านสบรวก',
    pageNameEng: 'Golden Triangle'
  },
  { pageName: 'ด่านตม.จว.ชุมพร', pageNameEng: 'Chumphon' },
  { pageName: 'ด่านตม.จว.ตรัง', pageNameEng: 'Trang' },
  {
    pageName: 'ด่านตม.จว.นครศรีธรรมราช',
    pageNameEng: 'Nakhon Si Thammarat'
  },
  { pageName: 'ด่าน ตม.ท่าเรือกรุงเทพ', pageNameEng: 'Bangkok Port' },
  { pageName: 'ด่าน ตม.ท่าเรือสงขลา', pageNameEng: 'Songkla Port' },
  { pageName: 'ด่าน ตม.มาบตาพุด', pageNameEng: 'Map Ta Phut' },
  {
    pageName: 'ด่านตม.ระนอง จุดตรวจพาหนะ (เรือ)',
    pageNameEng: 'Ranong boat checkpoint'
  },
  { pageName: 'ด่าน ตม.สมุย', pageNameEng: 'Samui' },
  { pageName: 'ด่าน ตม.สีชัง', pageNameEng: 'Sichang' },
  {
    pageName: 'ด่านท่าเทียบเรือศุลกากรระนอง (ประภาคาร)',
    pageNameEng: 'Ranong Customs House'
  },
  { pageName: 'ด่านท่าเรือตำมะลัง', pageNameEng: 'Tam ma lang' },
  { pageName: 'ด่านท่าเรือภูเก็ต', pageNameEng: 'Phuket Port' },
  { pageName: 'ด่านท่าเรือแหลมฉบัง', pageNameEng: 'Laem Chabang' }
]

export const THAI_LAND_PORT_LIST = [
  { pageName: 'ทุกด่านตรวจคนเข้าเมืองทางบก', pageNameEng: 'Overview' },
  {
    pageName: 'ด่านด่านตรวจคนเข้าเมืองกาบเชิง จว.สุรินทร์ จุดตรวจช่องจอม',
    pageNameEng: 'Chang Jom'
  },
  {
    pageName: 'ด่านด่านตรวจคนเข้าเมืองเชียงแสน จว.เชียงราย จุดผ่านแดนถาวรสะพานมิตรภาพไทย-ลาวแห่งที่ 4',
    pageNameEng: 'Friendship Bridge 4'
  },
  {
    pageName: 'ด่านด่านตรวจคนเข้าเมืองปาดังเบซาร์ จว.สงขลา',
    pageNameEng: 'Songkhla Padang Besar'
  },
  {
    pageName: 'ด่านด่านตรวจคนเข้าเมืองปาดังเบซาร์ จว.สงขลา ด่าน ตม.ปาดังเบซาร์',
    pageNameEng: 'Padang Besar'
  },
  {
    pageName: 'ด่านด่านตรวจคนเข้าเมืองปาดังเบซาร์ จว.สงขลา สถานีรถไฟร่วมปาดังเบซาร์ ไทย-มาเลเซีย',
    pageNameEng: 'Thai-Malaysia Padang Besar railway station'
  },
  { pageName: 'ด่านตม.จว.กระบี่', pageNameEng: 'Krabi' },
  {
    pageName: 'ด่านตม.จว.จันทบุรี จุดตรวจถาวรบ้านผักกาด',
    pageNameEng: 'Baan Pakkad'
  },
  {
    pageName: 'ด่านตม.จว.จันทบุรี จุดตรวจถาวรบ้านแหลม',
    pageNameEng: 'Baan Laem'
  },
  { pageName: 'ด่านตม.จว.ฉะเชิงเทรา', pageNameEng: 'Chachoengsao' },
  { pageName: 'ด่านตม.จว.ชลบุรี', pageNameEng: 'Chonburi' },
  {
    pageName: 'ด่านตม.จว.ชลบุรี ด่าน ตม.ศรีราชา',
    pageNameEng: 'Si Racha'
  },
  {
    pageName: 'ด่านตม.จว.เชียงราย จุดผ่านแดนถาวรสะพานข้ามแม่น้ำสาย แห่งที่ 1',
    pageNameEng: 'Thai–Lao friendship bridge 1'
  },
  {
    pageName: 'ด่านตม.จว.เชียงราย จุดผ่านแดนถาวรสะพานข้ามแม่น้ำสาย แห่งที่ 2',
    pageNameEng: 'Thai–Lao friendship bridge 2'
  },
  { pageName: 'ด่านตม.จว.เชียงใหม่', pageNameEng: 'Chiang Mai' },
  {
    pageName: 'ด่านตม.จว.ตราด ด่าน ตม.คลองใหญ่',
    pageNameEng: 'Klong Yai'
  },
  { pageName: 'ด่านตม.จว.ตราด อก.', pageNameEng: 'Trat' },
  { pageName: 'ด่านตม.จว.ตาก', pageNameEng: 'Tak' },
  {
    pageName: 'ด่านตม.จว.ตาก จุดตรวจสะพานมิตรภาพไทย-พม่า',
    pageNameEng: 'Thai-Myanmar friendship bridge'
  },
  {
    pageName: 'ด่านตม.จว.ตาก จุดตรวจสะพานมิตรภาพไทย-เมียนมาร์ แห่งที่ 2',
    pageNameEng: 'Thai-Myanmar friendship bridge 2'
  },
  {
    pageName: 'ด่านตม.จว.ตาก อาคาร Terminal',
    pageNameEng: 'Tak Terminal Building'
  },
  {
    pageName: 'ด่านตม.จว.นครพนม จุดตรวจสะพานมิตรภาพแห่งที่ 3',
    pageNameEng: 'Friendship Bridge 3'
  },
  {
    pageName: 'ด่านตม.จว.นครราชสีมา',
    pageNameEng: 'Nakhon Ratchasima'
  },
  {
    pageName: 'ด่านตม.จว.นราธิวาส จุดตรวจสะพานข้ามแม่น้ำสุไหงโก-ลก',
    pageNameEng: 'Sungai Golok Bridge'
  },
  {
    pageName: 'ด่านตม.จว.นราธิวาส ด่าน ตม.ตากใบ',
    pageNameEng: 'Tak-Bai'
  },
  {
    pageName: 'ด่านตม.จว.นราธิวาส ด่าน ตม.บูเก๊ะตา',
    pageNameEng: 'Buketa'
  },
  {
    pageName: 'ด่านตม.จว.นราธิวาส ด่าน ตม.สุไหงโก-ลก',
    pageNameEng: 'Su-ngai Kolok'
  },
  {
    pageName: 'ด่านตม.จว.น่าน จุดผ่านแดนถาวรบ้านห้วยโก๋น',
    pageNameEng: 'Ban Huai Kon'
  },
  {
    pageName: 'ด่านตม.จว.บึงกาฬ จุดตรวจศุลกากรใหม่',
    pageNameEng: 'Buengkan Customs House'
  },
  {
    pageName: 'ด่านตม.จว.ประจวบคีรีขันธ์',
    pageNameEng: 'Prachuap Khiri Khan'
  },
  {
    pageName: 'ด่านตม.จว.ประจวบคีรีขันธ์ จุดตรวจสิงขร',
    pageNameEng: 'Singkhon'
  },
  {
    pageName: 'ด่านตม.จว.พะเยา จุดตรวจผ่านแดนถาวรบ้านฮวก',
    pageNameEng: 'Ban Huak'
  },
  { pageName: 'ด่านตม.จว.พังงา', pageNameEng: 'Phang-nga' },
  { pageName: 'ด่านตม.จว.พิษณุโลก', pageNameEng: 'Phitsanulok' },
  { pageName: 'ด่านตม.จว.เพชรบุรี', pageNameEng: 'Phetchaburi' },
  { pageName: 'ด่านตม.จว.ภูเก็ต', pageNameEng: 'Phuket' },
  {
    pageName: 'ด่านตม.จว.ภูเก็ต จุดตรวจ ตม.จว.ภูเก็ต',
    pageNameEng: 'Phuket Checkpoint'
  },
  {
    pageName: 'ด่านตม.จว.มุกดาหาร จุดผ่านแดนถาวรสะพานมิตรภาพ 2',
    pageNameEng: 'Friendship Bridge 2'
  },
  { pageName: 'ด่านตม.จว.ยะลา ด่าน ตม.เบตง', pageNameEng: 'Betong' },
  { pageName: 'ด่านตม.จว.ระนอง', pageNameEng: 'Ranong' },
  {
    pageName: 'ด่านตม.จว.ระนอง ศูนย์ออกเอกสารรับรองบุคคล (CI) จว.ระนอง',
    pageNameEng: 'CI'
  },
  { pageName: 'ด่านตม.จว.ระยอง', pageNameEng: 'Rayong' },
  { pageName: 'ด่านตม.จว.ลพบุรี', pageNameEng: 'Lopburi' },
  { pageName: 'ด่านตม.จว.เลย ด่าน ตม.ท่าลี่', pageNameEng: 'Tha Li' },
  {
    pageName: 'ด่านตม.จว.ศรีสะเกษ จุดผ่านแดนถาวรช่องสะงำ',
    pageNameEng: 'Chong Sa-nyam'
  },
  { pageName: 'ด่านตม.จว.สงขลา', pageNameEng: 'Songkhla' },
  {
    pageName: 'ด่านตม.จว.สงขลา จุดผ่านแดนถาวรบ้านประกอบ',
    pageNameEng: 'Baan Prakrob'
  },
  { pageName: 'ด่านตม.จว.สงขลา ด่าน ตม.สะเดา', pageNameEng: 'Sadao' },
  { pageName: 'ด่านตม.จว.สตูล', pageNameEng: 'Satun' },
  {
    pageName: 'ด่านตม.จว.สตูล ด่าน ตม.ควนโดน',
    pageNameEng: 'Khuan Don'
  },
  {
    pageName: 'ด่านตม.จว.สตูล ด่าน ตม.สตูล',
    pageNameEng: 'Satun-Satun'
  },
  { pageName: 'ด่านตม.จว.สมุทรปราการ', pageNameEng: 'Samut Prakan' },
  { pageName: 'ด่านตม.จว.สมุทรสาคร', pageNameEng: 'Samut Sakhon' },
  {
    pageName: 'ด่านตม.จว.สระแก้ว จุดผ่านแดนถาวรบ้านเขาดิน',
    pageNameEng: 'Baan Kao Din'
  },
  {
    pageName: 'ด่านตม.จว.สระแก้ว จุดผ่านแดนถาวรบ้านคลองลึก',
    pageNameEng: 'Baan Klong Luk'
  },
  {
    pageName: 'ด่านตม.จว.สระแก้ว ด่าน ตม.อรัญประเทศ',
    pageNameEng: 'Aranyaprathet'
  },
  { pageName: 'ด่านตม.จว.สุราษฎร์ธานี', pageNameEng: 'Surat Thani' },
  { pageName: 'ด่านตม.จว.หนองคาย', pageNameEng: 'Nong Khai' },
  {
    pageName: 'ด่านตม.จว.หนองคาย จุดตรวจสถานีรถไฟหนองคาย',
    pageNameEng: 'Nong Khai railway station'
  },
  {
    pageName: 'ด่านตม.จว.หนองคาย จุดตรวจสะพานมิตรภาพ 1',
    pageNameEng: 'Friendship Bridge 1'
  },
  {
    pageName: 'ด่านตม.จว.อุตรดิตถ์ จุดผ่านแดนถาวรภูดู่',
    pageNameEng: 'Phu Duu'
  },
  {
    pageName: 'ด่านตม.จว.อุบลราชธานี จุดผ่านแดนถาวรช่องเม็ก',
    pageNameEng: 'Chong Mek'
  }
]

export const OVERVIEW_LIST = [
    {
        pageName: "ภาพรวมประเทศ",
        pageNameEng: "Overview",
    }
]