import React, { useState, useEffect } from "react"
import { useTitle } from "react-use"
import { Grid, TextField, ThemeProvider, createTheme } from "@mui/material"
import Button from "../../components/shared/Button/Button"
import Popup from "./Popup/Popup"
import { Formik, FormikHelpers } from "formik"
import { createInquiryEmail } from "../../services/data-connector"
import map from "./../../assets/contact-us/pic/map.png"
import ContactUsModule from "./ContactUs.module.css"
import "./ContactUs.css"
import CustomContainer from "../../components/shared/CustomContainer/CustomContainer"
import ErrorElement from "../../components/shared/ErrorElement/ErrorElement"
import { FormData, contact_validate } from "../../validation/contact"
import { textbox_theme } from "../../theme/textbox"
import { register_textbox_theme } from "../../theme/register"
import { mergeThemes } from "../../theme/merge"

function ContactUs(props: { hideTitle: boolean }) {
    if (!props?.hideTitle) {
        useTitle("Travel Link | Contact")
    }
    const [thankyouState, setThankyouState] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorText, setErrorText] = useState("")
    const mergedTheme = mergeThemes(register_textbox_theme, textbox_theme)
    const theme = createTheme(mergedTheme)

    const openModal = () => {
        setThankyouState(true)
        document.body.classList.add("modal-open")
    }

    const closeModal = () => {
        setThankyouState(false)
        document.body.classList.remove("modal-open")
    }

    const handleSubmit = async (data: FormData, actions: FormikHelpers<FormData>) => {
        try {
            setIsLoading(true)
            setErrorText('')
            const cleanedData = { ...data };
            let fieldsToClean: (keyof FormData)[] = ['phoneNo', 'org']
            fieldsToClean.forEach(field => {
                if (cleanedData[field] === '') {
                    delete cleanedData[field];
                }
            });

            let { success } = await createInquiryEmail(cleanedData)

            setIsLoading(false)
            // Alert Handling
            if (success) {
                setErrorText("")
                openModal()
                actions.resetForm()
            } else {
                setErrorText("เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่")
            }
        } catch (e) {
            setIsLoading(false)
            setErrorText("เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่")
        }
    }

    useEffect(() => {
        const close = (e: KeyboardEvent) => {
            if (e.keyCode === 27) {
                closeModal()
            }
        }
        window.addEventListener("keydown", close)
        return () => window.removeEventListener("keydown", close)
    }, [])

    return (
        <div style={{ marginTop: "30px", marginBottom: "100px" }}>
            {/* Popup after the question is submited successfully */}
            <Popup
                trigger={thankyouState}
                onClick={() => {
                    closeModal()
                    window.location.href = "/"
                }}
            />

            <CustomContainer>
                <p className={ContactUsModule.title}>ติดต่อเรา</p>
                <Grid container spacing={5}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{
                            order: {
                                xs: 2,
                                sm: 1,
                                md: 1,
                            },
                        }}
                        className={ContactUsModule.text}
                    >
                        <iframe
                            width="100%"
                            height="450"
                            style={{ border: 0 }}
                            loading="lazy"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15497.808737884425!2d100.5616824!3d13.8118645!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe1c64aa0a0229427!2z4Liq4Liz4LiZ4Lix4LiB4LiH4Liy4LiZ4Liq4LmI4LiH4LmA4Liq4Lij4Li04Lih4LmA4Lio4Lij4Lip4LiQ4LiB4Li04LiI4LiU4Li04LiI4Li04LiX4Lix4LilIChkZXBhKQ!5e0!3m2!1sen!2sth!4v1626335533300!5m2!1sen!2sth"
                        />

                        <p style={{ marginTop: "30px" }}>
                            สถาบันข้อมูลขนาดใหญ่ (องค์การมหาชน)
                        </p>
                        <p>
                            เลขที่ 80 ซอย ลาดพร้าว 4 แขวง จอมพล เขตจตุจักร
                            กรุงเทพมหานคร 10900
                        </p>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{
                            order: {
                                xs: 1,
                                sm: 2,
                                md: 2,
                            },
                        }}
                        textAlign="left"
                    >
                        <Grid
                            container
                            className={ContactUsModule.line_wrapper}
                        >
                            <Grid
                                item
                                md={8}
                                className={ContactUsModule.line_invitation_text}
                            >
                                เพื่อความสะดวกในการติดต่อ Travel Link <br />
                                สามารถพูดคุยกับเราได้ทาง Line Official Account
                            </Grid>
                            <Grid item md={4}>
                                <a
                                    href="https://lin.ee/2UhQtlT"
                                    target="_blank"
                                >
                                    <img
                                        src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png"
                                        alt="เพิ่มเพื่อน"
                                        height="36"
                                        className={ContactUsModule.line_img}
                                    />
                                </a>
                            </Grid>
                        </Grid>
                        <hr style={{ marginBottom: "30px" }}></hr>
                        <Formik
                            initialValues={{
                                firstName: "",
                                lastName: "",
                                org: "",
                                phoneNo: "",
                                email: "",
                                message: "",
                            }}
                            initialTouched={{
                                firstName: false,
                                lastName: false,
                                org: false,
                                phoneNo: false,
                                email: false,
                                message: false,
                            }}
                            onSubmit={async (data, actions) => {
                                await handleSubmit(data, actions)
                                
                            }}
                            validate={contact_validate}
                        >
                            {({
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <ThemeProvider theme={theme}>
                                        <div>
                                            <label
                                                className={
                                                    ContactUsModule.form_text
                                                }
                                            >
                                                ชื่อ
                                                <span
                                                    className={
                                                        ContactUsModule.text_require
                                                    }
                                                >
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <TextField
                                            variant="outlined"
                                            name="firstName"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                !!errors.firstName &&
                                                touched.firstName
                                            }
                                            helperText={
                                                !!errors.firstName &&
                                                touched.firstName ? (
                                                    <ErrorElement
                                                        errorMessage={
                                                            errors.firstName
                                                        }
                                                    />
                                                ) : (
                                                    ""
                                                )
                                            }
                                            placeholder="กรุณากรอกชื่อ"
                                            className={ContactUsModule.input}
                                        ></TextField>

                                        <div>
                                            <label
                                                className={
                                                    ContactUsModule.form_text
                                                }
                                            >
                                                นามสกุล
                                                <span
                                                    className={
                                                        ContactUsModule.text_require
                                                    }
                                                >
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <TextField
                                            variant="outlined"
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                !!errors.lastName &&
                                                touched.lastName
                                            }
                                            helperText={
                                                !!errors.lastName &&
                                                touched.lastName ? (
                                                    <ErrorElement
                                                        errorMessage={
                                                            errors.lastName
                                                        }
                                                    />
                                                ) : (
                                                    ""
                                                )
                                            }
                                            placeholder="กรุณากรอกนามสกุล"
                                            className={ContactUsModule.input}
                                        ></TextField>

                                        <div>
                                            <label
                                                className={
                                                    ContactUsModule.form_text
                                                }
                                            >
                                                บริษัท
                                            </label>
                                        </div>
                                        <TextField
                                            variant="outlined"
                                            name="org"
                                            value={values.org}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!errors.org && touched.org}
                                            helperText={
                                                !!errors.org && touched.org ? (
                                                    <ErrorElement
                                                        errorMessage={
                                                            errors.org
                                                        }
                                                    />
                                                ) : (
                                                    ""
                                                )
                                            }
                                            placeholder="กรุณากรอกชื่อบริษัท"
                                            className={ContactUsModule.input}
                                        ></TextField>

                                        <div>
                                            <label
                                                className={
                                                    ContactUsModule.form_text
                                                }
                                            >
                                                เบอร์โทรศัพท์
                                            </label>
                                        </div>
                                        <TextField
                                            variant="outlined"
                                            name="phoneNo"
                                            value={values.phoneNo}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!errors.phoneNo && touched.phoneNo}
                                            helperText={
                                                !!errors.phoneNo && touched.phoneNo ? (
                                                    <ErrorElement
                                                        errorMessage={
                                                            errors.phoneNo
                                                        }
                                                    />
                                                ) : (
                                                    ""
                                                )
                                            }
                                            placeholder="กรุณากรอกเบอร์โทรศัพท์ที่ติดต่อได้"
                                            className={ContactUsModule.input}
                                        ></TextField>

                                        <div>
                                            <label
                                                className={
                                                    ContactUsModule.form_text
                                                }
                                            >
                                                อีเมล
                                                <span
                                                    className={
                                                        ContactUsModule.text_require
                                                    }
                                                >
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <TextField
                                            variant="outlined"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                !!errors.email && touched.email
                                            }
                                            helperText={
                                                !!errors.email &&
                                                touched.email ? (
                                                    <ErrorElement
                                                        errorMessage={
                                                            errors.email
                                                        }
                                                    />
                                                ) : (
                                                    ""
                                                )
                                            }
                                            placeholder="กรุณากรอกข้อมูลอีเมล"
                                            className={ContactUsModule.input}
                                        ></TextField>

                                        <div>
                                            <label
                                                className={
                                                    ContactUsModule.form_text
                                                }
                                            >
                                                ข้อความที่ต้องการติดต่อสอบถาม
                                                <span
                                                    className={
                                                        ContactUsModule.text_require
                                                    }
                                                >
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <TextField
                                            variant="outlined"
                                            name="message"
                                            value={values.message}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                !!errors.message &&
                                                touched.message
                                            }
                                            multiline
                                            rows={4}
                                            helperText={
                                                !!errors.message &&
                                                touched.message ? (
                                                    <ErrorElement
                                                        errorMessage={
                                                            errors.message
                                                        }
                                                    />
                                                ) : (
                                                    ""
                                                )
                                            }
                                            placeholder="ยิ่งละเอียดเจ้าหน้าที่ก็ยิ่งสามารถแนะนำการใช้งานให้ตรงกับความต้องการกับการใช้งานให้มากขึ้น"
                                            className={ContactUsModule.input}
                                            sx = {{
                                                '& .MuiInputBase-root':{
                                                  padding: '0 26px 0 0',
                                                  borderRadius:'20px', 
                                                }
                                              }}
                                        ></TextField>

                                    {
                                        errorText ? 
                                        <div style={{display: 'flex', alignItems:'center', marginBottom: '10px'}}>
                                            <ErrorElement errorMessage={errorText} />
                                        </div>
                                        : <></>
                                    }
                                    </ThemeProvider>
                                    
                                    <Button
                                        size="large"
                                        fluid={false}
                                        type="submit"
                                        isLoading={isLoading}
                                    >
                                        ส่งข้อความติดต่อ
                                    </Button>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </CustomContainer>
        </div>
    )
}

export default ContactUs
