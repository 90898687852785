import React, { useState, useRef, useEffect } from "react"
import { Popover, List, ListItem, ListItemText, ListItemAvatar, Divider, Avatar, Box } from '@mui/material'
import { useHistory } from "react-router"
import { ReactComponent as Crown } from '../../../assets/navbar/crown_blank.svg'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

import { ThemeProvider, createTheme } from "@mui/material/styles";
import proavatar from '../../../assets/dashboard/proavatar.svg'
import { getUserAccount } from "../../../utils/login";
import { profileDropdown, profileNavbar } from "../../../theme/navbar";
import "./Profile.css"


interface ProfileProps {
    isCollapse: boolean,
    handleLogOut: () => void, 
    setOpenDrawer: React.Dispatch<string>
}

const Profile = ({ isCollapse, handleLogOut, setOpenDrawer }: ProfileProps) => {
    const user = getUserAccount()
    const [open, setOpen] = useState(false)
    const roleDescription = user.getIsLoggedIn() ? user.getRoleDescription!() : ''
    const isAdmin = user.getIsAdmin()
    const isPrivilege = user.getIsPrivileged()
    const isEmailVerified = user.getIsEmailVerified()
    const toggle = () => setOpen(!open)
    const history = useHistory()

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleListItemClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        toggle()
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const id = open ? 'simple-popover' : undefined;
    const isPending = user.getOrgPending().length > 0 && user.getApprovedOrg!().length == 0

    const ListItemTextTheme = createTheme(profileNavbar);
    const dropdownTheme = createTheme(profileDropdown)

      useEffect(() => {
        if(isCollapse) {
            setOpen(false)
        }
      },[isCollapse])

    return (
        <ThemeProvider theme = {ListItemTextTheme}>
            <Box
                display={{md:'block', sm:'none', xs: 'none'}}
                onClick={handleListItemClick}
            >

                    <List sx={{ 
                        width: '100%', 
                        maxWidth: 360, 
                        bgcolor: 'background.paper', 
                        maxHeight: 80, 
                        padding:"0", 
                        cursor: 'pointer' }}
                        >
                        <ListItem>

                                <ListItemAvatar sx={{position:'relative'}}>
                                
                                    {
                                        user.getIsLoggedIn() &&
                                        user.hasAvatarPicture() ? (
                                            <img
                                                src={user.getAvatarPicture!()}
                                                style={{ borderRadius: "50%", width: "35px", verticalAlign:"middle" }}
                                            />
                                        ) : (
                                            <Avatar/>
                                        )
                                        
                                    }
                                    {
                                        isPrivilege ? 
                                    <img src={proavatar} style={{width:'13px', height:'13px', position:'absolute', bottom:'0px', right:'10px'}}/>
                                    :<></>
                                    }

                                </ListItemAvatar>
                            <ListItemText 
                            primary={`${user.getIsLoggedIn() ? user.getFirstName!() : 'Travel'}` +
                                " " +
                                `${user.getIsLoggedIn() ? user.getLastName!() : "Link"}`} 
                            secondary={roleDescription}
                            primaryTypographyProps={{
                                fontSize: '14px',
                                color: "#032B42"
                            }}
                            secondaryTypographyProps={ isPending ? { 
                                color: '#EF6C00',
                                fontWeight: '500',
                                fontSize: '10px',

                            }: {
                                fontWeight: '500',
                                fontSize: '10px',
                                color: '#515151'
                            }} />
                            {
                                open ?
                                <ArrowDropUpRoundedIcon fontSize="medium"/>
                                :
                                <ArrowDropDownRoundedIcon fontSize="medium"/>
                            }
                    </ListItem>
                </List>
                <ThemeProvider theme={dropdownTheme}>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        className="nav-item item"
                        disableScrollLock
                    >
                            <List sx={{ 
                                width: '100%', 
                                maxWidth: 360,
                                '& .MuiListItem-root:hover': {
                                    '&, & .MuiListItemAvatar-root': {
                                        color: '#0182FA',
                                        
                                    },
                                    '&, & .MuiListItemText-root': {
                                        color: '#0182FA',
                                    }
                                } }}>
                                {
                                isEmailVerified &&  isPrivilege ?
                                    <ListItem
                                    onClick = {() => {
                                        history.push('/privilege')
                                        window.scrollTo(0,0)
                                        }} >
                                        <ListItemAvatar>
                                            <Crown className = 'crown' style={{stroke: '#515151'}} />
                                        </ListItemAvatar>

                                        <ListItemText 
                                        primary="สิทธิพิเศษ"/>
                                    </ListItem>
                                :
                                <></>
                                }
                                <ListItem
                                onClick={() => {
                                    window.location.href = "/bookmark"
                                }}>
                                    <ListItemAvatar>
                                        <BookmarkBorderIcon />
                                    </ListItemAvatar>
                                    <ListItemText primary="รายการที่ติดตาม" />
                                </ListItem>

                                {isEmailVerified && !isPending && isAdmin && (
                                    <a
                                        style={{ cursor: "pointer" }}
                                        href="/api/user/user/to-admin"
                                        target="_blank"
                                    >
                                        <ListItem>
                                            <ListItemAvatar>
                                                <ManageAccountsOutlinedIcon/>
                                            </ListItemAvatar>
                                            <ListItemText 
                                            primary="เข้าสู่ระบบแอดมิน" />
                                        </ListItem>
                                    </a>
                                    
                                )}

                            <Divider />

                            <a style={{ cursor: "pointer" }} href="/contact">
                                <ListItem>
                                    <ListItemAvatar>
                                        <HelpOutlineIcon />
                                    </ListItemAvatar>
                                        <ListItemText
                                        primary="ติดต่อเรา" />
                                </ListItem>
                            </a>

                            <ListItem 
                            onClick = {handleLogOut} 
                            >
                                <ListItemAvatar>
                                    <LogoutIcon />
                                </ListItemAvatar>

                                <ListItemText
                                primary="ลงชื่อออก" />
                            </ListItem>
                            </List>

                    </Popover>
                </ThemeProvider>

            </Box>
            <Box
            display={{md:'none', sm:'block', xs:'block'}} marginRight='5px'>
                <Box
                position='relative'
                width="100%"
                height="100%"
                margin="auto 0"
                display="flex"
                alignItems="center"
                sx={{cursor:'pointer'}}
                onClick={() => setOpenDrawer("right")}>
                {
                    user.getIsLoggedIn() &&
                    user.hasAvatarPicture() ? (
                        <img
                            src={user.getAvatarPicture!()}
                            style={{ borderRadius: "50%", width: "35px", verticalAlign:"middle" }}
                        />
                    ) : (
                        <Avatar/>
                    )
                    
                }
                {
                    isPrivilege ? 
                    <img src={proavatar} style={{width:'13px', height:'13px', position:'absolute', bottom:'10px', right:'0px'}}/>
                    :<></>
                }
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default Profile
