import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import dashboardReducer from './features/dashboard/dashboardSlice'
import authenticationReducer from './features/authentication/authenticationSlice'
import infographicReducer from './features/infographic/infographicSlice'

export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
    reducer:{
        dashboard: dashboardReducer,
        authentication: authenticationReducer,
        infographic: infographicReducer
    }
})

// Define the type for the thunk actions
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch
