import React from 'react'
import {
    Grid
} from '@mui/material'
import DashboardSkeleton from '../DashboardSkeleton/DashboardSkeleton'
import DashboardCard from '../../../components/shared/DashboardCard/DashboardCard'
import { DashboardGalleryCard } from '../../../interfaces/DashboardMetadata'
import { SnackbarMessage } from '../../../interfaces/Snackbar'
import PaginationElement from '../../../components/shared/PaginationElement/PaginationElement'
import style from '../Dashboard.module.css'
import { DashboardCategoryThai } from '../../../interfaces/DashboardTag'
interface DashboardTilesProps{
    offset: number
    setOffset: React.Dispatch<number>
    isLoading: boolean,
    setIsLoading: React.Dispatch<boolean>
    dashboardMetadatas: DashboardGalleryCard[]
    searchText: string,
    activeTag?: string,
    dashboardType?: string,
    dataUpdateCycle?: string,
    dataType?: string,
    portType?: string,
    province?: string,
    portName?: string,
    setActiveTag: React.Dispatch<DashboardCategoryThai>,
    setOpenSnackBar: React.Dispatch<boolean>
    setSnackPack: React.Dispatch<React.SetStateAction<readonly SnackbarMessage[]>>
    handleLoginPopUp: (link: string) => void
    handleLoginPopUpFromBookmark: () => void
}

const DashboardTiles = ({
    offset,
    setOffset,
    isLoading,
    setIsLoading,
    dashboardMetadatas,
    searchText,
    activeTag,
    dashboardType,
    dataUpdateCycle,
    dataType,
    portType,
    province,
    portName,
    setActiveTag,
    setOpenSnackBar,
    setSnackPack,
    handleLoginPopUp,
    handleLoginPopUpFromBookmark
}: DashboardTilesProps) => {
    const PAGE_SIZE = 9

    const handlePaginationChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        let pageNo = offset/PAGE_SIZE + 1
        if(pageNo !== value){
            const searchElement = document.querySelector('.MuiInputBase-root')    
            searchElement?.scrollIntoView({behavior:'smooth',block:'center'})
            setIsLoading(true)
            const delay: () => Promise<void> =() => new Promise((resolve) => {
                setTimeout(() => {
                resolve()
                }, 500); // Simulating an asynchronous operation with a delay
            });
            await delay()
            setOffset((value-1)*PAGE_SIZE)
            setIsLoading(false)
        }
    }

  return (
    <>
        <Grid container justifyContent='flex-start' marginBottom={5} spacing={3}>
            {
                isLoading ? 
                [1,2,3,4,5,6].map((i) => (
                    <Grid key={i} item md={4} sm={6} xs={12}>
                        <DashboardSkeleton />
                    </Grid>
                ))
                :
                dashboardMetadatas.length > 0 ?

                dashboardMetadatas.slice(offset, offset + PAGE_SIZE).map((dashboardMetadata) => (
                    <Grid 
                    key={dashboardMetadata.galleryId} 
                    item 
                    md={4} 
                    sm={6} 
                    xs={12}>
                        <DashboardCard
                        key={dashboardMetadata.galleryId} 
                        dashboardMetadata={dashboardMetadata}
                        searchText={searchText}
                        activeTag={activeTag}
                        dashboardType={dashboardType}
                        dataUpdateCycle={dataUpdateCycle}
                        dataType={dataType}
                        portType={portType}
                        province={province}
                        portName={portName}
                        setOpenSnackBar={setOpenSnackBar}
                        setSnackPack={setSnackPack}
                        handleLoginPopUp={handleLoginPopUp}
                        handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                        pathname='/dashboard'
                        />
                    </Grid>
                ))
                :
                <Grid className={style.notFound} md={12} sm={12} xs={12} marginLeft='24px'>
                    {
                    activeTag === "ทั้งหมด" ? 
                    <>ไม่พบแดชบอร์ดที่ท่านค้นหา</>
                    :
                    <>
                        ไม่พบแดชบอร์ดที่ท่านค้นหาใน<span style={{fontWeight:"500", whiteSpace:'nowrap'}}>หมวดหมู่{activeTag}</span>
                        <br />
                        กรุณาลองค้นหาใน
                        <span 
                        style={{
                            fontWeight:"500", 
                            textDecoration:'underline', 
                            cursor:'pointer',
                            color: "#0182FA",
                            whiteSpace:'nowrap'
                        }}
                        onClick={() => setActiveTag("ทั้งหมด")}>หมวดหมู่ทั้งหมด</span>อีกครั้ง
                    </>
                    }
                </Grid>
            }
            </Grid>

            <PaginationElement 
            noItems={dashboardMetadatas.length}
            pageSize={PAGE_SIZE}
            pageNo={Math.round(offset/PAGE_SIZE + 1)}
            handlePaginationChange={handlePaginationChange}
            />

    </>
    
  )
}

export default DashboardTiles