import { InfographicCardType } from "../interfaces/Infographic";
import { DataUpdateCycleThai, InfographicTypeThai, DataTypeThai, Classifier, PortTypeThai, Sorting } from '../interfaces/FilterTypes'
import { dataTypeItems, dataUpdateCycleItems, infographicTypeItems, portTypeItems, sortingTypeItems,  } from "../variable/filterType";
import { ProvinceObject, ProvinceThai } from "../interfaces/Province";
import { InfographicCategoryThai, InfographicTag } from "../interfaces/InfographicTag";

const infographic_tags: InfographicTag[] = require("../variable/infographic_tag.json")
const province_list: ProvinceObject = require("../variable/province.json")

const mainDynamicallyFilteredInfographicMetadata = (
    infographicMetadatas: InfographicCardType[],
    searchText: string,
    activeTag: InfographicCategoryThai,
    infographicType: "" | InfographicTypeThai,
    dataType: "" | DataTypeThai,
    dataUpdateCycle: "" | DataUpdateCycleThai) => {
    const searchFilterSelector = (infographicMetadata: InfographicCardType) => {
        let infographicStrings = [
            ...infographicMetadata.tags,
            ...infographicMetadata.additionalKeywords, 
            ...infographicMetadata.relevantProvinces]
        //add all text-related strings from metadata
        infographicStrings.push(infographicMetadata.infographicTitle.toLowerCase())
        infographicStrings = infographicStrings.concat()

        return infographicStrings.some((string) =>
                string.includes(searchText.toLowerCase())
            )
        
    }

    let infoMetadataCategoryFiltered = infographicMetadatas.filter((infographicMetadata) => 
    // searchText filter
    searchFilterSelector(infographicMetadata) &&
    // category filter
    (activeTag === "ทั้งหมด" || infographicMetadata.tags.includes(activeTag))
    )

    let infoMetadataMainDynamicFiltered = infoMetadataCategoryFiltered.filter((infographicMetadata) => (
        // infographicType
        ( (["", "อินโฟกราฟิกทั้งหมด"].includes(infographicType)) || 
        (infographicType === "อินโฟกราฟิกพิเศษ" && infographicMetadata.tags.includes("อินโฟกราฟิกพิเศษ")) ||
        // (infographicType === "อินโฟกราฟิกทั่วไป" && !infographicMetadata.tags.includes("อินโฟกราฟิกพิเศษ")) ||
        (infographicType === "อินโฟกราฟิกที่ติดตาม" && infographicMetadata.isBookmarked) ) 
        &&
        // dataUpdateCycle
        ((dataUpdateCycle === "") || 
        (infographicMetadata.dataUpdateCycle === dataUpdateCycleItems.find(({thai}) => thai === dataUpdateCycle)?.english))
        &&
        // dataType
        ((dataType === "") || 
        (dataType === "ภาพรวมประเทศ" && infographicMetadata.infographicClassifier === "ภาพรวมประเทศ") ||
        (dataType === "รายจังหวัด" && infographicMetadata.infographicClassifier === "รายจังหวัด") ||
        (dataType === "รายด่าน" && ["ทุกด่าน", "ด่านบก", "ด่านน้ำ", "ด่านอากาศ"].includes(infographicMetadata.infographicClassifier)))
        ))
    return infoMetadataMainDynamicFiltered
        
}

export const prepDynamicFilter = (
    infographicMetadatasFull: InfographicCardType[], 
    searchText: string,
    activeTag: InfographicCategoryThai,
    infographicType: "" | InfographicTypeThai,
    dataType: "" | DataTypeThai,
    dataUpdateCycle: "" | DataUpdateCycleThai,) => {
    // to be used for the first three filters
    let infographicMetadataCategoryFiltered = infographicMetadatasFull.filter(({tags}) => activeTag === "ทั้งหมด" || tags.includes(activeTag))
    
    let infographicMetadataMainDynamicallyFiltered = mainDynamicallyFilteredInfographicMetadata(
        infographicMetadatasFull,
        searchText,
        activeTag,
        infographicType,
        dataType,
        dataUpdateCycle
    )
    // infographicType
    let infographicTypeFilter: InfographicTypeThai[] = ["อินโฟกราฟิกทั้งหมด"]
    // infographicType: privileged
    if(infographicMetadataCategoryFiltered.some(({tags}) => tags.includes("อินโฟกราฟิกพิเศษ"))){
        infographicTypeFilter.push("อินโฟกราฟิกพิเศษ")
    }
    // // infographicType: public (non-privileged)
    // if(infographicMetadatas.some(({tags}) => !tags.includes("อินโฟกราฟิกพิเศษ"))){
    //     infographicTypeFilter.push("อินโฟกราฟิกทั่วไป")
    // }

    // infographic: bookmarked
    if(infographicMetadataCategoryFiltered.some(({isBookmarked}) => isBookmarked)){
        infographicTypeFilter.push("อินโฟกราฟิกที่ติดตาม")
    }
   

    // dataUpdateCycle
    let dataUpdateCycleList = infographicMetadataCategoryFiltered.map(({dataUpdateCycle}) => dataUpdateCycle)
    // Find all possible available item
    let dataUpdateCycleFilter = dataUpdateCycleItems.filter((dataUpdateCycleItem) => dataUpdateCycleList.includes(dataUpdateCycleItem.english)).map(({thai}) => thai)


    // dataType
    let dataTypeFilter: {dataTypeThai: DataTypeThai, correspondingList: any[]}[] = []
    let classifierMap: {[key in DataTypeThai]: Classifier[]} = {
        "รายด่าน":["ทุกด่าน","ด่านน้ำ","ด่านบก", "ด่านอากาศ"],
        "รายจังหวัด": ["รายจังหวัด"],
        "ภาพรวมประเทศ": ["ภาพรวมประเทศ"]
    }

    // dataType: port 
    if(infographicMetadataCategoryFiltered.some(({infographicClassifier}) => ["ทุกด่าน","ด่านน้ำ","ด่านบก", "ด่านอากาศ"].includes(infographicClassifier))){
        let portList:("ด่านทั้งหมด" | PortTypeThai)[] = []
        for(let portTypeItem of portTypeItems){
            if(infographicMetadataMainDynamicallyFiltered.some(({infographicClassifier}) => portTypeItem.thai === "ด่านทั้งหมด" || infographicClassifier === portTypeItem.thai)){
                portList.push(portTypeItem.thai)
            }
        }
        // add to dataTypeFilter
        dataTypeFilter.push({
            dataTypeThai:"รายด่าน",
            correspondingList: portList
        })
    }



    // dataType: province
    if(infographicMetadataCategoryFiltered.some(({infographicClassifier}) => infographicClassifier === 'รายจังหวัด')){
        let relevantProvincesArrays = infographicMetadataMainDynamicallyFiltered.map(({relevantProvinces}) => relevantProvinces)
        const INITIAL_PROVINCE_LIST: ("จังหวัดทั้งหมด" | ProvinceThai)[] = ["จังหวัดทั้งหมด"]
        let provinceList: ("จังหวัดทั้งหมด" | ProvinceThai)[] = relevantProvincesArrays.reduce((arrayA,arrayB) => arrayA.concat(arrayB), INITIAL_PROVINCE_LIST)
        let sortedUniqueProvince: ("จังหวัดทั้งหมด" | ProvinceThai)[] = provinceList.filter((value, index, self) => self.indexOf(value) === index).sort()

        // add to dataTypeFilter
        dataTypeFilter.push({
            dataTypeThai:"รายจังหวัด",
            correspondingList: sortedUniqueProvince
        })
    }
    
    // dataType: overall
    if(infographicMetadataCategoryFiltered.some(({infographicClassifier}) => infographicClassifier === 'ภาพรวมประเทศ')){
        // add to dataTypeFilter
        dataTypeFilter.push({
            dataTypeThai: "ภาพรวมประเทศ",
            correspondingList: []
        })
    }

    return {
        infographicTypeFilter: infographicTypeFilter,
        dataUpdateCycleFilter: dataUpdateCycleFilter,
        dataTypeFilter: dataTypeFilter
    }

}

export const filterInfoMetadata = (
    infographicMetadatas: InfographicCardType[],
    searchText: string,
    activeTag: InfographicCategoryThai,
    infographicType: "" | InfographicTypeThai,
    dataType: "" | DataTypeThai,
    dataUpdateCycle: "" | DataUpdateCycleThai,
    portType: "" | PortTypeThai,
    province: "" | ProvinceThai,
    sorting: Sorting
    ) => {
        const searchFilterSelector = (infographicMetadata: InfographicCardType) => {
            let infographicStrings = [
                ...infographicMetadata.tags,
                ...infographicMetadata.additionalKeywords, 
                ...infographicMetadata.relevantProvinces]
            //add all text-related strings from metadata
            infographicStrings.push(infographicMetadata.infographicTitle.toLowerCase())
            infographicStrings = infographicStrings.concat()
    
            return infographicStrings.some((string) =>
                    string.includes(searchText.toLowerCase())
                )
            
        }

        let infoMetadataCategoryFiltered = infographicMetadatas.filter((infographicMetadata) => 
        // searchText filter
        searchFilterSelector(infographicMetadata) &&
        // category filter
        (activeTag === "ทั้งหมด" || infographicMetadata.tags.includes(activeTag))
        )

        let infoMetadataDynamicFiltered = infoMetadataCategoryFiltered.filter((infographicMetadata) => (
            // infographicType
            ( (["", "อินโฟกราฟิกทั้งหมด"].includes(infographicType)) || 
            (infographicType === "อินโฟกราฟิกพิเศษ" && infographicMetadata.tags.includes("อินโฟกราฟิกพิเศษ")) ||
            // (infographicType === "อินโฟกราฟิกทั่วไป" && !infographicMetadata.tags.includes("อินโฟกราฟิกพิเศษ")) ||
            (infographicType === "อินโฟกราฟิกที่ติดตาม" && infographicMetadata.isBookmarked) ) 
            &&
            // dataUpdateCycle
            ((["","ทั้งหมด"].includes(dataUpdateCycle)) || 
            (infographicMetadata.dataUpdateCycle === dataUpdateCycleItems.find(({thai}) => thai === dataUpdateCycle)?.english))
            &&
            // dataType
            ((dataType === "") || 
            (dataType === "ภาพรวมประเทศ" && infographicMetadata.infographicClassifier === "ภาพรวมประเทศ") ||
            (dataType === "รายจังหวัด" && infographicMetadata.infographicClassifier === "รายจังหวัด") ||
            (dataType === "รายด่าน" && ["ทุกด่าน", "ด่านบก", "ด่านน้ำ", "ด่านอากาศ"].includes(infographicMetadata.infographicClassifier)))
            &&
            //portType
            ((["","ด่านทั้งหมด"].includes(portType)) ||
            (portType === "ด่านน้ำ" && infographicMetadata.infographicClassifier === "ด่านน้ำ") ||
            (portType === "ด่านบก" && infographicMetadata.infographicClassifier === "ด่านบก") ||
            (portType === "ด่านอากาศ" && infographicMetadata.infographicClassifier === "ด่านอากาศ"))
            &&
            //province
            ((["","จังหวัดทั้งหมด"].includes(province)) ||
            (infographicMetadata.relevantProvinces.includes(province as ProvinceThai)))
        ))

        // sorting
        let sortedInfoMetadata = infoMetadataDynamicFiltered.sort((a,b) => {
            if(sorting === "ความนิยม"){
                return a.viewCountRank - b.viewCountRank
            } else if(sorting === "ตัวอักษร ก-ฮ"){
                return a.infographicTitle.localeCompare(b.infographicTitle,'th')
            } else if(sorting === 'ตัวอักษร ฮ-ก'){
                return b.infographicTitle.localeCompare(a.infographicTitle,'th')
            } else{ // chronological order
                let aTime = new Date(a.createdDate)
                let bTime = new Date(b.createdDate)
                return aTime.getTime() - bTime.getTime()
            }
        })

        return sortedInfoMetadata
    }



export interface BookmarkAction{
    infographicId: string
    isBookmarked: boolean
}

export const updateInfoMetaBookmark = (infographicMetadatas: InfographicCardType[], bookmarkAction: BookmarkAction ) => {
    return infographicMetadatas.map((infographicMetadata) => {
        if(infographicMetadata.infographicId === bookmarkAction.infographicId){
            return {...infographicMetadata, isBookmarked: bookmarkAction.isBookmarked}
        } else{
            return infographicMetadata
        }
    })
}

// bookmarkPage

export const prepBookmarkInfographicMetadata = (
    infographicMetadatas: InfographicCardType[],
    searchText: string,
    activeTag: InfographicCategoryThai,
    sorting: Sorting) => {
    const searchFilterSelector = (infographicMetadata: InfographicCardType) => {
        let infographicStrings = [
            ...infographicMetadata.tags,
            ...infographicMetadata.additionalKeywords, 
            ...infographicMetadata.relevantProvinces]
        //add all text-related strings from metadata
        infographicStrings.push(infographicMetadata.infographicTitle.toLowerCase())
        infographicStrings = infographicStrings.concat()

        return infographicStrings.some((string) =>
                string.includes(searchText.toLowerCase())
            )
        
    }

    let infoMetadataCategoryFiltered = infographicMetadatas.filter((infographicMetadata) => 
    // searchText filter
    searchFilterSelector(infographicMetadata) &&
    // category filter
    (activeTag === "ทั้งหมด" || infographicMetadata.tags.includes(activeTag)) &&
    // isBookmarked
    infographicMetadata.isBookmarked
    )

    let sortedInfographicMetadatas = infoMetadataCategoryFiltered.sort((a,b) => {
        if(sorting === "ความนิยม"){
            return a.viewCountRank - b.viewCountRank
        } else if(sorting === "ตัวอักษร ก-ฮ"){
            return a.infographicTitle.localeCompare(b.infographicTitle,'th')
        } else if(sorting === 'ตัวอักษร ฮ-ก'){
            return b.infographicTitle.localeCompare(a.infographicTitle,'th')
        } else{ // chronological order
            let aTime = new Date(a.createdDate)
            let bTime = new Date(b.createdDate)
            return aTime.getTime() - bTime.getTime()
        }
    })
    return sortedInfographicMetadatas
}

export const prepQueryList = (
    category: InfographicCategoryThai, 
    searchText: string, 
    infographicType: "" | InfographicTypeThai,
    dataUpdateCycle: "" | DataUpdateCycleThai,
    dataType: "" | DataTypeThai,
    province: "" | ProvinceThai,
    portType: "" | PortTypeThai,
    sorting: Sorting) => {
    let query_list: string[] = []

    if (category !== "ทั้งหมด") {
        let matchedCategory = infographic_tags.find(({ category_thai }: ({category_thai: string})) => category_thai === category)
        if(matchedCategory){
            query_list.push("category=" + matchedCategory.category_eng)
        }
        
    }
    if (searchText !== "") {
        query_list.push("search=" + searchText)
    }

    if(infographicType !== ""){
        let matchedInfographicType = infographicTypeItems.find(({thai}) => thai === infographicType)
        if(matchedInfographicType){
            query_list.push("infographicType=" + matchedInfographicType.english)
        }
    }

    if(dataUpdateCycle !== ""){
        let matchedDataUpdateCycle = dataUpdateCycleItems.find(({thai}) => thai === dataUpdateCycle)
        if(matchedDataUpdateCycle){
            query_list.push("dataUpdateCycle=" + matchedDataUpdateCycle.english)
        }
    }

    if(dataType !== ""){
        let matchedDataType = dataTypeItems.find(({thai}) => thai === dataType)
        if(matchedDataType){
            query_list.push("dataType=" + matchedDataType.english)

            if(dataType === "รายจังหวัด" && province !== ""){
                let matchedProvince = province_list[province]
                if(matchedProvince){
                    query_list.push("province="+matchedProvince)
                }
            }  else if(dataType === "รายด่าน" && portType !== ""){
                let matchedPortType = portTypeItems.find(({thai}) => thai === portType)
                if(matchedPortType){
                    query_list.push("portType="+matchedPortType.english)
                }
            }
        }
    }

    if(sorting !== "ความนิยม"){
        let matchSorting = sortingTypeItems.find(({thai}) => thai === sorting)
        if(matchSorting){
            query_list.push(`sortBy=${matchSorting.english}`)
        }
    }

    return query_list
}


export function findFieldByValue(obj: any, targetValue: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === targetValue) {
        return key; // Found the field
      }
    }
    return null; // Field not found
  }


export const readQueryParams = (
    infographicMetadatasFull: InfographicCardType[],
    searchTextTmp: string | null, 
    activeTagTmp: string | null, 
    infographicTypeTmp: string | null, 
    dataTypeTmp: string | null, 
    dataUpdateCycleTmp: string | null, 
    provinceTmp: string | null, 
    portTypeTmp: string | null
) => {
    let searchText = ""
    let activeTag = "ทั้งหมด"
    let infographicType: "" | InfographicTypeThai = ""
    let dataType: "" | DataTypeThai = ""
    let dataUpdateCycle: "" | DataUpdateCycleThai = ""
    let province: "" | ProvinceThai = ""
    let portType: "" | PortTypeThai = ""

    if (activeTagTmp) {
        let matchedCategory = infographic_tags.find(({category_eng}) => category_eng === activeTagTmp)
        if (matchedCategory) {
            activeTag = matchedCategory.category_thai
        }
    }
    if (searchTextTmp) {
        searchText = searchTextTmp
    }
    if(infographicTypeTmp){
        let matchedInfographicType = infographicTypeItems.find(({english}) => english === infographicTypeTmp)
        if(matchedInfographicType){
            infographicType = matchedInfographicType.thai
        }
    }
    if(dataUpdateCycleTmp){
        let matchedDataUpdateCycle = dataUpdateCycleItems.find(({english}) => english === dataUpdateCycleTmp)
        if(matchedDataUpdateCycle){
            dataUpdateCycle = matchedDataUpdateCycle.thai
        }
    }
    if(dataTypeTmp){
        let matchedDataType = dataTypeItems.find(({english}) => english === dataTypeTmp)
        let classifierMap: {[key in DataTypeThai]: Classifier[]} = {
            "รายด่าน":["ทุกด่าน","ด่านน้ำ","ด่านบก", "ด่านอากาศ"],
            "รายจังหวัด": ["รายจังหวัด"],
            "ภาพรวมประเทศ": ["ภาพรวมประเทศ"]
        }

        if(matchedDataType){
            let classifierList = classifierMap[matchedDataType.thai]
            if(infographicMetadatasFull.some(({infographicClassifier}) => classifierList.includes(infographicClassifier))){
                dataType = matchedDataType.thai
            }
        }
    }

    if(dataType){
        if(dataType === "รายจังหวัด" && provinceTmp){
            let matchedProvince = findFieldByValue(province_list, provinceTmp) as ProvinceThai | null
            if(matchedProvince){
                province = matchedProvince
            }
        } else if(dataType === "รายด่าน" && portTypeTmp){
            let matchedPortType = portTypeItems.find(({english}) => english === portTypeTmp)
            if(matchedPortType){
                portType = matchedPortType.thai
            }
        }
    }

    // return allowed filters
    return {searchText, activeTag, infographicType, dataType, dataUpdateCycle, province, portType}
}