import React, { useState, useMemo, useEffect } from 'react'
import { 
    Select,
    MenuItem,
    ListSubheader,
    ThemeProvider,
    createTheme,
    TextField
} from '@mui/material'
import { dynamicSelectorTheme } from '../../../theme/dynamicSelector';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface DynamicSearchSelectorProps{
    noMaxWidth?: boolean,
    fullWidth?: boolean, 
    selectorName: string, 
    value: string, 
    setValue: React.Dispatch<string>, 
    dynamicList: string[]
}

const DynamicSearchSelector = ({
    noMaxWidth,
    fullWidth,
    selectorName,
    value,
    setValue,
    dynamicList
}: DynamicSearchSelectorProps) => {
    const theme = createTheme(dynamicSelectorTheme)
    const [searchText, setSearchText] = useState<string>("")
    const containsText = (text: string, searchText: string) =>
        text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    const filteredDynamicList = useMemo(
        () => dynamicList.filter((value) => containsText(value, searchText)).concat(value),
        [dynamicList, searchText]
    )

    
  return (
    <ThemeProvider theme={theme}>
        <Select
        // Disables auto focus on MenuItems and allows TextField to be in focus
        MenuProps={{
            autoFocus: false,
            style: {
                maxHeight: "400px",
            },
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onClose={() =>
            setSearchText("")
        }
        sx={{
            // this is for placeholder
            "& .MuiSelect-select .notranslate::after":
                {
                    content: `"${selectorName}"`,
                    opacity: 1,
                },
            maxWidth: (noMaxWidth || fullWidth) ? 'none' : '180px'
        }}
        fullWidth={fullWidth}
    >
        {/* TextField is put into ListSubheader so that it doesn't
    act as a selectable item in the menu
    i.e. we can click the TextField without triggering any selection.*/}
        <ListSubheader>
            <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder={selectorName}
                fullWidth
                onChange={(e) =>
                    setSearchText(
                        e.target.value
                    )
                }
                onKeyDown={(e) => {
                    if (
                        e.key !==
                        "Escape"
                    ) {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation()
                    } 
                }}
            />
        </ListSubheader>
        {filteredDynamicList.map(
            (itemValue, i) => (
                <MenuItem
                    disableRipple
                    key={i}
                    value={itemValue}
                    sx={{
                        fontFamily:
                            "Kanit",
                        display: i === filteredDynamicList.length - 1  ?
                        'none' : 'flex'
                    }}
                >
                    {itemValue}
                </MenuItem>
            )
        )}
    </Select>

    </ThemeProvider>
    
  )
}

export default DynamicSearchSelector