import React from "react"
import CustomContainer from "../../../components/shared/CustomContainer/CustomContainer"
import {
  Box
} from '@mui/material'
import {
  DES,
  MoTS,
  DoPA,
  DoCP,
  กรมอุทยาน,
  CAAT,
  ImBu,
  สำนักงานสถิติแห่งชาติ,
  TAT,
  AOT,
  DASTA,
  ศมส,
  Museumsiam,
  PTA,
  TCT,
  VajiraPhuket,
  MD,
  MoC,
  DoT,
  depa,
  BDI,
} from "../../../assets/home/partner/index"
import PartnerModule from "./Partner.module.css"

function Partner() {
  return (
    <div className={PartnerModule.partnerWrapper}>
      <h1 className={PartnerModule.text_header}>ความร่วมมือ</h1>
      <CustomContainer>
          <Box className={PartnerModule.imageContainer} paddingX={{md: "30px"}}>
            <img className={PartnerModule.partner} src={BDI} style={{paddingRight:'0'}} />
            <img className={PartnerModule.partner} src={depa} />
            <img className={PartnerModule.partner} src={TAT} />
            <img className={PartnerModule.partner} src={PTA} />
            <img className={PartnerModule.partner} src={TCT} />
            <img className={PartnerModule.partner} src={VajiraPhuket} />
            <img className={PartnerModule.partner} src={DES}/>
            <img className = {PartnerModule.partner} src={MoTS} />
            <img className = {PartnerModule.mCult} src={MoC}/>
            <img className={PartnerModule.partner} src={DoPA}/>
            <img className = {PartnerModule.partner} src={ImBu}/>
            <img className={PartnerModule.CAAT} src={CAAT}/>
            <img className={PartnerModule.AOT} src={AOT} />
            <img className={PartnerModule.partner} src={MD} />

            <img className={PartnerModule.SAC} src={ศมส} />
            <img className={PartnerModule.partner} src={DoT}/>
            <img className={PartnerModule.partner} src={DoCP}/>

            <img className={PartnerModule.DASTA} src={DASTA} />
            <img
              className={PartnerModule.NSO}
              src={สำนักงานสถิติแห่งชาติ}
            />
            <img className={PartnerModule.partner} src={Museumsiam} />
            <img className={PartnerModule.partner} src={กรมอุทยาน} />
          </Box>
      </CustomContainer>
    </div>
  )
}

export default Partner
