import React, { useState } from "react"
import {
    Dialog,
    ThemeProvider,
    DialogTitle,
    Typography,
    DialogContent,
    createTheme,
    ThemeOptions,
    TextField,
    InputAdornment,
    List,
    ListItem,
    Paper,
} from "@mui/material"
import SearchRoundedIcon from "@mui/icons-material/SearchRounded"
import close_icon from "./../../../assets/close_button.svg"
import logo from "../../../assets/navbar/tourism-logo.png"
import style from "./AlliancePopUp.module.css"

import { mergeThemes } from "../../../theme/merge"
import { longDialog_theme } from "../../../theme/longDialog"
import { typography_theme } from "../../../theme/typography"
import { textbox_theme } from "../../../theme/textbox"
import { register_textbox_theme } from "../../../theme/register"
import { list_theme } from "../../../theme/listItemScroll"

const AlliancePopUp = ({
    open,
    setOpen,
    allianceList,
}: {
    open: boolean
    setOpen: React.Dispatch<boolean>
    allianceList: string[]
}) => {
    const [searchText, setSearchText] = useState<string>("")
    const [filterList, setFilterList] = useState<string[]>(allianceList)

    const mergedTheme = mergeThemes(
        longDialog_theme,
        typography_theme,
        textbox_theme,
        register_textbox_theme
    )
    const theme = createTheme(mergedTheme)
    const handleClosing = () => setOpen(false)
        
    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open}
                onClose={() => handleClosing()}
                hideBackdrop
                maxWidth={false}
            >
                <div
                    className={style.closeButton}
                    onClick={() => handleClosing()}
                >
                    <img
                        src={close_icon}
                        style={{ borderRadius: "29px" }}
                    ></img>
                </div>
                
                <img src={logo} style={{width: '8.95681rem', height: '1.9375rem', objectFit: 'contain', alignSelf: 'center'}}></img>


                <DialogTitle>
                    <Typography variant="title" sx={{ color: "#194B7E" }}>
                        สมาชิกหน่วยงานภายใต้ MOU
                    </Typography>
                </DialogTitle>
                <Typography variant="bodyText">
                    หากท่านเป็นตัวแทนของหน่วยงาน หรือ เป็นบุคลากรของหน่วยงานภายใต้ MOU ท่านสามารถสมัครสมาชิกหน่วยงานภายใต้ MOU เพื่อดูแดชบอร์ดความร่วมมือที่ได้ทำข้อตกลงไว้
                </Typography>
                
                <DialogTitle>
                    <Typography variant="title" sx={{ color: "#194B7E" }}>
                        รายชื่อองค์กรและสมาคมภายใต้ MOU
                        
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ overflowY: "unset" }}>
                    <TextField
                        placeholder="ค้นหา"
                        name="searchText"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchRoundedIcon sx = {{color:'rgba(1, 130, 250, 1)'}} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ 
                            marginTop: "1px", 
                            '& .MuiOutlinedInput-input':{
                                paddingTop:'13.5px',
                                paddingBottom:'13.5px'
                            }
                            
                         }}
                    />
                    <ThemeProvider theme={list_theme}>
                        <Paper>
                            <List>
                                {
                                    allianceList
                                    .filter((alliance) =>
                                        alliance.includes(searchText)
                                    ).length !==0 ? 
                                allianceList
                                    .filter((alliance) =>
                                        alliance.includes(searchText)
                                    )
                                    .map((alliance) => (
                                        <ListItem>{alliance}</ListItem>
                                    ))
                                    :
                                    <>
                                        <div className={style.textBold}>ไม่พบคำที่ค้นหา </div>
                                        <div className={style.text}>หากสนใจเข้าร่วมเป็นหน่วยงาน MOU กับเรา <Typography variant='ahref'><a href='/mou-register'>คลิกที่นี่</a></Typography></div>

                                    </>
                                }
                            </List>
                        </Paper>
                    </ThemeProvider>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    )
}

export default AlliancePopUp
