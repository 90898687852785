export interface MOUForm {
    organization: string,
    purpose: string,
    firstName: string,
    lastName: string,
    department: string,
    position: string,
    email: string,
    phoneNo: string
}

export interface MOUFormValidation {
    organization?: string,
    purpose?: string,
    firstName?: string,
    lastName?: string,
    department?: string,
    position?: string,
    email?: string,
    phoneNo?: string
}

export const mouFormValidate = (values:MOUForm) => {
    let errors : MOUFormValidation = {}

    if (!values.organization) {
        errors.organization = "กรุณากรอกชื่อหน่วยงาน/สมาคมของท่าน"
    } 

    if (!values.purpose) {
        errors.purpose = "กรุณากรอกจุดประสงค์"
    } 

    if (!values.firstName) {
        errors.firstName = "กรุณากรอกชื่อของท่าน"
    } 

    if (!values.lastName) {
        errors.lastName = "กรุณากรอกนามสกุลของท่าน"
    } 

    if (!values.department) {
        errors.department = "กรุณากรอกฝ่าย/แผนกในหน่วยงานที่ท่านสังกัดอยู่ (ถ้าไม่มีให้พิมพ์ - )"
    } 

    if (!values.position) {
        errors.position = "กรุณากรอกตำแหน่งในหน่วยงานของท่าน"
    } 

    if (!values.phoneNo) {
        errors.phoneNo = "กรุณากรอกเบอร์โทรศัพท์"
    } else if (
        !/((\+66|0)((2)(-?\d{3}\-?\d{4})|(32|33|34|35|36|37|38|39|42|43|44|45|53|54|55|56|73|74|75|76|77)(-?\d{3}\-?\d{3})|([689])(\d{1}\-?\d{3}\-?\d{4})))/.test(
            values.phoneNo
        )
    ) {
        errors.phoneNo =
            "กรุณากรอกเบอร์โทรศัพท์ที่ใช้งานได้ในประเทศ (ตัวอย่างเช่น 0987654321, 055-123-456, +662-233-4455)"
    }

    if (!values.email) {
        errors.email = "กรุณากรอกอีเมล"
    } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            values.email
        )
    ) {
        errors.email = "กรุณากรอกอีเมลที่ถูกต้อง"
    }

    return errors
}