import React from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    ThemeProvider,
    createTheme
} from '@mui/material'
import PolicySelectionTab from './PolicySelectionTab/PolicySelectionTab'
import logo from "../../../assets/navbar/tourism-logo.png"
import style from './AgreementPopUp.module.css'
import { dialog_theme } from '../../../theme/agreementPopUp'
import close_icon from './../../../assets/close_button.svg'

const AgreementPopUp = ({open, setOpen}:{open:number, setOpen: React.Dispatch<React.SetStateAction<number>>}) => {
    const theme = createTheme(dialog_theme)
    return (
        <ThemeProvider theme={theme}>
            <Dialog open={open !== -1} onClose={() => setOpen(-1)}>
            <div className = {style.closeButton} onClick = {() => setOpen(-1)}>
                <img src = {close_icon} style = {{borderRadius: '29px'}}></img>
            </div>
            <DialogTitle sx={{textAlign:'center'}}>
                <img src={logo} style={{width: '8.95681rem', height: '1.9375rem', objectFit: 'contain', alignSelf: 'center'}}></img>

                <div className={style.title}>
                    Terms & Policies
                </div>
            </DialogTitle>
            <DialogContent>
                <PolicySelectionTab tabNo={open}/>
            </DialogContent>

        </Dialog>

        </ThemeProvider>
        
    )
}

export default AgreementPopUp