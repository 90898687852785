export interface FormData {
    firstName: string,
    lastName: string,
    email: string,
    org: string,
    phoneNo: string,
    message: string,
}

export const contact_validate = (values: FormData) => {
    let errors:{
    firstName?: string,
    lastName?: string,
    email?: string,
    org?: string,
    phoneNo?: string,
    message?: string,
    } = {};

    if (!values.firstName) {
    errors.firstName = "กรุณากรอกชื่อของคุณ";
    }
    if (!values.lastName) {
    errors.lastName = "กรุณากรอกนามสกุลของคุณ";
    }

    if( values.phoneNo){
        if (
            !/((\+66|0)((2)(-?\d{3}\-?\d{4})|(32|33|34|35|36|37|38|39|42|43|44|45|53|54|55|56|73|74|75|76|77)(-?\d{3}\-?\d{3})|([689])(\d{1}\-?\d{3}\-?\d{4})))/.test(
                values.phoneNo
            )
        ) {
            errors.phoneNo =
                "โปรดกรอกเบอร์โทรศัพท์ที่ใช้งานได้ในประเทศ (ตัวอย่างเช่น 0987654321, 055-123-456, +662-233-4455)"
        }
    }

    if (
    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        values.email
    )
    ) {
    errors.email = "กรุณากรอกอีเมลที่ถูกต้อง";
    }

    if (!values.message) {
    errors.message = "กรุณากรอกคำถาม";
    }

    return errors;
};