import { ThemeOptions, Theme, createTheme } from "@mui/material";
import { deepmerge } from '@mui/utils'

export const searchingBox: ThemeOptions = {
    components:{
        MuiOutlinedInput:{
            styleOverrides:{
                notchedOutline:{
                    borderRadius: "50px 0 0 50px",
                    backgroundColor: "#FBFBFB",
                    zIndex: "-1"
                }
            }
        }
    }
}

export const breakPointTheme: ThemeOptions = {
    breakpoints:{
        values:{
            xs: 0,
            sm: 600,
            md: 1050,
            lg: 1200,
            xl: 1536,
        }
    }
}

const toggleButton_basetheme: ThemeOptions = {
    components:{
        MuiToggleButton:{
            variants:[
                {
                    props:{selected: true},
                    style:{
                        backgroundColor: 'rgba(0, 0, 0, 0.23)',
                        borderColor: '#0182FA ',
                        color: '#0182FA !important'
                    }
                }
            ],
            styleOverrides:{
                root:{
                    borderRadius: '0 50px 50px 0',
                    paddingTop: '12.5px',
                    paddingBottom: '12.5px',
                    borderColor:'rgba(0, 0, 0, 0.23)',
                    '&:hover':{
                        backgroundColor: 'white',
                        borderColor: '#0182FA',
                        color: '#0182FA'
                    },
                    width: '75px'
                }
            }
        }
    }
}

const toggle_button_dynamic_theme:  (theme: Theme) => ThemeOptions  = (theme: Theme) => ({
    components:{
        MuiToggleButton: {
            styleOverrides:{
                root:{
                    [theme.breakpoints.down('sm')]:{
                        paddingTop: '10.5px',
                        paddingBottom: '10.5px',
                    }
                }
            }
        }
    }
})

export const toggleButton = deepmerge(toggle_button_dynamic_theme(createTheme({})), toggleButton_basetheme)

export const popoverTheme: ThemeOptions = {
    components:{
        MuiMenuItem:{
            variants:[
                {
                    props:{selected:true},
                    style:{
                        backgroundColor:'#0182FA',
                        color: '#0182FA',
                        fontWeight: '500'
                    }
                }
            ],
            styleOverrides:{
                root:{
                    fontFamily: 'Kanit',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '28px',
                    letterSpacing: '0.005em',
                    textAlign: 'left'
                }
            }
        },
        MuiMenu:{
            styleOverrides:{
                paper:{
                    maxHeight: '300px',
                    borderRadius:'12px'
                }
            }
        },
    }
}

export const accordionTheme: ThemeOptions = {
    components:{
        MuiAccordion:{
            styleOverrides:{
                root:{
                    fontFamily: "Kanit",
                    fontSize: '15px',
                    fontWeight: '400',
                    lineHeight: '28px',
                    letterSpacing: '0.005em',
                    textAlign: 'left',
                    boxShadow: "none",
                    borderBottom: '1px rgba(0, 0, 0, 0.12) solid'
                }
            }
        }
    }
}