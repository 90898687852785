import React from 'react'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getInfographicMetadata } from '../../../services/data-connector'
import { RootState } from '../../store';
import { InfographicCardType } from '../../../interfaces/Infographic';


interface InfographicState {
    infographicMetadata: InfographicCardType[],
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string
}

const initialState: InfographicState = {
    infographicMetadata: [],
    status: 'idle',
    error: ''
}

export const fetchInfographic = createAsyncThunk<InfographicCardType[],string>('infographic/fetchInfographic', async (accessToken,{rejectWithValue}) => {
    try{
        const { res, status } = await getInfographicMetadata(accessToken)
        if(status === 200){
            return res.infographicMetadatas
        }
        else{
            return rejectWithValue(res.error)
        }
    }
    catch(err){
        return rejectWithValue((err as Error).message)
    }
    
})

export const infographicSlice = createSlice({
    name: 'infographic',
    initialState,
    reducers:{
        infographicMetadataAdded(state, action) {
            state.infographicMetadata = action.payload
        }
        
    },
    extraReducers(builder) {
        builder
            .addCase(fetchInfographic.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchInfographic.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Adding date and reactions
                state.infographicMetadata = action.payload as Object as InfographicCardType[]
            })
            .addCase(fetchInfographic.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message ? action.error.message : ''
            })
        }
})

export const selectInfographicMetadata = (state: RootState) => state.infographic.infographicMetadata;

export const selectInfographicStatus = (state: RootState) => state.infographic.status

export const selectInfographicError = (state: RootState) => state.infographic.error

export const { infographicMetadataAdded } = infographicSlice.actions

export default infographicSlice.reducer
