import { ThemeOptions } from '@mui/material/styles';
import { createTheme, Theme } from '@mui/material';
import { deepmerge } from '@mui/utils';

const basetheme = createTheme({
    components:{ 
        MuiPaper:{
            styleOverrides:{
                root:{
                    width: '596px',
                    background: "#FFFFFF",
                    border: "1.5px solid #DEE2E6",
                    borderRadius: "12px",
                    paddingTop:'1.6rem',
                    paddingBottom: '1.6rem',
                    paddingLeft:'70px',
                    paddingRight:'70px',               
                }
            },
        },
        MuiDialog:{
            styleOverrides:{
                root:{
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    opacity: "1",
                }
            }
        },
        MuiDialogTitle:{
            styleOverrides:{
                root:{
                    textAlign:'center'
                }
            }
        },
        MuiButton:{
            styleOverrides:{
                root:{
                    marginBottom:'1rem'
                }
            }
        },
        MuiFormControl:{
            styleOverrides:{
                root:{
                    marginBottom:'1rem'
                }
            }
        },
        MuiLink:{
            styleOverrides:{
                root:{
                    textDecoration: 'underline !important',
                    color: '#0182FA !important'
                }
            }
        },
        MuiDivider:{
            styleOverrides:{
                root:{
                    margin: '20px 0'
                }
            }
        }
    }
}
)

const breakpointTheme = (theme: Theme) => ({
    components:{
        MuiDialogTitle:{
            styleOverrides:{
                root:{
                    [theme.breakpoints.down('sm')]:{
                        paddingLeft:'0',
                        paddingRight:'0',
                    }
                }
            }
        },
        MuiPaper:{
            styleOverrides:{
                root:{
                    [theme.breakpoints.down('sm')]:{
                        paddingLeft:'25px',
                        paddingRight:'25px',
                    }

                }
            }
        }
    }
})

const theme = createTheme({})

export const longDialog_theme =  deepmerge(basetheme, breakpointTheme(theme));