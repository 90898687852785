import React, { useState, useRef, useEffect } from 'react'
import {
    Box,
    List,
    ListItem,
    ListItemText,
    ThemeProvider,
    Menu,
    ListItemButton
} from '@mui/material'
import { dropdown_theme } from '../../../theme/navbar'
import { useHistory } from 'react-router-dom'

interface DropdownOption {
    key: string,
    name: React.ReactNode,
    ahref?: string,
    handleClick?: () => void,
    isShowing?: boolean,
    isNewTab?: boolean,
    isDisabled?: boolean
}

interface DropdownMenuProps{
    isCollapse: boolean,
    header: React.ReactNode,
    toPathname?: string,
    toExternalLink?: string,
    width: number,
    options: DropdownOption[]
}

const DropdownOption = ({name, ahref, handleClick, isShowing, isNewTab, isDisabled}: DropdownOption) => {
    if(isShowing === false){
        return <></>
    } else if (isDisabled){
        return (
        <ListItemButton
        disabled>
            <ListItemText primary={<>{name}<sup>เร็วๆนี้</sup></>} />
        </ListItemButton>
        )
    } 
    else if (ahref) {
        if(isNewTab){
            return (
                <a
                    href={ahref}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: "pointer" }}
                >
                    <ListItem>
                        <ListItemText primary={name} />
                    </ListItem>
                </a>
            );

        }
        return (
            <a
                onClick={(e) => {e.stopPropagation(); }}
                href={ahref}
                style={{ cursor: "pointer" }}
            >
                <ListItem>
                    <ListItemText primary={name} />
                </ListItem>
            </a>
        );
    } else if(handleClick) {
        return (
            <ListItem
            onClick={(e) => {e.stopPropagation(); handleClick()}}>
                <ListItemText primary={name} />
            </ListItem>
        );
    } else {
        return <></>
    }
}

const DropdownMenu = ({isCollapse, header, toPathname, toExternalLink, width, options}: DropdownMenuProps) => {

    const popoverAnchor = useRef(null);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const history = useHistory()

    useEffect(() => {
        if(isCollapse){
            setAnchorEl(null)
        }
    },[isCollapse])

  return (
    <ThemeProvider theme={dropdown_theme}>
        <a 
        href={toExternalLink} 
        target={toExternalLink ? '_blank' : 'none'} 
        onClick={toPathname ? (() => history.push(toPathname)) : undefined}
        style={{
            height:'100%',
            fontSize: '14px',
            cursor: 'pointer',
            margin: '0 10px',
            width: `${width}px`
        }}
        >
            <Box
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                ref={popoverAnchor}
                sx={open ?{

                        fontWeight: '600',
                        color: '#194B7E !important',
                        height:'100%',
                        display: 'flex',
                        justifyContent:'center',
                        alignItems: 'center',
                    } :
                    {
                        height: '100%',
                        display: 'flex',
                        justifyContent:'center',
                        alignItems: 'center',
                    }
                }
            >
                {header}
            </Box>

            <Menu
            anchorEl={popoverAnchor.current}
            open={open}
            onClose={handlePopoverClose}
            sx={{
                pointerEvents: 'none'
            }}
            MenuListProps={{
                'aria-labelledby': 'basic-button'
            }}
            PaperProps={{
                onMouseEnter: handlePopoverOpen,
                onMouseLeave: handlePopoverClose,
                sx: {
                    pointerEvents: 'auto',
                    borderRadius:'0 0 12px 12px'
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            disableScrollLock
            disableRestoreFocus
            onClick={(e) => {e.stopPropagation();}}
        >
            <List sx={{ width: '100%', maxWidth: 360 }}>
            {
                options.map((option) => (
                    <DropdownOption {...option}/>
                ))
            }
            </List>
        </Menu>
        </a>

        

    </ThemeProvider>
    
  )
}

export default DropdownMenu