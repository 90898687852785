import React from 'react'
import {
    Box,
    Grid,
    Skeleton,
} from '@mui/material'
import DashboardSkeleton from '../../DashboardSkeleton/DashboardSkeleton'

const DashboardRowSkeleton = () => {
  return (
    <Box marginBottom={5}>
        <Box display='flex' justifyContent='flex-start' alignItems='flex-end' marginBottom={2.5}>
            <Skeleton width={150} sx={{fontSize:"28px", lineHeight: "42px", marginRight:"25px"}}/>
            <Skeleton width={90} sx={{fontSize:"18px", lineHeight: "27px"}}/>
        </Box>
        <Grid container spacing={3}>
            <Grid item md={4} sm={6} xs={12}>
                <DashboardSkeleton />
            </Grid>
            <Grid item md={4} sm={6} display={{md:"block", sm:'block', xs:"none"}}>
                <DashboardSkeleton />
            </Grid>
            <Grid item md={4} display={{md:"block", sm:'none', xs:"none"}}>
                <DashboardSkeleton />
            </Grid>
        </Grid>
    </Box>
  )
}

export default DashboardRowSkeleton