import React from 'react'
import Grid from '@mui/material/Grid';
import Button from '../Button/Button'
import { useHistory, RouterChildContext } from 'react-router-dom'

import CookiesPopupModule from './CookiesPopup.module.css'

interface CookiesProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const CookiesPopup = (props: CookiesProps) => {
    const history  = useHistory<RouterChildContext>()
    return (
        <div
            className={CookiesPopupModule.background}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={8} md={6} textAlign="left">
                        <div style={{ fontFamily: 'Kanit', fontSize: '20px', textAlign: 'left', marginBottom: '10px' }}>
                            เว็บไซต์นี้ใช้คุกกี้
                        </div>
                        <span style={{ fontFamily: 'Kanit', fontSize: '12px' }}>
                            เพื่อสร้างประสบการณ์นำเสนอคอนเทนต์ที่ดีให้กับท่าน
                            รวมถึงเพื่อจัดการข้อมูลส่วนบุคคลเพื่อให้ท่านได้รับประสบการณ์ที่ดีบนบริการของเว็บไซต์เรา
                            หากท่านใช้บริการเว็บไซต์นี้ต่อไปโดยไม่มีการปรับตั้งค่าใดๆ
                            นั่นเป็นการแสดงว่าท่านอนุญาตยินยอมที่จะรับคุกกี้บนเว็บไซต์และนโยบายสิทธิส่วนบุคคลของเรา
                        </span>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign="right" sx={{
                    display: "flex",  
                    alignItems: "center",  
                    justifyContent: "center"}}>
                        <Button
                            onClick={props.onClick}
                            className={CookiesPopupModule.btn}
                        >
                            ยอมรับ


                        </Button>
                        <Button
                            onClick={() => history.push('/policies')}
                            className={CookiesPopupModule.btn}
                            secondary>
                            เรียนรู้เพิ่มเติม
                        </Button>
                </Grid>
            </Grid>


        </div>
    )
}

export default CookiesPopup
