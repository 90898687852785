import React from 'react'
import { DashboardGalleryCard } from '../../../../interfaces/DashboardMetadata'
import { Card, CardContent, CardMedia } from '@mui/material'
import { getUserAccount } from '../../../../utils/login'
import { Box } from '@mui/system'
import DashboardTagElement from '../../../../components/shared/DashboardTagElement/DashboardTagElement'

interface DashboardHomeCardProps{
  dashboardMetadata: DashboardGalleryCard
  setRedirectUrl: React.Dispatch<string>
  setOpenLogInPopUp: React.Dispatch<boolean>
}

const DashboardHomeCard = ({dashboardMetadata, setRedirectUrl, setOpenLogInPopUp}: DashboardHomeCardProps) => {
  const user = getUserAccount();

  const handleNotLoggedInClick = () => {
    setOpenLogInPopUp(true)
    setRedirectUrl(dashboardMetadata.galleryPages[0].link)
  }

  const DashboardHomeCardElement = () => (
    <Card>
        <CardMedia
        component='img'
        image={dashboardMetadata.imgUrl}
        alt={dashboardMetadata.galleryTitle}
        />
        <CardContent>
          <Box height='21px'sx = {{
            WebkitLineClamp: '1', 
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical'
          }} 
          overflow='hidden'
          title={dashboardMetadata.galleryTitle}>
            {dashboardMetadata.galleryTitle}
          </Box>
          <Box marginTop='5px' display={{md:'flex', sm: 'none', xs: 'none'}}  sx={{backgroundColor:'white', flexWrap:'wrap', gap: '5px', justifyContent:'center'}}>
            {
              dashboardMetadata.tags.map((tag) => (
                <DashboardTagElement key={tag} tag={tag} />
              ))
            }
          </Box>
          <Box display={{md:'none', sm: 'flex', xs: 'flex'}} sx={{backgroundColor:'white', flexWrap:'wrap', gap: '5px', justifyContent:'center'}}>
            {
              dashboardMetadata.tags.map((tag) => (
                <DashboardTagElement key={tag} tag={tag} tiny lean/>
              ))
            }
          </Box>
        </CardContent>
      </Card>
  )
  return (
    <>
    {
      user.getIsLoggedIn() ?
      <a href={dashboardMetadata.galleryPages[0].link} target="_blank">
        <DashboardHomeCardElement />
      </a>
      :
      <a onClick={handleNotLoggedInClick}>
        <DashboardHomeCardElement />
      </a>
    }
    </>
    
  )
}

export default DashboardHomeCard