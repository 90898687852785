import { ThemeOptions } from '@mui/material'

export const list_theme: ThemeOptions = {
    components:{
        MuiPaper:{
            styleOverrides:{
                root:{
                    width:'100%',
                    maxWidth:'405px',
                    height: 'calc(100vh - 450px)',
                    overflow:'auto',
                    boxShadow: 'none',
                    borderRadius: '12px',
                    border:'1.5px solid #DEE2E6',
                    maxHeight: '400px'
                }
            }
        },
        MuiListItem:{
            styleOverrides:{
                root:{
                    fontFamily: 'Kanit',
                    fontSize: '15px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    display: 'list-item',
                    listStyleType: 'disc',
                    paddingLeft: '5px',
                    paddingRight: '20px',
                    marginLeft: '40px',
                    width: 'calc(100% - 40px)',

                }
            }
        }
    }
}