const DASHBOARD_STATUS = {
    IDLE: 'idle',
    LOADING: 'loading',
    FAILED: 'failed',
    SUCCEEDED: 'succeeded'
}

export const STATUS = {
    IDLE: 'idle',
    LOADING: 'loading',
    FAILED: 'failed',
    SUCCEEDED: 'succeeded'
}

export default DASHBOARD_STATUS