import React, { useState, useRef, memo } from "react"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    ThemeProvider,
    createTheme,
    Typography,
    Divider,
    TextField,
    Button,
    InputAdornment,
    IconButton,
    Box
} from "@mui/material"
import close_icon from "./../../../assets/close_button.svg"
import { GoogleLogo, MicrosoftLogo } from "../../../assets/login/index"
import logo from "../../../assets/navbar/tourism-logo.png"
import style from "./LogInPopUp.module.css"
import { Formik } from "formik"
import { LogInForm } from "../../../interfaces/LogIn"
import ErrorElement from "../ErrorElement/ErrorElement"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { mergeThemes } from "../../../theme/merge"
import { longDialog_theme } from "../../../theme/longDialog"
import { typography_theme } from "../../../theme/typography"
import { narrow_textbox_theme } from "../../../theme/textbox"
import { register_textbox_theme } from "../../../theme/register"
import { tertiary_button_theme } from "../../../theme/tertiary_button_theme"
import { login_validate } from "../../../validation/login"
import TVLButton from "../TVLButton/TVLButton"
import WestRoundedIcon from "@mui/icons-material/WestRounded"
import ResponsePopUp from "../ResponsePopUp/ResponsePopUp"
import { login } from "../../../services/data-connector"
import { useHistory } from "react-router"
import { ErrorEng, APIHandlerObject } from "../../../interfaces/APIHandler"
import Cookies from "js-cookie"
const APIhandler: APIHandlerObject = require("../../../variable/APIhandler.json")

const LogInPopUp = memo(({
    open,
    setOpen,
    redirect_uri,
    fromUrl
}: {
    open: boolean
    setOpen: React.Dispatch<boolean>
    redirect_uri?: string,
    fromUrl?: string
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string>("")
    const [isTVLLogin, setIsTVLLogin] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [openVerifyEmail, setOpenVerifyEmail] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement | null>(null)
    const handleClosing = () => {
        setOpen(false)
        setError("")
        setEmail("")
        setIsTVLLogin(false)
    }
    const handleClosingVerifyEmail = () => {
        setOpen(false)
        setError("")
        setEmail("")
        setIsTVLLogin(false)
        setOpenVerifyEmail(false)
    }
    const mergedTheme = mergeThemes(
        longDialog_theme,
        typography_theme,
        narrow_textbox_theme,
        register_textbox_theme
    )
    const theme = createTheme(mergedTheme)
    const history = useHistory()

    let initialValues: { username: string } = {
        username: email,
    }
    let initialTouched = {
        username: false,
    }

    let initialValues2: LogInForm = {
        username: email,
        password: "",
    }
    let initialTouched2 = {
        username: true,
        password: false,
    }

    const email_validate = (values: { username: string }) => {
        let errors: { username?: string } = {}

        if (!values.username) {
            errors.username = "กรุณากรอกอีเมล"
        } else if (
            !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                values.username
            )
        ) {
            errors.username = "กรุณากรอกอีเมลที่ถูกต้อง"
        }

        return errors
    }

    

    const handleSubmit = async (username: string) => {
        setEmail(username)
        setIsTVLLogin(true)
    }

    const handleSubmit2 = async (
        platform: "Travel Link" | "Google" | "Microsoft",
        email: string | null = null,
        password: string | null = null
    ) => {
        if (platform === "Travel Link") {
            setIsLoading(true)
        }

        if (platform === "Travel Link") {
            const { success, res } = await login({
                username: email,
                password: password,
                redirectPath: redirect_uri
            })
            if (success) {
                let {refreshToken, redirectUrl} = res
                Cookies.set("token", refreshToken, {expires: 60})
                window.location.href = redirectUrl
            } else {
                if (res.detail === "Please verify your email address first.") {
                    setOpenVerifyEmail(true)
                } else {
                    setError(APIhandler[res.detail as ErrorEng])
                }
            }
            setIsLoading(false)
        }
    }
    
    // prep for platform login
    let queryParamsObj: {
        redirect_url?: string;
        fromUrl?: string;
    } = {};
    let cleanRedirectUrl = redirect_uri?.startsWith('/') ? redirect_uri.slice(1) : redirect_uri;
    
    if (cleanRedirectUrl) {
        queryParamsObj.redirect_url = cleanRedirectUrl;
    }
    
    if (fromUrl) {
        queryParamsObj.fromUrl = fromUrl;
    }
    
    let urlRedirectString: string = Object.keys(queryParamsObj).length > 0
        ? "?" + Object.entries(queryParamsObj)
            .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
            .join('&')
        : "";
    
    const handleGoBack = () => {
        setIsTVLLogin(false)
        setError("")
    }

    const handleLogInGoogle = () => {
        if(cleanRedirectUrl){
            Cookies.set('redirect_uri', cleanRedirectUrl)
        } else{
            Cookies.set('redirect_uri',"")
        }

        if(fromUrl){
            Cookies.set('fromUrl', fromUrl)
        } else{
            Cookies.set('fromUrl',"")
        }
    }
    
    return (
        <ThemeProvider theme={theme}>
            
                <ResponsePopUp 
                open = {open && openVerifyEmail}
                setOpen={() => handleClosingVerifyEmail()} 
                status='email'
                title= {`กรุณาตรวจสอบอีเมลเพื่อยืนยันตัวตน`}
                email = {email}
                text1='บัญชีของท่านยังไม่ได้รับการยืนยันตัวตน เราได้ส่งอีเมลใหม่เพื่อใช้ในการยืนยันตัวตนเรียบร้อยแล้ว'
                text2='กรุณาตรวจสอบอีเมลของท่านเพื่อทำการยืนยันตัวตนก่อนลงชื่อเข้าใช้บริการ'
                button='กลับสู่หน้าหลัก'
                handleButton={handleClosingVerifyEmail}/>
                <Dialog
                open={open && !openVerifyEmail}
                onClose={() => handleClosing()}
                hideBackdrop
                maxWidth="md"
            >
                <div
                    className={style.closeButton}
                    onClick={() => handleClosing()}
                >
                    <img
                        src={close_icon}
                        style={{ borderRadius: "29px" }}
                    ></img>
                </div>
                {isTVLLogin ? (
                    <div
                        style={{
                            position: "absolute",
                            left: "10px",
                            top: "10px",
                        }}
                    >
                        <WestRoundedIcon
                            sx={{ fontSize: "25px", cursor: "pointer" }}
                            onClick={handleGoBack}
                        />
                    </div>
                ) : (
                    <></>
                )}
                <img
                    src={logo}
                    style={{
                        width: "8.95681rem",
                        height: "1.9375rem",
                        objectFit: "contain",
                        alignSelf: "center",
                    }}
                ></img>

                {!isTVLLogin ? (
                    <>
                        <DialogTitle>
                            <Typography variant="title">
                                ลงชื่อเข้าใช้งาน Travel Link
                            </Typography>
                        </DialogTitle>
                        <DialogContent ref={ref}>
                            <a
                                href={
                                    "/api/user/user/login/google/"
                                }
                                onClick={handleLogInGoogle}
                            >
                                <button className={style.button}>
                                    <img
                                        src={GoogleLogo}
                                        alt=""
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                        }}
                                    />
                                    <div className={style.textButton}>
                                        ลงชื่อเข้าใช้ด้วย Google
                                    </div>
                                </button>
                            </a>
                            <a
                                href={
                                    "/api/user/user/login/microsoft" +
                                    urlRedirectString
                                }
                            >
                                <button className={style.button}>
                                    <img
                                        src={MicrosoftLogo}
                                        alt=""
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                        }}
                                    />
                                    <div className={style.textButton}>
                                        ลงชื่อเข้าใช้ด้วย Microsoft
                                    </div>
                                </button>
                            </a>

                            <Divider>
                                <Typography variant="bodyText">หรือ</Typography>
                            </Divider>
                            <Formik
                                initialValues={initialValues}
                                initialTouched={initialTouched}
                                onSubmit={(data, actions) => {
                                    handleSubmit(data["username"])
                                }}
                                validate={email_validate}
                            >
                                {({
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Typography variant="bodyText">
                                            อีเมล
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            name="username"
                                            value={values.username}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                !!errors.username &&
                                                touched.username
                                            }
                                            helperText={
                                                !!errors.username &&
                                                touched.username ? (
                                                    <ErrorElement
                                                        errorMessage={
                                                            errors.username
                                                        }
                                                    />
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            placeholder="อีเมล"
                                            fullWidth
                                            sx={{ marginTop: "5px" }}
                                        ></TextField>

                                        <TVLButton
                                            type="submit"
                                            fluid
                                            size="small"
                                            isLoading={isLoading}
                                            onMouseDown={(
                                                event: React.MouseEvent<HTMLButtonElement>
                                            ) => {
                                                event.preventDefault()
                                            }}
                                            sx={{ marginBottom: "80px" }}
                                        >
                                            ลงชื่อเข้าใช้ด้วยอีเมล
                                        </TVLButton>
                                    </form>
                                )}
                            </Formik>
                            <Typography
                                variant="bodyText"
                                sx={{ textAlign: "left" }}
                            >
                                ยังไม่มีบัญชี?{" "}
                                <Typography variant="ahref">
                                    <a href="/registration">ลงทะเบียนที่นี่</a>
                                </Typography>
                            </Typography>
                        </DialogContent>
                    </>
                ) : (
                    <>
                        <DialogTitle>
                            <Typography variant="title">
                                กรอกรหัสผ่าน
                            </Typography>
                        </DialogTitle>
                        <DialogContent sx={{ minHeight: "407.22px" }}>
                            <Formik
                                initialValues={initialValues2}
                                initialTouched={initialTouched2}
                                onSubmit={(data, actions) => {
                                    handleSubmit2(
                                        "Travel Link",
                                        data.username,
                                        data.password
                                    )
                                }}
                                validate={login_validate}
                            >
                                {({
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "100%",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box display="flex" justifyContent='space-between' flexDirection='column' minHeight="392px">
                                            <div>
                                                <Typography
                                                    variant="bodyText"
                                                    sx={{ textAlign: "left" }}
                                                >
                                                    อีเมล
                                                </Typography>
                                                <TextField
                                                    variant="outlined"
                                                    name="username"
                                                    value={values.username}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        !!errors.username &&
                                                        touched.username
                                                    }
                                                    helperText={
                                                        !!errors.username &&
                                                        touched.username ? (
                                                            <ErrorElement
                                                                errorMessage={
                                                                    errors.username
                                                                }
                                                            />
                                                        ) : (
                                                            ""
                                                        )
                                                    }
                                                    fullWidth
                                                    disabled
                                                    sx={{ marginTop: "5px" }}
                                                ></TextField>
                                                <Typography
                                                    variant="bodyText"
                                                    sx={{ textAlign: "left" }}
                                                >
                                                    รหัสผ่าน
                                                </Typography>
                                                <TextField
                                                    placeholder='รหัสผ่าน'
                                                    variant="outlined"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        !!errors.password &&
                                                        touched.password
                                                    }
                                                    FormHelperTextProps={{
                                                        style: {
                                                            color: "#FF3B3B",
                                                            fontFamily: "Kanit",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                            fontSize: "13px",
                                                            marginTop: "0",
                                                        },
                                                    }}
                                                    helperText={
                                                        !!errors.password &&
                                                        touched.password ? (
                                                            <ErrorElement
                                                                errorMessage={
                                                                    errors.password
                                                                }
                                                            />
                                                        ) : (
                                                            ""
                                                        )
                                                    }
                                                    type={
                                                        showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    className={style.input}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        setShowPassword(
                                                                            !showPassword
                                                                        )
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityOffIcon />
                                                                    ) : (
                                                                        <VisibilityIcon />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{ marginTop: "5px" }}
                                                ></TextField>
                                            </div>

                                            <Box>
                                                
                                                    {
                                                    error ? (
                                                        <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            marginBottom:'25px'
                                                        }}
                                                        >
                                                            <ErrorElement
                                                                errorMessage={error}
                                                            />
                                                        </div>

                                                    ) : (
                                                        <></>
                                                        
                                                    )}

                                                <TVLButton
                                                    type="submit"
                                                    fluid
                                                    size="small"
                                                    isLoading={isLoading}
                                                    onMouseDown={(
                                                        event: React.MouseEvent<HTMLButtonElement>
                                                    ) => {
                                                        event.preventDefault()
                                                    }}
                                                    onClick={() => setError("")}
                                                >
                                                    เข้าสู่ระบบ
                                                </TVLButton>
                                                <ThemeProvider
                                                    theme={createTheme(
                                                        tertiary_button_theme
                                                    )}
                                                >
                                                    <Button
                                                        disableRipple
                                                        sx={{
                                                            margin: "15px 0",
                                                            borderRadius: "50px",
                                                        }}
                                                        onMouseDown={(
                                                            event: React.MouseEvent<HTMLButtonElement>
                                                        ) => {
                                                            event.preventDefault()
                                                        }}
                                                        onClick={() => {window.location.href="/forgot-password"}}
                                                    >
                                                        ลืมรหัสผ่าน ?
                                                    </Button>
                                                </ThemeProvider>
                                            </Box>

                                        </Box>
                                        
                                    </form>
                                )}
                            </Formik>
                            <Box sx={{ height: "20px" }}></Box>
                            <Typography
                                variant="bodyText"
                                sx={{ textAlign: "left" }}
                            >
                                ยังไม่มีบัญชี?{" "}
                                <Typography variant="ahref">
                                    <a href="/registration">ลงทะเบียนที่นี่</a>
                                </Typography>
                            </Typography>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </ThemeProvider>
    )
}
)

export default LogInPopUp
