import React from 'react'
import style from '../PolicySelectionTab.module.css'


function PolicyPane1() {
    return (
        <div>
            <h1 className={style.header}>
                นโยบายการคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้งาน
                <br />
                (Privacy Policy for General Public) </h1>
            <p className={style.text} style={{ fontStyle: "italic" }}>ทบทวนครั้งล่าสุดเมื่อวันที่ 3 สิงหาคม 2564 </p>
            <p className={style.text}>
                ระบบบริการข้อมูลอัจฉริยะด้านการท่องเที่ยวในฐานวิถีชีวิตใหม่ (Travel Link) คือระบบให้บริการข้อมูลด้านการท่องเที่ยว อันเกิดขึ้นจากโครงการจัดทำระบบดิจิทัลและเทคโนโลยีเพื่อเชื่อมโยงข้อมูลที่เกี่ยวข้องกับอุตสาหกรรมการท่องเที่ยว ต่อยอดความร่วมมือการทำงานภายใต้บันทึกข้อตกลงความร่วมมือสานพลังประชารัฐส่งเสริมการท่องเที่ยวของประเทศไทย โครงการ Digital Tourism Platform และบันทึกความร่วมมือการสนับสนุนข้อมูลเพื่อจัดทำระบบสารสนเทศเพื่อการท่องเที่ยว
            </p>
            <p className={style.text}>
                เมื่อท่านเข้าใช้บริการของ Travel Link ท่านยินยอมให้กระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม และ สถาบันข้อมูลขนาดใหญ่ (องค์การมหาชน) ในฐานะหน่วยงานที่ได้รับมอบหมายจากกระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม (ซึ่งต่อไปจะเรียกว่า ‘ผู้ให้บริการ’) เก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านตามวัตถุประสงค์ดังที่ระบุในวัตถุประสงค์ในการเก็บรวมรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
            </p>
            <p className={style.text}>
                ผู้ให้บริการเคารพความเป็นส่วนตัว และตระหนักถึงความสำคัญในการคุ้มครองข้อมูลส่วนบุคคลของท่าน Travel Link จึงมีระบบรักษาความปลอดภัยของข้อมูล และมาตรการการดำเนินงานที่รัดกุม เพื่อป้องกันการเข้าถึง เปิดเผย นำไปใช้หรือเปลี่ยนแปลงข้อมูลของท่านโดยมิได้รับอนุญาต
            </p>
            <p className={style.text}>
                ผู้ให้บริการขอแจ้งให้ท่านทราบถึงรายละเอียดต่าง ๆ เกี่ยวกับการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ดังนี้
            </p>

            <h2 className={style.subheader}>ข้อมูลส่วนบุคคลที่โครงการฯ เก็บรวบรวมจากท่าน </h2>
            <p className={style.text}>
                ข้อมูลส่วนบุคคล หมายถึง ข้อมูลที่ทำให้สามารถระบุตัวตนหรือบ่งชี้ลักษณะเฉพาะตัวของท่านได้ ไม่ว่าจะทั้งทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรม ผู้ให้บริการจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านที่ผู้ให้บริการได้รับโดยทางตรงหรือทางอ้อม อันประกอบไปด้วย
                <ol type="1">
                    <li>ข้อมูลแสดงตัวตน (Identity data) เช่น ชื่อ นามสกุล เป็นต้น</li>
                    <li>ข้อมูลติดต่อ (Contact data) เช่น อีเมล หมายเลขโทรศัพท์ เป็นต้น</li>
                    <li>ข้อมูลทางเทคนิคและการใช้งาน (Technical and usage data) เช่น หมายเลข IP Address ข้อมูลการเข้าสู่ระบบข้อมูลการค้นหา (Website Browsing) จากการใช้ข้อมูล Cookie ID ประเภทอุปกรณ์การตั้งค่า แพลตฟอร์มและเทคโนโลยีอื่น ๆ ที่ใช้ในการเข้าถึงเว็บไซต์ของโครงการฯ </li>
                    <li>ข้อมูลสิ่งที่ท่านสนใจ (Profile data) เช่น ข้อมูลความสนใจ ความชอบ ความคิดเห็น หรือคำถามต่าง ๆ ที่ท่านส่งหาโครงการฯ และข้อมูลความเห็นจากการตอบแบบสำรวจ </li>
                    <li>ข้อมูลการตลาดและการสื่อสาร (Marketing and Communicating data) การตั้งค่าของท่านในการรับข้อมูลจากโครงการฯ และบุคคลที่สาม (Third Party) รวมถึงข้อมูลการติดต่อกับโครงการฯ เช่น เทปบันทึกกรณีที่ท่านติดต่อเข้ามาทาง call center หรือผ่านทางช่องทาง social media อื่น ๆ เป็นต้น </li>
                </ol>
            </p>
            <p className={style.text}>
                ข้อมูลที่ท่านจำเป็นต้องให้ไว้กับ Travel Link ซึ่งผู้ให้บริการสามารถเก็บรวบรวม ใช้ เปิดเผย ประมวลผลข้อมูลส่วนบุคคลได้ตามที่กฎหมายกำหนดโดยไม่ต้องขอความยินยอม ได้แก่ ข้อมูลที่ท่านให้ไว้เพื่อปฏิบัติตามกฎหมายหรือจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมาย หรือสัญญา หรือมีความจำเป็นต้องให้ข้อมูลเพื่อเข้าทำสัญญา หรือข้อมูลอื่นใดตามที่กฎหมายกำหนด ซึ่งหากท่านไม่ให้ข้อมูลดังกล่าวเหล่านี้กับ Travel Link ผู้ให้บริการจะไม่สามารถดำเนินการตามที่ท่านประสงค์ หรือตามที่ผู้ให้บริการได้แจ้งไว้ต่อท่าน หรือดำเนินการตามกฎหมายอื่น ๆ
            </p>
            <p className={style.text}>
                นอกจากนี้ Travel Link ยังมีการรวบรวม ใช้ และ เปิดเผย ข้อมูลโดยรวม เช่น ข้อมูลเชิงสถิติ ซึ่งข้อมูลดังกล่าวเป็นข้อมูลที่ไม่สามารถระบุตัวตนของบุคคลได้ เพื่อคำนวณอัตราของผู้ที่เข้าถึงเว็บไซต์ของโครงการฯ หรือทำข้อมูลเชิงสถิติต่าง ๆ และหากข้อมูลสามารถกลับมาระบุตัวตนได้ ผู้ให้บริการจะถือว่าข้อมูลดังกล่าวนั้นเป็นข้อมูลส่วนบุคคลซึ่งจะถูกใช้ตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
            </p>

            <h2 className={style.subheader}>วิธีเก็บข้อมูลส่วนบุคคล</h2>
            <p className={style.text}>
                <ol type="1">
                    <li>
                        เก็บรวบรวมข้อมูลส่วนบุคคลจากท่านโดยตรง เช่น ผ่านแอปพลิเคชันบนเว็บไซต์ (web applications) แอปพลิเคชันบนอุปกรณ์เคลื่อนที่ (mobile applications) ผู้ให้บริการ Call Center และแบบสอบถาม
                    </li>
                    <li>
                        เก็บรวบรวมข้อมูลส่วนบุคคลจากท่านโดยอ้อม เช่น ผ่านทางช่องทางสื่อออนไลน์ต่าง ๆ ของบุคคลที่สาม แพลตฟอร์มออนไลน์ของบุคคลที่สาม หรือ แหล่งข้อมูลสาธารณะอื่น ๆ และผ่านทางเจ้าหน้าที่และหน่วยงานผู้มีอำนาจตามกฎหมาย หรือบุคคลที่สาม (เช่น Facebook ผู้แทนของท่าน นายจ้าง ผู้สนับสนุน (sponsor) และบุคคลที่สามที่มีบทบาทในการให้บริการแก่ท่าน หรือบุคคลใด ๆ ที่ดำเนินการในนามของบุคคลเหล่านั้นเองซึ่งอาจให้ข้อมูลเกี่ยวกับท่านแก่ Travel Link)
                    </li>
                </ol>
            </p>

            <h2 className={style.subheader}>การเชื่อมต่อไปยังหน้าเว็บไซต์อื่น (Third-Party Links) </h2>
            <p className={style.text}>
                ขอบเขตการบังคับใช้นโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้จะสิ้นสุดลง เมื่อท่านคลิกลิงก์จากเว็บไซต์ของโครงการฯ เชื่อมต่อไปยังเว็บไซต์อื่น ซึ่งถือว่าท่านได้สิ้นสุดการเยี่ยมชมเว็บไซต์ของ Travel Link แล้ว ในกรณีที่เว็บไซต์อื่นมีการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เพื่อประโยชน์ต่อความเป็นส่วนตัวของท่าน Travel Link ขอแนะนำให้ท่านอ่านนโยบายการคุ้มครองข้อมูลส่วนบุคคลของทุกเว็บไซต์ที่ท่านเข้าเยี่ยมชม
            </p>

            <h2 className={style.subheader}>เหตุผลในการประมวลผลข้อมูลส่วนบุคคลของท่าน</h2>
            <p className={style.text}>
                ผู้ให้บริการจะทำการประมวลผลข้อมูลของท่านตามที่กฎหมายกำหนด ดังต่อไปนี้
                <ol type="1">
                    <li>
                        เมื่อได้รับความยินยอมจากท่าน
                        <br />
                        ผู้ให้บริการจะประมวลผลข้อมูลส่วนบุคคลของท่านตามวัตถุประสงค์ที่แจ้งและได้รับความยินยอมจากท่าน อย่างไรก็ตาม การที่ท่านใช้บริการของ Travel Link จะถือว่าท่านยินยอมให้ผู้ให้บริการประมวลผลข้อมูลตามวัตถุประสงค์ของบริการที่ท่านใช้งาน
                    </li>
                    <li>
                        เพื่อเป็นการปฏิบัติตามกฎหมาย
                        <br />
                        ผู้ให้บริการมีการประมวลผลข้อมูลโดยมีเหตุผลเพื่อเป็นการปฏิบัติตามกฎหมาย เช่น การป้องกันและตรวจจับความผิดปกติของธุรกรรมที่นำไปสู่กิจกรรมที่ผิดกฎหมาย การรายงานข้อมูลส่วนบุคคลต่อหน่วยงานราชการตามที่กฎหมายกำหนด                </li>
                    <li>
                        เพื่อประโยชน์สาธารณะ
                        <br />
                        ในบางกรณีผู้ให้บริการอาจมีความจำเป็นต้องประมวลผลข้อมูลส่วนบุคคลเพื่อประโยชน์สาธารณะ หรือปฏิบัติหน้าที่ตามที่ได้รับมอบหมายจากหน่วยงานของรัฐให้ดำเนินการ
                    </li>
                    <li>
                        เพื่อประโยชน์โดยชอบด้วยกฎหมาย
                        <br />
                        <ul>
                            <li>ผู้ให้บริการมีการประมวลผลข้อมูลส่วนบุคคล เพื่อประโยชน์โดยชอบด้วยกฎหมาย เช่น เพื่อการบริหารจัดการภายใน รวมถึงการเปิดเผยข้อมูลในกลุ่มธุรกิจเดียวกันเพื่อยกระดับมาตรฐานในการทำงานของ Travel Link ซึ่งการเปิดเผยข้อมูลดังกล่าวจะเป็นไปตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลของผู้ให้บริการรวมถึงนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ </li>
                            <li>เพื่อป้องกัน รับมือ ลดความเสี่ยง ที่อาจเกิดการกระทำผิดกฎหมาย </li>
                            <li>เพื่อรักษาความสัมพันธ์กับผู้ใช้งาน เช่น การจัดการข้อร้องเรียน การเสนอสิทธิประโยชน์ </li>
                        </ul>
                    </li>
                </ol>
            </p>

            <h2 className={style.subheader}>วัตถุประสงค์ในการเก็บรวมรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน </h2>
            <p className={style.text}>
                ผู้ให้บริการจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านไปใช้ตามคำขอ และ/หรือ ความยินยอมของท่าน เพื่อวัตถุประสงค์ดังต่อไปนี้
                <ol>
                    <li>
                        เพื่อทำรายการตามที่ท่านประสงค์ โดย ผู้ให้บริการจะเก็บรวบรวมข้อมูลพื้นฐานของท่าน เช่น ชื่อ นามสกุล อีเมล หมายเลขโทรศัพท์ และคำร้องขอหรือความคิดเห็นที่ท่านได้ให้ไว้ผ่านหน้าเว็บไซต์ของ Travel Link หรือผ่านทางช่องทางอื่น ๆ อาทิ กรอกเอกสารแบบสอบถาม ณ สถานที่ที่จัดกิจกรรม เมื่อท่านติดต่อมายังโครงการฯ และแจ้งความประสงค์ให้ติดต่อกลับเพื่อให้ข้อมูลโครงการ หรือเพื่อดำเนินการตามอื่น ๆ ตามที่ท่านร้องขอ
                    </li>
                    <li>
                        ติดตาม หรือบันทึกการทำธุรกรรม สิทธิและประโยชน์ของท่าน
                    </li>
                    <li>
                        เพื่อปรับปรุงและพัฒนาการให้บริการ
                    </li>
                    <li>
                        เพื่อปฏิบัติตามกฎหมายและกฎระเบียบที่เกี่ยวข้อง
                    </li>
                    <li>
                        เพื่อวัตถุประสงค์อื่นที่ระบุไว้ในนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ และ/หรือ ประโยชน์อื่นใดที่ท่านได้ให้ความยินยอม
                    </li>
                </ol>
            </p>

            <h2 className={style.subheader}>นโยบายการใช้คุกกี้ (Cookies) </h2>
            <p className={style.text}>
                คุกกี้มีประโยชน์ในการช่วยให้เว็บไซต์สามารถจดจำอุปกรณ์ของท่านได้และให้บริการที่เหมาะสมกับท่านมากยิ่งขึ้น การใช้คุกกี้และเทคโนโลยีที่คล้ายคลึงกันไม่ได้จำกัดเพียงการใช้ในเว็บไซต์และเว็บเบราว์เซอร์ แต่แอปพลิเคชันบนโทรศัพท์มือถือ ซึ่งโดยทั่วไปจะเชื่อมต่อกับเว็บไซต์และบริการบนเว็บไซต์ก็สามารถสร้างคุกกี้ได้เช่นกัน คุกกี้มีหน้าที่ทำงานได้หลากหลาย เช่น ช่วยให้ท่านสามารถเยี่ยมชมเว็บไซต์ได้อย่างราบรื่นและมีประสิทธิภาพ จดจำสิ่งที่ท่านชื่นชอบ และพัฒนาประสบการณ์การใช้งานของผู้ใช้เว็บไซต์
            </p>
            <p className={style.text}>
                เว็บไซต์ของ Travel Link ทำการจัดเก็บคุกกี้ เพื่อวัตถุประสงค์ในการปรับปรุงเว็บไซต์ที่ให้บริการ และสร้างข้อมูลโปรไฟล์เกี่ยวกับการเยี่ยมชมเว็บไซต์ของ Travel Link โดยไม่มีการเข้าถึงฮาร์ดไดรฟ์ของท่าน ท่านสามารถจัดการฟังก์ชั่นคุกกี้ในหน้าการตั้งค่าของเบราว์เซอร์ได้ เช่น การเลือกปิดคุ้กกี้บางประเภทตามที่ท่านต้องการ หากท่านต้องการทราบวิธีการจัดการฟังก์ชั่นคุกกี้ โปรดไปยังเมนูช่วยเหลือในบราว์เซอร์ของท่าน โดยการปิดฟังก์ชั่นคุกกี้อาจส่งผลกระทบต่อความสามารถของท่านในการทำธุรกรรม การใช้ฟังก์ชั่น และ การเข้าถึงเนื้อหาบางอย่างบนเว็บไซต์ของโครงการฯ
            </p>

            <h2 className={style.subheader}>รายละเอียดการเปิดเผยข้อมูลส่วนบุคคล </h2>
            <p className={style.text}>
                เพื่อเป็นการเพิ่มคุณภาพการบริการ และ/หรือ เพื่อเป็นช่องทางการนำเสนอผลิตภัณฑ์หรือบริการ แจ้งข่าวสารและสิทธิประโยชน์ต่าง ๆ และติดต่อสื่อสารเพื่อเชิญชวนเข้าร่วมงานกิจกรรมประชาสัมพันธ์ข้อมูลการตลาด ผู้ให้บริการอาจเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่หน่วยงานในเครือตามเหตุผลดังกล่าว
            </p>
            <p className={style.text}>
                Travel Link ไม่มีนโยบายในการขายข้อมูลส่วนบุคคลของท่านไปยังบุคคลที่สาม ข้อมูลของท่านจะถูกเก็บรักษาเป็นความลับ และ ผู้ให้บริการจะไม่เปิดเผยข้อมูลของท่านเว้นแต่กรณีที่จำเป็น เพื่อบรรลุวัตถุประสงค์ตามที่ได้แจ้งแก่ท่านตามรายละเอียดข้างต้น หรือที่ได้รับความยินยอมจากท่านไว้ก่อนหน้านี้ โดยอาจเปิดเผยข้อมูลของท่านให้กับบุคคลหรือหน่วยงานดังต่อไปนี้
                <ol>
                    <li>
                        ผู้ให้บริการภายนอก (Supplier/Outsource/Service Provider) เช่น ตัวแทน คู่ค้า ผู้รับเหมา ที่ปรึกษา ผู้ให้บริการ Cloud Computing ผู้รับจ้างทำกิจกรรมทางการตลาด ผู้รับจ้างพัฒนาเทคโนโลยีสารสนเทศ โดยบุคคลหรือหน่วยงานอื่นที่ Travel Link ทำการเปิดเผยข้อมูลส่วนบุคคลไปนั้น สามารถเก็บรวบรวม และใช้ได้เฉพาะเพื่อวัตถุประสงค์ตามที่ได้ระบุไว้ข้างต้นเท่านั้น
                    </li>
                    <li>
                        หน่วยงานราชการหรือหน่วยงานกำกับดูแล เพื่อปฏิบัติตามกฎหมายหรือเป็นไปตามคำสั่งของหน่วยงาน
                    </li>
                </ol>
            </p>

            <h2 className={style.subheader}>มาตรการในการรักษาความปลอดภัยของข้อมูลส่วนบุคคล (Data Security) </h2>
            <p className={style.text}>
                Travel Link มีมาตรการรักษาความปลอดภัยของข้อมูลส่วนบุคคลของท่านอย่างเคร่งครัด และเคารพในความเป็นส่วนตัวของท่าน รวมถึงดำเนินการตามที่กฎหมายกำหนด ดังนี้
                <ol>
                    <li>
                        จัดหมวดหมู่ให้ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลลับ (Confidential Information)
                    </li>
                    <li>
                        มีมาตรการในการบริหารจัดการข้อมูล (Organizational Measure) และมาตรการเชิงเทคนิค (Technical Measure) เพื่อป้องกันการดักฟัง ปลอมแปลงข้อความ การเข้าถึง การใช้งาน หรือการเปลี่ยนแปลงโดยมิได้รับอนุญาต เช่น ระบบสารสนเทศในการรักษาความปลอดภัย นโยบายการรักษาข้อมูลความลับของผู้ใช้ เป็นต้น
                    </li>
                    <li>
                        พนักงาน ลูกจ้าง และผู้ให้บริการภายนอกของโครงการฯ (Supplier/ Outsource/ Service Provider) มีหน้าที่ต้องรักษาความลับของข้อมูลส่วนบุคคลของผู้ใช้ ตามสัญญารักษาความลับที่ได้ลงนามไว้กับผู้ให้บริการ
                    </li>
                    <li>
                        จัดทำกระบวนการเพื่อจัดการกับการละเมิดข้อมูลส่วนบุคคล (Data Breach) และจะแจ้งให้ท่านทราบหากข้อมูลส่วนบุคคลของท่านถูกละเมิด โดยการดำเนินการจะเป็นไปตามที่กฎหมายกำหนด
                    </li>
                </ol>
                ทั้งนี้การรับส่งข้อมูลทางอินเตอร์เน็ตยังคงมีข้อจำกัดในการดูแลรักษาความปลอดภัย แม้ Travel Link จะมีมาตรการรักษาความปลอดภัยของข้อมูลอย่างเข้มงวดแล้วก็ตาม ด้วยเหตุนี้ผู้ให้บริการจึงไม่อาจรับประกันความปลอดภัยของข้อมูลที่ท่านเปิดเผยผ่านช่องทางออนไลน์ได้ ดังนั้นผู้ให้บริการจะไม่รับผิดชอบต่อความเสียหายหรือความสูญเสียที่เกิดขึ้น ไม่ว่าจะโดยตรง หรือโดยอ้อม จากการเข้าถึงข้อมูลโดยมิได้รับอนุญาต (Unauthorized Access) ที่ท่านให้ไว้ในเว็บไซต์ของ Travel Link เว้นแต่ในกรณีที่ผู้ให้บริการเป็นผู้ละเลย
            </p>

            <h2 className={style.subheader}>สิทธิเจ้าของข้อมูลส่วนบุคคล (Data Subject Rights) </h2>
            <p className={style.text}>
                ท่านมีสิทธิตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลที่ท่านควรทราบ โดยท่านสามารถยื่นคำร้องใช้สิทธิตามช่องทางการติดต่อที่ผู้ให้บริการได้ให้ไว้ในหัวข้อ “ติดต่อเรา” โดย ผู้ให้บริการจะดำเนินการตามคำร้องขอของท่านโดยเร็วที่สุดไม่เกิน 30 วัน หรือมากกว่า ขึ้นอยู่กับปริมาณและความซับซ้อนของคำร้องขอ
                <ol>
                    <li>
                        สิทธิในการถอนความยินยอม
                        <br />
                        ท่านมีสิทธิขอเพิกถอนความยินยอมที่ให้ไว้กับผู้ให้บริการในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเมื่อใดก็ได้ โดยผู้ให้บริการจะหยุดการประมวลผลข้อมูลของท่านโดยเร็วที่สุดรวมถึงอาจทำการลบข้อมูลของท่านออก เว้นแต่ในกรณีที่ผู้ให้บริการยังมีความจำเป็นต้องดำเนินการตามที่กฎหมายกำหนด
                    </li>
                    <li>
                        สิทธิในการเข้าถึงข้อมูลส่วนบุคคล
                        <br />
                        ท่านมีสิทธิขอทราบและขอรับสำเนาข้อมูลส่วนบุคคลของท่าน หรือ ขอให้ผู้ให้บริการเปิดเผยการได้มาซึ่งข้อมูลที่ได้
                    </li>
                    <li>
                        สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง
                        <br />
                        ท่านมีสิทธิขอให้ผู้ให้บริการดำเนินการแก้ไขเพื่อให้ข้อมูลถูกต้อง เป็นปัจจุบัน สมบูรณ์และไม่ก่อให้เกิดความเข้าใจผิด
                    </li>
                    <li>
                        สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล
                        <br />
                        ท่านมีสิทธิขอรับข้อมูลที่เกี่ยวกับท่าน รวมทั้งมีสิทธิขอให้ผู้ให้บริการส่งหรือโอนข้อมูลไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น หรือ ขอรับข้อมูลที่ผู้ให้บริการส่งหรือโอนไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง เว้นแต่มีเหตุผลอื่นใดที่ผู้ให้บริการไม่สามารถดำเนินการได้
                    </li>
                    <li>
                        สิทธิในการลบข้อมูลส่วนบุคคล
                        <br />
                        ท่านมีสิทธิขอให้ผู้ให้บริการ ลบ หรือ ทำลาย หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลได้ ในกรณีดังนี้
                        <ul>
                            <li>เมื่อข้อมูลดังกล่าวไม่มีความจำเป็นสำหรับวัตถุประสงค์ในการเก็บรวบรวมหรือประมวลผลข้อมูลส่วนบุคคลอีกต่อไป </li>
                            <li>ท่านถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลและผู้ให้บริการไม่มีอำนาจตามกฎหมายที่จะทำการประมวลผลได้ </li>
                            <li>ท่านได้ทำการคัดค้านการประมวลผล </li>
                            <li>มีการประมวลผลข้อมูลส่วนบุคคลอันมิชอบด้วยกฎหมาย </li>
                            <li>เจ้าของข้อมูลส่วนบุคคล คัดค้านการประมวลผลข้อมูล (นอกเหนือจากที่เกี่ยวข้องกับการคัดค้านการประมวลผลเพื่อวัตถุประสงค์เกี่ยวกับการตลาดแบบตรง) และผู้ให้บริการไม่มีเหตุแห่งการอ้างการประมวลผลโดยประโยชน์อันชอบธรรม </li>
                        </ul>
                    </li>
                    <li>
                        สิทธิในการห้ามมิให้ประมวลผลข้อมูลส่วนบุคคล
                        <br />
                        ท่านมีสิทธิห้ามมิให้ประมวลผลข้อมูลส่วนบุคคล เมื่อเข้าเงื่อนไขดังต่อไปนี้
                        <ul>
                            <li>เมื่อไม่มีความจำเป็นในการประมวลผลอีกต่อไป แต่การเก็บรักษาข้อมูลส่วนบุคคลยังคงมีความจำเป็นเพื่อการใช้สิทธิเรียกร้องทางกฎหมาย </li>
                            <li>เป็นการประมวลผลข้อมูลส่วนบุคคลอันมิชอบด้วยกฎหมาย แต่เจ้าของข้อมูลส่วนบุคคลนั้นต้องการห้ามมิให้มีการประมวลผลแทนการลบหรือทำลายข้อมูลส่วนบุคคลของตน </li>
                            <li>เมื่ออยู่ในระหว่างการตรวจสอบความถูกต้องของข้อมูลส่วนบุคคลตามที่ท่านร้องขอ </li>
                            <li>เมื่ออยู่ในระหว่างการพิสูจน์ให้เห็นถึงเหตุอันชอบด้วยกฎหมายที่สำคัญยิ่งกว่า </li>
                        </ul>
                    </li>
                    <li>
                        สิทธิในการร้องเรียน
                        <br />
                        ท่านมีสิทธิร้องเรียนต่อหน่วยงานของรัฐที่เกี่ยวข้องในกรณีที่ผู้ให้บริการ ลูกจ้างหรือผู้รับจ้างของผู้ให้บริการ กระทำการฝ่าฝืนหรือไม่ปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                    </li>
                </ol>
            </p>


            <h2 className={style.subheader}>ข้อมูลเกี่ยวกับผู้ควบคุมและผู้ประมวลผลข้อมูลส่วนบุคคลของท่าน </h2>
            <p className={style.text}>
                เมื่อท่านใช้บริการของ Travel Link ข้อมูลส่วนบุคคลของท่านจะถูกเก็บรวบรวม ใช้งาน และเปิดเผยโดยบุคคลหรือนิติบุคคลที่เกี่ยวข้องตามหน้าที่ความรับผิดชอบที่ได้รับมอบหมาย ซึ่งจำแนกออกเป็น
                <ol>
                    <li>
                        ผู้ควบคุมข้อมูลส่วนบุคคล หมายถึง บุคคลหรือนิติบุคคลผู้ซึ่งมีหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้งาน และเปิดเผยข้อมูลส่วนบุคคล ในที่นี้ ผู้ควบคุมข้อมูลส่วนบุคคลใน Travel Link ได้แก่
                        <ul>
                            <li>กระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม และหน่วยงานที่ได้รับมอบหมายจากกระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม ได้แก่ สถาบันข้อมูลขนาดใหญ่ (องค์การมหาชน) </li>
                        </ul>
                    </li>
                    <li>
                        ผู้ประมวลผลข้อมูลส่วนบุคคล หมายถึง บุคคลหรือนิติบุคคลซึ่งดำเนินการเกี่ยวกับ การเก็บรวบรวม เข้าถึง ใช้งาน จัดการ และเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของผู้ควบคุมข้อมูลส่วนบุคคล ในที่นี้ ผู้ประมวลผลข้อมูลส่วนบุคคลของท่านใน Travel Link ได้แก่
                        <ul>
                            <li>กระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม และหน่วยงานที่ได้รับมอบหมายจากกระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม ได้แก่ สถาบันข้อมูลขนาดใหญ่ (องค์การมหาชน) </li>
                            <li>บุคคลอื่น ที่ผู้ควบคุมข้อมูลกำหนดเพื่อดำเนินการตามวัตถุประสงค์ที่กล่าวไว้ข้างต้น </li>
                        </ul>
                    </li>
                </ol>
            </p>

            <h2 className={style.subheader}>การเปลี่ยนแปลงนโยบายการคุ้มครองข้อมูลส่วนบุคคล </h2>
            <p className={style.text}>
                โครงการฯ ขอสงวนสิทธิ์ในการปรับปรุงหรือแก้ไขรายละเอียดนโยบายคุ้มครองข้อมูลส่วนบุคคล
                <ol>
                    <li>เพื่อวัตถุประสงค์ของโครงการ </li>
                    <li>เพื่อพัฒนา ทดสอบ และให้บริการระบบ </li>
                    <li>เพื่อให้เกิดผลประโยชน์ต่อผู้ใช้งานระบบและอุตสาหกรรมท่องเที่ยวให้มากยิ่งขึ้น </li>
                    <li>
                        เพื่อให้สอดคล้องกับพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 หรือหลักกฎหมายอื่น ๆ ที่เกี่ยวข้อง
                        ซึ่งจะมีการประกาศแจ้งให้ท่านทราบอย่างชัดเจนก่อนการดำเนินการพร้อมทั้งขอความยินยอมจากท่านอีกครั้งหากจำเป็น
                    </li>
                </ol>
            </p>

            <h2 className={style.subheader}>การติดต่อเจ้าหน้าที่หรือผู้ที่เกี่ยวข้องกับข้อมูลส่วนบุคคล </h2>
            <p className={style.text}>
                หากท่านพบปัญหา มีข้อสงสัย หรือต้องการสอบถามข้อมูลเพิ่มเติมเกี่ยวกับนโยบายการคุ้มครองข้อมูลส่วนบุคคลในโครงการฯ ท่านสามารถติดต่อผู้ดูแลระบบ/เจ้าหน้าที่ได้ตามข้อมูลที่ปรากฏด้านล่างนี้ </p>

            <p className={style.text}>
                <span style={{ fontWeight: "bold" }}>สถาบันข้อมูลขนาดใหญ่ (องค์การมหาชน) หรือ Government Big Data Institute </span>
                <br />
                สถานที่ติดต่อ: 80 ถ. ลาดพร้าว แขวง จอมพล เขตจตุจักร กรุงเทพมหานคร 10900
                <br />
                เบอร์โทร: 02 026 2333 ต่อ 3456
                <br />
                อีเมล: travellink.th@gmail.com
                <br />
                เวลาทำการปกติ: วันจันทร์ถึงศุกร์ เวลา 9:00 – 17:00 น.
                <br />
                เว็บไซต์: gbdi.depa.or.th
            </p>













            {/* 
 
 */}
        </div>
    )
}

export default PolicyPane1
