export function formatDate(inputDate: string) {
    // Parse the input date string
    const date = new Date(inputDate);
  
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
  
    // Format the date
    const day = date.getDate();
    const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    const monthNamesThai = [
      "มกราคม", "กุมภาพันธ์", "มีนาคม",
      "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม",
      "สิงหาคม", "กันยายน", "ตุลาคม",
      "พฤศจิกายน", "ธันวาคม"
    ];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const buddhistYear = year + 543
  
    const formattedDate = `${day} ${monthNamesThai[monthIndex]} ${buddhistYear}`;
  
    return formattedDate;
  }
  