import React from 'react'
import CustomContainer from '../CustomContainer/CustomContainer'
import { Grid } from '@mui/material'
import { pic2, pic4, pic6 } from "../../../assets/searchTravel/Pic"
import {
    pict1,
    pict2,
    pict3,
    pict4,
    pict5,
    pict6,
} from "../../../assets/travel/pic"

import Style from './AuthenFrame.module.css'

const AuthenFrame = ({children}: {children: React.ReactNode}) => {
    const pic_list = [
        pict1,
        pict4,
        pict2,
        pict3,
        pict5,
        pict6,
        pic4,
        pic2,
        pic6,
    ]
  return (
    <CustomContainer>
        <div className={Style.wrapper} >
            <Grid container spacing = {5} sx = {{justifyContent: 'center'}}>
                <Grid item xs = {12} sm = {6} md = {6} className = {Style.wrapperLeft} sx = {{display:{xs: 'none', sm:'flex', md: 'flex', marginTop: '100px'}}}>
                    <div> 
                        {pic_list.map((pic) => (
                        <div className={Style.frame}>
                            <img src={pic} className={Style.image}/>
                        </div>
                    ))}

                    </div>
                </Grid>
                {/* This is where all elements goes in the second half
                This must be a Grid of width { md: {6}, xs: {12} } */}
                {children}
            </Grid>
        </div>
    </CustomContainer>
  )
}

export default AuthenFrame