import React, { useState, useCallback } from 'react'
import { 
  Box,
  ThemeProvider,
  createTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import style from './DynamicFilterBar.module.css'
import { DataTypeThai, DataUpdateCycleThai, InfographicTypeThai, PortTypeThai, Sorting } from '../../../interfaces/FilterTypes';
import DynamicSelector from '../../../components/DynamicFilterBar/DynamicSelector/DynamicSelector';
import { ProvinceThai } from '../../../interfaces/Province';
import { accordionTheme, breakPointTheme } from '../../../theme/filterBar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { mergeThemes } from '../../../theme/merge';
import SortingMenu from '../../../components/DynamicFilterBar/SortingMenu/SortingMenu';
import SelectorMobileElement from '../../../components/DynamicFilterBar/SelectorMobileElement/SelectorMobileElement';

interface DynamicFilterBarProps{
    infographicType: "" | InfographicTypeThai,
    setInfographicType: React.Dispatch<"" | InfographicTypeThai>
    dataUpdateCycle: "" | DataUpdateCycleThai,
    setDataUpdateCycle: React.Dispatch<"" | DataUpdateCycleThai>,
    dataType: "" | DataTypeThai,
    setDataType: React.Dispatch<"" | DataTypeThai>,
    portType: "" | PortTypeThai,
    setPortType: React.Dispatch<"" | PortTypeThai>,
    province: "" | ProvinceThai,
    setProvince: React.Dispatch<"" | ProvinceThai>,
    handleReset: () => void,
    sorting: Sorting,
    setSorting: React.Dispatch<Sorting>
    dynamicFilter: {
        infographicTypeFilter?: InfographicTypeThai[];
        dataUpdateCycleFilter: DataUpdateCycleThai[];
        dataTypeFilter: {
            dataTypeThai: DataTypeThai;
            correspondingList: any[];
        }[]
    }
}

const DynamicFilterBar = ({
  infographicType,
  setInfographicType,
  dataUpdateCycle,
  setDataUpdateCycle,
  dataType,
  setDataType,
  portType,
  setPortType,
  province,
  setProvince,
  handleReset,
  sorting,
  setSorting,
  dynamicFilter}: DynamicFilterBarProps) => {

    const filterCounts = useCallback(() => {
      let count = 0
      if(infographicType !== ""){
        count += 1
      }
      if(dataUpdateCycle !== ""){
        count += 1
      }
      if(dataType !== ""){
        count += 1
      }
      if(portType !== ""){
        count += 1
      }
      if(province !== ""){
        count += 1
      }
      return count
    },[infographicType,
      dataUpdateCycle,
      dataType,
      portType,
      province])

    const handleChangeDataType = (dataType: DataTypeThai) => {
        setPortType("")
        setProvince("")
        setDataType(dataType)
    }
    const isFiltered = (infographicType !== "") || (dataUpdateCycle !== "") || (dataType !== "") 
    const mergedTheme = mergeThemes(accordionTheme, breakPointTheme)
    const theme = createTheme(mergedTheme)

    

  return (
    <ThemeProvider theme={theme}>
      <Accordion sx={{display:{md:"none", sm:"block", xs:"block"}, marginBottom: '10px'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          ตัวกรอง {filterCounts() > 0 ? <div className={style.filterCountsCircle}><span className={style.filterCounts}>{filterCounts()}</span></div> : <></>}
        </AccordionSummary>
        <AccordionDetails>
          {/* {
            dynamicFilter.infographicTypeFilter ?

            <SelectorMobileElement
            selectorName='ประเภทอินโฟกราฟิก'
            placeholder='ประเภทอินโฟกราฟิก'
            value={infographicType}
            setValue={setInfographicType as React.Dispatch<string>}
            dynamicList={dynamicFilter.infographicTypeFilter} />

            
          :
          <></>
          } */}
          {
            dynamicFilter.dataUpdateCycleFilter ?
            <SelectorMobileElement
            selectorName='รอบการอัปเดต'
            placeholder='รอบการอัปเดต'
            value={dataUpdateCycle}
            setValue={setDataUpdateCycle as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataUpdateCycleFilter} />
          :
            <></>
          }
          {
            dynamicFilter.dataTypeFilter ?

            <SelectorMobileElement
            selectorName='การจำแนกข้อมูล'
            placeholder='การจำแนกข้อมูล'
            value={dataType}
            setValue={handleChangeDataType as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataTypeFilter.map(({dataTypeThai}) => dataTypeThai)} />  
          :
            <></>
          }

          {
            dataType === "รายด่าน" 
            && 
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')!.correspondingList.length > 0 ?
            <SelectorMobileElement
            selectorName='ประเภทด่าน'
            placeholder='เลือกประเภทด่าน'
            value={portType}
            setValue={setPortType as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน') ?
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')!.correspondingList : []} /> 
            :
            <></>
          }

          {
            dataType === "รายจังหวัด" 
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')!.correspondingList.length > 0 ?
            <SelectorMobileElement
            selectorName='จังหวัด'
            placeholder='เลือกจังหวัด'
            value={province}
            setValue={setProvince as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด') ?
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')!.correspondingList : []} /> 
            :
            <></>
          }
          <a onClick={handleReset} className={`${style.reset} ${isFiltered ? style.visible : style.hidden}`} >
            รีเซทตัวกรอง
          </a>

        </AccordionDetails>
      </Accordion>

      <Box sx={{display:'flex', justifyContent:{md:'space-between', sm:'flex-end', xs:'flex-end'}, alignItems:'center', marginBottom:3}}>
        <Box display={{md:'flex', sm: 'none', xs: 'none'}} alignItems='center'>
          <span className = {style.header}>ตัวกรอง: </span>
          {/* {
            dynamicFilter.infographicTypeFilter ?
            <DynamicSelector 
            selectorName="ประเภทอินโฟกราฟิก"
            value={infographicType}
            setValue={setInfographicType as React.Dispatch<string>}
            dynamicList={dynamicFilter.infographicTypeFilter}
            />
          :
          <></>
          } */}

          {
            dynamicFilter.dataUpdateCycleFilter ?
            <DynamicSelector 
            selectorName="รอบการอัปเดต"
            value={dataUpdateCycle}
            setValue={setDataUpdateCycle as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataUpdateCycleFilter}
            />
          :
            <></>
          }

          {
            dynamicFilter.dataTypeFilter ?
            <DynamicSelector 
            selectorName="การจำแนกข้อมูล"
            value={dataType}
            setValue={handleChangeDataType as React.Dispatch<string>}
            dynamicList={dynamicFilter.dataTypeFilter.map(({dataTypeThai}) => dataTypeThai)}
            />
          :
            <></>
          }

          {
            dataType === "รายด่าน" 
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')
            && 
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')!.correspondingList.length > 0 ?
            <DynamicSelector
            selectorName='เลือกประเภทด่าน' 
            value={portType}
            setValue={setPortType as React.Dispatch<string>}
            dynamicList={
              dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน') ?
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายด่าน')!.correspondingList : []} />
            :
            <></>
          }

          {
            dataType === "รายจังหวัด" 
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')
            &&
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')!.correspondingList.length > 0 ?
            <DynamicSelector
            selectorName='เลือกจังหวัด' 
            value={province}
            setValue={setProvince as React.Dispatch<string>}
            dynamicList={
              dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด') ?
            dynamicFilter.dataTypeFilter.find(({dataTypeThai}) => dataTypeThai === 'รายจังหวัด')!.correspondingList : []} />
            :
            <></>
          }

          <a onClick={handleReset} className={`${style.reset} ${isFiltered ? style.visible : style.hidden}`} >
            รีเซทตัวกรอง
          </a>
          
          
        </Box>

        <SortingMenu
        bookmarkTabSelection='infographic'
        dataType={dataType}
        sorting={sorting}
        setSorting={setSorting} />
    </Box>

    </ThemeProvider>
  )
}

export default DynamicFilterBar