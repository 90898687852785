import React, { useState } from 'react'
import {
    Box,
    Grid,
    Popover,
    RadioGroup,
    FormControlLabel,
    Radio,
    ThemeProvider,
    createTheme,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { DataTypeThai, Sorting } from '../../../interfaces/FilterTypes'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import style from './SortingMenu.module.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { displayButton } from '../../../theme/displayButton';
import { SortingSVG } from '../../../assets/dashboard';

interface SortingMenuProps{
    bookmarkTabSelection: "dashboard" | "infographic"
    dataType: "" | DataTypeThai
    sorting: Sorting
    setSorting: React.Dispatch<Sorting>
}

const SortingMenu = ({bookmarkTabSelection, dataType, sorting, setSorting}: SortingMenuProps) => {

    const [sortToggle, setSortToggle] = useState<boolean>(false)
    const history = useHistory()

    const handlePopoverClick = (event: React.MouseEvent<HTMLElement>) => {
      // Prevent the click event from reaching the parent elements (including the button)
      event.stopPropagation();
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClose = () => {
        setSortToggle(false)
        setAnchorEl(null)
    }

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setSortToggle(!sortToggle)
        setAnchorEl(event.currentTarget)
    }

    const handleClickToBookmark = () => {
      history.push({
        pathname:'/bookmark',
        state: {
          tabSelection: bookmarkTabSelection
        }
      })
    }
    let isCollapsed = !["","ภาพรวมประเทศ"].includes(dataType) && window.innerWidth > 1050

    return(
    <Box display='flex' alignItems="center">
      {
            !isCollapsed ? 
            <>
              <a href={`/bookmark?tabSelection=${bookmarkTabSelection}`} target="_blank">
                <Box display="flex" alignItems='center' sx={{cursor:'pointer'}} marginRight='10px'>
                  <BookmarkIcon sx={{color:'rgba(25, 75, 126, 1)'}}/>
                  <span className={style.reset}>
                      รายการที่ติดตาม
                  </span>
                </Box>
              </a>
              <a className={style.sort}>
                เรียงตาม: 
                <Box onClick={handleOpen} className={style.underline} display='flex' justifyContent='center' alignItems='center'>
                {sorting}
                {
                  sortToggle ? 
                  <ArrowDropUpIcon sx={{color:'rgba(0, 0, 0, 0.54)'}}/>
                  :
                  <ArrowDropDownIcon sx={{color:'rgba(0, 0, 0, 0.54)'}} />
                }
                </Box>
                
                <Popover 
                open={sortToggle}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiPopover-paper':{
                        borderRadius: '12px'
                    }
                  }}>
                    <Grid container onClick={handlePopoverClick} sx={{
                        padding: '10px 5px',
                    }}>
                        <Grid item>
                            <div className={style.optionHeader}>
                                เรียงตาม
                            </div>
                            <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={sorting}
                            onChange={(e,v) => {
                              setSorting(v as Sorting)
                              setSortToggle(false)
                            }}
                            >  
                            <FormControlLabel value="ความนิยม" control={<Radio disableRipple />} label={<span className={style.option}>ความนิยม</span>} sx={{marginLeft:'-5px'}} />
                            <FormControlLabel value="ใหม่" control={<Radio disableRipple/>} label={<span  className={style.option}>ใหม่</span>} sx={{marginLeft:'-5px'}} />
                            <FormControlLabel value="ตัวอักษร ก-ฮ" control={<Radio disableRipple/>} label={<span  className={style.option}>ตัวอักษร ก-ฮ</span>} sx={{marginLeft:'-5px'}} />
                            <FormControlLabel value="ตัวอักษร ฮ-ก" control={<Radio disableRipple/>} label={<span  className={style.option}>ตัวอักษร ฮ-ก</span>} sx={{marginLeft:'-5px'}} />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Popover>
              </a>
            </>
            :
            <ThemeProvider theme={createTheme(displayButton)}>
              <ToggleButtonGroup
                value={""}
                exclusive
                onChange={() => {}}
              >
                <ToggleButton value="bookmark" href={`/bookmark?tabSelection=${bookmarkTabSelection}`} target='_blank' disableRipple>
                  <BookmarkIcon sx={{color:'rgba(25, 75, 126, 1)'}}/>
                </ToggleButton>
                <ToggleButton value="sort" disableRipple onClick={handleOpen}>
                  <SortingSVG /> 
                  <span className = {style.underline}>{sorting}</span> 
                  {
                    sortToggle ? 
                    <ArrowDropUpIcon sx={{padding: '3px',color:'rgba(0, 0, 0, 0.54)'}}/>
                    :
                    <ArrowDropDownIcon sx={{padding: '3px', color:'rgba(0, 0, 0, 0.54)'}} />
                  }
                  <Popover 
                  open={sortToggle}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiPopover-paper':{
                          borderRadius: '12px'
                      }
                    }}>
                      <Grid container onClick={handlePopoverClick} sx={{
                          padding: '10px 5px',
                      }}>
                          <Grid item>
                              <div className={style.optionHeader}>
                                  เรียงตาม
                              </div>
                              <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              value={sorting}
                              onChange={(e,v) => {
                                setSorting(v as Sorting)
                                setSortToggle(false)
                              }}
                              >  
                              <FormControlLabel value="ความนิยม" control={<Radio disableRipple />} label={<span className={style.option}>ความนิยม</span>} sx={{marginLeft:'-5px'}} />
                              <FormControlLabel value="ใหม่" control={<Radio disableRipple/>} label={<span  className={style.option}>ใหม่</span>} sx={{marginLeft:'-5px'}} />
                              <FormControlLabel value="ตัวอักษร ก-ฮ" control={<Radio disableRipple/>} label={<span  className={style.option}>ตัวอักษร ก-ฮ</span>} sx={{marginLeft:'-5px'}} />
                              <FormControlLabel value="ตัวอักษร ฮ-ก" control={<Radio disableRipple/>} label={<span  className={style.option}>ตัวอักษร ฮ-ก</span>} sx={{marginLeft:'-5px'}} />
                              </RadioGroup>
                          </Grid>
                      </Grid>
                  </Popover>
                </ToggleButton>
              </ToggleButtonGroup>

            </ThemeProvider>
      }
      </Box>

        
  )}

export default SortingMenu