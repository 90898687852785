import React, { useState } from "react"
import {
    Tab,
    Tabs,
    Box,
    Grid,
    Card,
    ThemeProvider,
    createTheme,
} from "@mui/material"
import "./PolicySelectionTab.css"

import PolicyPane1 from "./PolicyPane1/PolicyPane1"
import PolicyPane2 from "./PolicyPane2/PolicyPane2"
import { card_theme, tab_theme } from "../../../theme/agreementPopUp"
import { mergeThemes } from "../../../theme/merge"

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

const PolicySelectionTab = () => {
    const [value, setValue] = useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }
    function a11yProps(index: number) {
        return {
            id: `vertical-tab-${index}`,
            "aria-controls": `vertical-tabpanel-${index}`,
        }
    }
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box
                        sx={{
                            p: 3,
                            paddingTop: {
                                md: 0,
                                sm: 3,
                            },
                        }}
                    >
                        {children}
                    </Box>
                )}
            </div>
        )
    }
    const mergedTheme = mergeThemes(card_theme, tab_theme)
    const theme = createTheme(mergedTheme)
    return (
        <ThemeProvider theme={theme}>
            <Grid container justifyContent="space-between">
                <Grid
                    item
                    xs={0}
                    md={2}
                    sx={{ display: { xs: "none", md: "flex" } }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="fullWidth"
                        value={value}
                        onChange={handleChange}
                        sx={{ textAlign: "right", paddingTop: 3 }}
                    >
                        <Tab
                            disableTouchRipple
                            label="Privacy Policies"
                            {...a11yProps(0)}
                            sx={{ fontFamily: "Kanit", alignItems: "end" }}
                        />

                        <Tab
                            disableTouchRipple
                            label="Terms & Conditions"
                            {...a11yProps(1)}
                            sx={{ fontFamily: "Kanit", alignItems: "end" }}
                        />
                    </Tabs>
                </Grid>
                <Grid
                    item
                    xs={0}
                    md={1}
                    sx={{
                        display: {
                            xs: "none",
                            md: "flex",
                            justifyContent: "end",
                        },
                    }}
                />
                <Grid item xs={12} sx={{ display: { xs: "flex", md: "none" } }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                    >
                        <Tab
                            disableTouchRipple
                            label="Privacy Policies"
                            {...a11yProps(0)}
                            sx={{ fontFamily: "Kanit" }}
                        />
                        <Tab
                            disableTouchRipple
                            label="Terms & Conditions"
                            {...a11yProps(1)}
                            sx={{ fontFamily: "Kanit" }}
                        />
                    </Tabs>
                </Grid>
                <Grid xs={12} md={9}>
                    <Card>
                        <TabPanel value={value} index={0}>
                            <PolicyPane1 />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <PolicyPane2 />
                        </TabPanel>
                    </Card>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default PolicySelectionTab
