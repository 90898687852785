import { LogInForm, LogInErrors } from '../interfaces/LogIn'

export const login_validate = (values: LogInForm) => {
    let errors: LogInErrors = {}

    if (!values.username) {
        errors.username = "กรุณากรอกอีเมล"
    } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            values.username
        )
    ) {
        errors.username = "กรุณากรอกอีเมลที่ถูกต้อง"
    }

    if (!values.password) {
        errors.password = "กรุณากรอกรหัสผ่าน"
    } else if (values.password.length < 8){
        errors.password = "รหัสผ่านมีความยาวไม่ต่ำกว่า 8 ตัวอักษร"
    }


    return errors
}