import { Formik } from "formik"
import React, { useState } from "react"
import { Grid, TextField, ThemeProvider, createTheme } from '@mui/material'
import TVLButton from "../../components/shared/TVLButton/TVLButton"
import { useTitle } from "react-use"
import Style from "./ForgotPassword.module.css"
import { forgot_password } from "../../services/data-connector"
import { checkCircle, cancel } from '../../assets/login/index'
import AuthenFrame from "../../components/shared/AuthenFrame/AuthenFrame"
import ErrorElement from "../../components/shared/ErrorElement/ErrorElement"
import { forgot_validate } from "../../validation/forgotPW"
import { textbox_theme } from "../../theme/textbox";
import { mergeThemes } from "../../theme/merge"
import { register_textbox_theme } from "../../theme/register"
function ForgotPassword() {
    useTitle("Travel Link | Forgot Password")
    const [email, setEmail] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSent, setIsSent] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const mergedTheme = mergeThemes(textbox_theme, register_textbox_theme)
    const theme = createTheme(mergedTheme)

    var initialValues = {
        email: '',
    }
    var initialTouched = {
        email: false,
    }



    const handleSubmit = async (email: string) => {
        
        setIsLoading(true)
        const { success, res } = await forgot_password({email})

        if(success){
            setIsSuccess(true)
        } else{
            setIsSuccess(false)
        }
        setIsSent(true)
        setIsLoading(false)
        
    }
    return (
        <ThemeProvider theme = {theme}>
            <AuthenFrame>
                <Grid item md = {6} sm = {6} xs = {12} >
                    <div className={Style.textHeader}>ลืมรหัสผ่าน</div>
                    {
                        !isSent ?
                        <>
                            <div className={Style.text}>
                                กรุณากรอกอีเมลของท่าน เราจะทำการส่งลิงก์ให้ท่านดำเนินการเปลี่ยนรหัสผ่านใหม่ในขั้นตอนต่อไป
                            </div>
                            <br />
                            <Formik
                            initialValues={initialValues}
                            initialTouched={initialTouched}
                            onSubmit={(data, actions) => {
                                handleSubmit(data['email'])
                            }}
                            validate={forgot_validate}
                        >
                            {({
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (

                                <form onSubmit = {handleSubmit}>
                                        <div>
                                            <label className = {Style.label}>อีเมล</label>
                                        </div>
                                        <TextField
                                            variant="outlined"
                                            name='email'
                                            value={values.email}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setEmail(e.target.value)
                                            }}
                                            onBlur={handleBlur}
                                            error={!!errors.email && touched.email}
                                            helperText={!!errors.email && touched.email ? 
                                                <ErrorElement errorMessage={errors.email}/>
                                                : 
                                                ''
                                            }
                                            className={Style.input}
                                            
                                        ></TextField>
                                        
                                        <TVLButton 
                                        type='submit' 
                                        fluid 
                                        size='large' 
                                        isLoading = {isLoading} 
                                        lean
                                        onMouseDown={(
                                            event: React.MouseEvent<HTMLButtonElement>
                                        ) => {
                                            event.preventDefault()
                                        }}>ตั้งรหัสผ่านใหม่</TVLButton>
                                    </form>
                            )
                            }
                                
                        </Formik>
                        </>
                        :
                        isSuccess ?
                        <div className={Style.text}>
                            <div style = {{display: 'flex', alignItems: 'center'}}>
                                <img src = {checkCircle} className= {Style.sign}></img>
                                เราได้ส่งลิงก์สำหรับตั้งรหัสผ่านใหม่ไปยัง{' ' + email + ' '}
                                กรุณาเช็คอีเมลของท่านและดำเนินการขั้นตอนถัดไป
                            </div>
                            <br />
                            
                            <a href = '/'><TVLButton type='submit' fluid size='large' lean>กลับสู่หน้าหลัก</TVLButton></a>
                        </div>
                        :
                        <div className={Style.text}>
                            <div style = {{display: 'flex', alignItems: 'center'}}>
                                <img src = {cancel} className= {Style.sign}></img>
                                ไม่พบอีเมล {email} ในระบบ
                            </div>
                            <br />
                            <a href = '/'><TVLButton type='submit' fluid size='large' lean>กลับสู่หน้าหลัก</TVLButton></a>
                        </div>
                    }
                </Grid>       
            </AuthenFrame>

        </ThemeProvider>
        
    )
}

export default ForgotPassword
