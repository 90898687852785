import React, { useState, useRef, useEffect } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    ThemeProvider,
    createTheme,
    Typography,
    Divider,
    TextField,
    CircularProgress,
    ThemeOptions
} from '@mui/material'
import close_icon from './../../../assets/close_button.svg'
import {GoogleLogo,MicrosoftLogo} from "../../../assets/login/index"
import logo from "../../../assets/navbar/tourism-logo.png"
import style from './RegistrationPopUp.module.css'
import { Formik } from "formik"
import ErrorElement from '../ErrorElement/ErrorElement'
import { mergeThemes } from '../../../theme/merge'
import { longDialog_theme } from '../../../theme/longDialog'
import { typography_theme } from '../../../theme/typography'
import { narrow_textbox_theme } from '../../../theme/textbox'
import TVLButton from '../TVLButton/TVLButton'
import { register_textbox_theme } from '../../../theme/register'
import { isEmailRegistered } from '../../../services/data-connector'

interface RegistrationPopUpProps{
    open: boolean, 
    setOpen: React.Dispatch<boolean>, 
    orgAbb?: string,
    setOpenLoginPopUp: React.Dispatch<boolean>
}

const RegistrationPopUp = ({open, setOpen, orgAbb, setOpenLoginPopUp}: RegistrationPopUpProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const handleClosing = () => {
        setOpen(false);
        setError('')
        setEmail('');
    }
    
    const mergedTheme = mergeThemes(longDialog_theme, typography_theme,narrow_textbox_theme, register_textbox_theme)
    const theme = createTheme(mergedTheme)


    let initialValues: {username:string} = {
        username: email
    }
    let initialTouched = {
        username: false
    }


    const email_validate = (values: {username:string}) => {
        let errors: {username?: string} = {}
    
        if (!values.username) {
            errors.username = "กรุณากรอกอีเมล"
        } else if (
            !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                values.username
            )
        ) {
            errors.username = "กรุณากรอกอีเมลที่ถูกต้อง"
        }

        return errors
    }
    const handleSubmit = async (email: string) => {
        setIsLoading(true)
            let {success, res} = await isEmailRegistered({email})

            if(!success){
                if(res.detail === 'The email has already been registered.'){
                    setError('อีเมลนี้ลงทะเบียนไปแล้ว กรุณาใช้อีเมลอื่นในการสมัคร')
                }
                else{
                    setError(res.detail)
                }
                setIsLoading(false)
                return
            } 

            if(orgAbb){
                let payload = {
                    email: email,
                    orgAbb: orgAbb
                }
                let tmp = encodeURIComponent(JSON.stringify(payload));
                window.location.href = '/register-member-alliance-tvl#data' + tmp
            } else {
                let payload = {
                    email: email,
                }
                let tmp = encodeURIComponent(JSON.stringify(payload));
                window.location.href = '/register-member-tvl#data' + tmp
            }
                
        setIsLoading(false)
        
      }

    let urlRedirectString = ''
    if(orgAbb){
        urlRedirectString = '?redirect_url=register-member-alliance-platform&orgAbb=' + orgAbb
    } else{
        urlRedirectString = '?redirect_url=register-member-platform'
    }

    const handleClickLogIn = () => {
        handleClosing()
        setTimeout(() => setOpenLoginPopUp(true), 500)
        
    }

  return (
    <ThemeProvider theme={theme}>
        <Dialog
            open={open}
            onClose={() => handleClosing()}
            hideBackdrop
            maxWidth='md'
        >
            <div className = {style.closeButton} onClick = {() => handleClosing()}>
                    <img src = {close_icon} style = {{borderRadius: '29px'}}></img>
                </div>
                <img src={logo} style={{width: '8.95681rem', height: '1.9375rem', objectFit: 'contain', alignSelf: 'center'}}></img>

                <DialogTitle>
                    <Typography variant = 'title'>
                        {
                            (orgAbb) ?
                                'ลงทะเบียนสมาชิกภายใต้หน่วยงาน MOU' 
                            : 'ลงทะเบียนสมาชิกทั่วไป'
                            
                        }
                    
                    </Typography>
                </DialogTitle>
                <DialogContent sx = {{minHeight:'407.22px'}}>
                    <a
                    href={'api/user/user/register/google' + urlRedirectString}>

                        <button  
                        className={style.button}>
                            <img
                            src={GoogleLogo}
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                            />
                            <div
                                className = {style.textButton}
                            >
                                ลงทะเบียนด้วย Google
                            </div> 
                            
                        </button>
                    </a>
                    <a
                    href={'api/user/user/register/microsoft' + urlRedirectString}>

                        <button  
                        className={style.button}>
                            <img
                                    src={MicrosoftLogo}
                                    alt=""
                                    style={{ width: "25px", height: "25px" }}
                                    />
                                    <div
                                className={style.textButton}
                            >
                                ลงทะเบียนด้วย Microsoft
                            </div>  
                            
                        </button>
                    </a>

                    <Divider><Typography variant='bodyText'>หรือ</Typography></Divider>
                        <Formik
                                initialValues={initialValues}
                                initialTouched={initialTouched}
                                onSubmit={(data, actions) => {
                                    handleSubmit(data['username'])
                                }}
                                validate={email_validate}
                            >
                                {({
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                }) => (
                                    <form onSubmit = {handleSubmit}>
                                        <Typography variant='bodyText'>อีเมล</Typography>
                                        <TextField
                                            variant="outlined"
                                            placeholder='อีเมล'
                                            name='username'
                                            value={values.username}
                                            onChange={(e) => {
                                                handleChange(e)
                                                setError('')
                                            }}
                                            onBlur={handleBlur}
                                            error={!!errors.username && touched.username}
                                            helperText={!!errors.username && touched.username ? 
                                                <ErrorElement errorMessage={errors.username}/>
                                                : 
                                                error ? 
                                                <ErrorElement errorMessage={error} />
                                                :
                                                <></>
                                            }
                                            fullWidth
                                            sx={{marginTop:'5px'}}
                                        ></TextField>


                                              
                                        <TVLButton 
                                        type='submit'  
                                        fluid 
                                        size='small' 
                                        isLoading = {isLoading}
                                        onMouseDown={(event:React.MouseEvent<HTMLButtonElement>)=>{event.preventDefault()}}
                                        sx={error || !!errors.username && touched.username ? {marginBottom:'60px'}: {marginBottom: '80px'}}>
                                            ลงทะเบียนด้วยอีเมล
                                        </TVLButton>
                                        
                                        
                                    </form>
                                )
                                }
                                    
                            </Formik>
                    <Typography variant='bodyText' sx= {{textAlign:'left'}} >หากเป็นสมาชิกอยู่แล้ว? <Typography variant='ahref'><a onClick={handleClickLogIn}>เข้าสู่ระบบ</a></Typography></Typography>
                    </DialogContent>
                
        </Dialog>   

    </ThemeProvider>
    
  )
}

export default RegistrationPopUp

