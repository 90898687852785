import { DashboardCategoryThai, DashboardTag } from "../interfaces/DashboardTag"
import { DashboardGalleryCard, DashboardPageCard } from "../interfaces/DashboardMetadata"
import { DataUpdateCycleThai, DashboardTypeThai, DataTypeThai, Classifier, PortTypeThai, Sorting } from '../interfaces/FilterTypes'
import { dashboardTypeItems, dataTypeItems, dataUpdateCycleItems, portTypeItems, sortingTypeItems,  } from "../variable/filterType";
import { ProvinceObject, ProvinceThai } from "../interfaces/Province";
import { DisplayMode } from "../interfaces/DisplayMode";
import { allProvinces } from "../variable/province";
import { OVERVIEW_LIST, THAI_AIR_PORT_LIST, THAI_LAND_PORT_LIST, THAI_SEA_PORT_LIST } from "../variable/ports";

const dashboard_tags: DashboardTag[] = require("../variable/dashboard_tag.json")
const province_list: ProvinceObject = require("../variable/province.json")

const isBookmarkedSort = (a: DashboardPageCard,b: DashboardPageCard) => {
    if(a.isBookmarked && !b.isBookmarked){
        return -1
    } else if(!a.isBookmarked && b.isBookmarked){
        return 1
    } else {
        return 0
    }
}

export const tagDashboardCounts = (dashboardMetadata: DashboardGalleryCard[], tag: DashboardCategoryThai) => {
    let countingList = dashboardMetadata.map(({tags,galleryPages}) => tag === "ทั้งหมด" || tags.includes(tag) ? galleryPages.length : 0)
    let countAll = countingList.reduce((a,b) => a + b, 0)
    return countAll
}

const categoryFilteredDashboardMetadata = (
    dashboardMetadatasFull: DashboardGalleryCard[],
    searchText: string,
    activeTag: DashboardCategoryThai
) => {
    // return a dashboard_list that is searchInput
    const search_filter_dashboard_list = (dashboardPage: DashboardPageCard) => {
        let searchStrings = []
        searchStrings.push(dashboardPage.pageName)
        searchStrings.push(dashboardPage.pageNameEng!.toLowerCase())
        searchStrings = searchStrings.concat(dashboardPage.relevantProvinces).concat(dashboardPage.additionalKeywords)

        return searchStrings.some((string) =>
            string!.includes(searchText.toLowerCase())
        )
    }
    const searchFilterSelector = (dashboardMetadata: DashboardGalleryCard) => {
        let dashboardStrings = [
            ...dashboardMetadata.tags]
        //add all text-related strings from metadata
        dashboardStrings.push(dashboardMetadata.galleryTitle.toLowerCase())
        dashboardStrings.push(dashboardMetadata.shortDescription)
        
        dashboardStrings = dashboardStrings.concat()
        // Check if, with no information about dashboard_list, the search is matched
        if (
            dashboardStrings.some((string) =>
                string.includes(searchText.toLowerCase())
            )
        ) {
            // for search match, return the whole dashboard_list
            return dashboardMetadata.galleryPages
        } 
        return dashboardMetadata.galleryPages.filter((obj) =>
            search_filter_dashboard_list(obj)
        )
        
    }

    let dashboardMetadataCategoryFiltered = dashboardMetadatasFull.filter((dashboardMetadata) => 
        // category filter
        (activeTag === "ทั้งหมด" || dashboardMetadata.tags.includes(activeTag))
        ).map((dashboardMetadata) => ({
            ...dashboardMetadata,
            galleryPages: searchFilterSelector(dashboardMetadata)
        }))

    return dashboardMetadataCategoryFiltered
}

const mainDynamicallyFilteredDashboardMetadata = (
    dashboardMetadatasFull: DashboardGalleryCard[],
    searchText: string,
    activeTag: DashboardCategoryThai,
    dashboardType: "" | DashboardTypeThai,
    dataType: "" | DataTypeThai,
    dataUpdateCycle: "" | DataUpdateCycleThai) => {

    let dashboardMetadataCategoryFiltered = categoryFilteredDashboardMetadata(dashboardMetadatasFull, searchText, activeTag)

    let dashboardMetadataMainDynamicFiltered = dashboardMetadataCategoryFiltered.filter((dashboardMetadata) => (
        // dashboardType
        ( (["", "แดชบอร์ดทั้งหมด", "แดชบอร์ดที่ติดตาม"].includes(dashboardType)) || 
        (dashboardType === "แดชบอร์ดพิเศษ" && dashboardMetadata.dashboardType === "privileged") ||
        (dashboardType === "แดชบอร์ดทั่วไป" && dashboardMetadata.dashboardType === "public")) 
        &&
        // dataUpdateCycle
        ((dataUpdateCycle === "") || 
        (dashboardMetadata.dataUpdateCycle === dataUpdateCycleItems.find(({thai}) => thai === dataUpdateCycle)?.english))
        &&
        // dataType
        ((dataType === "") || 
        (dataType === "ภาพรวมประเทศ" && dashboardMetadata.galleryClassifier === "ภาพรวมประเทศ") ||
        (dataType === "รายจังหวัด" && dashboardMetadata.galleryClassifier === "รายจังหวัด") ||
        (dataType === "รายด่าน" && ["ทุกด่าน", "ด่านบก", "ด่านน้ำ", "ด่านอากาศ"].includes(dashboardMetadata.galleryClassifier)))
        ))
        
    if(dashboardType === "แดชบอร์ดที่ติดตาม"){
        let dashboardMetadataBookmarked = dashboardMetadataMainDynamicFiltered
        .map((dashboardGallery) => (
                {
                    ...dashboardGallery,
                    galleryPages: dashboardGallery.galleryPages.filter(({isBookmarked}) => isBookmarked)
                }
            )
        ).filter(({galleryPages}) => galleryPages.length > 0)
        return dashboardMetadataBookmarked
    }
    return dashboardMetadataMainDynamicFiltered
        
}

export const prepDynamicFilter = (
    dashboardMetadatasFull: DashboardGalleryCard[], 
    searchText: string,
    activeTag: DashboardCategoryThai,
    dashboardType: "" | DashboardTypeThai,
    dataType: "" | DataTypeThai,
    dataUpdateCycle: "" | DataUpdateCycleThai,) => {
    // to be used for the first three filters
    let dashboardMetadataCategoryFiltered = dashboardMetadatasFull.filter(({tags}) => activeTag === "ทั้งหมด" || tags.includes(activeTag))
    
    let dashboardMetadataMainDynamicallyFiltered = mainDynamicallyFilteredDashboardMetadata(
        dashboardMetadatasFull,
        searchText,
        activeTag,
        dashboardType,
        dataType,
        dataUpdateCycle
    )
    // dashboardType
    let dashboardTypeFilter: DashboardTypeThai[] = ["แดชบอร์ดทั้งหมด"]
    // dashboardType: privileged
    if(dashboardMetadataCategoryFiltered.some(({dashboardType}) => dashboardType === "privileged")){
        dashboardTypeFilter.push("แดชบอร์ดพิเศษ")
    }
    // dashboardType: public (non-privileged)
    if(dashboardMetadataCategoryFiltered.some(({tags}) => !tags.includes("แดชบอร์ดพิเศษ"))){
        dashboardTypeFilter.push("แดชบอร์ดทั่วไป")
    }

    // dashboardType: bookmarked
    if(dashboardMetadataCategoryFiltered.some(({galleryPages}) => galleryPages.some(({isBookmarked}) => isBookmarked))){
        dashboardTypeFilter.push("แดชบอร์ดที่ติดตาม")
    }
   

    // dataUpdateCycle
    let dataUpdateCycleList = dashboardMetadataCategoryFiltered.map(({dataUpdateCycle}) => dataUpdateCycle)
    // Find all possible available item
    let dataUpdateCycleFilter = dataUpdateCycleItems.filter((dataUpdateCycleItem) => dataUpdateCycleList.includes(dataUpdateCycleItem.english)).map(({thai}) => thai)


    // dataType
    let dataTypeFilter: {dataTypeThai: DataTypeThai, correspondingList: any[]}[] = []
    let classifierMap: {[key in DataTypeThai]: Classifier[]} = {
        "รายด่าน":["ทุกด่าน","ด่านน้ำ","ด่านบก", "ด่านอากาศ"],
        "รายจังหวัด": ["รายจังหวัด"],
        "ภาพรวมประเทศ": ["ภาพรวมประเทศ"]
    }

    // dataType: port 
    if(dashboardMetadataCategoryFiltered.some(({galleryClassifier}) => ["ทุกด่าน","ด่านน้ำ","ด่านบก", "ด่านอากาศ"].includes(galleryClassifier))){
        let portList:{
            portType: "ด่านทั้งหมด" | PortTypeThai,
            portNameList: string[]
        }[] = []

        // add ด่านทั้งหมด
        // let allPorts = dashboardMetadataMainDynamicallyFiltered.filter(({galleryClassifier}) => ["ทุกด่าน","ด่านน้ำ","ด่านบก", "ด่านอากาศ"].includes(galleryClassifier))
        // .map(({galleryPages}) => galleryPages.map(({pageName}) => pageName)).reduce(
        //     (portListA, portListB) => {
        //         return portListA.concat(portListB)
        //     }
        // ,[])
        // portList.push({
        //     portType: "ด่านทั้งหมด",
        //     portNameList: allPorts
        // })
        for(let portTypeItem of portTypeItems){
            if(dashboardMetadataMainDynamicallyFiltered.some(({galleryClassifier}) => portTypeItem.thai === 'ด่านทั้งหมด' || galleryClassifier === portTypeItem.thai)){
                let portNameList = dashboardMetadataMainDynamicallyFiltered.filter(({galleryClassifier}) => portTypeItem.thai === 'ด่านทั้งหมด' || galleryClassifier === portTypeItem.thai)
                .map(({galleryPages}) => galleryPages.map(({pageName}) => pageName)).reduce(
                    (portListA, portListB) => {
                        return portListA.concat(portListB)
                    }
                ,[])
                // console.log(portNameList)
                // console.log(new Set(portNameList))
                portNameList = [...new Set(portNameList)]
                portList.push({
                    portType: portTypeItem.thai,
                    portNameList: portNameList
                })
            }
        }
        // add to dataTypeFilter
        dataTypeFilter.push({
            dataTypeThai:"รายด่าน",
            correspondingList: portList
        })
    }



    // dataType: province
    if(dashboardMetadataCategoryFiltered.some(({galleryClassifier}) => galleryClassifier === 'รายจังหวัด')){
        let relevantProvincesArrays = dashboardMetadataMainDynamicallyFiltered.map(({galleryPages}) => galleryPages.map(({relevantProvinces}) => relevantProvinces)).reduce(
            (provinceArrayA, provinceArrayB) => {
                return provinceArrayA.concat(provinceArrayB)
            }
        ,[])
        const INITIAL_PROVINCE_LIST: ("จังหวัดทั้งหมด" | ProvinceThai)[] = ["จังหวัดทั้งหมด"]
        let provinceList: ("จังหวัดทั้งหมด" | ProvinceThai)[] = relevantProvincesArrays.reduce((arrayA,arrayB) => arrayA.concat(arrayB), [])
        provinceList = [...new Set(provinceList)]
        let sortedUniqueProvince: ("จังหวัดทั้งหมด" | ProvinceThai)[] = [...INITIAL_PROVINCE_LIST].concat(provinceList.filter((value, index, self) => self.indexOf(value) === index).sort())
            
        // add to dataTypeFilter
        dataTypeFilter.push({
            dataTypeThai:"รายจังหวัด",
            correspondingList: sortedUniqueProvince
        })
    }
    
    // dataType: overall
    if(dashboardMetadataCategoryFiltered.some(({galleryClassifier}) => galleryClassifier === 'ภาพรวมประเทศ')){
        // add to dataTypeFilter
        dataTypeFilter.push({
            dataTypeThai: "ภาพรวมประเทศ",
            correspondingList: []
        })
    }

    return {
        dashboardTypeFilter: dashboardTypeFilter,
        dataUpdateCycleFilter: dataUpdateCycleFilter,
        dataTypeFilter: dataTypeFilter
    }

}

export const filterDashboardMetadata = (
    dashboardMetadatasFull: DashboardGalleryCard[],
    searchText: string,
    activeTag: DashboardCategoryThai,
    dashboardType: "" | DashboardTypeThai,
    dataType: "" | DataTypeThai,
    dataUpdateCycle: "" | DataUpdateCycleThai,
    portType: "" | PortTypeThai,
    province: "" | ProvinceThai,
    portName: string,
    sorting: Sorting
    ) => {

        let dashboardMetadataCategoryFiltered = categoryFilteredDashboardMetadata(dashboardMetadatasFull,searchText, activeTag)

        let dashboardMetadataDynamicFiltered = dashboardMetadataCategoryFiltered.filter((dashboardMetadata) => (
            ( (["", "แดชบอร์ดทั้งหมด"].includes(dashboardType)) || 
            (dashboardType === "แดชบอร์ดพิเศษ" && dashboardMetadata.dashboardType === 'privileged') ||
            (dashboardType === "แดชบอร์ดทั่วไป" && dashboardMetadata.dashboardType === 'public') ||
            (dashboardType === "แดชบอร์ดที่ติดตาม" && dashboardMetadata.galleryPages.some(({isBookmarked}) => isBookmarked))) 
            &&
            // dataUpdateCycle
            ((dataUpdateCycle === "") || 
            (dashboardMetadata.dataUpdateCycle === dataUpdateCycleItems.find(({thai}) => thai === dataUpdateCycle)?.english))
            &&
            // dataType
            ((dataType === "") || 
            (dataType === "ภาพรวมประเทศ" && dashboardMetadata.galleryClassifier === "ภาพรวมประเทศ") ||
            (dataType === "รายจังหวัด" && dashboardMetadata.galleryClassifier === "รายจังหวัด") ||
            (dataType === "รายด่าน" && ["ทุกด่าน", "ด่านบก", "ด่านน้ำ", "ด่านอากาศ"].includes(dashboardMetadata.galleryClassifier)))
            &&
            //portType
            ((["","ด่านทั้งหมด"].includes(portType)) ||
            (portType === "ด่านน้ำ" && dashboardMetadata.galleryClassifier === "ด่านน้ำ") ||
            (portType === "ด่านบก" && dashboardMetadata.galleryClassifier === "ด่านบก") ||
            (portType === "ด่านอากาศ" && dashboardMetadata.galleryClassifier === "ด่านอากาศ"))
            
        )).map((dashboardGallery) => (
            // province & portName
            {
                ...dashboardGallery,
                galleryPages: dashboardGallery.galleryPages.filter(({pageName ,relevantProvinces, isBookmarked}) => 
                // province
                (["","จังหวัดทั้งหมด"].includes(province) || relevantProvinces.includes(province as ProvinceThai)) &&
                // portName
                (portName === "" || pageName === portName) &&
                // isBookmarked
                (dashboardType !== "แดชบอร์ดที่ติดตาม" ||  isBookmarked)
                )
            }
        )).filter(({galleryPages}) => galleryPages.length > 0)

        // sorting
        let sortedDashboardMetadata = dashboardMetadataDynamicFiltered.sort((a,b) => {
            if(sorting === "ความนิยม"){
                return a.viewCountRank - b.viewCountRank
            } else if(sorting === "ตัวอักษร ก-ฮ"){
                return a.galleryTitle.localeCompare(b.galleryTitle,'th')
            } else if(sorting === 'ตัวอักษร ฮ-ก'){
                return b.galleryTitle.localeCompare(a.galleryTitle,'th')
            } else{ // chronological order
                let aTime = new Date(a.createdDate)
                let bTime = new Date(b.createdDate)
                return aTime.getTime() - bTime.getTime()
            }
        }).map((dashboardGallery) => ({
            ...dashboardGallery,
            galleryPages: dashboardGallery.galleryPages.sort(isBookmarkedSort)
        }))

        return sortedDashboardMetadata
    }



export interface BookmarkAction{
    pageId: string
    isBookmarked: boolean
}

export const updateDashboardMetadataBookmark = (dashboardMetadatas: DashboardGalleryCard[], bookmarkAction: BookmarkAction ) => {
    return dashboardMetadatas.map((dashboardMetadata) => {
        if(dashboardMetadata.galleryPages.some(({pageId}) => pageId === bookmarkAction.pageId)){
            let galleryPagesTmp = dashboardMetadata.galleryPages.map(
                (dashboardPage) => {
                    if(dashboardPage.pageId === bookmarkAction.pageId){
                        return {...dashboardPage, isBookmarked: bookmarkAction.isBookmarked}
                    } else {
                        return dashboardPage
                    }
                }
            )
            return {...dashboardMetadata, galleryPages: galleryPagesTmp}
        } else{
            return dashboardMetadata
        }
    })
}

export type categoricalMetadataType = {
    category: "แดชบอร์ดที่ติดตาม" | DashboardCategoryThai,
    dashboardGalleries: DashboardGalleryCard[]
}

export const sortDashboardMetadataByCategory = (dashboardMetadatas: DashboardGalleryCard[]) => {
    const mainCategory = dashboard_tags
    .filter(({category_thai}) => !["ทั้งหมด", "แดชบอร์ดพิเศษ"].includes(category_thai))

    let categoricalDashboardMetadatas: categoricalMetadataType[] = [
        {
            category: "แดชบอร์ดที่ติดตาม",
            dashboardGalleries: dashboardMetadatas.map((dashboardMetadata) => {
                return {
                    ...dashboardMetadata,
                    galleryPages: dashboardMetadata.galleryPages.filter(({isBookmarked}) => isBookmarked)
                }
            }).filter(({galleryPages}) => galleryPages.length > 0)
        }
    ]

    for(let category of mainCategory){
        categoricalDashboardMetadatas.push({
            category: category.category_thai,
            dashboardGalleries: dashboardMetadatas.filter(({tags}) => tags.includes(category.category_thai))
        })
    }

    categoricalDashboardMetadatas = categoricalDashboardMetadatas.filter(({dashboardGalleries}) => dashboardGalleries.length > 0)

    return categoricalDashboardMetadatas
}

// for BookmarkPage

export const prepBookmarkedDashboardMetadatas = (
    dashboardMetadatasFull: DashboardGalleryCard[],
    searchText: string, 
    activeTag: DashboardCategoryThai,
    sorting: Sorting) => {
    let dashboardMetadatasCategoryFiltered = categoryFilteredDashboardMetadata(dashboardMetadatasFull, searchText, activeTag)

    // select only bookmarked dashboardPages and make each of them their own dashboardGallery
    let bookmarkedDashboardMetadatas = dashboardMetadatasCategoryFiltered.map((dashboardGallery) => ({
        ...dashboardGallery,
        galleryPages: dashboardGallery.galleryPages.filter(({isBookmarked}) => isBookmarked)
    })).flatMap((dashboardGallery) => (dashboardGallery.galleryPages.map((galleryPage) => ({
        ...dashboardGallery,
        galleryPages: [galleryPage]
    } as DashboardGalleryCard))))

    //sort the data
    let sortedDashboardMetadatas = bookmarkedDashboardMetadatas.sort((a,b) => {
        if(sorting === "ความนิยม"){
            return a.viewCountRank - b.viewCountRank
        } else if(sorting === "ตัวอักษร ก-ฮ"){
            let aName = a.galleryTitle + a.galleryPages[0].pageName
            let bName = a.galleryTitle + a.galleryPages[0].pageName
            return aName.localeCompare(bName,'th')
        } else if(sorting === 'ตัวอักษร ฮ-ก'){
            let aName = a.galleryTitle + a.galleryPages[0].pageName
            let bName = a.galleryTitle + a.galleryPages[0].pageName
            return bName.localeCompare(aName,'th')
        } else{ // chronological order
            let aTime = new Date(a.createdDate)
            let bTime = new Date(b.createdDate)
            return aTime.getTime() - bTime.getTime()
        }
    })

    return sortedDashboardMetadatas


}

export const prepQueryList = (
    category: DashboardCategoryThai, 
    searchText: string, 
    dashboardType: "" | DashboardTypeThai,
    dataUpdateCycle: "" | DataUpdateCycleThai,
    dataType: "" | DataTypeThai,
    province: "" | ProvinceThai,
    portType: "" | PortTypeThai,
    portName: "" | string,
    displayMode: DisplayMode,
    sorting: Sorting) => {
    let query_list: string[] = []

    if (category !== "ทั้งหมด") {
        let matchedCategory = dashboard_tags.find(({ category_thai }: ({category_thai: string})) => category_thai === category)
        if(matchedCategory){
            query_list.push("category=" + matchedCategory.category_eng)
        }
        
    }
    if (searchText !== "") {
        query_list.push("search=" + searchText)
    }

    if(dashboardType !== ""){
        let matchedDashboardType = dashboardTypeItems.find(({thai}) => thai === dashboardType)
        if(matchedDashboardType){
            query_list.push("dashboardType=" + matchedDashboardType.english)
        }
    }

    if(dataUpdateCycle !== ""){
        let matchedDataUpdateCycle = dataUpdateCycleItems.find(({thai}) => thai === dataUpdateCycle)
        if(matchedDataUpdateCycle){
            query_list.push("dataUpdateCycle=" + matchedDataUpdateCycle.english)
        }
    }

    if(dataType !== ""){
        let matchedDataType = dataTypeItems.find(({thai}) => thai === dataType)
        if(matchedDataType){
            query_list.push("dataType=" + matchedDataType.english)

            if(dataType === "รายจังหวัด" && province !== ""){
                let matchedProvince = province_list[province]
                if(matchedProvince){
                    query_list.push("province="+matchedProvince)
                }
            }  else if(dataType === "รายด่าน" && portType !== ""){
                let matchedPortType = portTypeItems.find(({thai}) => thai === portType)
                if(matchedPortType){
                    query_list.push("portType="+matchedPortType.english)

                    if(portName !== ""){
                        query_list.push("portName="+portName)
                    }
                }
            }
        }
    }

    if(displayMode !== "rows"){
        query_list.push(`displayMode=${displayMode}`)
    }

    if(sorting !== "ความนิยม"){
        let matchSorting = sortingTypeItems.find(({thai}) => thai === sorting)
        if(matchSorting){
            query_list.push(`sortBy=${matchSorting.english}`)
        }
    }

    return query_list
}

export function findFieldByValue(obj: any, targetValue: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === targetValue) {
        return key; // Found the field
      }
    }
    return null; // Field not found
  }

export const readQueryParams = (
    dashboardMetadatasFull: DashboardGalleryCard[],
    searchTextTmp: string | null, 
    activeTagTmp: string | null, 
    dashboardTypeTmp: string | null, 
    dataTypeTmp: string | null, 
    dataUpdateCycleTmp: string | null, 
    provinceTmp: string | null, 
    portTypeTmp: string | null, 
    portNameTmp: string | null
) => {
    let searchText = ""
    let activeTag = "ทั้งหมด"
    let dashboardType: "" | DashboardTypeThai = ""
    let dataType: "" | DataTypeThai = ""
    let dataUpdateCycle: "" | DataUpdateCycleThai = ""
    let province: "" | ProvinceThai = ""
    let portType: "" | PortTypeThai = ""
    let portName: string = ""

    if (activeTagTmp) {
        let matchedCategory = dashboard_tags.find(({category_eng}) => category_eng === activeTagTmp)
        if (matchedCategory) {
            activeTag = matchedCategory.category_thai
        }
    }
    if (searchTextTmp) {
        searchText = searchTextTmp
    }
    if(dashboardTypeTmp){
        let matchedDashboardType = dashboardTypeItems.find(({english}) => english === dashboardTypeTmp)
        if(matchedDashboardType){
            dashboardType = matchedDashboardType.thai
        }
    }
    if(dataUpdateCycleTmp){
        let matchedDataUpdateCycle = dataUpdateCycleItems.find(({english}) => english === dataUpdateCycleTmp)
        if(matchedDataUpdateCycle){
            dataUpdateCycle = matchedDataUpdateCycle.thai
        }
    }
    if(dataTypeTmp){
        let matchedDataType = dataTypeItems.find(({english}) => english === dataTypeTmp)
        let classifierMap: {[key in DataTypeThai]: Classifier[]} = {
            "รายด่าน":["ทุกด่าน","ด่านน้ำ","ด่านบก", "ด่านอากาศ"],
            "รายจังหวัด": ["รายจังหวัด"],
            "ภาพรวมประเทศ": ["ภาพรวมประเทศ"]
        }

        if(matchedDataType){
            let classifierList = classifierMap[matchedDataType.thai]
            if(dashboardMetadatasFull.some(({galleryClassifier}) => classifierList.includes(galleryClassifier))){
                dataType = matchedDataType.thai
            }
        }
    }

    // filter through 
    let mainDynamicallyFilteredDashboardMetadatas = mainDynamicallyFilteredDashboardMetadata(dashboardMetadatasFull,searchText,activeTag,dashboardType,dataType,dataUpdateCycle)
    
    if(dataType){
        if(dataType === "รายจังหวัด" && provinceTmp){
            let matchedProvince = findFieldByValue(province_list, provinceTmp) as ProvinceThai | null
            if(matchedProvince && mainDynamicallyFilteredDashboardMetadatas.some(({galleryPages}) => galleryPages.some(({relevantProvinces}) => relevantProvinces.some((province) => province === matchedProvince)))){
                province = matchedProvince
            }
        } else if(dataType === "รายด่าน" && portTypeTmp){
            let matchedPortType = portTypeItems.find(({english}) => english === portTypeTmp)
            if(matchedPortType && mainDynamicallyFilteredDashboardMetadatas.some(({galleryClassifier}) => matchedPortType?.thai === "ด่านทั้งหมด" || galleryClassifier === matchedPortType?.thai)){
                portType = matchedPortType.thai
                if(portNameTmp){
                    portName = portNameTmp
                }
            }
        }
    }

    // return allowed filters
    return {searchText, activeTag, dashboardType, dataType, dataUpdateCycle, province, portType, portName}
}

export const replaceWithPageName = (text: string,pageName: string) => {
    return text.replace(/<[^>]*>/g, pageName);
} 

export const getPageList = (galleryClassifier: Classifier) => {
    switch (galleryClassifier) {
      case "ภาพรวมประเทศ":
        return OVERVIEW_LIST;
        
      case "รายจังหวัด":
        return allProvinces;
        
      case "ด่านน้ำ":
        return THAI_SEA_PORT_LIST;
        
      case "ด่านบก":
        return THAI_LAND_PORT_LIST;
        
      case "ด่านอากาศ":
        return THAI_AIR_PORT_LIST;
        
      default:
        return [];
    }
  };