import React from 'react'
import {Button, ThemeProvider, createTheme, Palette, PaletteColor, styled} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { CircularProgress } from '@mui/material';
import { SxProps } from '@mui/system';

type Color = OverridableStringUnion<
'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' | 'inherit' | 'tertiary',
'default'
>;

type Size = 'small' | 'medium' | 'large'

interface ButtonProps{
  children?: React.ReactNode, 
  color?: Color, 
  size: Size, 
  disabled?: boolean,
  iconBefore?: React.ReactNode,
  iconAfter?: React.ReactNode,
  isLoading?: boolean,
  fluid?: boolean,
  sx?: SxProps<{}>,
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  type?: "button" | "reset" | "submit" | undefined,
  rect?: boolean,
  lean?: boolean
}

import style from './TVLButton.module.css'
const TVLButton = ({
  children, 
  color = 'primary', 
  size, 
  disabled = false, 
  iconBefore, 
  iconAfter, 
  isLoading = false, 
  fluid = false,
  onMouseDown,
  onClick,
  type = 'button',
  sx,
  rect= false,
  lean= false
} : ButtonProps) => {

  // const color: keyof Palette = 'primary'

  const theme = createTheme({
    palette:{
      primary:{
        main: '#0182FA',
        light: '#67B4FC',
        dark: "#0182FA",
        contrastText: "#ffffff"
      },
      secondary:{
        main:'#F2F2F5',
        light:  "#CCE6FE",
        dark:'#C7C9D9',
        contrastText: "#3A3A3C"
      },
      tertiary:{
        main: '#0182FA',
        light: '#CCE6FE',
        dark: "#C7C9D9",
        contrastText: "#ffffff"
      }
    },
    typography:{
      button:{
        fontFamily:'Kanit',
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%', /* 1.3125rem */
        letterSpacing: '0.00438rem'

      }
    }
  })

  const buttonTheme = (color: keyof Palette) => createTheme(theme, {
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '10em',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: (theme.palette[color] as PaletteColor).light,
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: (theme.palette[color] as PaletteColor).dark,
              boxShadow: 'none',
            }
          },
          outlined:{
            backgroundColor: 'white'
          }
        },
      },
      MuiCircularProgress:{
        styleOverrides:{
          root:{
            position: 'absolute',
            visibility: 'visible',
            width: '20px !important',
            height: '20px !important'
          }
        }
      }
    },
  })

  const smallSize = {
    '--padding': '12px 24px',
    '--fontSize': '14px'
  } as React.CSSProperties;

  const smallLean = {
    '--padding': '6px 14px',
    '--fontSize': '14px'
  } as React.CSSProperties;

  const mediumSize = {
    '--padding': '14px 32px',
    '--fontSize': '16px'
  } as React.CSSProperties;

  const mediumLean ={
    '--padding': '8px 17px',
    '--fontSize': '16px'
  } as React.CSSProperties;
  
  const largeSize = {
    '--padding': '1rem 3rem',
    '--fontSize': '1.125rem'
  } as React.CSSProperties;

  const largeLean ={
    '--padding': '10px 20px',
    '--fontSize': '18px'
  } as React.CSSProperties;

  const sizeButton = (size: Size, lean: boolean): React.CSSProperties => {
    if(size == 'small'){
      if(lean){
        return smallLean
      }
      return smallSize
    } else if(size == 'medium'){
      if(lean){
        return mediumLean
      }
      return mediumSize
    } else if(size == 'large'){
      if(lean){
        return largeLean
      }
      return largeSize
    }else{
      return smallSize
    }
  }

  const disabledPrimary = {
    '--background-color': '#D8EAFC',
    '--color': '#BFDEFC' 
  } as React.CSSProperties

  const disabledSecondary = {
    '--background-color': '#EFF1F5',
    '--color': '#D6D7DA' 
  } as React.CSSProperties

  const disabledButton = (color: Color, isLoading: boolean): React.CSSProperties => {
    if(color === 'primary'){
      if(isLoading){
        return {
          '--background-color': '#D8EAFC',
          '--color': '#D8EAFC' 
        } as React.CSSProperties
      }
      return {
        '--background-color': '#D8EAFC',
        '--color': '#BFDEFC' 
      } as React.CSSProperties
    } else if(color === 'secondary'){
      if(isLoading){
        return {
          '--background-color': '#EFF1F5',
          '--color': '#EFF1F5' 
        } as React.CSSProperties
      }
      return {
        '--background-color': '#EFF1F5',
        '--color': '#D6D7DA' 
      } as React.CSSProperties
    } 
    return disabledPrimary

  }

  const loadingColor = (color: Color): React.CSSProperties => {
    if (color === 'primary'){
      return {'--colorCir': '#0182FA'} as React.CSSProperties
    }
    else if(color === 'secondary'){
      return {'--colorCir':'#3A3A3C'} as React.CSSProperties
    }
    return {'--colorCir': '#0182FA'} as React.CSSProperties
  }

  const isRectangle = (rect: boolean, fluid: boolean) => {
    if (rect) {
      return {
        '--borderRadius': '0.5rem',
        '--width': '100%'
      } as React.CSSProperties
    } else if(fluid){
      return {
        '--borderRadius': '10em',
        '--width': '100%'
      } as React.CSSProperties
    }
    return {
      '--borderRadius': '10em',
      '--width': 'auto'
    } as React.CSSProperties
  }

  const CustomButton = styled(Button)({
    padding: 'var(--padding)',
    fontSize: 'var(--fontSize)',
    width: 'var(--width,auto)',
    borderRadius: 'var(--borderRadius, 10em)',
    
    
    '&:disabled':{
      backgroundColor: 'var(--background-color)',
      color: 'var(--color)'
    }
  })

  const CustomCircularProgress = styled(CircularProgress)({
    color: 'var(--colorCir)'
  })

  const all_vars = Object.assign(sizeButton(size, lean), disabledButton(color, isLoading), isRectangle(rect, fluid)) //merging all object together

  return (
    // <div className={style.buttonWrapper}>
      <ThemeProvider theme={buttonTheme(color as keyof Palette)}>
        <CustomButton 
        style={all_vars} 
        variant={color !== 'tertiary' ? 'contained' : "outlined"}
        color={color} 
        disableRipple 
        className={style.myButton}
        disabled = {isLoading || disabled}
        startIcon={iconBefore}
        endIcon={iconAfter}
        onMouseDown={onMouseDown}
        onClick={onClick}
        type={type}
        sx = {sx}>
          {isLoading ? 
          <CustomCircularProgress style = {loadingColor(color)}/>
          :
          <></>}
          <span>{children}</span>
        </CustomButton>
      </ThemeProvider>
    // </div>
    )
}

export default TVLButton