import React from 'react'
import { useTitle } from 'react-use';
import Vision from './Vision/Vision'
import Why from './Why/Why'
import Value from './Value/Value'
import Comment from './Comment/Comment'
import Partner from './Partner/Partner'

function Mission() {
    useTitle('Travel Link | About');
    return (
        <div>
            <div id={"vision"}>
                <Vision />
            </div>

            <div id="why">
                <Why />
            </div>

            <div id='value'>
                <Value />
            </div>

            <div id='comment'>
                <Comment />
            </div>

            <div id='partner'>
                <Partner />
            </div >

        </div>
    )
}

export default Mission
