import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import { Grid, TextField, InputAdornment, IconButton, ThemeProvider, createTheme } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TVLButton from "../../components/shared/TVLButton/TVLButton";
import { useTitle } from "react-use"
import Style from "./ChangePassword.module.css"
import { reset_password } from "../../services/data-connector"
import Loading from "../Loading/Loading"
import { useParams } from "react-router-dom"
import { cancel } from "../../assets/login/index"
import AuthenFrame from "../../components/shared/AuthenFrame/AuthenFrame"
import ErrorElement from "../../components/shared/ErrorElement/ErrorElement"
import SuccessPopUp from "./SuccessPopUp/SuccessPopUp"
import { APIHandlerObject, ErrorEng } from "../../interfaces/APIHandler"
const APIhandler: APIHandlerObject = require('../../variable/APIhandler.json')
import { changePW_validate } from "../../validation/changePW";
import { mergeThemes } from "../../theme/merge";
import { textbox_theme } from "../../theme/textbox";
import { register_textbox_theme } from "../../theme/register";


function ChangePassword() {
    useTitle("Travel Link | Change Password")
    const {token} = useParams<{token: string}>()
    const [error, setError] = useState<string>('')
    const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true)
    const [isLoadingAPI, setIsLoadingAPI] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [isSent, setIsSent] = useState<boolean>(false)
    const [showPassword1,setShowPassword1] = useState<boolean>(false)
    const [showPassword2,setShowPassword2] = useState<boolean>(false)
    const mergedTheme = mergeThemes(textbox_theme, register_textbox_theme)
    const theme = createTheme(mergedTheme)

    var initialValues = {
        password1: '',
        password2: ''
    }
    var initialTouched = {
        password1: false,
        password2: false
    }

    
    const handleSubmit = async (password1: string) => {
        
        setIsLoadingAPI(true)
        const { success, res } = await reset_password(token,{password:password1})

        if(success){
            setIsSuccess(true)
        } else{
            setIsSuccess(false)
            setError(APIhandler[res.error as ErrorEng])
        }
        setIsSent(true)
        setIsLoadingAPI(false)
    }

    useEffect(() => {
    reset_password(token).then(({res, success}) => {
        setIsSuccess(success)
        if(!success){
            setError(APIhandler[res.detail as ErrorEng])
        }
        setIsLoadingPage(false)
        })
    },[])
       

    return (
        <ThemeProvider theme = {theme}>
            <SuccessPopUp isSuccess={isSent && isSuccess} text = 'เปลี่ยนรหัสผ่านสำเร็จ'/>
            <AuthenFrame>
                <Grid item sm = {6} md = {6} xs = {12}>
                    <div className={Style.textHeader}>เปลี่ยนรหัสผ่าน</div>
                    {
                        isLoadingPage ? <Loading></Loading>
                        :
                        isSuccess ?
                        <>
                            <div className={Style.text}>
                                กรุณาระบุรหัสผ่านใหม่ของท่าน
                            </div>
                            <br />
                            <Formik
                            initialValues={initialValues}
                            initialTouched={initialTouched}
                            onSubmit={(data, actions) => {
                                handleSubmit(data['password1'])
                            }} 
                            validate={changePW_validate}
                        >
                            {({
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (

                                <form onSubmit = {handleSubmit}>
                                    <div>
                                    <label className = {Style.label}>รหัสผ่านใหม่</label>
                                    </div>
                                    <TextField
                                        variant="outlined"
                                        name='password1'
                                        value={values.password1}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.password1 && touched.password1}
                                        helperText={!!errors.password1 && touched.password1 ? 
                                            <ErrorElement errorMessage={errors.password1}/>
                                            : 
                                            ''
                                        }
                                        type = {showPassword1 ? 'text' :'password'}
                                        className={Style.input}
                                        InputProps={{
                                            endAdornment:
                                            (<InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword1(!showPassword1)}
                                                edge="end"
                                                >
                                                {showPassword1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>)
                                          }}
                                        
                                    ></TextField>


                                    <div>
                                    <label className = {Style.label}>ยืนยันรหัสผ่าน</label>
                                    </div>
                                    <TextField
                                        variant="outlined"
                                        name='password2'
                                        value={values.password2}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!errors.password2 && touched.password2}
                                        helperText={!!errors.password2 && touched.password2 ? 
                                            <ErrorElement errorMessage={errors.password2}/>
                                            : 
                                            ''
                                        }
                                        type = {showPassword2 ? 'text' :'password'}
                                        className={Style.input}
                                        InputProps={{
                                            endAdornment:
                                            (<InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword2(!showPassword2)}
                                                edge="end"
                                                >
                                                {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>)
                                          }}
                                        
                                    ></TextField>
                                                    
                                    
                                    <TVLButton 
                                    type='submit' 
                                    fluid 
                                    size='large' 
                                    isLoading = {isLoadingAPI}
                                    onMouseDown={(
                                        event: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        event.preventDefault()
                                    }}
                                    lean>ตั้งรหัสผ่านใหม่</TVLButton>
                                </form>
                                )
                                }
                                    
                            </Formik>
                            
                        </>
                        :
                        <div className={Style.text}>
                            <div style = {{display: 'flex', alignItems: 'center'}}>
                                <img src={cancel} className = {Style.sign}/>
                                {error}
                            </div>
                            <br />
                                <TVLButton 
                                type='submit'
                                fluid 
                                size='large'
                                lean
                                onClick={() => {window.location.href="/"}}>
                                    กลับสู่หน้าหลัก
                                </TVLButton>
                        </div>
                        
                    }
                    
                    
                </Grid>

            </AuthenFrame>
        </ThemeProvider>
    )
}

export default ChangePassword
