import React, { useState } from 'react'
import {
    Card,
    Skeleton
} from '@mui/material'

import style from './InfographicCardSkeleton.module.css'

const infographic_tag = require('../../../variable/infographic_tag.json')

const InfographicCardSkeleton = () => {
  return (
    <Card sx = {{justifyContent:"flex-start"}}>
        <Skeleton 
        variant='rectangular'
        width='100%'
        sx={{position:'relative', ':before':{content:'""', display:'block', paddingTop:'100%'}}}>
            <div style={{position:'absolute', top:'0', left:'0', width:'100%', height:'100%'}}></div>
        </Skeleton>
        <div className={style.titleWrapper}>
            <Skeleton className={style.title}></Skeleton>
            <Skeleton variant='circular' height={40} width={40} sx={{position:"absolute", top: 10, right: 0}}></Skeleton>
        </div>

        <div style = {{display:'flex', marginTop:'0'}}>
            <Skeleton variant = 'rounded' className = {style.tag}></Skeleton>
            <Skeleton variant = 'rounded' className = {style.tag}></Skeleton>
        </div>
    </Card>
  )
}

export default InfographicCardSkeleton