import React from 'react'
import { Grid } from '@mui/material'
import CustomContainer from '../../../components/shared/CustomContainer/CustomContainer'

import visionimg from '../../../assets/mission/Pic/Vision.png'

import VisionModule from './Vision.module.css'

function Vision() {
    return (
        <div className = {VisionModule.wrapper}>
            <CustomContainer>
                <Grid container sx = {{display: {xs: 'none', sm: 'flex', md: 'flex'}}}>
                    <Grid sm = {6} md = {6} style = {{verticalAlign: 'middle', alignSelf: 'center'}}>
                        <p className = {VisionModule.text1}>วิสัยทัศน์โครงการ</p>                     
                        <p className = {VisionModule.text2}>ศูนย์กลางการเชื่อมโยงและพัฒนาบริการข้อมูลด้านการท่องเที่ยว
                         สนับสนุนให้เกิดการใช้ประโยชน์ข้อมูลเพื่อขยายขีดความสามารถการแข่งขันในอุตสาหกรรมท่องเที่ยวของประเทศ  </p>
                    </Grid>
                    <Grid sm = {1} md = {1}></Grid>
                    <Grid sm = {5} md = {5} sx = {{margin: '20px auto'}}>
                        <img style = {{objectFit:'contain', width: '100%'}} src = {visionimg} />
                    </Grid>
                </Grid>
                <Grid container sx = {{display: {xs: 'flex', sm:'none', md: 'none'}}}>
                    <Grid  sx = {{margin: '20px auto'}}>
                        <img src = {visionimg} style={{ width: '100%'}}/>
                    </Grid>
                    <Grid xs = {12} style = {{verticalAlign: 'middle', alignSelf: 'center'}}>
                        <p className = {VisionModule.text1}>วิสัยทัศน์โครงการ</p>                     
                        <p className = {VisionModule.text2}>ศูนย์กลางการเชื่อมโยงและพัฒนาบริการข้อมูลด้านการท่องเที่ยว
                         สนับสนุนให้เกิดการใช้ประโยชน์ข้อมูลเพื่อขยายขีดความสามารถการแข่งขันในอุตสาหกรรมท่องเที่ยวของประเทศ  </p>
                    </Grid>
                </Grid>
            </CustomContainer>
        </div>
        
    )
}

export default Vision
