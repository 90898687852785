import React  from 'react'
import Button from '../../../components/shared/Button/Button'
import { useHistory } from 'react-router-dom'
import close_icon from './../../../assets/close_button.svg'

import SuccessPopUpModule from './SuccessPopUp.module.css'
import { checkCircle } from '../../../assets/login'
import { Dialog, DialogActions, DialogContent } from '@mui/material'

const SuccessPopUp = ({isSuccess, text}: {isSuccess: boolean, text: string}) => {
    const history = useHistory()
    const handleClosing = () =>{
        history.push('/')
    }
    return (
        <>
            <Dialog
                open={isSuccess}
                onClose={() => handleClosing()}
                sx={{
                    backgroundColor: 'rgba(250, 250, 252, 0.6)',
                    opacity: "1",
                }}
                PaperProps={{sx:{
                    background: "#FFFFFF",
                    border: "1.5px solid #DEE2E6",
                    boxShadow: "2px 19px 21px -8px #E6E6E6",
                    borderRadius: "12px",
                    padding:"15px"
                }}}
                maxWidth='md'
                hideBackdrop
            >
                <div className = {SuccessPopUpModule.closeButton} onClick = {() => handleClosing()}>
                    <img src = {close_icon} style = {{borderRadius: '29px'}}></img>
                </div>
                <DialogContent sx = {{textAlign: 'center'}}>
                    <img src = {checkCircle} className = {SuccessPopUpModule.sign}></img>
                    <div className = {SuccessPopUpModule.description}>{text}</div>
                </DialogContent>
                <DialogActions sx={{justifyContent:'center'}}>
                    <div className = {SuccessPopUpModule.button}>
                        <Button onClick = {() => handleClosing() }>กลับสู่หน้าหลัก</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
        
    )
}

export default SuccessPopUp