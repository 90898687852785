import React, { useState } from "react"
import { useTitle } from "react-use"
import { Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import FAQModule from "./FAQ.module.css"
import ContactUs from "../ContactUs/ContactUs"
import {
  error_report,
  apple1,
  apple2,
  chrome2,
  chrome3,
} from "../../assets/FAQ"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CustomContainer from "../../components/shared/CustomContainer/CustomContainer"

const FAQ = () => {
  useTitle("Travel Link | FAQ")
  const [activeIndex, setActiveIndex] = useState(-1)

  const handleClick = (index: number) => {
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex)
  }

  return (
    <CustomContainer>
      <div style = {{marginBottom: "100px"}}>
        <div className={FAQModule.header}>ความช่วยเหลือ</div>
        <div className={FAQModule.topic}>
          กรณีที่เข้าสู่ระบบการใช้งานของหน่วยงานพันธมิตรแล้วไม่สามารถดูแดชบอร์ดได้
          ต้องแก้ไขอย่างไร?
        </div>
        <div className = {FAQModule.envelop}>
          <Accordion 
          expanded = {activeIndex === 0}
          onClick = {() => handleClick(0)}
          sx = {{
            boxShadow: "none", 
            alignItems:'center', 
            backgroundColor: '#FAFAFA', 
            borderTopLeftRadius: '10px !important', 
            borderTopRightRadius:'10px !important'
            }}>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              >
                  <div className={FAQModule.title}>สำหรับ Google Chrome</div>
              </AccordionSummary>
              <AccordionDetails>
                  <div className = {FAQModule.content}>
                    <p>
                    ในกรณีที่ได้ลงทะเบียนกับทางหน่วยงานพันธมิตรของ Travel Link แล้ว
                    แต่ไม่สามารถดูแดชบอร์ด PRO ได้ตามรูปที่แสดงด้านล่าง
                    ท่านจำเป็นต้องอนุญาตให้ใช้ Cookies บนเว็ปเบราว์เซอร์ของท่านก่อน
                  </p>
                  <img className={FAQModule.img} src={error_report} />
                  <br />
                  <p>
                    โปรดปฏิบัติตามคำแนะนำในการเปิดใช้งาน Cookies สำหรับ Google Chrome
                    เพื่อเปิดแดชบอร์ดของเรา ดังต่อไปนี้
                  </p>
                  <ol className={FAQModule.ulFAQ}>
                    <li>
                      เปิดด้วย PC:
                      <ol type="a">
                        <li>เปิดหน้า Chrome </li>
                        <li>
                          กดเลือกเมนูไอคอนที่มุมบนขวาตามภาพด้านล่าง
                          <br />
                          <img className={FAQModule.img2} src={chrome2} />
                        </li>
                        <li>เลือก Settings </li>
                        <li>เลือก Privacy and Security ในแถบเมนูด้านซ้าย </li>
                        <li>เลือก Allow all cookies </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      เปิดด้วย Mac:
                      <ol type="a">
                        <li>เปิดหน้า Chrome </li>
                        <li>กดที่คำว่า Chrome มุมบนขวาและเลือก Preferences... </li>
                        <li>เลือก Privacy and Security ในแถบเมนูด้านซ้าย </li>
                        <li>เลือก Cookies and other site data </li>
                        <li>เลือก Allow all cookies </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      เปิดด้วย Android:
                      <ol type="a">
                        <li>เปิดหน้า Chrome </li>
                        <li>
                          กดเลือกเมนูไอคอนที่มุมบนขวาตามภาพด้านล่าง
                          <br/>
                          <img className={FAQModule.img} src={chrome3} />{" "}
                        </li>
                        <li>เลือก Settings</li>
                        <li>เลือก Site settings จากนั้นเลือก Cookies </li>
                        <li>เลือก Allow third-party cookies</li>
                      </ol>
                    </li>
                    <br />
                    คุณอาจจะต้องรีสตาร์ทเว็บเบราว์เซอร์เพื่อดำเนินการต่อ
                  </ol>
                  </div>
              </AccordionDetails>
          </Accordion>
          <Accordion 
          expanded = {activeIndex === 1}
          onClick = {() => handleClick(1)}
          sx = {{
            boxShadow: "none", 
            alignItems:'center', 
            backgroundColor: '#FAFAFA'
            }}>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              >
                  <div className={FAQModule.title}>สำหรับ Safari</div>
              </AccordionSummary>
              <AccordionDetails>
                  <div className = {FAQModule.content}>
                    <p>
                    ในกรณีที่ได้ลงทะเบียนกับทางหน่วยงานพันธมิตรของ Travel Link แล้ว
                    แต่ไม่สามารถดูแดชบอร์ด PRO ได้ตามรูปที่แสดงด้านล่าง
                    ท่านจำเป็นต้องอนุญาตให้ใช้ Cookies บนเว็ปเบราว์เซอร์ของท่านก่อน
                  </p>
                  <img className={FAQModule.img} src={error_report} />
                  <br />
                  <p>
                    โปรดปฏิบัติตามคำแนะนำในการเปิดใช้งาน Cookies สำหรับ Safari
                    เพื่อเปิดแดชบอร์ดของเรา ดังต่อไปนี้
                  </p>
                  <ol className={FAQModule.ulFAQ}>
                    <li>
                      เปิดด้วย Mac:
                      <ol type="a">
                        <li>เปิดแอป Safari</li>
                        <li>เลือก Preferences…</li>
                        <li>เลือก Privacy ในแถบด้านบน</li>
                        <li>ในส่วน Block cookies เลือก Never</li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      เปิดด้วย iPhone/iPad ที่ใช้ iOS11 ขึ้นไป:
                      <ol type="a">
                        <li>เปิดหน้า Settings ของเครื่อง</li>
                        <li>เลื่อนลงมาด้านล่างและเลือก Safari</li>
                        <li>
                          ในส่วนของ Privacy & Security, เลื่อนปิดในส่วนของ “Prevent
                          Cross-Site Tracking” และ “Block All Cookies”
                        </li>
                      </ol>
                    </li>
                    <br />
                    <Grid container>
                      <Grid md = {6}>
                        <img className={FAQModule.img} src={apple1} />
                      </Grid>
                      <Grid md = {6}>
                        <img className={FAQModule.img} src={apple2} />
                      </Grid>
                    </Grid>
                    <br />
                    คุณอาจจะต้องรีสตาร์ทเว็บเบราว์เซอร์เพื่อดำเนินการต่อ
                  </ol>
                  </div>
              </AccordionDetails>
          </Accordion>
          <Accordion 
          expanded = {activeIndex === 2}
          onClick = {() => handleClick(2)}
          sx = {{
            boxShadow: "none", 
            alignItems:'center', 
            backgroundColor: '#FAFAFA',
            borderBottomLeftRadius: '10px !important', 
            borderBottomRightRadius:'10px !important'
            }}>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              >
                  <div className={FAQModule.title}>สำหรับ Mozilla Firefox</div>
              </AccordionSummary>
              <AccordionDetails>
                  <div className = {FAQModule.content}>
                    <p>
                    ในกรณีที่ได้ลงทะเบียนกับทางหน่วยงานพันธมิตรของ Travel Link แล้ว
                    แต่ไม่สามารถดูแดชบอร์ด PRO ได้ตามรูปที่แสดงด้านล่าง
                    ท่านจำเป็นต้องอนุญาตให้ใช้ Cookies บนเว็ปเบราว์เซอร์ของท่านก่อน
                  </p>
                  <img className={FAQModule.img} src={error_report} />
                  <br />
                  <p>
                    โปรดปฏิบัติตามคำแนะนำในการเปิดใช้งาน Cookies สำหรับ Mozilla Firefox
                    เพื่อเปิดแดชบอร์ดของเรา ดังต่อไปนี้
                  </p>
                  <ol className={FAQModule.ulFAQ}>
                    <li>
                      สำหรับ Mozilla Firefox 3.x ที่เปิดด้วย PC:
                      <ol type="a">
                        <li>เปิดหน้า Mozilla Firefox</li>
                        <li>เลือก Tools และคลิก Options</li>
                        <li>เลือก Privacy ที่แถบด้านบน</li>
                        <li>กดเลือกในช่อง “Accept cookies from sites”</li>
                        <li>กดเลือกในช่อง “Accept third-party cookies”</li>
                        <li>คลิก OK</li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      สำหรับ Mozilla Firefox 2.x ที่เปิดด้วย PC:
                      <ol type="a">
                        <li>เปิดหน้า Mozilla Firefox</li>
                        <li>เลือก Tools และคลิก Options</li>
                        <li>เลือก Privacy ที่แถบด้านบน</li>
                        <li>กดเลือกในช่อง “Accept cookies from sites”</li>
                        <li>คลิก OK</li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      สำหรับ Mozilla Firefox 1.x ที่เปิดด้วย PC:
                      <ol type="a">
                        <li>เปิดหน้า Mozilla Firefox</li>
                        <li>เลือก Tools และคลิก Options</li>
                        <li>เลือก Privacy ที่แถบด้านบน</li>
                        <li>เลือก Cookies</li>
                        <li>กดเลือกในช่อง “Accept third-party cookies”</li>
                        <li>คลิก OK</li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      เปิดด้วย Mac:
                      <ol type="a">
                        <li>เปิดหน้า Mozilla Firefox</li>
                        <li>กดที่คำว่า Firefox มุมบนขวาและเลือก Preferences...</li>
                        <li>เลือก Privacy </li>
                        <li>ในส่วนของ Cookies, เลือก “Accept cookies from sites”</li>
                      </ol>
                    </li>
                    <br />
                    คุณอาจจะต้องรีสตาร์ทเว็บเบราว์เซอร์เพื่อดำเนินการต่อ
                  </ol>
                  </div>
              </AccordionDetails>
          </Accordion>

        </div>
        <div style={{ paddingTop: "50px" }}></div>
        <ContactUs hideTitle={true} />
      </div>
    </CustomContainer>
  )
}

export default FAQ
