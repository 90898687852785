import React from 'react'
import Thankyou from '../../../components/shared/Thankyou/Thankyou'

import PopupModule from './Popup.module.css'

function Popup(props: {trigger: boolean, onClick: () => void}) {

    return (
        props.trigger ? <div className = {PopupModule.popup}>

            <Thankyou 
            onClick = {props.onClick}
            message = 'เจ้าหน้าที่จะติดต่อกลับอย่างเร็วที่สุด'/>
                
            
        </div>: <></>
    )
}

export default Popup
