import React from 'react';
import Button from '@mui/material/Button';
import ButtonModule from './Button.module.css';
import { SxProps } from '@mui/system';
import { CircularProgress } from '@mui/material';


type ButtonProps = {
  rect?: Boolean;
  fluid?: Boolean;
  size?: 'mini' | 'tiny' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'massive';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: SxProps<{}>;
  type?: 'button' | 'reset' | 'submit';
  tertiary?: Boolean;
  secondary?: Boolean;
  className?: string;
  isLoading?: Boolean;
  children: React.ReactNode;
};

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#194B7E',
    }
  },
}
)
const Button_2: React.FC<ButtonProps> = (props) => {
  const styling: SxProps<{}> = {
    padding: '.78571429em 1.5em',
    borderRadius: '10em',
    lineHeight: '1em',
  };

  const additional_style = typeof props.style === 'undefined' ? {} : props.style;

  if (props.rect) {
    styling['borderRadius'] = '.28571429rem';
  }

  if (props.fluid) {
    styling['display'] = 'block';
    styling['width'] = '100%';
  }

  if (props.size === 'mini') {
    styling['fontSize'] = '.78571429rem';
  } else if (props.size === 'tiny') {
    styling['fontSize'] = '.85714286rem';
  } else if (props.size === 'small') {
    styling['fontSize'] = '.92857143rem';
  } else if (props.size === 'medium') {
    styling['fontSize'] = '1em';
  } else if (props.size === 'large') {
    styling['fontSize'] = '1.14285714rem';
  } else if (props.size === 'big') {
    styling['fontSize'] = '1.28571429rem';
  } else if (props.size === 'huge') {
    styling['fontSize'] = '1.42857143rem';
  } else if (props.size === 'massive') {
    styling['fontSize'] = '1.71428571rem';
  }


  const combined_styling: SxProps<{}> = {...styling, ...additional_style}
  return (
      <Button
        onClick={props.onClick}
        sx={combined_styling}
        type={props.type}
        className={
          props.tertiary
            ? ButtonModule.tertiary
            : props.secondary
            ? ButtonModule.secondary2
            : ButtonModule.primary1
        }
        disableRipple>
        {props.children}
        <span style ={{marginLeft: props.isLoading ? '10px' : '0'}}>
          <ThemeProvider theme = {theme}>
          
            <CircularProgress sx={{
              display: props.isLoading ? 'inline-block' : 'none',
              width: '20px !important',
              height: '20px !important'}} />
          </ThemeProvider>
        </span>
        
        
      </Button>
    
  );
};

export default Button_2;