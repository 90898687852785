import React, { useState, useEffect, useCallback } from 'react'
import {
    ThemeProvider,
    createTheme,
    Container,
    Grid,
    Snackbar,
    IconButton,
    Box,
} from '@mui/material'
import { useTitle } from "react-use"
import style from './Infographic.module.css'
import { InfographicTag, InfographicCategoryThai, InfographicCategoryEng } from '../../interfaces/InfographicTag'
import FilterBar from './FilterBar/FilterBar'
import { getInfographicMetadata } from '../../services/data-connector'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccessToken, selectAccessTokenError, selectAccessTokenStatus, selectExpirationAt, fetchAccessToken } from '../../redux/features/authentication/authenticationSlice'
import { selectInfographicMetadata, selectInfographicStatus, selectInfographicError, fetchInfographic, infographicMetadataAdded } from '../../redux/features/infographic/infographicSlice'
import { STATUS } from '../../variable/enum'
import { AppDispatch } from '../../redux/store'
import { getUserAccount } from '../../utils/login'
import { InfographicCardType } from '../../interfaces/Infographic'
import InfographicCard from '../../components/shared/InfographicCard/InfographicCard'
import { dashboard_card } from '../../theme/dashboard'
import { sortPopUpTheme } from '../../theme/sortPopUp'
import { mergeThemes } from '../../theme/merge'
import PaginationElement from '../../components/shared/PaginationElement/PaginationElement'
import DynamicFilterBar from './DynamicFilterBar/DynamicFilterBar'
import { DataUpdateCycleThai, InfographicTypeThai, DataTypeThai, PortTypeThai, Sorting, InfographicType } from '../../interfaces/FilterTypes'
import { ProvinceThai } from '../../interfaces/Province'
import TVLButton from '../../components/shared/TVLButton/TVLButton'
import { prepDynamicFilter, filterInfoMetadata, BookmarkAction, updateInfoMetaBookmark, readQueryParams, prepQueryList } from '../../utils/infographic'
import InfographicCardSkeleton from './InfographicCardSkeleton/InfographicCardSkeleton'
import { SnackbarMessage } from '../../interfaces/Snackbar' 
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useHistory } from "react-router"
import { Location } from 'history';
import LogInPopUp from '../../components/shared/LogInPopUp/LogInPopUp'
import { infographicTypeItems, sortingTypeItems } from '../../variable/filterType'

const infographic_tag: InfographicTag[] = require('../../variable/infographic_tag.json')

const Infographic = ({location} : {location: Location<{category: InfographicCategoryEng, infographicType: InfographicType}>}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [activeTag, setActiveTag] = useState<InfographicCategoryThai>('ทั้งหมด')
    const [activeTagList, setActiveTagList] = useState<InfographicCategoryThai[]>(['ทั้งหมด', 'การเดินทาง'])
    const [searchText, setSearchText] = useState<string>('')
    const [infographicType, setInfographicType] = useState<"" | InfographicTypeThai>("")
    const [dataUpdateCycle, setDataUpdateCycle] = useState<"" | DataUpdateCycleThai>("")
    const [dataType, setDataType] = useState<"" | DataTypeThai>("")
    const [portType, setPortType] = useState<"" | PortTypeThai>("")
    const [province, setProvince] = useState<"" | ProvinceThai>("")
    const [dynamicFilter, setDynamicFilter] = useState<any>({
        dataUpdateCycleFilter: [],
        dataTypeFilter: [
            {
                dataTypeThai: "รายจังหวัด",
                correspondingList: []
            }
        ]
    })
    const[sorting, setSorting] = useState<Sorting>("ความนิยม")
    const [isParamsSet, setIsParamsSet] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const user = getUserAccount()
    const mergedTheme = mergeThemes(dashboard_card, sortPopUpTheme)
    const theme = createTheme(mergedTheme)
    const PAGE_SIZE = 9
    const [offset, setOffset] = useState<number>(0)
    const dispatch = useDispatch<AppDispatch>()
    const accessToken = useSelector(selectAccessToken)
    const accessTokenStatus = useSelector(selectAccessTokenStatus)
    const accessTokenError = useSelector(selectAccessTokenError)
    const expiresAt = useSelector(selectExpirationAt)
    const infographicMetadatasFull = useSelector(selectInfographicMetadata)
    const infographicStatus = useSelector(selectInfographicStatus)
    const infographicError = useSelector(selectInfographicError)
    const [infographicMetadatas, setInfographicMetadatas] = useState<InfographicCardType[]>([])
    // change site title
    useTitle(
        `Travel Link | Infographic${
            activeTag === "ทั้งหมด" ? "" : " | " + activeTag
        }`
    )

    // handle redirect with state passed
    const history = useHistory()
    const changeUrl = useCallback((
        category: InfographicCategoryThai, 
        searchText: string, 
        dashboardType: "" | InfographicTypeThai,
        dataUpdateCycle: "" | DataUpdateCycleThai,
        dataType: "" | DataTypeThai,
        province: "" | ProvinceThai,
        portType: "" | PortTypeThai,
        sorting: Sorting) => {
        let query_list: string[] = prepQueryList(
            category, 
            searchText,
            dashboardType, 
            dataUpdateCycle,
            dataType,
            province,
            portType,
            sorting)
        
        if (query_list.length !== 0 ) {
            history.push("?" + query_list.join("&"))
        } else if(isParamsSet) {
            history.push("/infographic")
        }
    },[infographic_tag, history, isParamsSet])

    const useQuery = () => {
        const { search } = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search])
    }

    var all_query = useQuery()
    const getAllQuery = () => {
        let searchTextTmp = all_query.get("search")
        let activeTagTmp = all_query.get("category")
        let dashboardTypeTmp = all_query.get("dashboardType")
        let dataUpdateCycleTmp = all_query.get("dataUpdateCycle")
        let dataTypeTmp = all_query.get("dataType")
        let provinceTmp = all_query.get("province")
        let portTypeTmp = all_query.get("portType")

        
        return {searchTextTmp, activeTagTmp, dashboardTypeTmp, dataTypeTmp, dataUpdateCycleTmp, provinceTmp, portTypeTmp}
    }


    // category selected from NavBar, TagElement
    useEffect(() => {
        if (location.state) {
            if(location.state.category){
                let queryTag = infographic_tag.find(({category_eng}) => category_eng === location.state.category) 
                if(queryTag){
                    setActiveTag(queryTag.category_thai)
                }
            } else {
                setActiveTag("ทั้งหมด")
            }
            if(location.state.infographicType){
                let matchedInfographicType = infographicTypeItems.find(({english}) => english === location.state.infographicType)
                if(matchedInfographicType){
                    setInfographicType(matchedInfographicType.thai)
                }
            } else {
                setInfographicType("")
            }
        }
    }, [location])
     

    const handleGetInfographicMetadata = async () => {
        let {searchTextTmp, activeTagTmp, dashboardTypeTmp, dataTypeTmp, dataUpdateCycleTmp, provinceTmp, portTypeTmp} = getAllQuery()

        if(infographicStatus === STATUS.IDLE){
            dispatch(fetchInfographic(accessToken)).unwrap()
            .then((infographicMetadatasFullTmp) => {

                let {searchText, activeTag, infographicType, dataType, dataUpdateCycle, province, portType}
                = readQueryParams(infographicMetadatasFullTmp,searchTextTmp, activeTagTmp, dashboardTypeTmp, dataTypeTmp, dataUpdateCycleTmp, provinceTmp, portTypeTmp)
                // set all filter variables that pass query params
                setSearchText(searchText)
                setSearchInput(searchText)
                setActiveTag(activeTag)
                setInfographicType(infographicType)
                setDataType(dataType)
                setDataUpdateCycle(dataUpdateCycle)
                setProvince(province)
                setPortType(portType)

                // first initialize of the infographic metadatas
                let infographicMetadatasTmp = filterInfoMetadata(infographicMetadatasFullTmp,searchText, activeTag, infographicType, dataType, dataUpdateCycle, portType, province, "ความนิยม" )
                setInfographicMetadatas(infographicMetadatasTmp)
                let dynamicFilterTmp = prepDynamicFilter(infographicMetadatasFullTmp, searchText, activeTag, infographicType, dataType, dataUpdateCycle)
                setDynamicFilter(dynamicFilterTmp)
                setIsLoading(false)
                setIsParamsSet(true)
            })
            .catch(() => {
                setErrorMessage('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            })
        } else if (infographicStatus === STATUS.SUCCEEDED){

            let {searchText, activeTag, infographicType, dataType, dataUpdateCycle, province, portType}
                = readQueryParams(infographicMetadatasFull,searchTextTmp, activeTagTmp, dashboardTypeTmp, dataTypeTmp, dataUpdateCycleTmp, provinceTmp, portTypeTmp)
                // set all filter variables that pass query params
                setSearchText(searchText)
                setSearchInput(searchText)
                setActiveTag(activeTag)
                setInfographicType(infographicType)
                setDataType(dataType)
                setDataUpdateCycle(dataUpdateCycle)
                setProvince(province)
                setPortType(portType)
                
            // first initialize of the infographic metadatas
            let infographicMetadatasTmp = filterInfoMetadata(infographicMetadatasFull,searchText, activeTag, infographicType, dataType, dataUpdateCycle, portType, province, "ความนิยม" )
            setInfographicMetadatas(infographicMetadatasTmp)
            let dynamicFilterTmp = prepDynamicFilter(infographicMetadatasFull, searchText, activeTag, infographicType, dataType, dataUpdateCycle)
            setDynamicFilter(dynamicFilterTmp)
            setIsLoading(false)
            setIsParamsSet(true)
        }
    }

    const fetchData = useCallback(async () => {
        setIsLoading(true)
        // first time getting infographic metadata
        if(user.getIsLoggedIn()){
            if(accessTokenStatus === STATUS.IDLE){
                dispatch(fetchAccessToken())
            } else if (accessTokenStatus === STATUS.SUCCEEDED) {
                    try {
                        if (expiresAt < Math.floor(Date.now() / 1000)) {                  
                            dispatch(fetchAccessToken());
                        }
                        else{
                            handleGetInfographicMetadata()
                        }
                      } catch (error) {
                        // Handle error if any
                        console.log(error)
                      }
            } 
        } else { //user not loggedIn
            handleGetInfographicMetadata()
        }
        }, [accessToken])


    useEffect(() => {
        fetchData()
        }, [fetchData, accessToken])

    const handlePaginationChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        let pageNo = offset/PAGE_SIZE + 1
        if(pageNo !== value){
            const searchElement = document.querySelector('.MuiInputBase-root')    
            setIsLoading(true)
            searchElement?.scrollIntoView({behavior:'smooth',block:'center'})
            setOffset((value-1)*PAGE_SIZE)
            setIsLoading(false)

        }
        
    }
           

       
    const handleTagChange = useCallback((category: InfographicCategoryThai) => {
        setActiveTag(category)
        setInfographicType("")
        setDataUpdateCycle("")
        setDataType("")
        setPortType("")
        setProvince("")
    },[ 
        setActiveTag,
        setInfographicType,
        setDataUpdateCycle,
        setDataType,
        setPortType,
        setProvince])

    const handleReset = useCallback(() =>{
        handleTagChange(activeTag)
    },[activeTag, handleTagChange, setSearchText])

    const handleResetText = useCallback(() =>{
        setSearchText("")
        setSearchInput("")
        changeUrl(activeTag, "", infographicType, dataUpdateCycle, dataType, province, portType, sorting)
    },[activeTag, handleTagChange, setSearchText, infographicType, dataUpdateCycle, dataType, province, portType])


    const handleSearchTextChange = (searchText: string) => {
        setSearchText(searchText)
        changeUrl(activeTag, searchText, infographicType, dataUpdateCycle, dataType, province, portType, sorting)
    }

    // Setup Snackbar stuff
    
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false)
    const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>([]);
    const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(
        undefined
    )

    const handleSnackbarClose = () => {
        setOpenSnackBar(false)
    }

    const snackbarAction = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
    useEffect(() => {

      if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpenSnackBar(true);
        } else if (snackPack.length && messageInfo && openSnackBar) {
            // Close an active snack when a new one is added
            setOpenSnackBar(false);
        }
    }, [snackPack, messageInfo, openSnackBar]);


    const handleExited = () => {
        setMessageInfo(undefined)
    }
    

    // update user interaction
    useEffect(() => {
        async function setData(): Promise<void> {
            return new Promise((resolve) => {
                setTimeout(() => {
                resolve()
                }, 500); // Simulating an asynchronous operation with a delay
            });
        }
        const handleSetData = async () => {
            setIsLoading(true)
            changeUrl(activeTag, searchText, infographicType, dataUpdateCycle, dataType, province, portType, sorting)
            await setData()

            // filter the rest
            let infoMetadataTmp = filterInfoMetadata(infographicMetadatasFull, searchText, activeTag, infographicType, dataType, dataUpdateCycle, portType, province, sorting)
            setInfographicMetadatas(infoMetadataTmp)
            setDynamicFilter(prepDynamicFilter(infographicMetadatasFull, searchText, activeTag,infographicType, dataType, dataUpdateCycle))
            setOffset(0)
            setIsLoading(false)
        }

        // if infographicMetadatasFull has not been received, stop handleSetData
        if(isParamsSet){
            handleSetData()
        }
        
    },[ activeTag, searchText, infographicType, dataUpdateCycle, dataType, portType, province, sorting])

    // Setup LogInPopUp
    const [openLoginPopUp, setOpenLoginPopUp] = useState<boolean>(false)
    const [redirectUrl, setRedirectUrl] = useState<string>('')
    
    const handleLoginPopUpFromBookmark = () => {
        setRedirectUrl('/infographic')
        setOpenLoginPopUp(true)
    }

    const isEmptyCategory = searchText === '' && infographicType === '' && dataUpdateCycle === '' && dataType === ''
    
    // delay searchInput
    const [searchInput, setSearchInput] = useState<string>('');

    useEffect(() => {
        let DELAY_MS = 400
        const delayFn = setTimeout(() => handleSearchTextChange(searchInput), DELAY_MS);
        return () => clearTimeout(delayFn);
    }, [searchInput]);

    useEffect(() => {
        if(isParamsSet){
            let sortingQuery =  all_query.get("sortBy")
            if(sortingQuery){
                let matchSorting = sortingTypeItems.find(({english}) => sortingQuery === english)
                if(matchSorting){
                    setSorting(matchSorting.thai)
                }
            }
        }
    },[isParamsSet])
  
    return (
    <Container>
        <LogInPopUp open={openLoginPopUp} setOpen={setOpenLoginPopUp} redirect_uri={redirectUrl}/>
        <ThemeProvider theme = {theme}>
            <div className={style.title}>สำรวจอินโฟกราฟิก</div>
            <FilterBar 
            activeTag={activeTag} 
            setActiveTag={handleTagChange}
            searchText={searchInput}
            setSearchText={setSearchInput}
            activeTagList={activeTagList}
            handleResetText={handleResetText} />
            
            <DynamicFilterBar 
            infographicType={infographicType}
            setInfographicType={setInfographicType}
            dataUpdateCycle={dataUpdateCycle}
            setDataUpdateCycle={setDataUpdateCycle}
            dataType={dataType}
            setDataType={setDataType}
            portType={portType}
            setPortType={setPortType}
            province={province}
            setProvince={setProvince}
            handleReset={handleReset}
            sorting={sorting}
            setSorting={setSorting}
            dynamicFilter={dynamicFilter}
            />
            {
                isLoading ?
                <Grid container spacing={3} justifyContent='flex-start' marginBottom={5}>
                    {
                    Array.from({ length: 3 }, (_, index) => index + 1).map((i) => (
                        <Grid item xs = {12} sm = {6} md = {4}>
                            <InfographicCardSkeleton key={i} />
                        </Grid>
                        )
                    )}
                </Grid>

                :
                <>
                {
                    infographicMetadatas.length > 0 ?
                    <Grid container spacing={3} justifyContent='flex-start' marginBottom={5}>
                        {
                            infographicMetadatas.slice(offset,offset+PAGE_SIZE).map((infographicMetadata) => (
                                <Grid item xs = {12} sm = {6} md = {4}>
                                    <InfographicCard 
                                    key={infographicMetadata.infographicId}
                                    infographicMetadata={infographicMetadata} 
                                    searchText = {searchText}
                                    setOpenSnackBar={setOpenSnackBar}
                                    setSnackPack={setSnackPack}
                                    handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                                    pathname='/infographic' />
                                </Grid>
                            ))
                        }
                    </Grid>

                    :
                    // No Info Matched
                    <>
                        {
                            isEmptyCategory ?
                            <div className={style.notFound}>
                                Coming Soon...
                                <br />
                                <TVLButton size='small' onClick={() => handleTagChange("ทั้งหมด")} sx={{padding:'8px 20px'}}>
                                    <Box display='flex' alignItems='center'>
                                        <Box>
                                            กลับไปยังอินโฟกราฟิกทั้งหมด
                                        </Box>
                                    </Box>
                                    
                                </TVLButton>
                            </div>
                            :

                            <div className={style.notFound}>
                                {
                                    activeTag === "ทั้งหมด" ? 
                                    <>ไม่พบอินโฟกราฟิกที่ท่านค้นหา</>
                                    :
                                    <>
                                        ไม่พบอินโฟกราฟิกที่ท่านค้นหาใน<span style={{fontWeight:"500", whiteSpace:'nowrap'}}>หมวดหมู่{activeTag}</span>
                                        <br />
                                        กรุณาลองค้นหาใน
                                        <span 
                                        style={{
                                            fontWeight:"500", 
                                            textDecoration:'underline', 
                                            cursor:'pointer',
                                            color: "#0182FA",
                                            whiteSpace:'nowrap'
                                        }}
                                        onClick={() => setActiveTag("ทั้งหมด")}>หมวดหมู่ทั้งหมด</span>อีกครั้ง
                                    </>
                                }
                            </div>
                            
                        }
                    </>
                    
                    
                }
                </>
                
            }
            
            


            <PaginationElement 
            noItems={infographicMetadatas.length}
            pageSize={PAGE_SIZE}
            pageNo={Math.round(offset/PAGE_SIZE + 1)}
            handlePaginationChange={handlePaginationChange}
            />

            <Snackbar
            key={messageInfo ? messageInfo.key : undefined}
            TransitionProps={{ onExited: handleExited }}
            message={messageInfo ? messageInfo.message : undefined}
            open={openSnackBar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            action={snackbarAction}/>
            

        </ThemeProvider>
    </Container>
    
    
  )
}

export default Infographic