import {ThemeOptions,Theme, createTheme} from '@mui/material'
import { mergeThemes } from './merge'

export const card_theme: ThemeOptions ={
    components:{
        MuiCard:{
            styleOverrides:{
                root:{
                    border: '1px solid #CBCBCB',
                    boxShadow: 'none',
                    borderRadius: '12px',
                    maxHeight: '400px',
                    overflow:'auto',
                }
            }
        }
    }
}

const dialog_theme_breakpoint = (theme: Theme) => ({
    components:{
        MuiDialog:{
            styleOverrides:{
                paper:{
                    [theme.breakpoints.up('md')]:{
                        alignItems:'center',
                        maxWidth: '750px'
                    }
                    
                }
            }
        }
    }
})

export const dialog_theme = dialog_theme_breakpoint(createTheme({}))

export const tab_base_theme: ThemeOptions = {
    components:{
        MuiTab:{
            styleOverrides:{
                root:{
                    textTransform:'none',
                    
                }
            }
        }
    }
}

export const breakpoint = (theme: Theme) => ({
    components:{
        MuiTab:{
            styleOverrides:{
                root:{
                    [theme.breakpoints.up('md')]:{
                        textAlign: 'right',
                        borderRight: `1px solid grey`
                    }
                }
                
            }
        }        
    }
})

export const tab_theme = mergeThemes(breakpoint(createTheme({})),tab_base_theme)