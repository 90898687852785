import React from "react"
import { useTitle } from "react-use"
import Box from "@mui/material/Box"
import TVLButton from "../../components/shared/TVLButton/TVLButton"

const Forbidden = () => {
    useTitle('Travel Link | Forbidden')
    return (
        <div>
            <Box textAlign="center">
                <h1 className="not-found-text">
                    ท่านไม่มีสิทธิเข้าถึงหน้านี้
                    <br />
                    หากท่านมีข้อสงสัยเพิ่มเติมกรุณา<a href = "/contact" style = {{color:'#0182FA'}}>ติดต่อ</a>
                </h1>
                <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', marginBottom: '50px'}}>
                    <a href='/'>
                        <TVLButton size='small'>กลับสู่หน้าหลัก</TVLButton>
                    </a>
                </div>
            </Box>
        </div>
    )
}

export default Forbidden
