import React, {memo, useCallback} from 'react'
import {
    ThemeProvider,
    SwipeableDrawer,
    Box,
    Divider,
} from '@mui/material'
import { accordionTheme } from '../../../theme/navbar'
import { DashboardTag } from '../../../interfaces/DashboardTag'
import { InfographicTag } from '../../../interfaces/InfographicTag'
import { useHistory } from 'react-router-dom'
import CloseIcon from "@mui/icons-material/Close"
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getUserAccount } from '../../../utils/login'
import CollapsibleList from '../CollapsibleList/CollapsibleList'
import { DashboardType, InfographicType } from '../../../interfaces/FilterTypes'
import {ReactComponent as Crown} from '../../../assets/navbar/crown_filled.svg'

const dashboard_tag: DashboardTag[] = require("../../../variable/dashboard_tag.json")
const infographicTag: InfographicTag[] = require("../../../variable/infographic_tag.json")
interface DrawerLeftProps {
    openDrawer: string,
    setOpenDrawer: React.Dispatch<string>,
    activeAccordion: string,
    setActiveAccordion: React.Dispatch<string>

}

const DrawerLeft = memo(({
    openDrawer,
    setOpenDrawer,
    activeAccordion,
    setActiveAccordion
}: DrawerLeftProps) => {
    const history = useHistory()
    const user = getUserAccount()
    const isLoggedIn = user.getIsLoggedIn()

    const handleClickPathname = useCallback((pathname: string, category: string): void => {
        let tagList: {category_thai: string, category_eng: string}[] = []
        let tagType: "dashboard" | "infographic" | "" = "" 
        if(pathname.includes('dashboard')){
            tagType = "dashboard"
            tagList = dashboard_tag
        } else if(pathname.includes('infographic')){
            tagType = "infographic"
            tagList = infographicTag
        }
        let matchedCategory = tagList.find(
            ({ category_thai }: { category_thai: string }) =>
                category_thai === category
        )
        let payload: {
            pathname: string
            state?: { category?: string, dashboardType?: DashboardType, infographicType?: InfographicType }
            search?: string
        } = {
            pathname: pathname,
        }
        let query_list = []

        if(matchedCategory){
            if(matchedCategory.category_eng !== "privileged"){
                payload['state'] = {category: matchedCategory.category_eng}
                if(matchedCategory.category_thai !== "ทั้งหมด"){
                    query_list.push(
                        "category=" +
                            matchedCategory.category_eng
                    )
                }
            } else { // privileged case
                if(tagType === "dashboard"){
                    payload['state'] = {dashboardType: matchedCategory.category_eng}
                    query_list.push(
                        "dashboardType=" +
                            matchedCategory.category_eng
                    )
                } else { // infographic case
                    payload['state'] = {infographicType: matchedCategory.category_eng}
                    query_list.push(
                        "infographicType=" +
                            matchedCategory.category_eng
                    )
                }
            }
        }
        
        if (query_list.length > 0) {
            payload["search"] = "?" + query_list.join("&")
        }

        history.push(payload)
        window.scrollTo(0,0)
    },[history])
    
  return (
    <ThemeProvider theme={accordionTheme}>
        <SwipeableDrawer
        anchor='left'
        open={openDrawer === "left"}
        onOpen={() => setOpenDrawer("left")}
        onClose={() => {
            setOpenDrawer("")
            setActiveAccordion("")
            }}>
            <Box width={{sm:'530px', xs:'100vw'}} paddingX='8px'>
                <Box height="64px" display='flex' justifyContent='flex-end' padding='14px'>
                    <CloseIcon
                        sx={{
                            width: "40px",
                            height: "40px",
                            cursor: "pointer",
                            padding:'5px', 
                            borderRadius: '30px',
                            color:'#194B7E',
                            ":hover": {
                                backgroundColor: "rgba(32,32,32,0.1)",
                            }
                        }}
                        onClick={() => {
                            setOpenDrawer("")
                            setActiveAccordion("")
                        }}
                    />
                </Box>
                
                <Box display={{md:'none', sm:'none', xs: 'block'}}>
                    <Divider />
                    
                </Box>
                <CollapsibleList 
                header="แดชบอร์ดท่องเที่ยว"
                setOpenDrawer={setOpenDrawer}
                activeAccordion={activeAccordion}
                setActiveAccordion={setActiveAccordion}
                HeaderIcon={<DashboardOutlinedIcon sx={{strokeWidth:0.5, stroke:"#ffffff", fill: '#515151'}} />}
                options={dashboard_tag.map(
                    ({ category_thai, category_eng }) => ({
                        key: category_eng,
                        name:
                        category_thai === "ทั้งหมด"
                        ? "แดชบอร์ดทั้งหมด"
                        : category_eng === 'privileged' 
                        ? <>แดชบอร์ดพิเศษ <Crown style={{width:'11px', marginLeft: '10px', marginBottom:"-2px"}}/></>
                        : category_thai
                        ,
                        handleClick: () =>
                            handleClickPathname(
                                '/dashboard',
                                category_thai
                            ),
                        isShowing:
                            category_thai !==
                                "แดชบอร์ดพิเศษ" ||
                            (isLoggedIn &&
                                user.getIsPrivileged()),
                    })
                )}/>
                <CollapsibleList 
                header="อินโฟกราฟิกท่องเที่ยว"
                setOpenDrawer={setOpenDrawer}
                activeAccordion={activeAccordion}
                setActiveAccordion={setActiveAccordion}
                HeaderIcon={<InsertChartOutlinedTwoToneIcon sx={{stroke: '#ffffff', strokeWidth:0.5, fill: '#515151'}}/>}
                options={infographicTag.map(
                    ({ category_thai, category_eng }) => ({
                        key: category_eng,
                        name:
                            category_thai === "ทั้งหมด"
                                ? "อินโฟกราฟิกทั้งหมด"
                                : category_thai,
                            handleClick: () =>
                            handleClickPathname(
                                '/infographic',
                                category_thai
                            ),
                        isShowing:
                            category_thai !==
                                "อินโฟกราฟิกพิเศษ" ,
                        isDisabled: !['ทั้งหมด','การเดินทาง'].includes(category_thai)
                    }))} />

                <CollapsibleList 
                header="แค็ตตาล็อกข้อมูล"
                setOpenDrawer={setOpenDrawer}
                activeAccordion={activeAccordion}
                setActiveAccordion={setActiveAccordion}
                HeaderIcon={<MenuBookOutlinedIcon sx={{strokeWidth:0.5, stroke:"#ffffff", fill: '#515151'}} />}
                options={[
                    {
                        key:'1',
                        name: "ทั้งหมด",
                        ahref: "https://catalog.travellink.go.th/",
                        isNewTab: true
                        
                    },
                    {
                        key:'2',
                        name: "สถานที่พักแรม",
                        ahref: "https://catalog.travellink.go.th/group/hotel",
                        isNewTab: true
                    },
                    {
                        key:'3',
                        name: "แหล่งท่องเที่ยว",
                        ahref: "https://catalog.travellink.go.th/group/attraction",
                        isNewTab: true
                    },
                    {
                        key:'4',
                        name: "กิจกรรมท่องเที่ยว",
                        ahref: "https://catalog.travellink.go.th/group/activities",
                        isNewTab: true
                    },
                ]} />
                <CollapsibleList 
                header="รู้จักเรา"
                setOpenDrawer={setOpenDrawer}
                activeAccordion={activeAccordion}
                setActiveAccordion={setActiveAccordion}
                HeaderIcon={<InfoOutlinedIcon sx={{strokeWidth:0.5, stroke:"#ffffff", fill: '#515151'}} />}
                options={[
                    {
                        key:'1',
                        name: "Mission ของโครงการ",
                        ahref: "/about-us#vision",
                        isNewTab: false,
                    },
                    {
                        key:'2',
                        name: "ทำไมถึงเกิดโครงการนี้",
                        ahref: "/about-us#why",
                        isNewTab: false,
                    },
                    {
                        key:'3',
                        name: "อะไรคือคุณค่าของเรา",
                        ahref: "/about-us#value",
                        isNewTab: false,
                    },
                    {
                        key:'4',
                        name: "ความเห็นของผู้เกี่ยวข้อง",
                        ahref: "/about-us#comment",
                        isNewTab: false,
                    },
                    {
                        key:'5',
                        name: "พันธมิตร",
                        ahref: "/about-us#partner",
                        isNewTab: false,
                    },
                    {
                        key:'6',
                        name: "ติดต่อเรา",
                        ahref: "/contact",
                        isNewTab: false,
                    },
                ]} />
            </Box>
        </SwipeableDrawer>

    </ThemeProvider>
  )
}
)

export default DrawerLeft