import React, { useMemo, useState, useEffect } from "react"
import {
    ThemeProvider,
    createTheme,
    TextField,
    Grid,
    Card,
    RadioGroup,
    FormHelperText,
    FormControlLabel,
    Radio,
    Box,
    FormControl,
    Autocomplete,
    Checkbox,
    Typography
} from "@mui/material"
import { Formik, FormikHelpers } from "formik"
import style from "./RegisterMemberAlliancePlatform.module.css"
import CustomContainer from "../../components/shared/CustomContainer/CustomContainer"
import AddRoundedIcon from "@mui/icons-material/AddRounded"
import { mergeThemes } from "../../theme/merge"
import { register_textbox_theme } from "../../theme/register"
import { textbox_theme } from "../../theme/textbox"
import { registernew_theme } from "../../theme/registernew"
import { typography_theme } from "../../theme/typography"
import TVLButton from "../../components/shared/TVLButton/TVLButton"
import {
    RegisterPlatformAlliance,
    registerPlatformAllianceValidate,
} from "../../validation/register"
import ErrorElement from "../../components/shared/ErrorElement/ErrorElement"
import ResponsePopUp from "../../components/shared/ResponsePopUp/ResponsePopUp"
import { ScrollToFieldError } from '../../components/shared/ScrollToFieldError/ScrollToFieldError'
import TextRequired from "../../components/shared/TextRequired/TextRequired"
import AgreementPopUp from "../../components/shared/AgreementPopUp/AgreementPopUp"
import AllianceCard from "./AllianceCard/AllianceCard"
import { getQuestionaire, getOrg, registerPlatformAlliance } from "../../services/data-connector"
import { Organization, Questionaire } from "../../interfaces/Register"
import Cookies from "js-cookie"

const RegisterMemberAlliancePlatform = () => {
    const mergedTheme = mergeThemes(
        register_textbox_theme,
        textbox_theme,
        registernew_theme,
        typography_theme
    )
    const theme = createTheme(mergedTheme)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>("")
    const [errorForm, setErrorForm] = useState<string>("")
    const [agreementNo, setAgreementNo] = useState<number>(-1)
    const [platform, setPlatform] = useState<'Google' | 'Microsoft' | null>(null)
    const [responseStatus, setResponseStatus] = useState<
        "" | "succeed"
    >("")
    const [response, setResponse] = useState<React.ReactNode>(<></>)
    const [allianceList, setAllianceList] = useState<Organization[]>([])
    const [questionaireList, setQuestionaireList] = useState<Questionaire[]>([])
    const [ErrorSummary, setErrorSummary] = useState<React.ReactNode>(<></>)

    const containsText = (text: string, searchText: string) =>
        text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

    const year_list: string[] = []
    for (let i = 2565; i > 2460; i--) {
        year_list.push(String(i))
    }
    const displayedOptions = useMemo(
        () => year_list.filter((value) => containsText(value, searchText)),
        [searchText]
    )

    const INITIAL_ALLIANCE_CARD = {
        orgAbb: '',
        organizationQuestionaires:[{
          questionName: '',
          questionId: '',
          answer: ''
        }]
      }
      const INITAL_ALLIANCE_VALIDATE = {
        orgAbb: false,
        organizationQuestionaires: [{
          answer: false
        }]
      }

    const [initialValues, setInitialValues] = useState<RegisterPlatformAlliance>({
        firstName: "",
        lastName: "",
        organization: "",
        position: "",
        email: "",
        phoneNo: "",
        gender: "",
        platform: "",
        yearOfBirth: "",
        checkBox: false,
        privileges: [
            {...INITIAL_ALLIANCE_CARD}
        ]
    })

    const initialTouched = {
        firstName: false,
        lastName: false,
        organization: false,
        position: false,
        email: false,
        phoneNo: false,
        gender: false,
        platform: true,
        yearOfBirth: false,
        checkBox: false,
        privileges:[
            {...INITAL_ALLIANCE_VALIDATE}
        ]
    }
    const fragment = window.location.hash.substr(1)
    const encoded_data = fragment.split("data")[1]
    useEffect(() => {
        if (encoded_data) {
            const data = JSON.parse(decodeURIComponent(encoded_data))
            let allianceSelected = allianceList.find(({orgAbb}) => orgAbb === data['orgAbb'])
            if(allianceSelected){
            let tmp : {privileges?: Array<{
                orgAbb: string,
                organizationQuestionaires: Array<{
                questionId: string,
                questionName: string,
                answer: string
                }>
            }>, 
            email?: string,
            platform?: "Microsoft" | "Google",
            firstName?: string,
            lastName?: string} = {}
            if(data.email){
                tmp['email'] = data['email']
                initialTouched['email'] = true
            }
            if(data.firstName){
                tmp['firstName'] = data['firstName']
                initialTouched['firstName'] = true
            }
            if(data.lastName){
                tmp['lastName'] = data['lastName']
                initialTouched['lastName'] = true
            }
            if(data.orgAbb){
                let orgQuestionList = questionaireList.filter(({orgID}) => orgID === data.orgAbb)
                tmp['privileges'] = [{
                orgAbb: data['orgAbb'],
                organizationQuestionaires: orgQuestionList.map(({id, name}) => ({
                    questionId: id,
                    questionName: name,
                    answer: ''
                }))
                }]
                initialTouched['privileges']![0]['orgAbb'] = true
                initialTouched['privileges']![0]['organizationQuestionaires'] = orgQuestionList.map(() => ({
                answer: false
                }))
                }
                if (data.platform) {
                    tmp["platform"] = data["platform"] as "Google" | "Microsoft"
                    initialTouched["platform"] = true
                    setPlatform(data.platform as "Google" | "Microsoft")
                }
                setInitialValues({ ...initialValues, ...tmp })
            }
        } else {
            window.location.href='/registration'
        }
    }, [allianceList, questionaireList])

    const response_element = () => {
        if (responseStatus === "") {
            return <></>
        } else if (responseStatus === "succeed") {
            let redirectUrl = '/'
            if(platform === 'Google'){
                redirectUrl = '/api/user/user/login/google/'
                Cookies.set('redirect_uri', '')
                Cookies.set('fromUrl', '')
            } else if(platform === 'Microsoft'){
                redirectUrl = '/api/user/user/login/microsoft'
            }
            return (
                <ResponsePopUp
                    open
                    setOpen={() => {
                        window.location.href = redirectUrl
                    }}
                    status="succeed"
                    title="ลงทะเบียนสำเร็จ"
                    text1="ท่านสามารถใช้งานแดชบอร์ดทั่วไป"
                    text2="สิทธิในการใช้งานแดชบอร์ดพิเศษของท่าน
        จะสามารถใช้งานได้หลังจากผ่านการอนุมัติโดยแอดมิน"
                    button="กลับสู่หน้าหลัก"
                    handleButton={() => {
                        window.location.href = redirectUrl
                    }}
                />
            )
        }
    }

    const handleSubmit  = async (value: RegisterPlatformAlliance, action: FormikHelpers<RegisterPlatformAlliance>) => {
        setIsLoading(true)
        let {phoneNo, ...payload} = value 
        if(phoneNo){
          payload = value
        }
    
        let {success, res} = await registerPlatformAlliance(payload)
    
        if(success){
          setResponseStatus('succeed')
        } else if(res.detail === 'The email has already been registered.'){
            action.setFieldError('email','อีเมลนี้ลงทะเบียนไปแล้ว กรุณาใช้อีเมลอื่นในการสมัคร')
            setErrorForm('อีเมลนี้ลงทะเบียนไปแล้ว กรุณาใช้อีเมลอื่นในการสมัคร')

            // scroll to email field
            let emailElement = document.querySelector(`input[name='email']`)
            emailElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else{
          setErrorForm(res.detail)
        }
        
    
        setIsLoading(false)
    
    
      }

    useEffect(() => {
        setResponse(response_element())
    }, [responseStatus])

    useEffect(() => {
        const fetchOrgData = async () => {
            const { success,res } = await getOrg()
            let allianceList_tmp = res["orgList"] as Organization[]
            allianceList_tmp = allianceList_tmp.filter(({orgType}) => orgType === "สมาคม")
            setAllianceList(allianceList_tmp)
        }
    
          fetchOrgData()
      },[])
    
      useEffect(() => {
        const fetchQuestionaireData = async () => {
            const { success,res } = await getQuestionaire()
            setQuestionaireList(res["questionaires"] as Questionaire[])
        }
          fetchQuestionaireData()
      },[])

      useEffect(() => {
        let errorElement = errorForm ? 
        (<div style ={{marginBottom:'10px'}}>
          <ErrorElement errorMessage={errorForm}/>
        </div>) : (<></>)
        setErrorSummary(errorElement)
      }
      ,[errorForm])

    return (
        <CustomContainer>
            <AgreementPopUp open={agreementNo} setOpen={setAgreementNo}/>
            {response}
            <ThemeProvider theme={theme}>
                <div className={style.title}>
                    ลงทะเบียนสมาชิกภายใต้ MOU
                </div>
                <div className={style.subtitle}>
                    กรอกข้อมูลลงทะเบียนเพิ่มเติม
                </div>
                <Box sx = {{padding:{md: '0 30px', sm: '0 30px', xs: '0'}, marginBottom: '100px'}}>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        initialTouched={initialTouched}
                        validate={registerPlatformAllianceValidate}
                        onSubmit={(values, action) => {
                            handleSubmit(values, action)
                            setResponseStatus("succeed")
                        }}
                    >
                        {({
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            setValues,
                            setTouched
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <ScrollToFieldError />
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div className={style.header}>
                                        ข้อมูลสำหรับเข้าใช้งาน
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                            backgroundColor: "#ABABAB",
                                            height: "1px",
                                            margin: "0 10px",
                                        }}
                                    ></div>
                                </div>

                                <Grid
                                    container
                                    columnSpacing={5}
                                    sx={{
                                        marginBottom: "70px",
                                        paddingLeft: "24px",
                                        paddingRight: "24px",
                                    }}
                                >
                                    <Grid item md={6} sm={6} xs={12}>
                                        <div className={style.label}>
                                            อีเมล
                                            <TextRequired />
                                        </div>
                                        <TextField
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                !!errors.email && touched.email
                                            }
                                            helperText={
                                                !!errors.email &&
                                                touched.email ? (
                                                    <ErrorElement
                                                        errorMessage={
                                                            errors.email
                                                        }
                                                    />
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            placeholder="อีเมล"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <div className={style.label}>
                                            ประเภทบัญชีเข้าใช้งาน
                                            <TextRequired />
                                        </div>
                                        <TextField
                                            name="platform"
                                            value={values.platform}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                !!errors.platform &&
                                                touched.platform
                                            }
                                            helperText={
                                                !!errors.platform &&
                                                touched.platform ? (
                                                    <ErrorElement
                                                        errorMessage={
                                                            errors.platform
                                                        }
                                                    />
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            placeholder="อีเมล"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "25px",
                                    }}
                                >
                                    <div className={style.header}>
                                        ข้อมูลผู้สมัครใช้งาน
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                            backgroundColor: "#ABABAB",
                                            height: "1px",
                                            margin: "0 10px",
                                        }}
                                    ></div>
                                </div>

                                <Grid
                                    container
                                    columnSpacing={5}
                                    sx={{ marginBottom: "35px" }}
                                >
                                    <Grid item md={6} sm={6} xs={12} display="flex" flexDirection="column">
                                        <div className={style.subheader}>
                                            รายละเอียดผู้สมัครใช้งาน
                                        </div>

                                        <Card sx={{flex:"1"}}>
                                            <div
                                                className={style.label}
                                                style={{ marginTop: "0" }}
                                            >
                                                ชื่อ
                                                <TextRequired />
                                            </div>
                                            <TextField
                                                name="firstName"
                                                value={values.firstName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    !!errors.firstName &&
                                                    touched.firstName
                                                }
                                                helperText={
                                                    !!errors.firstName &&
                                                    touched.firstName ? (
                                                        <ErrorElement
                                                            errorMessage={
                                                                errors.firstName
                                                            }
                                                        />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                placeholder="ชื่อ"
                                                fullWidth
                                            />

                                            <div className={style.label}>
                                                นามสกุล
                                                <TextRequired />
                                            </div>
                                            <TextField
                                                name="lastName"
                                                value={values.lastName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    !!errors.lastName &&
                                                    touched.lastName
                                                }
                                                helperText={
                                                    !!errors.lastName &&
                                                    touched.lastName ? (
                                                        <ErrorElement
                                                            errorMessage={
                                                                errors.lastName
                                                            }
                                                        />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                placeholder="นามสกุล"
                                                fullWidth
                                            />
                                            
                                            <div className={style.label}>ปีเกิด<TextRequired /></div>
                                                <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={values.yearOfBirth} 
                                                options={year_list}
                                                onChange={(event: any, newValue: string | null) => {
                                                    setFieldValue('yearOfBirth', newValue)
                                                }}
                                                fullWidth
                                                renderInput={(params) => 
                                                    <TextField  
                                                    {...params} 
                                                    placeholder='ปีเกิด (พ.ศ.)'
                                                    name="alliance" 
                                                    error={touched.yearOfBirth && !!errors.yearOfBirth}
                                                    helperText={
                                                        touched.yearOfBirth && !!errors.yearOfBirth ?
                                                        <ErrorElement errorMessage={errors.yearOfBirth} /> : <></>
                                                    }
                                                    sx={{
                                                        '& .MuiOutlinedInput-notchedOutline legend':{
                                                            marginLeft: '10px'
                                                        }

                                                    }}
                                                    />}
                                                    sx={{
                                                    '.MuiInputBase-root':{
                                                        paddingLeft:'24px',
                                                        paddingTop: '6px',
                                                        paddingBottom: '6px'
                                                    }
                                                    }}
                                                />
                                                
                                            <div className={style.label}>
                                                เพศ
                                                <TextRequired />
                                            </div>
                                            <RadioGroup
                                                row
                                                name="gender"
                                                value={values.gender}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    value="male"
                                                    label="ชาย"
                                                />
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    value="female"
                                                    label="หญิง"
                                                />

                                                <FormControlLabel
                                                    control={<Radio />}
                                                    value="others"
                                                    label="ไม่ระบุ"
                                                />
                                            </RadioGroup>

                                            {touched.gender && errors.gender ? (
                                                <FormHelperText
                                                    error
                                                    sx={{
                                                        paddingLeft: "15px",
                                                        marginTop: "-10px",
                                                        marginBottom:
                                                            "10px !important",
                                                    }}
                                                >
                                                    {
                                                        <ErrorElement
                                                            errorMessage={
                                                                errors.gender
                                                            }
                                                        />
                                                    }
                                                </FormHelperText>
                                            ) : (
                                                <></>
                                            )}
                                        </Card>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        sm={6}
                                        xs={12}
                                        container
                                        flexDirection="column"
                                        display="flex"
                                    >
                                        <Box>
                                            <div className={style.subheader}>
                                                ข้อมูลสำหรับการติดต่อ
                                            </div>
                                            <Card>
                                                <div
                                                    className={style.label}
                                                    style={{ marginTop: "0" }}
                                                >
                                                    เบอร์โทรศัพท์มือถือ
                                                </div>
                                                <TextField
                                                    name="phoneNo"
                                                    value={values.phoneNo}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        !!errors.phoneNo &&
                                                        touched.phoneNo
                                                    }
                                                    helperText={
                                                        !!errors.phoneNo &&
                                                        touched.phoneNo ? (
                                                            <ErrorElement
                                                                errorMessage={
                                                                    errors.phoneNo
                                                                }
                                                            />
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    placeholder="เบอร์โทรศัพท์มือถือ"
                                                    fullWidth
                                                />
                                            </Card>
                                        </Box>
                                        <Box height={20}/>

                                        <Box display="flex" flexDirection='column' flex="1">
                                            <div className={style.subheader}>
                                                รายละเอียดเกี่ยวกับธุรกิจ
                                            </div>
                                            <Card sx={{flex:'1'}}>
                                                <div
                                                    className={style.label}
                                                    style={{ marginTop: "0" }}
                                                >
                                                    บริษัท
                                                    <TextRequired />
                                                </div>
                                                <TextField
                                                    name="organization"
                                                    value={values.organization}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        !!errors.organization &&
                                                        touched.organization
                                                    }
                                                    helperText={
                                                        !!errors.organization &&
                                                        touched.organization ? (
                                                            <ErrorElement
                                                                errorMessage={
                                                                    errors.organization
                                                                }
                                                            />
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    placeholder="บริษัท"
                                                    fullWidth
                                                />

                                                <div className={style.label}>
                                                    ตำแหน่ง
                                                    <TextRequired />
                                                </div>
                                                <TextField
                                                    name="position"
                                                    value={values.position}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        !!errors.position &&
                                                        touched.position
                                                    }
                                                    helperText={
                                                        !!errors.position &&
                                                        touched.position ? (
                                                            <ErrorElement
                                                                errorMessage={
                                                                    errors.position
                                                                }
                                                            />
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    placeholder="ตำแหน่ง"
                                                    fullWidth
                                                />
                                            </Card>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "25px",
                                        marginBottom: "25px",
                                    }}
                                >
                                    <div className={style.header}>
                                        ข้อมูลประกอบการยืนยันตัวตน
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                            backgroundColor: "#ABABAB",
                                            height: "1px",
                                            margin: "0 10px",
                                        }}
                                    ></div>
                                </div>

                                {
                                values.privileges.map((field,index) => (
                                    <AllianceCard index={index} cardValues={field} orgList = {allianceList} questionaires={questionaireList} />
                                ))
                                }
                                <div 
                                style={{textAlign:'left', marginTop:'35px'}} 
                                onClick={() => {
                                    let updateAllianceCardValue = values.privileges
                                    let updateAllianceCardTouched = touched.privileges
                                    updateAllianceCardValue.push({...{orgAbb:'',organizationQuestionaires:[]}})
                                    updateAllianceCardTouched?.push({...{orgAbb:false}})
                                    setValues({...values,privileges:updateAllianceCardValue})
                                    setTouched({...touched,privileges:updateAllianceCardTouched})
                                }}>
                                    <AddRoundedIcon sx={{fontSize:'18px', color:'#0182FA', verticalAlign:'sub'}}/>
                                    <Typography variant='ahref' >เพิ่มสมาคมที่คุณสังกัด</Typography> 
                                </div>

                                <div>
                                <FormControl sx={{left:'0', right:'0', margin:'auto', marginBottom:'35px'}}>
                                    <FormControlLabel control={
                                        <Checkbox name = "checkBox" 
                                        value={values.checkBox} 
                                        onChange={(e) => {
                                        setFieldValue('checkBox', e.target.checked)
                                        setErrorForm('')
                                        }}/>} 
                                        label={
                                        <Box textAlign='left'>ท่านได้ทำการอ่าน และยอมรับ 
                                        <Typography variant='ahref' sx={{margin:'0 5px', fontWeight:'400'}} onClick={(e) => {e.preventDefault();setAgreementNo(0)}}>Privacy Policy</Typography>
                                        และ  
                                        <Typography variant='ahref' sx={{margin:'0 5px', fontWeight:'400'}} onClick={(e) => {e.preventDefault();setAgreementNo(1)}}>Terms & Condition</Typography> แล้ว </Box>
                                        } 
                                        sx={{
                                          alignItems: 'flex-start',
                                          '& .MuiCheckbox-root':{
                                            paddingTop:'0'
                                          }
                                        }}/>
                                    </FormControl>
                                </div>

                                <div>
                                    {ErrorSummary}
                                    <TVLButton
                                        type="submit"
                                        size="large"
                                        isLoading={isLoading}
                                        onMouseDown={(event:React.MouseEvent<HTMLButtonElement>)=>{event.preventDefault()}}
                                        onClick={() => {
                                            if (
                                                !values.checkBox &&
                                                Object.keys(errors).length === 1
                                            ) {
                                                setErrorForm(errors.checkBox!)
                                            }
                                        }}
                                    >
                                        ลงทะเบียน
                                    </TVLButton>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Box>
            </ThemeProvider>
        </CustomContainer>
    )
}

export default RegisterMemberAlliancePlatform
