import { ThemeOptions } from "@mui/material";

export const listItemTheme: ThemeOptions = {
    components:{
        MuiListItem:{
            styleOverrides:{
                root:{
                    color: '#515151',
                    ":hover":{
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        fill:'rgba(0, 0, 0, 0.87)',
                        cursor: 'pointer'
                    }
                }
            }
        }
    }
}