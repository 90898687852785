import React from 'react'
import { Grid } from '@mui/material'
import CustomContainer from '../../../components/shared/CustomContainer/CustomContainer'

import project from '../../../assets/mission/Pic/Project.png'

import WhyModule from './Why.module.css'

function Why() {
    return (
        <div className={WhyModule.wrapper} style={{ backgroundColor: '#F1F7FD' }}>
            <CustomContainer>
                <Grid container>
                    
                    <Grid xs={10} sm = {4} md={4} sx = {{margin: '20px auto'}}>
                        <img style = {{objectFit:'contain', width: '100%'}} src={project} />
                    </Grid>
                    <Grid xs={0} sm = {0} md = {1} />
                    <Grid xs={12} sm = {7} md={7} sx = {{verticalAlignment: 'middle' , alignSelf: 'center'}}>
                        <p className={WhyModule.text_header}>ทำไมถึงเกิดโครงการนี้</p>
                        <p className={WhyModule.text}>ระบบบริการข้อมูลอัจฉริยะด้านการท่องเที่ยวในฐานวิถีชีวิตใหม่
                            (Travel Link) เป็นการต่อยอดความร่วมมือการทำงานภายใต้บันทึกข้อตกลงความร่วมมือสานพลังประชารัฐ
                            ส่งเสริมการท่องเที่ยวของประเทศไทย โครงการ Digital Tourism Platform และบันทึกความร่วมมือ
                            การสนับสนุนข้อมูลเพื่อจัดทำระบบสารสนเทศเพื่อการท่องเที่ยว เพื่อร่วมกันพัฒนาระบบบริการข้อมูลด้าน
                            การท่องเที่ยวของทางภาครัฐให้มีความเป็นเอกภาพ สะดวก เข้าถึงได้ง่าย สำหรับทุกภาคส่วน ทั้งสำหรับหน่วยงาน
                            ภาครัฐ ภาคเอกชน และประชาชน </p>
                    </Grid>
                </Grid>
            </CustomContainer>
        </div>
    )
}

export default Why
