import React from 'react'
import { InfographicTag, InfographicCategoryThai } from '../../../interfaces/InfographicTag'
import { Pro } from '../../../assets/dashboard';
import Highlighter from "react-highlight-words";
import style from './InfographicTagElement.module.css'
import { useHistory } from 'react-router-dom';
const infographic_tag: InfographicTag[] = require('../../../variable/infographic_tag.json')

interface InfographicTagElementProps {
    tag: InfographicCategoryThai, 
    pathname: string,
    searchText?: string
}

const InfographicTagElement = ({tag, searchText, pathname}: InfographicTagElementProps) => {
    const remove_undefined = (text:string|undefined):string => (text ? text : '')
    const history = useHistory()
    const handleClickTag = (e: React.MouseEvent) => {
        // remove effect on ahref
        e.preventDefault()
        e.stopPropagation()


        let matchedCategory = infographic_tag.find(({category_thai}) => category_thai === tag)
        if(matchedCategory){
            history.push({
                pathname: pathname,
                state:{
                    category: matchedCategory.category_eng,
                },
                search: "?category=" + matchedCategory.category_eng
            })
            window.scrollTo(0,0)

        }
        
    }

    const handleClickTagPrivilege = (e: React.MouseEvent) => {
        // remove effect on ahref
        e.preventDefault()
        e.stopPropagation()


        history.push({
            pathname: pathname,
            state:{
                dashboardType: 'privileged',
            },
            search: "?infographicType=privileged"
        })
        window.scrollTo(0,0)
        
    }
  return (
    (tag === 'อินโฟกราฟิกพิเศษ' ?
        <span className={style.tag} style={{backgroundColor:'rgba(251, 188, 4, 0.3)', }}>
            <a onClick={handleClickTagPrivilege}>
                <Pro style={{marginBottom:'-2px'}}/>
            </a>
        </span>
        :
        <span className = {style.tag} style={{backgroundColor:`${infographic_tag.find(({category_thai}:{category_thai:string}) => category_thai == tag)!['color']}`}}>
            {
                typeof searchText !== "undefined" ?
                <Highlighter
                sanitize={remove_undefined}
                highlightStyle={{backgroundColor:'#bfdefc'}}
                searchWords={[searchText!]}
                autoEscape={true}
                textToHighlight={tag} 
                onClick = {(e: React.MouseEvent) => handleClickTag(e)}/>
                :
                <a onClick={(e: React.MouseEvent) => handleClickTag(e)} style={{cursor: 'pointer'}}>
                    {tag}
                </a>   
            }
        </span>)
  )
}

export default InfographicTagElement