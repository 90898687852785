import React from 'react'
import {
    ThemeProvider,
    Pagination
} from '@mui/material'
import { pagination_theme } from '../../../theme/dashboard'

interface PaginationElementProps {
  noItems: number
  pageSize: number
  pageNo: number
  handlePaginationChange: (event: React.ChangeEvent<unknown>, page: number) => void
}

const PaginationElement = ({noItems, pageSize, pageNo, handlePaginationChange}: PaginationElementProps) => {
  return (
    <ThemeProvider theme={pagination_theme}>
        <Pagination 
        count={Math.ceil(noItems / pageSize)} 
        color="primary" 
        showFirstButton 
        showLastButton 
        onChange={handlePaginationChange}
        page={pageNo}
        sx={{marginBottom: '20px', '& .MuiPagination-ul':{justifyContent: 'center'} }}/>

    </ThemeProvider>
  )
}

export default PaginationElement