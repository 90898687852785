import React, { useEffect, useState } from 'react'
import style from './DashboardShowCase.module.css'
import {
    Box, Grid, createTheme
} from '@mui/material'
import { DashboardCategoryThai, DashboardTag } from '../../../interfaces/DashboardTag'
import DashboardHomeCard from './DashboardHomeCard/DashboardHomeCard'
import { DashboardGalleryCard } from '../../../interfaces/DashboardMetadata'
import { getDashboardMetadata } from '../../../services/data-connector'
import { SwiperContainer, SwiperSlide } from './SwiperDashboard/SwiperDashboard'
import 'swiper/swiper-bundle.css';
import { useHistory } from 'react-router-dom'
import LogInPopUp from '../../../components/shared/LogInPopUp/LogInPopUp'
import TVLButton from '../../../components/shared/TVLButton/TVLButton'
import { ThemeProvider } from '@emotion/react'
import { dashboardShowCaseTheme } from '../../../theme/homeThemes'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const SwiperDashboard = ({dashboardMetadatas, setOpenLogInPopUp, setRedirectUrl}:{dashboardMetadatas: DashboardGalleryCard[], setOpenLogInPopUp: React.Dispatch<boolean>, setRedirectUrl: React.Dispatch<string>}) => {
    return (
        <SwiperContainer
            spaceBetween={20}
            slidesPerView={3}
            breakpoints={{
                // when window width is >= 600px, but < 900px
                600: {
                    slidesPerView: 3,
                    spaceBetween: 35
                    },
                // when window width is >= 900px
                900: {
                slidesPerView: 3,
                spaceBetween: 50
                }
            }}
            navigation={{
                nextEl: `.custom_next_dashboard`,
                prevEl: `.custom_prev_dashboard`
            }}
            pagination={{
                clickable: true
            }}
            autoplay= {{
                delay: 5000,
                pauseOnMouseEnter: true
            }}
            loop
            centeredSlides
            >
            {
                dashboardMetadatas.map((dashboardMetadata) =>
                <SwiperSlide
                    key={dashboardMetadata.galleryId}
                    style={{height: "auto"}}>
                    <Grid width='99%' height="99%" item>

                        <DashboardHomeCard 
                        dashboardMetadata={dashboardMetadata}
                        setOpenLogInPopUp={setOpenLogInPopUp}
                        setRedirectUrl={setRedirectUrl}
                        />

                    </Grid>
                </SwiperSlide>

            )
                
            }
        </SwiperContainer>
    )
}


interface CategoryInfo {
    category_thai: string;
    start: number,
    end: number;
}


const DashboardShowCase = () => {

    const dashboard_tags: DashboardTag[] = require('../../../variable/dashboard_tag.json')
    const selectedCategories = ["การเดินทาง","การใช้จ่าย","สื่อโซเชียล"]
    const selectableCategories = dashboard_tags.filter(({category_thai}) => selectedCategories.includes(category_thai))
    const [selectedTag, setSelectedTag] = useState<DashboardCategoryThai>("การเดินทาง")
    const [dashboardMetadatas, setDashboardMetadatas] = useState<DashboardGalleryCard[]>([])
    const [breakpointList, setBreakPointList] = useState<CategoryInfo[]>([])
    const [isFinished, setIsFinished] = useState<boolean>(false)
    const [openLogInPopUp, setOpenLogInPopUp] = useState<boolean>(false)
    const [redirectUrl, setRedirectUrl] = useState<string>("")
    const [isError, setIsError] = useState<boolean>(false)
    const history = useHistory()
    const handleTagClick = (tag: DashboardCategoryThai) => {
        if(tag !== selectedTag){
            setSelectedTag(tag)
        }        
    }

    const fetchDashboardMetadata = async () => {
        let { success, res } = await getDashboardMetadata("")
        if(success){
            let dashboardMetadataTmp: DashboardGalleryCard[] = res.dashboard_metadata
            let dashboardMetadataReturn: DashboardGalleryCard[] = []
            // adding dashboard to show in order
            // remove duplicate, keep last
            selectableCategories.map((dashboardTag, i, selectableArray) => {
                let start = dashboardMetadataReturn.length
                let dashboardFiltered = dashboardMetadataTmp.filter(({tags}) => tags.includes(dashboardTag.category_thai))
                dashboardFiltered.map((dashboardMetadata) => {
                    if(selectableArray.slice(i+1,).every(({category_thai}) => !dashboardMetadata.tags.includes(category_thai))){
                        dashboardMetadataReturn.push(dashboardMetadata)
                    }
                })
                setBreakPointList((prev) => [...prev, {category_thai: dashboardTag.category_thai, start: start, end: dashboardMetadataReturn.length}])
    
            })
            setDashboardMetadatas(dashboardMetadataReturn)
            setIsFinished(true)
        } else {
            setIsError(true)
        }
        
    }

    useEffect(() => {
        fetchDashboardMetadata()
    },[])
    
    function getCategoryThai(number: number, breakpoints: CategoryInfo[]): string {
        for (const categoryInfo of breakpoints) {
            if (number < categoryInfo.end && number >= categoryInfo.start) {
                return categoryInfo.category_thai;
            }
        }
        return breakpoints[breakpoints.length - 1].category_thai;
    }

    useEffect(() => {
        if(isFinished) {
            const swiperElList = document.querySelectorAll('swiper-container');
            const swiperEl = swiperElList[1]
            const buttonTouristMovementEl = document.querySelector('.category-tag-tourist-movement');
            const buttonSpendingEl = document.querySelector('.category-tag-spending-insights');
            const buttonSocialEl = document.querySelector('.category-tag-social');
            const handleClick = (tag: DashboardCategoryThai) => {
                if (swiperEl) {
                    let breakpoint = breakpointList.find(({category_thai}) => tag === category_thai)
                    if(breakpoint){
                        swiperEl.swiper.slideToLoop(breakpoint.start);
                    }
                }
            };
        
            if (buttonTouristMovementEl) {
                buttonTouristMovementEl.addEventListener('click', () => handleClick('การเดินทาง'));
            }

            if (buttonSpendingEl) {
                buttonSpendingEl.addEventListener('click', () => handleClick('การใช้จ่าย'));
            }

            if (buttonSocialEl) {
                buttonSocialEl.addEventListener('click', () => handleClick('สื่อโซเชียล'));
            }

            //add slide changing event listener
            if(swiperEl){
                swiperEl.addEventListener('swiperslidechange', (event) => {
                    let selectTagTmp = getCategoryThai(swiperEl.swiper.realIndex,breakpointList)
                    setSelectedTag(selectTagTmp)
                  });
            }
            
        
            // Clean up event listeners when component unmounts
            return () => {
        if(swiperEl){
            swiperEl.removeEventListener('swiperslidechange', (event) => {
                let selectTagTmp = getCategoryThai(swiperEl.swiper.realIndex,breakpointList)
                setSelectedTag(selectTagTmp)
                });
        }
            if (buttonTouristMovementEl) {
                buttonTouristMovementEl.removeEventListener('click',() => handleClick('การเดินทาง'));
            }

            if (buttonSpendingEl) {
                buttonSpendingEl.removeEventListener('click',() => handleClick('การใช้จ่าย'));
            }

            if (buttonSocialEl) {
                buttonSocialEl.removeEventListener('click',() => handleClick('สื่อโซเชียล'));
            }
            };

        }
      }, [isFinished]);

    
    const theme = createTheme(dashboardShowCaseTheme)
    return (
    <Box sx={{backgroundColor: 'transparent'}} id='dashboard'>
        <LogInPopUp open={openLogInPopUp} setOpen={setOpenLogInPopUp} redirect_uri={redirectUrl} />
        <Box className={style.title}>
            แดชบอร์ดข้อมูลท่องเที่ยว
        </Box>
        <Box className={style.description} sx={{color: '#515151'}}  marginBottom='20px' marginTop='5px'>
            ลงชื่อเข้าใช้เพื่อดูแดชบอร์ด<span style={{fontWeight:'600'}}>ฟรี!</span>
        </Box>
        {/* <Box display='flex' justifyContent='space-around' marginBottom='40px'>
            <Box className={style.selectionBox}>
                {
                    selectableCategories.map(({category_thai, category_eng}) => (
                        <span 
                        key={category_eng}
                        onClick={() => handleTagClick(category_thai)}
                        className={`category-tag-${category_eng} ${style.tag} ${category_thai === selectedTag ? style.activeTag : ""}`}>
                            {category_thai}
                        </span>
                    ))
                }
            </Box>
        </Box> */}
            
        <Box 
        marginX={{xl: 'auto',md: '-100px', sm: '-100px', xs: '-92px'}} 
        maxWidth='1736px' 
        position='relative'>

            <span className={`custom_prev_dashboard ${style.prev}`}>
                <ArrowBackIosRoundedIcon sx={{
                    width:{md: "24px", sm: "24px", xs:"18px"}, 
                    height: {md: "24px", sm: "24px", xs:"18px"}
                    }}/>
            </span>
            <span className={`custom_next_dashboard ${style.next}`}>
                <ArrowForwardIosRoundedIcon sx={{
                    width:{md: "24px", sm: "24px", xs:"18px"}, 
                    height: {md: "24px", sm: "24px", xs:"18px"}
                    }}/>
            </span>
            
            <ThemeProvider theme={theme}>
                {
                    isFinished && !isError ?
                    <SwiperDashboard 
                    dashboardMetadatas={dashboardMetadatas}
                    setRedirectUrl={setRedirectUrl}
                    setOpenLogInPopUp={setOpenLogInPopUp}/>
                    :
                    <></>
                }
                
            </ThemeProvider>
        </Box>
        <Box marginTop='30px' marginBottom='30px' className = {style.description} paddingX={{md:'200px', sm: '100px', xs:'20px'}}>
            ติดตามสถานการณ์การท่องเที่ยวผ่านตัวเลขเชิงสถิติที่บ่งบอกถึงพฤติกรรมการท่องเที่ยว
            ที่เปลี่ยนแปลงตลอดเวลา ให้คุณไม่พลาดทุกความเคลื่อนไหว
            ช่วยให้การวิเคราะห์แนวโน้มระดับประเทศทำได้ง่ายขึ้น
        </Box>
        <Box paddingBottom="90px">
                <TVLButton 
                color='tertiary'
                size='medium'
                sx={{
                        padding: "14px 32px",
                        fontWeight: "500",
                        fontSize:{
                            md: '18px',
                            sm: '18px',
                            xs: '14px'
                        }
                    }}
                onClick={() => {
                    history.push('/dashboard')
                    window.scrollTo(0,0)
                }}>
                        สำรวจแดชบอร์ด
                </TVLButton>

        </Box>
        
    </Box>
  )
}



export default DashboardShowCase