import {ThemeOptions} from '@mui/material'

export const dynamicSelectorTheme: ThemeOptions = {
    components:{
        MuiSelect:{
            styleOverrides:{
                select:{
                    padding:"8.5px 20px",
                    fontFamily: "Kanit",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: '0em',
                    textAlign: "left",
                }
            }
        },
        MuiOutlinedInput:{
            styleOverrides:{
                notchedOutline:{
                    borderRadius:'50px'
                },
                root:{
                    marginRight:"10px",
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#0182FA',
                    },
                }
            }
        },
        MuiMenuItem:{
            styleOverrides:{
                root:{
                    fontFamily: "Kanit",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    letterSpacing: '0.005em',
                    textAlign: "left"
                }
            }
        },
        MuiPopover:{
            styleOverrides:{
                paper:{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "0.5px solid #CBCBCB",
                    borderRadius: "12px"
                }
            }
        }

    }
}

export const dynamicAutocompleteTheme: ThemeOptions = {
    components:{
        MuiOutlinedInput:{
            styleOverrides:{
                notchedOutline:{
                    borderRadius:'50px'
                },
                root:{
                    marginRight:"10px",
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#0182FA',
                    },
                }
            }
        },
        MuiAutocomplete:{
            styleOverrides:{
                input:{
                    padding:"0px !important"
                },
                inputRoot:{
                    padding:"9px 65px 9px 20px"
                },
                root:{
                    marginRight:"10px",

                },
                popper:{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "0.5px solid #CBCBCB",
                    borderRadius: "12px !important",
                    width: "fit-content"
                },
                paper:{
                    borderRadius: "12px !important",
                },
                option:{
                    fontFamily: "Kanit",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    letterSpacing: '0.005em',
                    textAlign: "left"
                },
                noOptions:{
                    fontFamily: "Kanit",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    letterSpacing: '0.005em',
                    textAlign: "left"
                }
            }
        }
    }
}