import React, { useState, useEffect, useCallback } from 'react'
import {
    ThemeProvider,
    createTheme,
    Grid,
    Container,
    Box,
    Button,
    Divider,
    IconButton,
    Snackbar
} from '@mui/material'
import { useTitle } from 'react-use'
import { useParams } from "react-router-dom"
import { InfographicMetadataFull, InfographicThumbnail, RelatedDashboard } from '../../interfaces/Infographic'
import { bookmarkInfographic, getInfographicMetadataDetail, unbookmarkDashboard } from '../../services/data-connector'
import style from './InfographicDetail.module.css'
import ShareIcon from '@mui/icons-material/Share';
import Iframe from 'react-iframe'
import { formatDate } from '../../utils/formatDate'
import InfographicTagElement from '../../components/shared/InfographicTagElement/InfographicTagElement'
import BookmarkElement from '../../components/shared/BookmarkElement/BookmarkElement'
import LogInPopUp from '../../components/shared/LogInPopUp/LogInPopUp'
import { SnackbarMessage } from '../../interfaces/Snackbar'
import CloseIcon from '@mui/icons-material/Close';
import { mergeThemes } from '../../theme/merge'
import { dashboard_card } from '../../theme/dashboard'
import { snackbarTheme } from '../../theme/snackbar'
import RelatedDashboardBox from './RelatedDashboardBox/RelatedDashboardBox'
import { selectAccessToken, selectAccessTokenStatus, fetchAccessToken, selectAccessTokenError, selectExpirationAt } from '../../redux/features/authentication/authenticationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { STATUS } from '../../variable/enum'
import { getUserAccount } from '../../utils/login'
import InfographicCard from '../../components/shared/InfographicCard/InfographicCard'
import SkeletonPage from './SkeletonPage/SkeletonPage'
import SharePopup from '../../components/shared/SharePopup/SharePopup'
import { useHistory } from 'react-router-dom'
import Unauthorized from '../Unauthorized/Unauthorized'
import Forbidden from '../Forbidden/Forbidden'
import { selectInfographicMetadata, selectInfographicStatus, infographicMetadataAdded } from '../../redux/features/infographic/infographicSlice'
import { updateInfoMetaBookmark } from '../../utils/infographic'
import TVLButton from '../../components/shared/TVLButton/TVLButton'

const InfographicDetail = () => {
    const { id } = useParams<{ id: string }>()
    const INITIAL_INFO_METADATA = {
        infographicId: '',
        imgUrl: '',
        infographicTitle: '',
        tags: [],
        createdDate:'',
        embededLink: '',
        description: '',
        isBookmarked: false
    }
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [infographicMetadataFull, setInfographicMetadataFull] = useState<InfographicMetadataFull>(INITIAL_INFO_METADATA)
    const [relatedDashboards, setRelatedDashboards] = useState<RelatedDashboard[]>()
    const [otherInfographicMetadatas, setOtherInfographicMetadatas] = useState<InfographicThumbnail[]>([])
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isBookmarked, setIsBookmarked] = useState<boolean>(false)
    const user = getUserAccount()
    const history = useHistory()
    
    const dispatch = useDispatch<AppDispatch>()
    const accessToken = useSelector(selectAccessToken)
    const accessTokenStatus = useSelector(selectAccessTokenStatus)
    const accessTokenError = useSelector(selectAccessTokenError)
    const expiresAt = useSelector(selectExpirationAt)

    var title = "Travel Link | "
    if (isLoading) {
        title = title + "Loading"
    } else {
        title = title + infographicMetadataFull.infographicTitle
    }
    useTitle(title)


    const handleInfographicDetails = async () => {
        let {status, success, res} = await getInfographicMetadataDetail(accessToken, id)

        if (success) {
            let infographicMetadataFull: InfographicMetadataFull = res.infographicMetadata
            setInfographicMetadataFull(infographicMetadataFull)
            setRelatedDashboards(res.relatedDashboardMetadatas as RelatedDashboard[])
            setOtherInfographicMetadatas(res.otherInfographicMetadatas as InfographicThumbnail[])
            setIsBookmarked(infographicMetadataFull.isBookmarked)
            setIsLoading(false)
        } else {
            // possible errors are
            // 401 - invalid token + not login before accessing the site (token not given)
            // 404 - dashboard not found
            if(res.detail){
                setErrorMessage(res.detail)
            } else {
                setErrorMessage('เกิดปัญหาขัดข้อง กรุณาลองใหม่')
            }
            setIsLoading(false)
            setAPIStatus(status)
        }
    }

    const fetchData = useCallback(async () => {
        setIsLoading(true)
                // first time getting infographic metadata
                if(user.getIsLoggedIn() && accessTokenStatus === STATUS.IDLE){
                    dispatch(fetchAccessToken())
                } else if (accessTokenStatus === STATUS.SUCCEEDED) {
                        try {
                            if (expiresAt < Math.floor(Date.now() / 1000)) {                  
                                dispatch(fetchAccessToken());
                            }
                            else{
                                handleInfographicDetails()
                            }
                          } catch (error) {
                            // Handle error if any
                            console.log(error)
                          }
                } else if (!user.getIsLoggedIn() && isLoading){
                    handleInfographicDetails()
                }
            // setIsLoading(false)
        }, [accessToken])

    useEffect(() => {
        fetchData()
    }, [fetchData, accessToken])

    const mergedTheme = mergeThemes(snackbarTheme, dashboard_card)
    const theme = createTheme(mergedTheme)

    // Setup Snackbar
    
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false)
    const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>([]);
    const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(
        undefined
    )

    const handleSnackbarClose = () => {
        setOpenSnackBar(false)
    }

    const snackbarAction = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
    useEffect(() => {

      if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpenSnackBar(true);
        } else if (snackPack.length && messageInfo && openSnackBar) {
            // Close an active snack when a new one is added
            setOpenSnackBar(false);
        }
    }, [snackPack, messageInfo, openSnackBar]);


    const handleExited = () => {
        setMessageInfo(undefined)
    }

    // Setup LogInPopUp
    const [openLoginPopUp, setOpenLoginPopUp] = useState<boolean>(false)
    const [redirectUrl, setRedirectUrl] = useState<string>('')
    
    const handleLoginPopUpFromBookmark = () => {
        setRedirectUrl('/infographic/' + infographicMetadataFull.infographicId)
        setOpenLoginPopUp(true)
    }

    // SharePopUp
    const [openSharePopUp, setOpenSharePopUp] = useState<boolean>(false)
    const url = window.location.href

    // handle response
    const [APIStatus, setAPIStatus] = useState<number>(0)
    const FailedResponse = () => {
        return (
            <>
                {
                    isLoading ? 
                    (<SkeletonPage />)
                    :
                    APIStatus === 403 ?
                    (<Forbidden />)
                    : 
                    (<Unauthorized>{errorMessage}</Unauthorized>)
                }
            </>
            
        )
    }

    // make the redux sync with the database 

    const infographicMetadatasFull = useSelector(selectInfographicMetadata)
    const infographicStatus = useSelector(selectInfographicStatus)

    const setBookmarkInternal = (pageId: string) => {
        if(infographicStatus === STATUS.SUCCEEDED){
            let dashboardMetadataTmp = updateInfoMetaBookmark(infographicMetadatasFull, {infographicId: pageId, isBookmarked: true})
            dispatch(infographicMetadataAdded(dashboardMetadataTmp))
        }
    }

    const setUnbookmarkInternal = (pageId: string) => {
        if(infographicStatus === STATUS.SUCCEEDED){
            let dashboardMetadataTmp = updateInfoMetaBookmark(infographicMetadatasFull, {infographicId: pageId, isBookmarked: false})
            dispatch(infographicMetadataAdded(dashboardMetadataTmp))
        }
    }

  return (
    <Container>
        <LogInPopUp open={openLoginPopUp} setOpen={setOpenLoginPopUp} redirect_uri={redirectUrl} />
        <SharePopup open={openSharePopUp} setOpen={setOpenSharePopUp} />
        {
            isLoading || errorMessage ?
            <FailedResponse />
            :
            <ThemeProvider theme={theme}>
                <Box className={style.title} marginTop={5}>{infographicMetadataFull.infographicTitle}</Box>
                <Box display='flex' justifyContent='space-between' alignContent='center' marginBottom={2}>
                    <Box paddingTop='10px' display= 'flex' justifyContent="space-around" alignItems='center'>
                        {
                            infographicMetadataFull.tags.map((tag) => 
                            (<InfographicTagElement tag={tag} pathname='/infographic' />))
                        } 
                        <Divider orientation='vertical' flexItem sx={{ borderRightWidth: 2, borderColor:'rgba(0,0,0,0.5)', margin: "7px 0" }} />
                        <BookmarkElement 
                        pageId={infographicMetadataFull.infographicId}
                        isBookmarked={isBookmarked}
                        setIsBookmarked={setIsBookmarked}
                        setOpenSnackBar={setOpenSnackBar}
                        setSnackPack={setSnackPack}
                        urlLink=''
                        bookmarkTooltipText="ติดตามอินโฟกราฟิกนี้" 
                        unbookmarkTooltipText="เลิกติดตามอินโฟกราฟิกนี้"
                        bookmarkSnackbarText={`ติดตามอินโฟกราฟิก "${infographicMetadataFull.infographicTitle}" เรียบร้อยแล้ว`}
                        unbookmarkSnackbarText="เลิกติดตามอินโฟกราฟิกเรียบร้อยแล้ว"
                        callBookmarkAPI={bookmarkInfographic}
                        callUnbookmarkAPI={unbookmarkDashboard}
                        setBookmarkInternal={setBookmarkInternal}
                        setUnbookmarkInternal={setUnbookmarkInternal}
                        handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                        bookmarkIconStyle={{
                            padding: '0',
                            marginLeft: '7px'
                        }}
                        />

                    </Box>
                    <Box>
                        <TVLButton 
                        color='tertiary'
                        size='medium'
                        sx={{
                                padding: "8px 17px",
                            }} 
                            onClick={() => setOpenSharePopUp(true)}>
                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    <ShareIcon sx={{marginRight:'10px'}} /> แชร์
                                    
                                </Box>
                        </TVLButton>
                    </Box>
                </Box>
                <Grid container spacing={3} marginBottom={3}>
                    <Grid item xs = {12} sm = {12} md={9}>
                        <Box 
                        className={style.infoBox} 
                        width={{
                            md: "min(95vh,calc(75vw - 80.25px),831px)", 
                            sm: "calc(100vw - 99px)", 
                            xs: "calc(100vw - 99px)"
                            }}
                        height = {{
                            md: "min(95vh,calc(75vw - 80.25px), 831px)", 
                            sm: "calc(100vw - 99px)", 
                            xs: "calc(100vw - 99px)"
                            }} 
                        margin='auto'>
                        <Iframe 
                        url={infographicMetadataFull.embededLink}
                        styles={{width: '100%', height:'100%'}}
                        frameBorder={0}
                        />

                        </Box>
                        

                    </Grid>
                    <Grid item xs = {12} sm={12} md={3}>
                        <Box className={style.detailHeader} marginBottom={3}>
                            รายละเอียด
                        </Box>
                        <Box textAlign='left' display='flex' marginBottom={3}>
                            <Box className={style.header} width={100}>
                                จัดทำโดย:
                            </Box>
                            <Box flex={1} className={style.text}>
                                Travel Link
                            </Box>
                        </Box>
                        <Box textAlign='left' display='flex' marginBottom={3}>
                            <Box className={style.header} width={100}>
                                วันที่สร้าง:
                            </Box>
                            <Box flex={1} className={style.text}>
                                {formatDate(infographicMetadataFull.createdDate)}
                            </Box>
                        </Box>
                        <Box textAlign='left'>
                            <Box className={style.header} marginBottom={2}>
                                คำอธิบาย:
                            </Box>
                            <Box className={style.text}>
                                {infographicMetadataFull.description}
                            </Box>

                        </Box>

                    </Grid>
                </Grid>

                {/* Related Dashboards */}
                {
                    relatedDashboards && relatedDashboards.length > 0 ? 
                    <>
                        <Box className = {style.relatedHeader} marginBottom={2}>แดชบอร์ดที่เกี่ยวข้อง</Box>
                        <Grid container columnSpacing={2} marginBottom={3}>
                            {
                                relatedDashboards.map((relatedDashboard) => 
                                    <Grid item md={4} sm={6} xs={12}>
                                        <RelatedDashboardBox relatedDashboard={relatedDashboard}/>
                                    </Grid>
                                )
                            }
                            
                        </Grid>
                        
                    </>
                    :
                    <></>
                }
                <Divider orientation='horizontal' flexItem sx={{marginBottom:'30px'}}/>
                
                {/* Other Infographics */}
                <Grid display='flex' justifyContent='space-between' alignItems='flex-end' marginBottom={2} container>
                    <Grid className = {style.relatedHeader} item >อินโฟกราฟฟิกอื่น ๆ ที่น่าสนใจ</Grid>
                    <Grid className={style.ahref} onClick={() => history.push('/infographic')} item textAlign='right' md = {1} xs={12}>ดูทั้งหมด</Grid>
                </Grid>
                <Grid container spacing={3} justifyContent='flex-start' marginBottom={5}>
                    {
                        otherInfographicMetadatas.map((infographicMetadata) => (
                            <Grid item xs = {12} sm = {6} md = {4}>
                                <InfographicCard 
                                infographicMetadata={infographicMetadata}
                                searchText=''
                                setOpenSnackBar={setOpenSnackBar}
                                setSnackPack={setSnackPack}
                                handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                                pathname='/infographic'
                                />
                            </Grid>
                        ))   
                    }
                </Grid>


                {/* Snackbar */}
                <Snackbar
                key={messageInfo ? messageInfo.key : undefined}
                TransitionProps={{ onExited: handleExited }}
                message={messageInfo ? messageInfo.message : undefined}
                open={openSnackBar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                action={snackbarAction}/>

            </ThemeProvider>

    
}
    </Container>
    
  )
}

export default InfographicDetail