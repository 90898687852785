import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
// import jwt from "jsonwebtoken";
const { decodeJwt } = require("jose")

import { getAccessToken } from '../../../services/data-connector';

import { AccountInfo } from "../../../interfaces/AccountInfo";
import { RootState } from '../../store';

interface AuthState {
    access_token: string,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string
    expiresAt: number
}

const initialState: AuthState = {
    access_token: '',
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: '',
    expiresAt: 0
}

export const fetchAccessToken = createAsyncThunk<string, void>('authentication/fetchAccessToken', async (_,{rejectWithValue}) => {
    try{
        let token_list = document.cookie.split(";").filter((string) => string.includes("token="))
        let token = ''
        //check if logged in
        if(token_list.length != 0){
            token = token_list[0].replace("token=","").trim()
        }
        const { res, status } = await getAccessToken(token)
        if(status === 200){
            let access_token = res.access_token
            if(res.hasOwnProperty('refresh_token')){
                let refresh_token = res.refresh_token
                Cookies.set('token',refresh_token, {expires: 60})
                window.location.reload()
            }
            return access_token
        }
        else{
            return rejectWithValue(res.error)
        }
    }catch (err){
        return rejectWithValue((err as Error).message);
    }
})

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers:{
        access_tokenAdded(state, action) {
            state.access_token = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAccessToken.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchAccessToken.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Adding date and reactions
                state.access_token = action.payload
                const decodedToken = decodeJwt(action.payload) as AccountInfo;
                
                if (decodedToken && decodedToken.exp) {
                    state.expiresAt = decodedToken.exp
                } 
            })
            .addCase(fetchAccessToken.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message ? action.error.message : ''
            })
    }
})

export const selectAccessToken = (state: RootState) => state.authentication.access_token;

export const selectAccessTokenStatus = (state: RootState) => state.authentication.status

export const selectAccessTokenError = (state: RootState) => state.authentication.error

export const selectExpirationAt = (state: RootState) => state.authentication.expiresAt

export const { access_tokenAdded } = authenticationSlice.actions

export default authenticationSlice.reducer