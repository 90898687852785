import { ThemeOptions } from '@mui/material'

export const displayButton: ThemeOptions = {
    components:{
        MuiToggleButton:{
            variants:[
                {
                    props:{
                        selected: true,
                    },
                    style:{
                        'svg':{
                            stroke: 'white'
                        },
                        backgroundColor: '#0182FA !important'
                    }
                }
            ],
            styleOverrides:{
                root:{
                    stroke: '#515151',
                    border: '0',
                    borderRadius: '4px !important',
                    padding: '8px',
                    marginRight: '4px'
                }
            },
        }
    }
}