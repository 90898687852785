import React from "react"

import FooterFeaturesModule from "./FooterFeatures.module.css"
import { Box } from "@mui/material"

const FooterFeatures: React.FC = () => {
    return (
        <div className={FooterFeaturesModule.content}>
            <Box className={FooterFeaturesModule.header} marginBottom="22px">การใช้งาน</Box>
            <ul className={FooterFeaturesModule.nobull}>
                {/* <li className = {FooterFeaturesModule.link}><a className={FooterFeaturesModule.linktext} onClick = {()=>window.location.href="/#attraction-data"}>ดูตัวอย่างข้อมูล</a></li> */}
                <li className={FooterFeaturesModule.link}>
                    <a
                        className={FooterFeaturesModule.linktext}
                        href="/policies"
                    >
                        เงื่อนไขการใช้งาน
                    </a>
                </li>
                <li className={FooterFeaturesModule.link}>
                    <a className={FooterFeaturesModule.linktext} href="/faq">
                        ความช่วยเหลือ
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default FooterFeatures
