import { ThemeOptions } from "@mui/material";

export const tableTheme: ThemeOptions = {
    components:{
        MuiTableCell:{
            styleOverrides:{
                head:{
                    fontFamily: "Kanit",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#194B7E"
                },
                body:{
                    fontFamily: "Kanit",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    textAlign: "left",
                    color: "#515151"
                }
            }
        },
    }
}