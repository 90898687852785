import { ThemeOptions } from "@mui/material";

export const sortPopUpTheme: ThemeOptions = {
    components:{
        MuiRadio:{
            styleOverrides:{
                root:{
                    padding: '2px',
                    marginRight:'10px'
                }
            }
        },
        MuiCheckbox:{
            styleOverrides:{
                root:{
                    padding: '2px',
                    marginRight:'10px'
                }
            }
        },
        MuiFormControlLabel:{
            styleOverrides:{
                root:{
                    marginLeft:'-5px',
                    marginRight: '0'
                },
                label:{
                    padding:'5px 0'
                }
            }
        },
        MuiPopover:{
            styleOverrides:{
                paper:{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "0.5px solid #CBCBCB",
                    borderRadius: "12px"
                }
            }
        }
    }
}