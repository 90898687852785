import React, { useState, useEffect } from 'react'
import {
    Tooltip,
    Checkbox,
    Snackbar,
    ThemeProvider,
    createTheme
} from '@mui/material'
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import checkedfilled from '../../../assets/dashboard/CheckFilled.svg'
import crossfilled from '../../../assets/dashboard/CrossFilled.svg'
import style from './BookmarkElement.module.css'
import { SxProps } from '@mui/system';
import { fetchAccessToken, selectAccessToken, selectAccessTokenError, selectAccessTokenStatus, selectExpirationAt } from '../../../redux/features/authentication/authenticationSlice';
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store';
import { STATUS } from '../../../variable/enum';
import { SnackbarMessage } from '../../../interfaces/Snackbar';
import { getUserAccount } from '../../../utils/login';

interface BookmarkElementProps{
    // to be used for api call
    pageId: string
    // set bookmark on the card
    isBookmarked: boolean
    setIsBookmarked: React.Dispatch<boolean>,
    // for queueing the snackbar popup in the main page
    setOpenSnackBar: React.Dispatch<boolean>,
    setSnackPack: React.Dispatch<React.SetStateAction<readonly SnackbarMessage[]>>,
    // link to 
    urlLink: string,
    // set up text on tooltip
    bookmarkTooltipText: React.ReactNode,
    unbookmarkTooltipText: React.ReactNode,
    // set up text on snackbar
    bookmarkSnackbarText: string,
    unbookmarkSnackbarText: string,
    // set up style (sx) of the icon on the card
    bookmarkIconStyle?: SxProps<{}>,
    // bookmark function that calls the API
    callBookmarkAPI: (accessToken: string, pageId: any) => Promise<{
        success: boolean;
        res: any;
        status: any;
    }>,
    callUnbookmarkAPI: (accessToken: any, pageId: any) => Promise<{
        success: boolean;
        res: any;
        status: any;
    }>
    // recording bookmark action on the main page
    setBookmarkInternal: (infographicId: string) => void,
    setUnbookmarkInternal: (infographicId: string) => void
    // setLoginPopUp open over on the main page
    handleLoginPopUpFromBookmark: () => void
}

const BookmarkElement = ({
    pageId, 
    isBookmarked, 
    setIsBookmarked,
    setOpenSnackBar,
    setSnackPack,
    urlLink, 
    bookmarkTooltipText, 
    unbookmarkTooltipText, 
    bookmarkSnackbarText, 
    unbookmarkSnackbarText, 
    bookmarkIconStyle,
    callBookmarkAPI,
    callUnbookmarkAPI,
    setBookmarkInternal,
    setUnbookmarkInternal,
    handleLoginPopUpFromBookmark
}: BookmarkElementProps) => {
    const [isHover, setIsHover] = useState<boolean>(false)
    const accessToken = useSelector(selectAccessToken)
    const accessTokenStatus = useSelector(selectAccessTokenStatus)
    const accessTokenError = useSelector(selectAccessTokenError)
    const expiresAt = useSelector(selectExpirationAt)
    const isExpired = () => (expiresAt < Math.floor(Date.now() / 1000)) 
    const dispatch = useDispatch<AppDispatch>()
    const unchecked_icon = (isHover: boolean) => (
        isHover ? 
        <Tooltip title={bookmarkTooltipText} arrow>
            <BookmarkAddIcon sx={{color:'rgba(81, 81, 81, 0.7)' , zIndex: "1"}} fontSize='large'/> 
        </Tooltip>
        :
        <BookmarkAddOutlinedIcon fontSize='large'/>
    )

    const checked_icon = (isHover: boolean) => (
        isHover ?
        <Tooltip title={unbookmarkTooltipText} arrow>
            <BookmarkRemoveIcon sx={{color:'rgba(81, 81, 81, 0.7)', zIndex: "1"}} fontSize='large'/> 
        </Tooltip>
        :
        
        <BookmarkIcon sx={{color:'rgba(25, 75, 126, 1)'}} fontSize='large'/> 
    )

    const handleBookmarkAPICall = (accessToken: string) => {
        if(isBookmarked){
            // unbookmark the page
            callUnbookmarkAPI(accessToken, pageId).then(({success}) => {
                if(success){
                    let message = 
                    <span style={{display: 'flex', alignItems:'center'}}>
                        <img src={checkedfilled} style={{width:'24px', height:'24px', marginRight:'10px'}}></img>
                        {unbookmarkSnackbarText}
                    </span>
                    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }])
                    setUnbookmarkInternal(pageId)
                }else{
                    let message = 
                    <span style={{display: 'flex', alignItems:'center'}}>
                        <img src={crossfilled} style={{width:'24px', height:'24px', marginRight:'10px'}}></img>
                        เกิดปัญหาบางอย่าง กรุณาลองใหม่
                    </span>
                    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }])
                    setIsBookmarked(isBookmarked)
                }
            }).finally(() => {
                setOpenSnackBar(true)
            })
        } else{
            // bookmark the page
            callBookmarkAPI(accessToken, pageId).then(({success}) => {
                if(success){
                    let message = 
                    <span style={{display: 'flex', alignItems:'center'}}>
                        <img src={checkedfilled} style={{width:'24px', height:'24px', marginRight:'10px'}}></img>
                        {bookmarkSnackbarText}
                        {urlLink ? 
                        <a href={urlLink} target='_blank' style ={{marginLeft: '15px', marginRight: '-15px'}}>
                            <span  className={style.ahref}>
                                ดูเลย
                            </span>
                        </a>
                        :
                        <></>
                        }
                        
                    </span>
                    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }])
                    setBookmarkInternal(pageId)
                }else{
                    let message = 
                    <span style={{display: 'flex', alignItems:'center'}}>
                        <img src={crossfilled} style={{width:'24px', height:'24px', marginRight:'10px'}}></img>
                        เกิดปัญหาบางอย่าง กรุณาลองใหม่
                    </span>
                    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }])
                    setIsBookmarked(isBookmarked)
                }
            }).finally(() => {
                setOpenSnackBar(true)
            })
        }

    }

    const user = getUserAccount()

    const handleClickBookmark = () => {
        if(user.getIsLoggedIn()){
            setIsBookmarked(!isBookmarked)
            setIsHover(false)
            if(accessTokenStatus === STATUS.SUCCEEDED){
                if(isExpired()){
                    dispatch(fetchAccessToken()).unwrap().then((accessTokenTmp) => {
                        handleBookmarkAPICall(accessTokenTmp)
                    })
                } else {
                    handleBookmarkAPICall(accessToken)
                }
            }
        } else {
            handleLoginPopUpFromBookmark()
        }
        
    }
    function clickHandler(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault()
        e.stopPropagation()
  }

  const theme = createTheme({
    components:{
        MuiTooltip:{
            styleOverrides:{
                tooltip:{
                    
                    fontFamily: 'Kanit',
                    fontSize: '14px',
                    fontWeight: '300',
                    lineHeight: '14px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                    padding: "7px 12.5px"
                }
            }
        }
    }
  })
    
  return (
    <div onClick={clickHandler}>
        <ThemeProvider theme={theme}>
            <Checkbox
            onMouseEnter={()=>setIsHover(true)}
            onMouseLeave={()=>setIsHover(false)}
            className = {style.bookmark}
            checked={isBookmarked}
            disableRipple
            checkedIcon={checked_icon(isHover)}
            icon={unchecked_icon(isHover)}
            onClick={handleClickBookmark} 
            sx={bookmarkIconStyle}/>
        </ThemeProvider>
    </div>
        
  )
}

export default BookmarkElement