import React from 'react'
import ThankyouPopup from './ThankyouPopup.module.css'
import { Grid, Dialog, DialogContent, createTheme, ThemeProvider } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import thankyouimg from './../../../assets/home/Thank you.svg'
import Button from '../Button/Button'
import { thank_you_theme } from '../../../theme/thankyouDialog'

interface ThankyouProps {
    message: string;
    onClick:  React.MouseEventHandler;
}

const theme = createTheme(thank_you_theme)

function Thankyou(props: ThankyouProps) {

    return (
        <ThemeProvider theme={theme}>
            <Dialog open maxWidth='md'>
                <CloseRoundedIcon className={ThankyouPopup.close_btn_white} onClick={props.onClick} />
                <img src={thankyouimg} className = {ThankyouPopup.image} />
                <p className={ThankyouPopup.tytext1}>ขอบคุณในความสนใจของท่าน</p>
                <p className={ThankyouPopup.tytext2}>{props.message}</p>
                <Grid className={ThankyouPopup.line_wrapper} container >
                    <Grid item md = {6} sm = {8} className={ThankyouPopup.line_invitation_text} p = {{md:2, sm: 2, xs: 0}}>เพื่อความสะดวกในการติดต่อ <span style={{whiteSpace:'nowrap'}}>Travel Link</span> สามารถพูดคุยกับเราได้ทาง Line Official Account</Grid>
                    <Grid item md = {3} sm = {4} p = {{md:2, sm: 2, xs: 0}}><a href="https://lin.ee/2UhQtlT" target='_blank'><img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" className={ThankyouPopup.line_img} /></a></Grid>

                </Grid>
                <Button secondary={true} onClick={props.onClick} size='big'>กลับสู่หน้าหลัก</Button>
            </Dialog>

        </ThemeProvider>
        
    )
}

export default Thankyou
