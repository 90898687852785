import React, { useEffect, useState, useCallback } from "react"
import Unauthorized from "../Unauthorized/Unauthorized"
// import jwt from "jsonwebtoken"
const { decodeJwt } = require("jose")
import { useHistory } from "react-router-dom"
import EastRoundedIcon from "@mui/icons-material/EastRounded"
import { Grid } from "@mui/material"
import { pantip } from "../../assets/dashboard/index"
import { useSelector, useDispatch } from "react-redux"
import {
    selectDashboardMetadata,
    selectDashboardStatus,
    fetchDashboard,
    selectDashboardError,
} from "../../redux/features/dashboard/dashboardSlice"
import {
    selectAccessToken,
    selectAccessTokenError,
    selectAccessTokenStatus,
    fetchAccessToken,
    selectExpirationAt,
} from "../../redux/features/authentication/authenticationSlice"
import DASHBOARD_STATUS from "../../variable/enum"
import dashboard_tag from "../../variable/dashboard_tag.json"

import PrivilegeModule from "./Privilege.module.css"
import Loading from "../Loading/Loading"
import ExpiredPopUp from "../../components/shared/ExpiredPopUp/ExpiredPopUp"
import { useTitle } from "react-use"
import CustomContainer from "../../components/shared/CustomContainer/CustomContainer"
import { AppDispatch } from "../../redux/store"
import { getUserAccount } from "../../utils/login"
import Forbidden from "../Forbidden/Forbidden"

function Privilege() {
    useTitle("Travel Link | Privilege")
    const user = getUserAccount()
    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const [isLoading, setIsLoading] = useState<Boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const dashboardStatus = useSelector(selectDashboardStatus)
    const dashboardMetadata = useSelector(selectDashboardMetadata)
    const dashboardError = useSelector(selectDashboardError)

    const accessToken = useSelector(selectAccessToken)
    const accessTokenStatus = useSelector(selectAccessTokenStatus)
    const accessTokenError = useSelector(selectAccessTokenError)
    const expiresAt = useSelector(selectExpirationAt)

    const fetchData = useCallback(() => {
        // first time getting dashboard_metadata
        if (user.getIsLoggedIn() && accessTokenStatus === DASHBOARD_STATUS.IDLE) {
            dispatch(fetchAccessToken());
        } else if (
            accessTokenStatus === DASHBOARD_STATUS.SUCCEEDED &&
            dashboardStatus === DASHBOARD_STATUS.IDLE
        ) {
            try {
                if (expiresAt < Math.floor(Date.now() / 1000)) {
                    dispatch(fetchAccessToken())
                } else {
                    // dispatch(fetchDashboard(accessToken));
                    dispatch(fetchDashboard(accessToken))
                }
            } catch (error) {
                // Handle error if any
                console.log(error)
            }
            // already has dashboard_metadata
        } else if (dashboardStatus === DASHBOARD_STATUS.SUCCEEDED) {
            setIsLoading(false)
            // Dashboard API error
        } else if (dashboardStatus === DASHBOARD_STATUS.FAILED) {
            setIsLoading(false)
            setIsOpen(true)
            setIsError(true)
            setErrorMessage(dashboardError)
            // Access Token API error
        } else if (accessTokenStatus === DASHBOARD_STATUS.FAILED) {
            setIsLoading(false)
            setIsError(true)
            setIsOpen(true)
            setErrorMessage(accessTokenError)
        }
    }, [accessToken, dashboardMetadata])

    useEffect(() => {
        fetchData()
    }, [fetchData, dashboardMetadata])

    if (!user.getIsPrivileged()) {
        return <Forbidden />
    }

    let role_string =
        "ปัจจุบันท่านได้รับสิทธิพิเศษในการเข้าถึงข้อมูล " +
        user.getApprovedOrg!().length +
        " หน่วยงาน ได้แก่ " +
        user.getApprovedOrg!().join(", ") +
        " ด้วยสิทธิ " +
        user.getAllApprovedRoles!().join(", ") +
        " หากสิทธิไม่ถูกต้องโปรดไปยัง"

    const count_privilege_dashboards = dashboardMetadata
        .map(({ tags, galleryPages }) => {
            let count = 0
            if (tags.includes("แดชบอร์ดพิเศษ")) {
                count += galleryPages.length
            }
            return count
        })
        .reduce((a, b) => a + b, 0)

    const handleClick = (category: string) => {
        let payload: {
            pathname: string
            state: { dashboardType: string }
            search?: string
        } = {
            pathname: "/dashboard",
            state: {
                dashboardType: category,
            },
        }
        if (category !== "ทั้งหมด") {
            payload["search"] =
                "?dashboardType=" +
                dashboard_tag.find(
                    ({ category_thai }: { category_thai: string }) =>
                        category_thai === category
                )!["category_eng"]
        }
        history.push(payload)
        window.scrollTo(0,0)
    }

    return (
        <>
            { isLoading ? (
                <Loading />
            ) : (
                <CustomContainer>
                    <div className={PrivilegeModule.wrapper}>
                        <ExpiredPopUp isError={isError} error={errorMessage} />
                        <div className={PrivilegeModule.header}>สิทธิพิเศษ</div>

                        <div className={PrivilegeModule.subheader}>
                            สิทธิพิเศษในปัจจุบัน
                        </div>
                        <div className={PrivilegeModule.text}>
                            {" "}
                            {role_string}{" "}
                            <a href="/faq" style={{ color: "#4183c4" }}>
                                ความช่วยเหลือ
                            </a>
                        </div>

                        <div className={PrivilegeModule.subheader}>
                            สิทธิพิเศษที่ได้รับ
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <img src={pantip} style={{width:'100%'}}/>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                sx={{
                                    verticalAlign: "middle",
                                    alignSelf: "center",
                                }}
                            >
                                <div className={PrivilegeModule.title}>
                                    แดชบอร์ดพิเศษ
                                </div>
                                <div className={PrivilegeModule.subtitle}>
                                    มีความละเอียดของข้อมูลที่มากขึ้น{" "}
                                    {dashboardStatus ===
                                        DASHBOARD_STATUS.SUCCEEDED &&
                                        count_privilege_dashboards}{" "}
                                    แดชบอร์ด จากข้อมูลในแต่ละหน่วยงาน
                                    <br />
                                    <a
                                        onClick={() => {
                                            handleClick("แดชบอร์ดพิเศษ")
                                        }}
                                        className={PrivilegeModule.ahref}
                                    >
                                        ดูแดชบอร์ด
                                        
                                        <EastRoundedIcon
                                            sx={{
                                                verticalAlign: "middle",
                                                paddingBottom: "2px",
                                                marginLeft:"5px"
                                            }}
                                        />
                                    </a>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </CustomContainer>
            )}
        </>
    )
}

export default Privilege
