import React from 'react'
import CustomContainer from '../../../components/shared/CustomContainer/CustomContainer'
import { Grid } from '@mui/material'

import valueimg from '../../../assets/mission/Pic/value.png'

import ValueModule from './Value.module.css'

function Value() {
    return (
        <div className={ValueModule.wrapper}  >
            <CustomContainer>
                <Grid container sx = {{display: {xs: 'none', sm: 'flex', md: 'flex'}}}>
                    <Grid sm = {6} md={6} sx = {{verticalAlign: 'middle', alignSelf: 'center'}}>
                        <p className={ValueModule.text_header}>อะไรคือคุณค่าของเรา</p>
                        <p className={ValueModule.text}>ให้บริการข้อมูลด้านการท่องเที่ยวที่มีคุณภาพ เอกภาพ ครบถ้วน สมบูรณ์ เข้าถึงได้สะดวกง่ายดายด้วยเทคโนโลยีดิจิทัล </p>
                    </Grid>
                    <Grid sm = {1} md={1} />
                    <Grid sm = {5} md={5} sx = {{margin: '20px auto'}}>
                        <img style = {{objectFit:'contain', width: '100%'}} src={valueimg}/>
                    </Grid>


                </Grid>
                <Grid container sx = {{display: {xs: 'flex', sm:'none', md: 'none'}}}>
                    <Grid xs={10} sx = {{margin: '20px auto'}}>
                        <img src={valueimg} style={{width:'100%'}}/>
                    </Grid>
                    <Grid xs={12} sx = {{verticalAlign: 'middle', alignSelf: 'center'}}>
                        <p className={ValueModule.text_header}>อะไรคือคุณค่าของเรา</p>
                        <p className={ValueModule.text}>ให้บริการข้อมูลด้านการท่องเที่ยวที่มีคุณภาพ เอกภาพ ครบถ้วน สมบูรณ์ เข้าถึงได้สะดวกง่ายดายด้วยเทคโนโลยีดิจิทัล </p>
                    </Grid>
                </Grid>
            </CustomContainer>
        </div>
    )
}

export default Value
