import React from "react"
import TVLButton from "../../../components/shared/TVLButton/TVLButton"

import FooterAboutUsModule from "./FooterAboutUs.module.css"
import { Box } from "@mui/material"
import { getUserAccount } from "../../../utils/login"

const FooterAboutUs: React.FC = () => {
    const user = getUserAccount()
    return (
        <div className={FooterAboutUsModule.content}>
            <Box className={FooterAboutUsModule.header} marginBottom="22px">เกี่ยวกับเรา</Box>
            <ul className={FooterAboutUsModule.nobull}>
                <li className={FooterAboutUsModule.link}>
                    <a
                        className={FooterAboutUsModule.linktext}
                        onClick={() =>
                            (window.location.href = "/about-us#vision")
                        }
                    >
                        Mission ของโครงการ
                    </a>
                </li>
                <li className={FooterAboutUsModule.link}>
                    <a
                        className={FooterAboutUsModule.linktext}
                        onClick={() => (window.location.href = "/about-us#why")}
                    >
                        ทำไมถึงเกิดโครงการนี้
                    </a>
                </li>
                <li className={FooterAboutUsModule.link}>
                    <a
                        className={FooterAboutUsModule.linktext}
                        onClick={() =>
                            (window.location.href = "/about-us#value")
                        }
                    >
                        อะไรคือคุณค่าของเรา
                    </a>
                </li>
                <li className={FooterAboutUsModule.link}>
                    <a
                        className={FooterAboutUsModule.linktext}
                        onClick={() =>
                            (window.location.href = "/about-us#partner")
                        }
                    >
                        พันธมิตร
                    </a>
                </li>
                <li className={FooterAboutUsModule.link}>
                    <a
                        className={FooterAboutUsModule.linktext}
                        onClick={() => (window.location.href = "/contact")}
                    >
                        ติดต่อเรา
                    </a>
                </li>
            </ul>
            {
                user.getIsLoggedIn() ?
                <></>
                :
                <a href="/registration">
                    <TVLButton
                        size="large"
                        lean
                        sx = {{fontSize:'16px', fontWeight: '500'}}
                    >
                        ลงทะเบียน
                    </TVLButton>
                </a>
            }
            
        </div>
    )
}

export default FooterAboutUs
