import React from 'react'
import {  Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
// import style from './AccordionTablet.module.css'

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface ListDetail {
    key: string,
    name: React.ReactNode,
    ahref?: string,
    handleClick?: () => void,
    isShowing?: boolean,
    isNewTab?: boolean,
    isDisabled?: boolean
}

interface CollapsibleListProps {
    header: string,
    HeaderIcon: React.ReactNode,
    setOpenDrawer: React.Dispatch<string>,
    activeAccordion: string,
    setActiveAccordion: React.Dispatch<string>,
    options: ListDetail[]
}

const CollapsibleList = ({
    header,
    activeAccordion,
    setOpenDrawer,
    setActiveAccordion,
    HeaderIcon,
    options
}: CollapsibleListProps) => {

    const ListItemElement = ({
        name,
        ahref,
        handleClick,
        isShowing,
        isNewTab,
        isDisabled
    }: ListDetail) => {
        if(isShowing === false){
            return <></>
        } else if (isDisabled){
            return (
                <ListItemButton sx={{ pl: 9 }} disabled>
                    <ListItemText primary={
                    <>
                        {name}
                        <sup>
                            เร็วๆนี้
                        </sup>
                    </>} />
                </ListItemButton>
            )
        } else if (ahref) {
            if(isNewTab){
                return (
                    <ListItemButton 
                    disableRipple
                    href={ahref}
                    target='_blank'
                    sx={{ pl: 9 }}
                    onClick={() => setActiveAccordion("")}
                    >
                        <ListItemText primary={name} />
                    </ListItemButton>
                );

            }
            return (
                <ListItemButton 
                    disableRipple
                    href={ahref}
                    sx={{ pl: 9 }}
                    onClick={() => {
                        setActiveAccordion("")
                        setOpenDrawer("")
                    }}
                    >
                        <ListItemText primary={name} />
                    </ListItemButton>
            );
        } else {
            // onClick
            return (
                <ListItemButton 
                    disableRipple
                    sx={{ pl: 9 }}
                    onClick={() => {
                        handleClick!()
                        setActiveAccordion("")
                        setOpenDrawer("")
                    }}
                    >
                        <ListItemText primary={name} />
                    </ListItemButton>
                
            );
        }
    }
    const isSelected = activeAccordion === header

    const handleSummaryClick = () => {
        if(isSelected) {
            setActiveAccordion("")
        } else {
            setActiveAccordion(header)
        }
    }
  return (
    <List>
        <ListItemButton 
        disableRipple
        selected={isSelected} 
        onClick={handleSummaryClick}
        sx={{
            "&.Mui-selected, &.Mui-selected:hover":{
                background: '#F1F7FD',
                color: '#0182FA',
                fill: '#0182FA'
            },
        }}>
            <ListItemIcon
            sx={
                isSelected ? {
                    "&.MuiListItemIcon-root > .MuiSvgIcon-root":{
                        fill: '#0182FA !important',
                        transition: 'none'
                    }
             } : {
                "&.MuiListItemIcon-root > .MuiSvgIcon-root":{
                    transition: 'none'
                }
             }
            }>
                {HeaderIcon}
            </ListItemIcon>
            <ListItemText primary={header} sx={isSelected ? {color: '#0182FA'} : {}}/>
            {isSelected ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isSelected} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {options.map(
                    (listDetail) =>
                        (
                        <ListItemElement {...listDetail} />
                        )
                )}
            </List>
        </Collapse>
    </List>
  )
}

export default CollapsibleList