import { ThemeOptions } from '@mui/material'

export const relatedDashboardTheme: ThemeOptions = {
    components:{
        MuiCard:{
            styleOverrides:{
                root:{
                    padding:'13px',
                    border: '1px solid #CBCBCB',
                    borderRadius:'12px',
                    cursor: 'pointer',
                    marginBottom:'20px',
                    boxShadow: 'none',
                    '&:hover':{
                        boxShadow: 'none',
                        backgroundColor:'#F1F7FD',
                        border: '1px solid #0182FA'
                    },

                    display:'flex',
                    justifyContent:'space-between',
                    alignItems: 'flex-start',
                }
            }
        },
    }
}