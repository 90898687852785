import React from 'react'
import { Typography, ThemeProvider, createTheme } from '@mui/material'
import { typography_theme } from '../../../theme/typography'
import {ReactComponent as ErrorSign} from '../../../assets/register-member/Error.svg'

const ErrorElement = ({errorMessage, size}: {errorMessage: string, size?: string}) => {
  
  return (
    <div style = {{marginTop: '10px'}}>
      <ThemeProvider theme = {typography_theme}>
        <Typography variant = 'formHelperText' color = 'error' sx={size==='big' ? {fontSize:'16px'}: {}}>
          <ErrorSign style = {{width: '20px', height:'20px',verticalAlign: 'top', marginRight: '10px', fill:"#FF3B3B"}} />{errorMessage}
      
        </Typography>

      </ThemeProvider>
      
      </div> 
  )
}

export default ErrorElement