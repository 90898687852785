import { ThemeOptions } from '@mui/material'

const basetheme: ThemeOptions = {
    components:{
        MuiPaper:{
            styleOverrides:{
                root:{
                    textAlign: 'center',
                    width: '620px',
                    
                }
            }
        },
        MuiDialogContent:{
            styleOverrides:{
                root:{
                    
                }
            }
        }
    },
    
}

export const response_theme = basetheme