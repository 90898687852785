import React, { useState, useEffect, useCallback } from 'react'
import {
    Container,
    ThemeProvider,
    Box,
    createTheme,
    Tabs,
    Tab,
    Popover,
    FormControlLabel,
    Radio,
    RadioGroup,
    Grid,
    IconButton,
    Snackbar
} from '@mui/material'
import { useLocation, useTitle } from 'react-use'
import LogInPopUp from '../../components/shared/LogInPopUp/LogInPopUp'
import FilterBar from './FilterBar/FilterBar'
import style from './BookmarkPage.module.css'
import { mergeThemes } from '../../theme/merge'
import { dashboard_card, select_theme, toggle_button } from '../../theme/dashboard'
import { DashboardCategoryEng, DashboardCategoryThai, DashboardTag } from '../../interfaces/DashboardTag'
import { Sorting } from '../../interfaces/FilterTypes'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { selectAccessToken, selectAccessTokenError, selectAccessTokenStatus, selectExpirationAt, fetchAccessToken } from '../../redux/features/authentication/authenticationSlice';
import { selectDashboardMetadata, selectDashboardError, selectDashboardStatus, fetchDashboard, dashboardMetadataAdded } from '../../redux/features/dashboard/dashboardSlice';
import { DashboardGalleryCard } from '../../interfaces/DashboardMetadata'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { tabTheme } from '../../theme/bookmarkPage'
import { prepBookmarkedDashboardMetadatas, updateDashboardMetadataBookmark } from '../../utils/dashboard'
import { STATUS } from '../../variable/enum'
import { getUserAccount } from '../../utils/login'
import PaginationElement from '../../components/shared/PaginationElement/PaginationElement'
import DashboardCard from '../../components/shared/DashboardCard/DashboardCard'
import { SnackbarMessage } from '../../interfaces/Snackbar'
import CloseIcon from '@mui/icons-material/Close';
import { textbox_theme } from '../../theme/textbox'
import { register_textbox_theme } from '../../theme/register'
import DashboardSkeleton from '../../components/shared/DashboardSkeleton/DashboardSkeleton'
import InfographicCardSkeleton from '../../components/shared/InfographicCardSkeleton/InfographicCardSkeleton'
import { InfographicCardType } from '../../interfaces/Infographic'
import { prepBookmarkInfographicMetadata } from '../../utils/infographic'
import InfographicCard from '../../components/shared/InfographicCard/InfographicCard'
import { fetchInfographic, selectInfographicError, selectInfographicMetadata, selectInfographicStatus } from '../../redux/features/infographic/infographicSlice'
import { Location } from 'history';
import { useHistory } from 'react-router-dom'
import { accordionTheme } from '../../theme/filterBar'
import { sortingTypeItems } from '../../variable/filterType'
const dashboard_tag: DashboardTag[] = require('../../variable/dashboard_tag.json')

const BookmarkPage = ({location}: {location: Location<{category: DashboardCategoryEng, tabSelection: "dashboard" | "infographic"}>}) => {
    const [openLoginPopUp, setOpenLoginPopUp] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [activeTag, setActiveTag] = useState<DashboardCategoryThai>('ทั้งหมด')
    const [activeTagList, setActiveTagList] = useState<DashboardCategoryThai[]>(dashboard_tag.map(({category_thai}) => category_thai))
    const [searchText, setSearchText] = useState<string>('')
    const [tabSelection, setTabSelection] = useState<"dashboard" | "infographic">("dashboard")
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [isParamsSet, setIsParamsSet] = useState<boolean>(false)
    const [sorting, setSorting] = useState<Sorting>("ความนิยม")
    const dispatch = useDispatch<AppDispatch>()
    const accessToken = useSelector(selectAccessToken)
    const accessTokenStatus = useSelector(selectAccessTokenStatus)
    const accessTokenError = useSelector(selectAccessTokenError)
    const expiresAt = useSelector(selectExpirationAt)
    const dashboardMetadatasFull = useSelector(selectDashboardMetadata)
    const dashboardError = useSelector(selectDashboardError)
    const dashboardStatus = useSelector(selectDashboardStatus)
    const [dashboardMetadatas, setDashboardMetadatas] = useState<DashboardGalleryCard[]>([])
    const infographicMetadatasFull = useSelector(selectInfographicMetadata)
    const infographicStatus = useSelector(selectInfographicStatus)
    const infographicError = useSelector(selectInfographicError)
    const [infographicMetadatas, setInfographicMetadatas] = useState<InfographicCardType[]>([])
    const user = getUserAccount()
    const history = useHistory()
    // category selected from NavBar, TagElement
    useEffect(() => {
        if (location.state) {
            if(location.state.category){
                let queryTag = dashboard_tag.find(({category_eng}) => category_eng === location.state.category) 
                if(queryTag){
                    setActiveTag(queryTag.category_thai)
                }
            }
            if(location.state.tabSelection){
                setTabSelection(location.state.tabSelection)
            }
        }
    }, [location])

    // handle pagination
    const PAGE_SIZE = 9
    const [offset, setOffset] = useState<number>(0)

    const handlePaginationChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        let pageNo = offset/PAGE_SIZE + 1
        if(pageNo !== value){
            const searchElement = document.querySelector('.MuiInputBase-root')    
            setIsLoading(true)
            searchElement?.scrollIntoView({behavior:'smooth',block:'center'})
            setOffset((value-1)*PAGE_SIZE)
            setIsLoading(false)
        }
    }

    // handle loading dashboard metadata
    const handleGetDashboardMetadata = (accessToken: string) => {
        let activeTagTmp = "ทั้งหมด"
        if(location.state){
            if(location.state.category){
                let queryTag = dashboard_tag.find(({category_eng}) => category_eng === location.state.category) 
                if(queryTag){
                    activeTagTmp = queryTag.category_thai
                }
            }
        }
        if(dashboardStatus === STATUS.IDLE){
            dispatch(fetchDashboard(accessToken)).unwrap()
            .then((dashboardMetadatasFullTmp) => {
                // first initialize of the dashboard metadatas
                let dashboardMetadatasTmp = prepBookmarkedDashboardMetadatas(dashboardMetadatasFullTmp,"", activeTagTmp, "ความนิยม")
                setDashboardMetadatas(dashboardMetadatasTmp)
                setIsLoading(false)
                setIsParamsSet(true)
            })
            .catch((error) => {
                setErrorMessage("เกิดปัญหาบางอย่าง กรุณาลองใหม่")
            })
        } else if(dashboardStatus !== STATUS.SUCCEEDED){
                setErrorMessage("เกิดปัญหาบางอย่าง กรุณาลองใหม่")
        } else {
            // first initialize of the dashboard metadatas
            let dashboardMetadatasTmp = prepBookmarkedDashboardMetadatas(dashboardMetadatasFull,"", activeTagTmp, "ความนิยม")
            setDashboardMetadatas(dashboardMetadatasTmp)
            setIsLoading(false)
            setIsParamsSet(true)
        }
    }

    // handle loading dashboard metadata
    const handleGetInfographicMetadata = async (accessToken: string) => {
        let activeTagTmp = "ทั้งหมด"
        if(location.state){
            let queryTag = dashboard_tag.find(({category_eng}) => category_eng === location.state.category) 
            if(queryTag){
                activeTagTmp = queryTag.category_thai
            }
        }
        if(infographicStatus === STATUS.IDLE){
            dispatch(fetchInfographic(accessToken)).unwrap()
            .then((infographicMetadatasFullTmp) => {
                // first initialize of the dashboard metadatas
                let infographicMetadatasTmp = prepBookmarkInfographicMetadata(infographicMetadatasFullTmp,"", activeTagTmp, "ความนิยม")
                setInfographicMetadatas(infographicMetadatasTmp)
                setIsLoading(false)
                setIsParamsSet(true)
            })
            .catch((error) => {
                setErrorMessage("เกิดปัญหาบางอย่าง กรุณาลองใหม่")
            })
        } else if(infographicStatus !== STATUS.SUCCEEDED){
            setErrorMessage("เกิดปัญหาบางอย่าง กรุณาลองใหม่")
        } else {
            // first initialize of the dashboard metadatas
            let infographicMetadatasTmp = prepBookmarkInfographicMetadata(infographicMetadatasFull,"", activeTagTmp, "ความนิยม")
            setInfographicMetadatas(infographicMetadatasTmp)
            setIsLoading(false)
            setIsParamsSet(true)
        }
    }

    const fetchData = useCallback(async () => {
        setIsLoading(true)
                // first time getting dashboard metadata
                if(user.getIsLoggedIn() && accessTokenStatus === STATUS.IDLE){
                    dispatch(fetchAccessToken())
                } else if (accessTokenStatus === STATUS.SUCCEEDED) {
                        try {
                            if (expiresAt < Math.floor(Date.now() / 1000)) {                  
                                dispatch(fetchAccessToken());
                            }
                            else{
                                handleGetDashboardMetadata(accessToken)
                                handleGetInfographicMetadata(accessToken)
                            }
                          } catch (error) {
                            // Handle error if any
                            console.log(error)
                          }
                // already has dashboard_metadata
                } else if (!user.getIsLoggedIn() && dashboardMetadatasFull.length === 0){
                    setOpenLoginPopUp(true)
                }
        }, [accessToken])

    useEffect(() => {
        fetchData()
    }, [fetchData, accessToken])

    const mergedTheme = mergeThemes(textbox_theme, register_textbox_theme, dashboard_card, toggle_button, select_theme, tabTheme)
    const theme = createTheme(mergedTheme)

    // change site title
    useTitle(
        `Travel Link | Bookmark`
    )

    const SortingMenu = () => {

        const [sortToggle, setSortToggle] = useState<boolean>(false)
  
        const handlePopoverClick = (event: React.MouseEvent<HTMLElement>) => {
          // Prevent the click event from reaching the parent elements (including the button)
          event.stopPropagation();
        };
  
        const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  
        const handleClose = () => {
            setSortToggle(false)
            setAnchorEl(null)
        }
  
        const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
            setSortToggle(!sortToggle)
            setAnchorEl(event.currentTarget)
        }
        const theme = createTheme(accordionTheme)
        return(
        <ThemeProvider theme={theme}>
            <Box display='flex' alignItems="center">
                <Box display={{md:'flex', sm:'flex',xs:'none'}} className={style.sort}>
                    เรียงตาม: 
                <Box onClick={handleOpen} className={style.underline} display='flex' justifyContent='center' alignItems='center'>
                {sorting}
                {
                    sortToggle ? 
                    <ArrowDropUpIcon sx={{color:'rgba(0, 0, 0, 0.54)'}}/>
                    :
                    <ArrowDropDownIcon sx={{color:'rgba(0, 0, 0, 0.54)'}} />
                }
                </Box>
                
                <Popover 
                open={sortToggle}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    sx={{
                    '& .MuiPopover-paper':{
                        borderRadius: '12px'
                    }
                    }}>
                    <Grid container onClick={handlePopoverClick} sx={{
                        padding: '10px 5px',
                    }}>
                        <Grid item>
                            <div className={style.optionHeader}>
                                เรียงตาม
                            </div>
                            <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={sorting}
                            onChange={(e,v) => {
                                setSorting(v as Sorting)
                                setSortToggle(false)
                            }}
                            >  
                            <FormControlLabel value="ความนิยม" control={<Radio disableRipple />} label={<span className={style.option}>ความนิยม</span>} sx={{marginLeft:'-5px'}} />
                            <FormControlLabel value="ใหม่" control={<Radio disableRipple/>} label={<span  className={style.option}>ใหม่</span>} sx={{marginLeft:'-5px'}} />
                            <FormControlLabel value="ตัวอักษร ก-ฮ" control={<Radio disableRipple/>} label={<span  className={style.option}>ตัวอักษร ก-ฮ</span>} sx={{marginLeft:'-5px'}} />
                            <FormControlLabel value="ตัวอักษร ฮ-ก" control={<Radio disableRipple/>} label={<span  className={style.option}>ตัวอักษร ฮ-ก</span>} sx={{marginLeft:'-5px'}} />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Popover>
                </Box>
            </Box>

        </ThemeProvider>
      )}

    const handleTagChange = (category: DashboardCategoryThai) => {
        setActiveTag(category)
    }

    // delay searchInput
    const [searchInput, setSearchInput] = useState<string>('');

    const handleSearchTextChange = (searchInput: string) => {
        setSearchText(searchInput)
    }

    useEffect(() => {
        let DELAY_MS = 400
        const delayFn = setTimeout(() => handleSearchTextChange(searchInput), DELAY_MS);
        return () => clearTimeout(delayFn);
    }, [searchInput]);

    // Setup Snackbar stuff
    
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false)
    const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>([]);
    const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(
        undefined
    )

    const handleSnackbarClose = () => {
        setOpenSnackBar(false)
    }

    const snackbarAction = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
    useEffect(() => {

      if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpenSnackBar(true);
        } else if (snackPack.length && messageInfo && openSnackBar) {
            // Close an active snack when a new one is added
            setOpenSnackBar(false);
        }
    }, [snackPack, messageInfo, openSnackBar]);


    const handleExited = () => {
        setMessageInfo(undefined)
    }
    
    // handle LoginPopUp stuff
    const [redirectUrl,setRedirectUrl] = useState<string>('/bookmark')

    const handleLoginPopUp = (link: string) => {
        setRedirectUrl(link)
        setOpenLoginPopUp(true)
    }
    
    const handleLoginPopUpFromBookmark = () => {
        setRedirectUrl('/dashboard')
        setOpenLoginPopUp(true)
    }   

    // render card
    useEffect(() => {

        async function setData(): Promise<void> {
            return new Promise((resolve) => {
                setTimeout(() => {
                resolve()
                }, 500); // Simulating an asynchronous operation with a delay
            });
        }
        const handleSetData = async () => {
            if(tabSelection === "dashboard"){
                setIsLoading(true)
                await setData()
                setDashboardMetadatas(prepBookmarkedDashboardMetadatas(dashboardMetadatasFull, searchText, activeTag, sorting))
                setIsLoading(false)
            } else {
                setIsLoading(true)
                await setData()
                setInfographicMetadatas(prepBookmarkInfographicMetadata(infographicMetadatasFull, searchText, activeTag, sorting))
                setIsLoading(false)
            }
        }
        if(isParamsSet){
            handleSetData()
        }
         
    },[searchText, activeTag, sorting, tabSelection])


    const useQuery = () => {
        const { search } = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search])
    }

    let all_query = useQuery()
    useEffect(() => {
        let tabSelectionQuery = all_query.get("tabSelection")

        if(tabSelectionQuery){
            if(tabSelectionQuery === 'dashboard'){
                setTabSelection("dashboard")
            } else if(tabSelectionQuery === 'infographic'){
                setTabSelection("infographic")
            }
        }

        let sortingQuery = all_query.get("sortBy")

        if(sortingQuery){
            let matchSorting = sortingTypeItems.find(({english}) => english === sortingQuery)
            if(matchSorting){
                setSorting(matchSorting.thai)
            }
        }
    },[])

    useEffect(() => {
        let queryList = [`?tabSelection=${tabSelection}`]
        if (activeTag !== "ทั้งหมด"){
            let matchCategory = dashboard_tag.find(({category_thai}) => category_thai === activeTag)
            if(matchCategory){
                queryList.push(`category=${matchCategory.category_eng}`)
            }
        }
        if (sorting !== 'ความนิยม'){
            let matchSorting = sortingTypeItems.find(({thai}) => sorting === thai)
            if(matchSorting){
                queryList.push(`sortBy=${matchSorting.english}`)
            }
        }
        history.push({
            pathname: '/bookmark',
            search: queryList.join('&')
        })
    },[tabSelection, activeTag, sorting])

  return (
    <Container>
        <LogInPopUp 
        open={openLoginPopUp} 
        setOpen={(bool) => {
            setOpenLoginPopUp(bool)
            window.location.href = "/"
        }} redirect_uri={redirectUrl} />
        <ThemeProvider theme={theme}>

            <Box className = {style.title}>รายการที่ติดตาม</Box>
            <FilterBar 
            activeTag={activeTag}
            setActiveTag= {handleTagChange}
            searchText={searchInput}
            setSearchText={setSearchInput}
            activeTagList={activeTagList}
            />

            <Tabs value={tabSelection} 
            onChange={(e, newValue) => {
                if(["dashboard","infographic"].includes(newValue)){
                    setTabSelection(newValue)
                }
            }}
            sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                <Tab disableTouchRipple label="แดชบอร์ด" value="dashboard" sx = {{fontFamily: 'Kanit'}}/>
                <Tab disableTouchRipple label="อินโฟกราฟิก" value="infographic" sx = {{fontFamily: 'Kanit'}}/>
                <Box margin='auto 0 auto auto'>
                    <SortingMenu />
                </Box>
            </Tabs>

            <Grid container spacing={3} marginBottom={5}>
            {
                tabSelection === "dashboard" ? 
                <>
                    {
                        isLoading ? 
                        [1,2,3,4,5,6].map(() => (
                            <Grid item md={4} sm={6} xs={12}>
                                <DashboardSkeleton />
                            </Grid>
                        ))
                        :
                        dashboardMetadatas.length > 0 ?

                        dashboardMetadatas.slice(offset, offset + PAGE_SIZE).map((dashboardMetadata) => (
                            <Grid item md={4} sm={6} xs={12}>
                                <DashboardCard
                                key={dashboardMetadata.galleryId}
                                dashboardMetadata={dashboardMetadata}
                                searchText={searchText}
                                activeTag={activeTag}
                                setOpenSnackBar={setOpenSnackBar}
                                setSnackPack={setSnackPack}
                                handleLoginPopUp={handleLoginPopUp}
                                handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                                pathname='/bookmark'
                                />
                            </Grid>
                        ))
                        :
                        <Grid item className={style.notFound} md={12} sm={12} xs={12} marginY='50px'>
                            {
                                activeTag === "ทั้งหมด" ? 
                                <>ไม่พบแดชบอร์ดที่ท่านค้นหา</>
                                :
                                <>
                                    ไม่พบแดชบอร์ดที่ท่านค้นหาใน<span style={{fontWeight:"500", whiteSpace:'nowrap'}}>หมวดหมู่{activeTag}</span>
                                    <br />
                                    กรุณาลองค้นหาใน
                                    <span 
                                    style={{
                                        fontWeight:"500", 
                                        textDecoration:'underline', 
                                        cursor:'pointer',
                                        color: "#0182FA",
                                        whiteSpace:'nowrap'
                                    }}
                                    onClick={() => setActiveTag("ทั้งหมด")}>หมวดหมู่ทั้งหมด</span>อีกครั้ง
                                </>
                            }
                        </Grid>
                    }
                </>
                
            :
            <>
                {
                    isLoading ?
                    [1,2,3,4,5,6].map(() => (
                        <Grid item md={4} sm={6} xs={12}>
                            <InfographicCardSkeleton />
                        </Grid>
                    ))
                    :
                    infographicMetadatas.length > 0 ?

                    infographicMetadatas.map((infographicMetadata) => 
                        <Grid item md={4} sm={6} xs={12}>
                            <InfographicCard
                            infographicMetadata={infographicMetadata}
                            searchText={searchText}
                            setOpenSnackBar={setOpenSnackBar}
                            setSnackPack={setSnackPack}
                            handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                            pathname='/bookamrk'
                            />
                        </Grid>
                    )
                    :
                    <Grid item className={style.notFound}  md={12} sm={12} xs={12} marginY='50px'>
                        {
                            activeTag === "ทั้งหมด" ? 
                            <>ไม่พบอินโฟกราฟิกที่ท่านค้นหา</>
                            :
                            <>
                                ไม่พบอินโฟกราฟิกที่ท่านค้นหาใน<span style={{fontWeight:"500", whiteSpace:'nowrap'}}>หมวดหมู่{activeTag}</span>
                                <br />
                                กรุณาลองค้นหาใน
                                <span 
                                style={{
                                    fontWeight:"500", 
                                    textDecoration:'underline', 
                                    cursor:'pointer',
                                    color: "#0182FA",
                                    whiteSpace:'nowrap'
                                }}
                                onClick={() => setActiveTag("ทั้งหมด")}>หมวดหมู่ทั้งหมด</span>อีกครั้ง
                            </>
                        }
                    </Grid>
                }
            
            </>
            
        }

            </Grid>
            
            <PaginationElement 
            noItems={dashboardMetadatas.length}
            pageSize={PAGE_SIZE}
            pageNo={Math.round(offset/PAGE_SIZE + 1)}
            handlePaginationChange={handlePaginationChange}
            />

            <Snackbar
            key={messageInfo ? messageInfo.key : undefined}
            TransitionProps={{ onExited: handleExited }}
            message={messageInfo ? messageInfo.message : undefined}
            open={openSnackBar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            action={snackbarAction}/>

            
        </ThemeProvider>
    </Container>
  )
}

export default BookmarkPage