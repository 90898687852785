export const forgot_validate = (values : {email: string}) => {
    let errors: {email?: string} = {}

    if (!values.email) {
        errors.email = "กรุณากรอกอีเมล"
    } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            values.email
        )
    ) {
        errors.email = "กรุณากรอกอีเมลที่ถูกต้อง"
    }
    return errors
}