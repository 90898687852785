import React, {useState, useEffect} from 'react'
import Box from "@mui/material/Box"
import './Loading.css'
import { useTitle } from 'react-use';

const Loading: React.FC = () => {
    const [dots, setDots] = useState('...');
    useTitle('Travel Link | Loading')

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (dots === '...') {
        setDots('');
      } else if (dots === '') {
        setDots('.');
      } else if (dots === '.') {
        setDots('..');
      } else if (dots === '..') {
        setDots('...');
      }
    }, 500); // Change the interval duration (in milliseconds) as per your preference

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  }, [dots]);
    return (
        <div>
            <Box textAlign='center' >
                <h1 className='not-found-text'> Loading{dots}</h1>
            </Box>



        </div>
    )
}

export default Loading
