import React, { useMemo, useState, useEffect } from 'react'
import {
  ThemeProvider,
  createTheme,
  TextField,
  Grid,
  Card,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  Radio,
  Box,
  FormControl,
  Autocomplete,
  InputAdornment,
  IconButton,
  Checkbox,
  Typography,
} from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { Formik, FormikHelpers } from 'formik'
import style from './RegisterMemberMOU.module.css'
import CustomContainer from '../../components/shared/CustomContainer/CustomContainer'
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { mergeThemes } from '../../theme/merge';
import { register_textbox_theme } from '../../theme/register';
import { autocomplete_theme, select_theme } from '../../theme/registration'
import { textbox_theme } from '../../theme/textbox';
import { registernew_theme, form_radio_gov_theme } from '../../theme/registernew'
import { typography_theme } from '../../theme/typography'
import TVLButton from '../../components/shared/TVLButton/TVLButton';
import { RegisterMouForm, mouNewOrgValidate } from '../../validation/register'
import ErrorElement from '../../components/shared/ErrorElement/ErrorElement'
import ResponsePopUp from '../../components/shared/ResponsePopUp/ResponsePopUp'
import { getOrg, registerTvlMou, registerTvlMouOther } from '../../services/data-connector'
import { Organization } from '../../interfaces/Register'
import { ScrollToFieldError } from '../../components/shared/ScrollToFieldError/ScrollToFieldError'
import TextRequired from '../../components/shared/TextRequired/TextRequired'
import AgreementPopUp from '../../components/shared/AgreementPopUp/AgreementPopUp'
import { useLocation } from 'react-use'
import { useHistory } from 'react-router-dom'
import { joinWithCommaAnd } from '../../utils/register'
import {ReactComponent as ErrorSign} from '../../assets/register-member/Error.svg'

const RegisterMemberMOU = () => {
  const mergedTheme = mergeThemes(register_textbox_theme, textbox_theme, registernew_theme,typography_theme, form_radio_gov_theme, select_theme)
  const theme = createTheme(mergedTheme)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showPassword2, setShowPassword2] = useState<boolean>(false)
  const [errorForm, setErrorForm] = useState<string>('')
  const [responseStatus, setResponseStatus] = useState<'' | 'email_partner'>('')
  const [response, setResponse] = useState<React.ReactNode>(<></>)
  const [email, setEmail] = useState<string>('')
  const [orgList, setOrgList] = useState<Organization[]>([])
  const [domain, setDomain] = useState<string[]>([])
  const [agreementNo, setAgreementNo] = useState<number>(-1)
  const [ErrorSummary, setErrorSummary] = useState<React.ReactNode>(<></>)

  const year_list: string[] = []
  for (let i = 2565; i > 2460; i--) {
      year_list.push(String(i))
  }

  const [initialValues, setInitialValues] = useState<RegisterMouForm>({
    firstName: '',
    lastName: '',
    organization: null,
    orgAbb: '',
    newOrg: '',
    position: '',
    email: '',
    phoneNo: '',
    gender: '',
    password: '',
    password2: '',
    yearOfBirth: null,
    checkBox: false,
  })
  
  const [initialTouched, setInitialTouched] = useState({
    firstName: false,
    lastName: false,
    organization: false,
    position: false,
    email: false,
    phoneNo: false,
    gender: false,
    password: false,
    password2: false,
    yearOfBirth: false,
    checkBox: false
  })

  useEffect(() => {
    const fetchData = async () => {
        const { res } = await getOrg()
        let orgListTmp: Organization[] = res["orgList"].filter((orgTmp: Organization) => orgTmp.orgType === "หน่วยงาน")
        orgListTmp.sort((a,b) => a.orgName.localeCompare(b.orgName,"th"))
        orgListTmp.push({
          orgAbb: "other",
          orgEmails: ['.go.th', '.or.th', '.ac.th', '.mi.th'],
          orgName:'อื่น ๆ',
          orgType: 'หน่วยงาน'
        })
        setOrgList(orgListTmp)
    }

      fetchData()
  },[])

  const handleSubmit  = async (values: RegisterMouForm, action: FormikHelpers<RegisterMouForm>) => {
    setIsLoading(true)
    let {phoneNo, ...payload} = values
    if(phoneNo){
      payload = values
    }
    let formSubmission: (data: any) => Promise<{
        success: boolean;
        res: any;
    }> = registerTvlMou
    if(values.organization === "อื่น ๆ"){
      formSubmission = registerTvlMouOther
    } 

    let {success, res} = await formSubmission(payload)

    if(success){
      setEmail(values.email)
      setResponseStatus('email_partner')
    } else if(res.detail === 'The email has already been registered.'){
        action.setFieldError('email','อีเมลนี้ลงทะเบียนไปแล้ว กรุณาใช้อีเมลอื่นในการสมัคร')
        setErrorForm('อีเมลนี้ลงทะเบียนไปแล้ว กรุณาใช้อีเมลอื่นในการสมัคร')

        // scroll to email field
        let emailElement = document.querySelector(`input[name='email']`)
        emailElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    } else{
      setErrorForm(res.detail)
    }
    

    setIsLoading(false)


  }

  
  const response_element = (email?:string) => {
    if(responseStatus === ''){
      return <></>
    } else if(responseStatus === 'email_partner'){
      return (
        <ResponsePopUp 
        open
        setOpen={() => {window.location.href='/'}} 
        status='email'
        title= {`กรุณาตรวจสอบอีเมล`}
        email = {email}
        text1='ข้อมูลการลงทะเบียนของท่านได้ถูกจัดเก็บเข้าระบบเรียบร้อยแล้ว'
        text2='กรุณาตรวจสอบอีเมลของท่านเพื่อทำการยืนยันตัวตน'
        button='กลับสู่หน้าหลัก'
        handleButton={() => {window.location.href = '/'}}/>
      )
    }
  }

  useEffect(() => {
    setResponse(response_element(email))
  },[responseStatus])

  

  const extended_validation = (domain: string[]) => (value: RegisterMouForm) => {
    let errors = mouNewOrgValidate(value)

    if (domain.length === 0){
      return errors
    } else if(errors.email){
      return errors
    } else {
      let domainFound = false;

    domain.forEach((dom) => {
      if (value.email.endsWith(dom)) {
        domainFound = true;
      }
    });

    if (domainFound) {
      return errors;
    } else {
      return {
        ...errors,
        email:'กรุณากรอกอีเมลของหน่วยงานที่ท่านใช้ (ลงท้ายด้วย ' + joinWithCommaAnd(domain) + ')'
      }
    }
  }
}
  useEffect(() => {
    let errorElement = errorForm ? 
    (<div style ={{marginBottom:'10px'}}>
      <ErrorElement errorMessage={errorForm}/>
    </div>) : (<></>)
    setErrorSummary(errorElement)
  }
  ,[errorForm])

  // for autocomplete
  const filter = createFilterOptions<string>();

  return (
    <CustomContainer>
      <AgreementPopUp open={agreementNo} setOpen={setAgreementNo} />
      {response}
      <ThemeProvider theme = {theme}>
      <div className = {style.title}>ลงทะเบียนสมาชิกจากภาครัฐ</div>
      <div className={style.subtitle}>กรอกข้อมูลลงทะเบียนเพิ่มเติม</div>
      <Box sx = {{padding:{md: '0 30px', sm: '0 30px', xs: '0'}, marginBottom: '100px'}}>
        <Formik
        enableReinitialize
        initialValues={initialValues}
        initialTouched={initialTouched}
        validate={extended_validation(domain)}
        onSubmit = {(values, action) => {
          handleSubmit(values,action)
        }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            setFieldError,
          }) => (
            <form onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <div className={style.header}>
                  ข้อมูลสำหรับเข้าใช้งาน
                </div>
                <div style={{ flex: 1, backgroundColor: '#ABABAB', height: '1px', margin: '0 10px' }}></div>
              </div>

              <Grid container columnSpacing={5} rowSpacing={4} sx={{marginBottom:{md:'70px', sm:'70px', xs:'50px'}}}>
            <Grid item md={6} sm={6} xs={12}>
            <div className={style.subheader}>ข้อมูลสำหรับเข้าใช้งาน</div>
              <Card sx={{height:"90%"}}>
                <div className={style.label}>อีเมลหน่วยงาน/องค์กร<TextRequired /></div>
                  <TextField 
                  name="email"
                  value={values.email}
                  onChange = {handleChange}
                  onBlur = {handleBlur}
                  error={!!errors.email && touched.email}
                  helperText={!!errors.email && touched.email ? <ErrorElement errorMessage={errors.email} />: <></>}    
                  placeholder='อีเมล'
                  fullWidth/>
                  
                  <div className={style.label}>
                    <span>
                      ตั้งค่ารหัสผ่าน<TextRequired />
                    </span>
                  </div>
                  <TextField 
                  name="password"
                  value={values.password}
                  onChange = {handleChange}
                  onBlur = {handleBlur}
                  error={!!errors.password && touched.password}
                  helperText={!!errors.password && touched.password ? <ErrorElement errorMessage={errors.password} />: <></>}    
                  placeholder='รหัสผ่าน'
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                    setShowPassword(
                                        !showPassword
                                    )
                                }
                                edge="end"
                            >
                                {showPassword ? (
                                    <VisibilityOffIcon sx={{marginRight: '7px'}} />
                                ) : (
                                    <VisibilityIcon sx={{marginRight: '7px'}} />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                  fullWidth/>

                <div className={style.label}>
                  <span>
                    ยืนยันรหัสผ่าน<TextRequired />
                  </span>
                </div>
                <TextField 
                name="password2"
                value={values.password2}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.password2 && touched.password2}
                helperText={!!errors.password2 && touched.password2 ? <ErrorElement errorMessage={errors.password2} />: <></>}    
                placeholder='รหัสผ่าน'
                type={showPassword2 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                  setShowPassword2(
                                      !showPassword2
                                  )
                              }
                              edge="end"
                          >
                              {showPassword2 ? (
                                  <VisibilityOffIcon sx={{marginRight: '7px'}} />
                              ) : (
                                  <VisibilityIcon sx={{marginRight: '7px'}} />
                              )}
                          </IconButton>
                      </InputAdornment>
                  ),
              }}
                fullWidth/>
                </Card>
              
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <div className={style.subheader}>ข้อมูลหน่วยงาน/องค์กร</div>
                <Card sx={{height:{md:"90%", sm: "90%", xs: 'inherit'}}}>
                  <div className={style.label}>หน่วยงาน/องค์กร<TextRequired /></div>

                  <ThemeProvider theme={createTheme(autocomplete_theme)}>
                    <Autocomplete
                      disablePortal
                      value={values.organization} 
                      options={orgList.map((orgTmp) => orgTmp.orgName)}
                      onBlur={(e) => {handleBlur(e); setFieldTouched('organization',false)}  }
                      onChange={async (event: any, newValue: string | null) => {
                          setFieldValue('organization', newValue)
                          let matchOrg = orgList.find(({orgName}) => orgName === newValue)
                          if(newValue == "อื่น ๆ"){
                            if(!newValue.startsWith(searchText) && searchText !== "อื่นๆ"){ // if user intends to select อื่น ๆ by searching other words, auto fill the option. 
                              setFieldValue("newOrg", searchText)
                            }
                            setFieldTouched('newOrg', false)
                            setFieldTouched('orgAbb', false)
                          } else if (newValue === null){
                            setSearchText("")
                            setDomain([])
                            setFieldTouched('organization', false)
                            setFieldValue("newOrg", "")
                            setFieldValue("orgAbb", "")
                        }
                          if(matchOrg){
                            setDomain(matchOrg.orgEmails)
                            setFieldTouched('organization', false)
                          } 
                          setFieldTouched('email', true)
                          setFieldError('email',"")
                          setFieldError("organization", "")
                      }}
                      fullWidth
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => option.includes(inputValue));
                        if (inputValue !== '' && !isExisting) {
                          filtered.push("อื่น ๆ");
                        }
                
                        return filtered;
                      }}
                      
                      renderInput={(params) => 
                          <TextField  
                          {...params} 
                          name="organization" 
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          placeholder="เลือกหน่วยงาน หรือองค์กร"
                          error={touched.organization && !!errors.organization}
                          helperText={
                              touched.organization && !!errors.organization ?
                              <ErrorElement errorMessage={errors.organization} /> :  
                              !values.organization ?
                              <div style = {{marginTop: '10px'}}>
                              <ThemeProvider theme = {typography_theme}>
                                <Typography variant = 'formHelperText' color = 'primary'>
                                  <ErrorSign style = {{width: '20px', height:"20px", verticalAlign: 'top', marginRight: '10px', fill: "#0182FA"}} />{"หากในรายการไม่มีหน่วยงาน/องค์กรของท่าน กรุณาเลือก “อื่น ๆ”"}
                              
                                </Typography>
                        
                              </ThemeProvider>
                              
                              </div> 
                              :
                              <></>
                          }
                          sx={{
                              marginTop: '0'

                          }}
                          />}
                      />

                  </ThemeProvider>
                  
                  {
                    values.organization === 'อื่น ๆ' ?
                    <>
                        <Box height='20px'></Box>
                        <TextField 
                        name="newOrg"
                        value={values.newOrg}
                        onChange = {handleChange}
                        onBlur = {handleBlur}
                        error={!!errors.newOrg && touched.newOrg}
                        helperText={!!errors.newOrg && touched.newOrg ? <ErrorElement errorMessage={errors.newOrg} />: <></>}
                        placeholder='ชื่อหน่วยงาน/องค์กร'
                        fullWidth/>

                        <Box height='5px'></Box>

                        <TextField 
                        name="orgAbb"
                        value={values.orgAbb}
                        onChange = {handleChange}
                        onBlur = {handleBlur}
                        error={!!errors.orgAbb && touched.orgAbb}
                        helperText={!!errors.orgAbb && touched.orgAbb ? <ErrorElement errorMessage={errors.orgAbb} />: <></>}
                        placeholder='ชื่อย่อหน่วยงาน/องค์กร'
                        fullWidth/>
                    </>
                    :
                    <></>
                  }
                  

                  <div className={style.label}>ตำแหน่ง<TextRequired /></div>
                  <TextField 
                  name="position"
                  value={values.position}
                  onChange = {handleChange}
                  onBlur = {handleBlur}
                  error={!!errors.position && touched.position}
                  helperText={!!errors.position && touched.position ? <ErrorElement errorMessage={errors.position} />: <></>}
                  placeholder='ตำแหน่ง'
                  fullWidth/>

                  </Card>
              </Grid>
            </Grid>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '25px', marginBottom:'10px'}}>
              <div className={style.header}>
                ข้อมูลผู้สมัครใช้งาน
              </div>
              <div style={{ flex: 1, backgroundColor: '#ABABAB', height: '1px', margin: '0 10px' }}></div>
            </div>
            
            <div className={style.subheader}>รายละเอียดผู้สมัครใช้งาน</div>
            <Card>
            <Grid container columnSpacing={9}>

            <Grid item md={6} sm={6} xs={12} >

                <div className={style.label} >ชื่อ<TextRequired /></div>
                <TextField
                name="firstName"
                value={values.firstName}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.firstName && touched.firstName}
                helperText={!!errors.firstName && touched.firstName ? <ErrorElement errorMessage={errors.firstName} />: <></>}     
                placeholder='ชื่อ'
                fullWidth/>

                <div className={style.label}>นามสกุล<TextRequired /></div>
                <TextField
                name="lastName"
                value={values.lastName}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.lastName && touched.lastName}
                helperText={!!errors.lastName && touched.lastName ? <ErrorElement errorMessage={errors.lastName} />: <></>}     
                placeholder='นามสกุล'
                fullWidth/>
                
                <Box display={{md:'flex', sm:'flex', xs:'block'}} justifyContent={{md:"flex-start", sm:"flex-start"}} margin={{xs: "15px 0", sm: "15px 0 0 0", md: "15px 0 0 0"}}>
                  <Box className={style.label} sx={{ margin: '9px 10px auto 0 !important'}}>เพศ<TextRequired /></Box>
                  <RadioGroup
                      row
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                  >
                      <FormControlLabel
                          control={<Radio disableRipple />}
                          value="male"
                          label="ชาย"
                      />
                      <FormControlLabel
                          control={<Radio disableRipple />}
                          value="female"
                          label="หญิง"
                      />
                      
                      <FormControlLabel
                          control={<Radio disableRipple />}
                          value="others"
                          label="ไม่ระบุ"
                      />
                  </RadioGroup>

                </Box>
                

                {touched.gender && errors.gender ? (
                    <FormHelperText
                        error
                        sx={{
                            paddingLeft: "15px",
                            marginTop: "-10px",
                            marginBottom:
                                "10px !important",
                        }}
                    >
                        {
                            <ErrorElement
                                errorMessage={
                                    errors.gender
                                }
                            />
                        }
                    </FormHelperText>
                ) : (
                    <></>
                )}
                
              </Grid>
              <Grid item md={6} sm={6} xs={12} container direction='column' display='flex'>

                <div className={style.label}>เบอร์โทรศัพท์มือถือ</div>
                <TextField 
                name="phoneNo"
                value={values.phoneNo}
                onChange = {handleChange}
                onBlur = {handleBlur}
                error={!!errors.phoneNo && touched.phoneNo}
                helperText={!!errors.phoneNo && touched.phoneNo ? <ErrorElement errorMessage={errors.phoneNo} />: <></>}    
                placeholder='เบอร์โทรศัพท์มือถือ'
                fullWidth/>


                <div className={style.label}>ปีเกิด<TextRequired /></div>
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={values.yearOfBirth} 
                options={year_list}
                onChange={(event: any, newValue: string | null) => {
                    setFieldValue('yearOfBirth', newValue)
                  }}
                fullWidth
                renderInput={(params) => 
                    <TextField  
                    {...params} 
                    placeholder='ปีเกิด (พ.ศ.)'
                    name="alliance" 
                    error={touched.yearOfBirth && !!errors.yearOfBirth}
                    helperText={
                        touched.yearOfBirth && !!errors.yearOfBirth ?
                        <ErrorElement errorMessage={errors.yearOfBirth} /> : <></>
                    }
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline legend':{
                            marginLeft: '10px'
                        }

                    }}
                    />}
                    sx={{
                      '.MuiInputBase-root':{
                        paddingLeft:'24px',
                        paddingTop: '6px',
                        paddingBottom: '6px'
                      }
                    }}
                />
                
                
                  </Grid>
                </Grid>
              
              </Card>


            <FormControl sx={{left:'0', right:'0', margin:'35px auto'}}>
              <FormControlLabel control={
                <Checkbox 
                disableRipple
                name = "checkBox" 
                value={values.checkBox} 
                onChange={(e) => {
                  setFieldValue('checkBox', e.target.checked)
                  setErrorForm('')
                }}/>} 
                label={
                  <Box textAlign='left'>ท่านได้ทำการอ่าน และยอมรับ 
                  <Typography variant='ahref' sx={{margin:'0 5px', fontWeight:'400'}} onClick={(e) => {e.preventDefault();setAgreementNo(0)}}>Privacy Policy</Typography>
                และ  
                <Typography variant='ahref' sx={{margin:'0 5px', fontWeight:'400'}} onClick={(e) => {e.preventDefault();setAgreementNo(1)}}>Terms & Condition</Typography> แล้ว </Box>
                } 
                sx={{
                  alignItems: 'flex-start',
                  '& .MuiCheckbox-root':{
                    paddingTop:'0'
                  }
                }}/>
              </FormControl>
            <div>
              {ErrorSummary}
            <TVLButton type = 'submit' size = 'large' 
            isLoading={isLoading}
            onMouseDown={(event:React.MouseEvent<HTMLButtonElement>)=>{event.preventDefault()}}
            onClick={() =>{
              if(!values.checkBox && Object.keys(errors).length === 1){
                setErrorForm(errors.checkBox!)
              }
              
            }
              
            }>ลงทะเบียน</TVLButton>

            </div>
            </form>
          
          )}
        </Formik>

        


      </Box>
      
    </ThemeProvider>

    </CustomContainer>
    
    
  )
}

export default RegisterMemberMOU