import React from 'react'
import Grid from '@mui/material/Grid';

import FooterAddress from './FooterAddress/FooterAddress'
import FooterProduct from './FooterProduct/FooterProduct'
import FooterUtility from './FooterFeatures/FooterFeatures'
import FooterAboutUs from './FooterAboutUs/FooterAboutUs'

import FooterModule from './Footer.module.css'

const Footer: React.FC = () => {
    return (
        <div className={FooterModule.wrapper}>
            {/* <Container> */}
            <Grid container spacing={2} rowSpacing='30px'>
                <Grid item md={1} sx = {{display: {sm: 'none', xs: 'none', md:'block'}}} />
                <Grid item xs={8} sm={12} md={4}>
                    <FooterAddress />
                </Grid>
                <Grid item xs={8} sm={4} md={2}>
                    <FooterProduct />
                </Grid>
                <Grid item xs={8} sm={4} md={2}>
                    <FooterUtility />
                </Grid>
                <Grid item xs={8} sm={4} md={2}>
                    <FooterAboutUs />
                </Grid>
            </Grid>
                < div className={FooterModule.copyright}>COPYRIGHT © {new Date().getFullYear()} Travel Link</div>
            {/* </Container> */}
        </div>

    )
}

export default Footer
