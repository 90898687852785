export const allProvinces = [
    { pageName: 'กรุงเทพมหานคร', pageNameEng: 'Bangkok' },
    { pageName: 'ภูเก็ต', pageNameEng: 'Phuket' },
    { pageName: 'กระบี่', pageNameEng: 'Krabi' },
    { pageName: 'กาญจนบุรี', pageNameEng: 'Kanchanaburi' },
    { pageName: 'กาฬสินธุ์', pageNameEng: 'Kalasin' },
    { pageName: 'กำแพงเพชร', pageNameEng: 'Kamphaeng Phet' },
    { pageName: 'เกาะสมุย', pageNameEng: 'Ko Samui' },
    { pageName: 'ขอนแก่น', pageNameEng: 'Khon Kaen' },
    { pageName: 'จันทบุรี', pageNameEng: 'Chanthaburi' },
    { pageName: 'ฉะเชิงเทรา', pageNameEng: 'Chachoengsao' },
    { pageName: 'ชลบุรี', pageNameEng: 'Chonburi' },
    { pageName: 'ชัยนาท', pageNameEng: 'Chai Nat' },
    { pageName: 'ชัยภูมิ', pageNameEng: 'Chaiyaphum' },
    { pageName: 'ชุมพร', pageNameEng: 'Chumphon' },
    { pageName: 'เชียงราย', pageNameEng: 'Chiang Rai' },
    { pageName: 'เชียงใหม่', pageNameEng: 'Chiang Mai' },
    { pageName: 'ตรัง', pageNameEng: 'Trang' },
    { pageName: 'ตราด', pageNameEng: 'Trat' },
    { pageName: 'ตาก', pageNameEng: 'Tak' },
    { pageName: 'นครนายก', pageNameEng: 'Nakhon Nayok' },
    { pageName: 'นครปฐม', pageNameEng: 'Nakhon Pathom' },
    { pageName: 'นครพนม', pageNameEng: 'Nakhon Phanom' },
    { pageName: 'นครราชสีมา', pageNameEng: 'Nakhon Ratchasima' },
    { pageName: 'นครศรีธรรมราช', pageNameEng: 'Nakhon Si Thammarat' },
    { pageName: 'นครสวรรค์', pageNameEng: 'Nakhon Sawan' },
    { pageName: 'นนทบุรี', pageNameEng: 'Nonthaburi' },
    { pageName: 'นราธิวาส', pageNameEng: 'Narathiwat' },
    { pageName: 'น่าน', pageNameEng: 'Nan' },
    { pageName: 'บุรีรัมย์', pageNameEng: 'Buriram' },
    { pageName: 'ปทุมธานี', pageNameEng: 'Pathum Thani' },
    { pageName: 'ประจวบคีรีขันธ์', pageNameEng: 'Prachuap Khiri Khan' },
    { pageName: 'ปราจีนบุรี', pageNameEng: 'Prachinburi' },
    { pageName: 'ปัตตานี', pageNameEng: 'Pattani' },
    {
      pageName: 'พระนครศรีอยุธยา',
      pageNameEng: 'Phra Nakhon Si Ayutthaya'
    },
    { pageName: 'พะเยา', pageNameEng: 'Phayao' },
    { pageName: 'พังงา', pageNameEng: 'Phang Nga' },
    { pageName: 'พัทลุง', pageNameEng: 'Phatthalung' },
    { pageName: 'พิจิตร', pageNameEng: 'Phichit' },
    { pageName: 'พิษณุโลก', pageNameEng: 'Phitsanulok' },
    { pageName: 'เพชรบุรี', pageNameEng: 'Phetchaburi' },
    { pageName: 'เพชรบูรณ์', pageNameEng: 'Phetchabun' },
    { pageName: 'แพร่', pageNameEng: 'Phrae' },
    { pageName: 'มหาสารคาม', pageNameEng: 'Maha Sarakham' },
    { pageName: 'มุกดาหาร', pageNameEng: 'Mukdahan' },
    { pageName: 'แม่ฮ่องสอน', pageNameEng: 'Mae Hong Son' },
    { pageName: 'ยโสธร', pageNameEng: 'Yasothon' },
    { pageName: 'ยะลา', pageNameEng: 'Yala' },
    { pageName: 'ร้อยเอ็ด', pageNameEng: 'Roi Et' },
    { pageName: 'ระนอง', pageNameEng: 'Ranong' },
    { pageName: 'ระยอง', pageNameEng: 'Rayong' },
    { pageName: 'ราชบุรี', pageNameEng: 'Ratchaburi' },
    { pageName: 'ลพบุรี', pageNameEng: 'Lopburi' },
    { pageName: 'ลำปาง', pageNameEng: 'Lampang' },
    { pageName: 'ลำพูน', pageNameEng: 'Lamphun' },
    { pageName: 'เลย', pageNameEng: 'Loei' },
    { pageName: 'ศรีสะเกษ', pageNameEng: 'Sisaket' },
    { pageName: 'สกลนคร', pageNameEng: 'Sakon Nakhon' },
    { pageName: 'สงขลา', pageNameEng: 'Songkhla' },
    { pageName: 'สตูล', pageNameEng: 'Satun' },
    { pageName: 'สมุทรปราการ', pageNameEng: 'Samut Prakan' },
    { pageName: 'สมุทรสงคราม', pageNameEng: 'Samut Songkhram' },
    { pageName: 'สมุทรสาคร', pageNameEng: 'Samut Sakhon' },
    { pageName: 'สระแก้ว', pageNameEng: 'Sa Kaeo' },
    { pageName: 'สระบุรี', pageNameEng: 'Saraburi' },
    { pageName: 'สิงห์บุรี', pageNameEng: 'Sing Buri' },
    { pageName: 'สุโขทัย', pageNameEng: 'Sukhothai' },
    { pageName: 'สุพรรณบุรี', pageNameEng: 'Suphan Buri' },
    { pageName: 'สุราษฎร์ธานี', pageNameEng: 'Surat Thani' },
    { pageName: 'สุรินทร์', pageNameEng: 'Surin' },
    { pageName: 'หนองคาย', pageNameEng: 'Nong Khai' },
    { pageName: 'หนองบัวลำภู', pageNameEng: 'Nong Bua Lam Phu' },
    { pageName: 'อ่างทอง', pageNameEng: 'Ang Thong' },
    { pageName: 'อำนาจเจริญ', pageNameEng: 'Amnat Charoen' },
    { pageName: 'อุดรธานี', pageNameEng: 'Udon Thani' },
    { pageName: 'อุตรดิตถ์', pageNameEng: 'Uttaradit' },
    { pageName: 'อุทัยธานี', pageNameEng: 'Uthai Thani' },
    { pageName: 'อุบลราชธานี', pageNameEng: 'Ubon Ratchathani' }
  ]

export const DEFAULT_OPTION_LIST: readonly string[] = allProvinces.flatMap(({ pageName, pageNameEng }) => [pageName, pageNameEng])